import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../components/firebase/auth";
import { useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import MainIcon from "../../components/ui/img/MainIcon";
import { Helmet } from "react-helmet-async";
import { useThemeState } from "../../components/utils/globalStates";
import "./Auth.css";

export default function AuthStart() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const navigate = useNavigate();
  const globalTheme = useThemeState((state) => state.globalTheme);

  useEffect(() => {
    if (user !== null) {
      return navigate("/");
    }
  }, []);

  const styles = {
  button: {
    width: "100%",
    marginTop: "25px",
    borderRadius: "50px",
    backgroundColor: globalTheme.buttonBackground.backgroundColor,
    marginBottom: "25px",
    color: globalTheme.textColor.color,
    textTransform: "none",
    boxShadow: globalTheme.boxShadow.boxShadow,
    border: "none",
    "&:hover": {
      backgroundColor: globalTheme.buttonBackground.backgroundColor,
      color: globalTheme.textColor.color,
      opacity: 0.96,
      boxShadow: globalTheme.boxShadow.boxShadow,
      border: "none",
    },
  },
  iconButton: {
    borderRadius: "50px",
    backgroundColor: globalTheme.buttonBackground.backgroundColor,
    color: globalTheme.altIconColor.color,
    marginBottom: "25px",
    boxShadow: globalTheme.boxShadow.boxShadow,
    "&:hover": {
      backgroundColor: globalTheme.buttonBackground.backgroundColor,
      color: globalTheme.altIconColor.color,
      opacity: 0.96,
      boxShadow: globalTheme.boxShadow.boxShadow,
    },
  },
};


  return (
    <div className="LoginViewOuter" style={{ ...globalTheme.altBackground }}>
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Auth</title>
      </Helmet>
      <div className="LoginView">
        <div className="AuthIconStart">
          <MainIcon fillColor={globalTheme.solidMainBlack.color} />
        </div>
        <div className="AuthStartButtons">
          <Button
            sx={styles.button}
            size="large"
            color="inherit"
            variant="outlined"
            startIcon={<LoginIcon />}
            onClick={() => navigate("/auth/login")}
          >
            Log In
          </Button>
          <Button
            sx={styles.button}
            size="large"
            color="inherit"
            variant="outlined"
            startIcon={<HowToRegIcon />}
            onClick={() => navigate("/auth/register")}
          >
            Sign Up
          </Button>
        </div>
        <div className="AuthStartFooter">
          <IconButton
            sx={styles.iconButton}
            color="inherit"
            href="https://gath3r.co"
            target="_blank"
            rel="noopener noreferrer"
            size="large"
          >
            <HomeOutlinedIcon fontSize="large" />
          </IconButton>
        </div>
      </div>
    </div>
  );
}