import React, { useState } from "react";
import { doc, deleteDoc } from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";
import { useThemeState } from "../../../components/utils/globalStates";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import "./ListingItems.css";

export default function AdminNewsletterItem({ email }: any) {
  const [verifyDelete, setVerifyDelete] = useState(false);
  const globalTheme = useThemeState((state) => state.globalTheme);

  const onDelete = async () => {
    setVerifyDelete(false);
    const userRef = doc(firestoreDb, "newsletter", email);
    await deleteDoc(userRef);
  };
  return !verifyDelete ? (
    <div
      className="AdminNewsletterItem"
      style={{
        backgroundColor: globalTheme.boxBackgroundWhite.color,
        ...globalTheme.boxShadow,
        border: `1px solid ${globalTheme.solidMainBlack.color}`,
        color: globalTheme.textMainBlack.color,
      }}
    >
      <div className="AdminUserPreRegItemInner">
        <p className="AdminUserPreRegItemText">
          <b>Email: </b>
          {email}
        </p>
      </div>
      <IconButton
        aria-label="delete"
        color="inherit"
        sx={{ marginRight: "3%", color: globalTheme.colorPaletteRed.color }}
        size="small"
        onClick={() => setVerifyDelete(true)}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  ) : (
    <div
      className="AdminNewsletterItem"
      style={{
        backgroundColor: globalTheme.boxBackgroundWhite.color,
        ...globalTheme.boxShadow,
        border: `1px solid ${globalTheme.solidMainBlack.color}`,
        color: globalTheme.textMainBlack.color,
      }}
    >
      <div className="AdminUserPreRegItemInner">
        <p className="AdminUserPreRegItemText">
          Delete {email}?
        </p>
      </div>
      <>
        <Button
          size="small"
          sx={{
            marginRight: "5px",
            fontSize: "13px",
            "&:hover": {
              opacity: 0.9,
            },
          }}
          color="inherit"
          onClick={() => setVerifyDelete(false)}
        >
          Cancel
        </Button>
        <Button
          size="small"
          sx={{
            color: globalTheme.colorPaletteRed.color,
            marginRight: "10px",
            fontSize: "13px",
            "&:hover": {
              opacity: 0.9,
            },
          }}
          color="inherit"
          onClick={onDelete}
        >
          Delete
        </Button>
      </>
    </div>
  );
}
// issue test