import React, { useState, useEffect } from "react";

import { CircularProgress } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import Pagination from "@mui/material/Pagination";
import { Helmet } from "react-helmet-async";

// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Radio from "@mui/material/Radio";

import {
  useExploreSortState,
  useTabSettingsState,
  useHeaderSettingsState,
  useThemeState,
  useDeviceState
} from "../../components/utils/globalStates";

import NewListingBox from "../listings/new/NewListingBox";
import SearchAndExplore from "../../components/ui/topBoxes/SearchAndExplore";
import moment from "moment";
import {
  algoliaListingsRelevance,
  algoliaListingsCreatedAtDesc,
  algoliaListingsRatingDesc,
  algoliaListingsTrendingHourDesc,
  algoliaListingsViewsTotalDesc,
} from "../../components/search/algoliaSearch";
import ProjectListItem from "../../components/ui/listItems/ProjectListItem";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../components/firebase/auth";
import PickersCardView from "../../components/ui/pickers/PickerCardView";
import "./Explore.css";

moment().format();

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Explore() {
  const [user] = useAuthState(firebaseAuth);
  const globalTheme = useThemeState((state) => state.globalTheme);
  const exploreState = useExploreSortState((state) => state);
  const headerSettingsState = useHeaderSettingsState();
  const tabSettingsState = useTabSettingsState();
  const deviceState = useDeviceState();
  const [loading, setLoading] = useState(true);
  const [sortDialogOpen, setSortDialogOpen] = useState(false);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    exploreState.setPage(value - 1);
  };

  const fetchTrendingHourDesc = () => {
    setLoading(true);
    const fromDate = Date.now() - 60 * 60 * 1000 * 200;
    algoliaListingsTrendingHourDesc
      .search(exploreState.search, {
        hitsPerPage: exploreState.hitsPerPage,
        page: exploreState.page,
        numericFilters: [`trendingHourUpdated > ${fromDate}`],
        clickAnalytics: true,
        userToken: user?.uid,
      })
      .then((res: any) => {
        exploreState.setListingPages(res.nbPages);
        exploreState.setListingCount(res.nbHits);
        let tempArray: any[] = [];
        for (let i = 0; i < res.hits.length; i++) {
          const tempListing = res.hits[i];
          tempArray.push({ ...tempListing, searchId: res.queryID });
        }
        exploreState.setResults(tempArray);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setLoading(false);
      });
  };

  const fetchTemplate = (fetchObj: any) => {
    setLoading(true);
    fetchObj
      .search(exploreState.search, {
        hitsPerPage: exploreState.hitsPerPage,
        page: exploreState.page,
        clickAnalytics: true,
        userToken: user?.uid,
      })
      .then((res: any) => {
        exploreState.setListingPages(res.nbPages);
        exploreState.setListingCount(res.nbHits);
        let tempArray: any[] = [];
        for (let i = 0; i < res.hits.length; i++) {
          const tempListing = res.hits[i];
          tempArray.push({ ...tempListing, searchId: res.queryID });
        }
        exploreState.setResults(tempArray);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (exploreState.sortType === "rating") {
      fetchTemplate(algoliaListingsRatingDesc);
    } else if (exploreState.sortType === "trendingHour") {
      fetchTrendingHourDesc();
    } else if (exploreState.sortType === "viewsTotal") {
      fetchTemplate(algoliaListingsViewsTotalDesc);
    } else if (exploreState.sortType === "relevance") {
      fetchTemplate(algoliaListingsRelevance);
    } else {
      fetchTemplate(algoliaListingsCreatedAtDesc);
    }
  }, [
    exploreState.sortOrder,
    exploreState.sortType,
    exploreState.hitsPerPage,
    exploreState.page,
    exploreState.search,
  ]);

  useEffect(() => {
    headerSettingsState.setIsLogoOpen(true);
    headerSettingsState.setLogoBGColor(globalTheme.exploreBackground.color);
    headerSettingsState.setLogoColor(globalTheme.backgroundMainOffYellow.color);
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.exploreBackground.color);
    return () => {
      headerSettingsState.setIsLogoOpen(false);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
      tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  const styles = {
    dialogRoot: {
      zIndex: 9999,
      backgroundColor: "rgba(253, 247, 234, 0.5)",
      "& .MuiDialog-paper": {
        backgroundColor: globalTheme.exploreBackground.color,
        color: globalTheme.exploreBackgroundOff.color,
        width: "94%",
        maxWidth: "600px",
        margin: 0,
        paddingBottom: "70px",
        paddingTop: "20px",
        position: "absolute",
        bottom: "-10px",
      },
    },
    dialogTitle: {
      color: globalTheme.exploreBackgroundOff.color,
      textAlign: "center",
      fontSize: "16px",
      marginTop: "20px",
      fontWeight: 600,
    },
    radio: {
      color: globalTheme.exploreBackgroundOff.color,
      "&.Mui-checked": {
        color: globalTheme.exploreBackgroundOff.color,
      },
    },
  };

  return (
    <div
      className="Explore"
      style={{
        backgroundColor: globalTheme.exploreBackground.color,
        marginTop: "-10px",
      }}
    >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Explore</title>
      </Helmet>
      <SearchAndExplore />
      <div className="ExploreNewListingBox">
        <NewListingBox
          bgColorProps={globalTheme.figmaAlt2.color}
          alternativeDisplay={false}
        />
      </div>
      <div className="ExploreSort">
        <ButtonBase
          onClick={() => {
            setSortDialogOpen(true);
          }}
        >
          <div className="ExploreSortInner">
            <SwapVertOutlinedIcon
              sx={{
                color: globalTheme.exploreBackgroundOff.color,
                fontSize: "17px",
                "@media (min-width: 350px)": {
                  fontSize: "18px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "19px",
                },
                "@media (min-width: 700px)": {
                  fontSize: "20px",
                },
              }}
            />
            <p
              className="ExploreSortTitle"
              style={{
                color: globalTheme.exploreBackgroundOff.color,
                marginLeft: "5px",
                fontWeight: 500,
              }}
            >
              {exploreState.sortType === "createdAt"
                ? "Latest"
                : exploreState.sortType === "rating"
                ? "Rating"
                : exploreState.sortType === "trendingHour"
                ? "Trending"
                : exploreState.sortType === "relevance"
                ? "Relevance"
                : "Views"}
            </p>
          </div>
        </ButtonBase>
        {/*<ButtonBase
          onClick={() => {
            setSortDialogOpen(true);
          }}
        >
          <div className="ExploreSortInner">
            <p
              className="ExploreSortTitle"
              style={{
                color: globalTheme.figmaPrimaryText.color,
                fontWeight: 500,
              }}
            >
              Include
            </p>
            <FilterListOutlinedIcon
              sx={{
                color: globalTheme.figmaPrimaryText.color,
                fontSize: "17px",
                marginLeft: "5px",
                "@media (min-width: 350px)": {
                  fontSize: "18px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "19px",
                },
                "@media (min-width: 700px)": {
                  fontSize: "20px",
                },
              }}
            />
          </div>
            </ButtonBase>*/}
        
        <PickersCardView iconColor={globalTheme.exploreBackgroundOff.color} />
      </div>
      {loading ? (
        <div className="UserListLoading">
          <CircularProgress
            sx={{ color: globalTheme.backgroundMainOffYellow.color }}
          />
        </div>
      ) : exploreState.results.length === 0 ? (
        <div className="UserListLoading">
          <p
            className="UserListSortTitle"
            style={{ color: globalTheme.backgroundMainOffYellow.color }}
          >
            No listings found
          </p>
        </div>
      ) : (
        <div className="ExploreListInner">
          {exploreState.results.map((listingsItem, index) => {
            return (
              <ProjectListItem
                listItem={listingsItem}
                indexKey={index}
                itemSize={deviceState.viewSizeBox}
                clickAdmin={false}
                key={listingsItem.id}
              />
            );
          })}
        </div>
      )}
      {!loading &&
        exploreState.results.length > 0 &&
        exploreState.listingPages !== 0 && (
          <Pagination
            count={exploreState.listingPages}
            page={exploreState.page + 1}
            onChange={handlePageChange}
            sx={{
              marginTop: "20px",
              marginBottom: "0px",
              "& button": {
                color: globalTheme.exploreBackgroundOff.color,
              },

              "& .MuiPaginationItem-root": {
                color: globalTheme.exploreBackgroundOff.color,
              },
              "& .MuiPaginationItem-page.Mui-selected": {
                backgroundColor: globalTheme.exploreBackgroundOff.color,
                color: globalTheme.exploreBackground.color,
              },
            }}
            size="small"
          />
        )}

      <Dialog
        open={sortDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSortDialogOpen(false)}
        sx={styles.dialogRoot}
      >
        <DialogTitle sx={styles.dialogTitle}>{"Sort by"}</DialogTitle>
        <DialogContent>
          <div className="UserListSortDialog">
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                exploreState.setSortType("relevance");
                exploreState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.exploreBackgroundOff.color }}
                >
                  Relevance
                </p>
                <Radio
                  checked={exploreState.sortType === "relevance"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                exploreState.setSortType("createdAt");
                exploreState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.exploreBackgroundOff.color }}
                >
                  Latest
                </p>
                <Radio
                  checked={exploreState.sortType === "createdAt"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                exploreState.setSortType("rating");
                exploreState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.exploreBackgroundOff.color }}
                >
                  Rating
                </p>
                <Radio
                  checked={exploreState.sortType === "rating"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                exploreState.setSortType("trendingHour");
                exploreState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.exploreBackgroundOff.color }}
                >
                  Trending
                </p>
                <Radio
                  checked={exploreState.sortType === "trendingHour"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                exploreState.setSortType("viewsTotal");
                exploreState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.exploreBackgroundOff.color }}
                >
                  Views
                </p>
                <Radio
                  checked={exploreState.sortType === "viewsTotal"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
