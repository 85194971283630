import React, { useState, useEffect } from "react";

import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../../components/firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../../components/firebase/auth";


import { ButtonBase, CircularProgress } from "@mui/material";
import {
  useSnackbarErrorState,
  useSnackbarState,
  useAuthStates,
  useThemeState,
} from "../../../../components/utils/globalStates";
import "./ProfileSettingsComp.css";

export default function DeleteAccount() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const snackbarStates = useSnackbarState((state) => state);
  const snackbarErrorStates = useSnackbarErrorState((state) => state);
  const authStates = useAuthStates((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);

  return (
    <div
      className="DeleteAccount"
      style={{
        borderTop: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
        borderBottom: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
      }}
    >
      {/*<p className="ProfileSettingsSub" style={{ width: "95%", color: globalTheme.textMainBlack.color }}>
        <b>Suspend your account,</b> hides your activity on GATH3R.CO but keeps
        your account if you would like to reactivate it at a later point.
      </p>
      <p
        className="ProfileSettingsSub"
        style={{
          borderRadius: "15px",
          border: `1px solid ${globalTheme.coreOrange.color}`,
          color: globalTheme.coreOrange.color,
          padding: "4px 10px",
          width: "unset",
            cursor: "pointer",
            marginBottom: '20px'
        }}
      >
        Suspend account
      </p>*/}
      <p className="ProfileSettingsSub" style={{ width: "95%", color: globalTheme.textMainBlack.color }}>
        <b>Deleting your account</b> removes all user data, reviews, ratings, and G-Points. Listings will remain, but attribution will be removed.
      </p>
      <p
        className="ProfileSettingsSub"
        style={{
          borderRadius: "15px",
          border: `1px solid #F7174E`,
          padding: "4px 10px",
          width: "unset",
            cursor: "pointer",
            color: '#F7174E',
            marginBottom: '0px'
        }}
      >
        Delete account Permanently
      </p>
    </div>
  );
}
