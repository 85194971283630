import React, { useState, useEffect } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import ListingsWidgetItem from "../widgets/components/ListingsWidgetItem";
import { ButtonBase } from "@mui/material";
import ColorPicker from "./ColorPicker";
import { useThemeState } from "../../../components/utils/globalStates";
import { categoryEnums } from "../../../components/utils/enums";
import "./DashboardAddWidget.css";

export default function AddListingsWidget({
  addWidget,
  addwidgetRow,
}: {
  addWidget: Function;
  addwidgetRow: number;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [isExpanded, setIsExpanded] = useState(false);
  const [widgetTitle, setWidgetTitle] = useState("Listings");
  const [pickedColor, setPickedColor] = useState(
    globalTheme.figmaYellowPastel.color
  );
  const [widgetType, setWidgetType] = useState("");
  const [widgetCategory, setWidgetCategory] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setWidgetType(event.target.value as string);
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setWidgetCategory(event.target.value as string);
  };

  // Update widget title on widgetType change
  useEffect(() => {
    if (widgetType === "createdAt") {
      setWidgetTitle("New Projects");
    } else if (widgetType === "rating") {
      setWidgetTitle("Top Projects");
    } else if (widgetType === "trendingHour") {
      setWidgetTitle("Trending Projects");
    } else if (widgetType === "viewsTotal") {
      setWidgetTitle("Project Views");
    } else {
      setWidgetTitle("Projects");
    }
  }, [widgetType]);

  const onAddWidgetPress = () => {
    const widgetData = {
      filter: widgetType,
      category: widgetCategory,
      bgColorCode: pickedColor,
      type: "listings",
      rowLength: 4,
      uidRequired: false,
    };
    addWidget(widgetData, addwidgetRow);
  };

  const styles = {
    formOuter: {
      marginTop: "15px",
      backgroundColor: "transparent",
      color: globalTheme.figmaPrimaryText.color,
      borderColor: globalTheme.figmaPrimaryText.color + " !important",
      "& .MuiInputLabel-root": {
        color: globalTheme.figmaPrimaryText.color + " !important",
        "& .Mui-focused ": {
          color: globalTheme.figmaPrimaryText.color,
        },
      },
    },
    select: {
      backgroundColor: "transparent",
      color: globalTheme.figmaPrimaryText.color,
      borderColor: globalTheme.figmaPrimaryText.color + " !important",
      "& .MuiSelect-root": {
        "& fieldset": {
          color: globalTheme.figmaPrimaryText.color,
          borderColor: globalTheme.figmaPrimaryText.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.figmaPrimaryText.color,
          borderColor: globalTheme.figmaPrimaryText.color + " !important",
        },
        "&:after": {
          color: globalTheme.figmaPrimaryText.color,
          borderColor: globalTheme.figmaPrimaryText.color + " !important",
        },
      },
      "&.Mui-focused fieldset": {
        color: globalTheme.figmaPrimaryText.color,
        borderColor: globalTheme.figmaPrimaryText.color + " !important",
      },
      "&.Mui-focused ": {
        color: globalTheme.figmaPrimaryText.color,
        borderColor: globalTheme.figmaPrimaryText.color + " !important",
      },
    },
    selectMenu: {
      zIndex: 9999,
      color: globalTheme.figmaPrimaryText.color,
      "& .MuiPaper-root": {
        backgroundColor: "#fff",
        color: globalTheme.figmaPrimaryText.color,
        "& .MuiMenuItem-root": {
          "& .Mui-selected": {
            color: globalTheme.figmaPrimaryText.color,
            backgroundColor: globalTheme.altTextColor.color,
          },
        },
        "& .Mui-selected": {
          color: globalTheme.figmaPrimaryText.color,
          backgroundColor: globalTheme.background.backgroundColor,
        },
      },
    },
  };

  return (
    <div
      className="AddWidget"
      style={{
        color: globalTheme.figmaPrimaryText.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        backgroundColor: globalTheme.figmaWhite.color,
      }}
    >
      <ButtonBase
        onClick={() => setIsExpanded(!isExpanded)}
        className="ListingsWidget"
        style={{
          color: globalTheme.figmaPrimaryText.color,
          border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          borderRadius: "8px",
          backgroundColor: pickedColor,
          width: "calc(100% + 2px)",
          marginTop: "-1px",
          marginBottom: "-1px",
        }}
      >
        <div
          className="ListingsWidgetHeader"
          style={{
            color: globalTheme.figmaPrimaryText.color,
            borderBottom: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          }}
        >
          <p className="ListingsWidgetTitle">{widgetTitle}</p>
          <p
            className="ListingsWidgetCategory"
            style={{
              border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            }}
          >
            {widgetCategory !== null &&
            widgetCategory !== undefined &&
            widgetCategory !== "" &&
            widgetCategory !== "all"
              ? categoryEnums.find(
                  (category) => category.value === widgetCategory
                )?.displayname
              : "All"}
          </p>
        </div>
        <div className="ListingsWidgetInner">
          <ListingsWidgetItem
            isLink={false}
            dataToRender={{
              logo: "https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Flogo512.png?alt=media",
              name: "GATH3R",
              createdAt: Date.now() - 1000 * 60 * 60 * 24 * 2,
              category: "Tools",
              type: widgetType !== "" ? widgetType : "createdAt",
              rating: 4.9,
              viewsTotal: 9702,
              trendingHour: 400,
              id: "gath3r",
              reviewCount: 100,
              ratingCount: 100,
              viewsDay: 100,
            }}
          />
        </div>
      </ButtonBase>
      <div
        className="AddWidgetSettingsOuter"
        style={{ maxHeight: isExpanded ? "500px" : "0px" }}
      >
        <div className="AddWidgetSettings">
          <div className="AddWidgetSettingsInner">
            <FormControl fullWidth size="small" sx={styles.formOuter}>
              <InputLabel>Type</InputLabel>
              <Select
                value={widgetType}
                label="Type"
                onChange={handleChange}
                sx={{ ...styles.select }}
                MenuProps={{
                  sx: styles.selectMenu,
                }}
              >
                <MenuItem value={"createdAt"}>New Projects</MenuItem>
                <MenuItem value={"rating"}>Top Projects</MenuItem>
                <MenuItem value={"trendingHour"}>Trending Projects</MenuItem>
                <MenuItem value={"viewsTotal"}>Project Views</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small" sx={styles.formOuter}>
              <InputLabel>Category</InputLabel>
              <Select
                value={widgetCategory}
                label="Category"
                onChange={handleCategoryChange}
                sx={{ ...styles.select }}
                MenuProps={{
                  sx: styles.selectMenu,
                }}
              >
                <MenuItem value={"all"}>All</MenuItem>
                {categoryEnums.map((category, index) => (
                  <MenuItem key={index} value={category.value}>
                    {category.displayname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <ColorPicker
            setPickedColor={setPickedColor}
            pickedColor={pickedColor}
          />
        </div>
        <ButtonBase
          disabled={widgetType === "" || widgetCategory === ""}
          onClick={onAddWidgetPress}
          className="AddWidgetButton"
          style={{
            backgroundColor: globalTheme.figmaPrimaryText.color,
            color: globalTheme.figmaWhite.color,
            width: "100%",
            padding: "2px",
            borderRadius: "0px 0px 6px 6px",
            justifySelf: "flex-end",
          }}
        >
          <p>ADD WIDGET</p>
        </ButtonBase>
      </div>
    </div>
  );
}
