import moment from "moment";
import { useNavigate } from "react-router-dom";
import Rating from "@mui/material/Rating";
import { Avatar, Skeleton } from "@mui/material";
import { LazyLoadImg } from "../../../../components/ui/misc/LazyLoadImg";
import { useThemeState } from "../../../../components/utils/globalStates";
import "./DashWidgetsComp.css";

moment().format();
export default function ReviewWidgetItem({
  dataToRender,
  isLink,
}: {
  dataToRender: any;
  isLink: boolean;
}) {
  const navigate = useNavigate();
  const globalTheme = useThemeState((state) => state.globalTheme);

  const onLinkClick = () => {
    if (isLink) {
      navigate(
        dataToRender.review
          ? `/reviews/listing/${dataToRender.listingId}`
          : `/reviews/listing/rating/${dataToRender.listingId}`
      );
    }
  };
  return dataToRender === null ? (
    <Skeleton
      variant="rectangular"
      style={{
        width: "100%",
        height: "30px",
        borderRadius: "8px",
        marginBottom: "3px",
        marginTop: "3px",
      }}
    />
  ) : (
    <div
      onClick={onLinkClick}
      className="ListingsWidgetItem"
      style={{
        color: globalTheme.figmaPrimaryText.color,
      }}
    >
      {dataToRender.logo !== "" &&
      dataToRender.logo !== undefined &&
      dataToRender.logo !== null ? (
        <div className="ListingsWidgetItemAvatar">
          <LazyLoadImg imgUrl={dataToRender.logo} />
        </div>
      ) : (
        <div className="ListingsWidgetItemAvatar">
          <Avatar
            sx={{
              bgcolor: globalTheme.figmaWhite.color,
              color: globalTheme.figmaSecondaryText.color,
              width: "101%",
              height: "101%",
              borderRadius: "5px",
              fontSize: "5px",
              textDecoration: "none",
              "@media (min-width: 350px)": {
                fontSize: "8px",
              },
              "@media (min-width: 550px)": {
                fontSize: "10px",
              },
              "@media (min-width: 700px)": {
                fontSize: "20px",
              },
            }}
            alt={dataToRender.name}
          >
            {dataToRender.name.substr(0, 2)}
          </Avatar>
        </div>
      )}
      <div className="ListingsWidgetItemInner">
        <p
          className="ListingsWidgetItemTitle"
          style={{
            color: globalTheme.figmaPrimaryText.color,
          }}
        >
          {dataToRender.name}
          <span
            style={{
              color: globalTheme.figmaPrimaryTextSub.color,
              fontSize: "75%",
              marginLeft: "5px",
            }}
          >
            {moment(dataToRender.createdAt).fromNow()}
          </span>
        </p>
        {dataToRender.listingRating > 0 ? (
          <div
            className="ListingsWidgetItemTitleOuter"
            style={{
              marginBottom: "-1px",
              alignItems: "center",
              marginTop: "-4px",
            }}
          >
            <div className="RatingItemRating" style={{ marginLeft: "0px" }}>
              <p
                className="ListingsWidgetItemSub"
                style={{
                  color: globalTheme.figmaPrimaryTextSub.color,
                  marginRight: "5px",
                }}
              >
                {dataToRender.listingRating.toFixed(1)}
              </p>
              <Rating
                value={dataToRender.listingRating}
                readOnly
                size="small"
                sx={{ "& svg": { color: globalTheme.figmaPrimaryText.color } }}
              />
            </div>
            {dataToRender.ratingCount > 0 && (
              <p
                className="ListingsWidgetItemSub"
                style={{
                  color: globalTheme.figmaPrimaryTextSub.color,
                  marginLeft: "5px",
                }}
              >
                {dataToRender.ratingCount} Ratings
              </p>
            )}
            {dataToRender.reviewCount > 0 && (
              <p
                className="ListingsWidgetItemSub"
                style={{
                  color: globalTheme.figmaPrimaryTextSub.color,
                  marginLeft: "3px",
                }}
              >
                {dataToRender.reviewCount} Reviews
              </p>
            )}
          </div>
        ) : (
          <div className="RatingItemRating">
            <div
              className="ListingsWidgetItemSub"
              style={{ color: globalTheme.figmaPrimaryTextSub.color }}
            >
              Be the first to rate or review
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
