import React, { useEffect, useState } from "react";

import { useThemeState } from "../../components/utils/globalStates";
import "./Admin.css";

export default function AdminReviews() {

  const globalTheme = useThemeState((state) => state.globalTheme);

  return (
    <div className="AdminInner" style={{ ...globalTheme.backgroundMainOffBeige }}>
      Reviews
    </div>
  );
}

const styles = {
  
};
