import React, { useEffect, useState } from "react";
import Resizer from "react-image-file-resizer";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import {
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
} from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { firebaseStorage } from "../../../components/firebase/storage";
import { firestoreDb } from "../../../components/firebase/firestore";

import { useThemeState } from "../../../components/utils/globalStates";
import "./AdminComponents.css";

export default function AdminFeaturedImageUpload({ listingId }: any) {
  const [logoObj, setLogoObj] = useState<any>(null);
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [chosenImgError, setChosenImgError] = useState("");

  const globalTheme = useThemeState((state) => state.globalTheme);

  const updateLogoObj = (e: any) => {
    setLogoObj(e.target.files[0]);
    setChosenImgError("Upload chosen image or clear");
  };

  const onClear = () => {
    setLogoObj(null);
    setChosenImgError("Pick a logo to upload");
    setIsSent(false);
  };

  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "jpeg",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });

  const checkIfLogoExists = async () => {
    try {
      const imageRef = ref(firebaseStorage, `public/img/featured/listings/${listingId}.jpeg`);
      await getDownloadURL(imageRef);
      return true;
    } catch (err) {
      return false;
    }
  };
  const onUpload = async () => {
    if (logoObj === null) {
      setChosenImgError("Please pick an image to upload.");
      return;
    }
    setIsSending(true);
    setChosenImgError("Uploading image to storage...");
    try {
      let imageObj: any = null;
      const file = logoObj;
      const image = await resizeFile(file);
      imageObj = image;

      const imageRef = ref(firebaseStorage, `public/img/featured/listings/${listingId}.jpeg`);

      const hasFile = await checkIfLogoExists();

      if (hasFile) {
        await deleteObject(imageRef);
      }

      const uplodedImage = await uploadBytes(imageRef, imageObj);
      const uploadedImageUrl = await getDownloadURL(uplodedImage.ref);

      setChosenImgError("Setting image URL in DB...");
      // update listing with new logo
      const listingRef = doc(firestoreDb, "listings", listingId);
      await updateDoc(listingRef, {
        featuredImage: uploadedImageUrl,
      });
      setIsSent(true);
      setIsSending(false);
      setChosenImgError("Image uploaded successfully.");
    } catch (err) {
      console.error(err);
      setIsSending(false);
      setChosenImgError("Something went wrong. Please try again.");
    }
  };

  return (
    <div
      className="AdminLogoUpload"
      style={{
        border: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
        color: globalTheme.backgroundMainOffBeige.color,
        width: "calc(100% - 10px)",
        paddingTop: "2px",
        paddingBottom: "2px",
      }}
    >
      {chosenImgError !== "" ? (
        <p
          className="AdminLogoUploadTitle"
          style={{ ...globalTheme.backgroundMainOffBeige }}
        >
          {chosenImgError}
        </p>
      ) : (
        <p
          className="AdminLogoUploadTitle"
          style={{ ...globalTheme.backgroundMainOffBeige }}
        >
          Upload image to set as featured image
        </p>
      )}
      {isSending ? (
        <CircularProgress
          sx={{ marginRight: "25px" }}
          color="inherit"
          size={20}
        />
      ) : isSent ? (
        <Button
          sx={{
            marginRight: "5px",
            fontSize: "15px",
            "&:hover": {
              opacity: 0.9,
            },
          }}
          color="inherit"
          disabled={isSending}
          onClick={onClear}
        >
          Ok
        </Button>
      ) : logoObj !== null ? (
        <>
          <Button
            sx={{
              marginRight: "5px",
              fontSize: "15px",
              "&:hover": {
                opacity: 0.9,
              },
            }}
            color="inherit"
            disabled={isSending}
            onClick={onClear}
          >
            Clear
          </Button>
          <Button
            sx={{
              marginRight: "5px",
              fontSize: "15px",
              "&:hover": {
                opacity: 0.9,
              },
            }}
            color="inherit"
            disabled={isSending}
            onClick={onUpload}
          >
            Upload
          </Button>
        </>
      ) : (
        <Button
          sx={{
            marginRight: "5px",
            fontSize: "15px",
            "&:hover": {
              opacity: 0.9,
            },
          }}
          color="inherit"
          component="label"
          disabled={isSending}
        >
          Pick Image
          <input
            type="file"
            hidden
            accept="image/png, image/jpeg, image/jpg, image/webp"
            onChange={updateLogoObj}
          />
        </Button>
      )}
    </div>
  );
}
