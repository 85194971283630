import React, { useState, useEffect } from "react";

import Rating from "@mui/material/Rating";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";

import {
  getDocs,
  where,
  limit,
  orderBy,
  OrderByDirection,
  doc,
  query,
  collectionGroup,
  getDoc,
} from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";
import ListItemText from "@mui/material/ListItemText";
import { ButtonBase, Skeleton } from "@mui/material";
import ColorPicker from "./ColorPicker";
import { useThemeState } from "../../../components/utils/globalStates";
import "./DashboardAddWidget.css";

export default function AddSingleListingWidget({
  addWidget,
  addwidgetRow,
}: {
  addWidget: Function;
  addwidgetRow: number;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [user] = useAuthState(firebaseAuth);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedWidth, setIsExpandedWidth] = useState(false);
  const [widgetTitle, setWidgetTitle] = useState("");
  const [pickedColor, setPickedColor] = useState(
    globalTheme.figmaBeigeDark.color
  );
  const [fetchedName, setFetchedName] = useState("");
  const [fetchedRating, setFetchedRating] = useState(0);
  const [fetchedRatingCount, setFetchedRatingCount] = useState(0);
  const [fetchedReviewCount, setFetchedReviewCount] = useState(0);
  const [fetchedBookmarks, setFetchedBookmarks] = useState<any[]>([]);
  const [fetchedBookmarksLoading, setFetchedBookmarksLoading] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setWidgetTitle(event.target.value as string);
    setFetchedName(
      fetchedBookmarks.find((page) => page.following === event.target.value)!
        .name
    );
  };

  const onWidgetExpand = () => {
    setIsExpandedWidth(true);
    setTimeout(() => {
      setIsExpanded(true);
    }, 350);
  };

  const onWidgetCollapse = () => {
    setIsExpanded(false);
    setTimeout(() => {
      setIsExpandedWidth(false);
    }, 350);
  };

  const onWidgetPress = () => {
    if (isExpanded) {
      onWidgetCollapse();
    } else {
      onWidgetExpand();
    }
  };

  const onAddWidgetPress = () => {
    const widgetData = {
      type: "singleListing",
      rowLength: 2,
      projectId: widgetTitle,
      bgColorCode: pickedColor,
    };
    addWidget(widgetData, addwidgetRow);
  };

  // Fetch project bookmarks
  useEffect(() => {
    if (user === null || user === undefined) return;
    if (fetchedBookmarksLoading) return;
    if (fetchedBookmarks.length > 0) return;
    const fetchProject = async () => {
      try {
        setFetchedBookmarksLoading(true);
        const querySnapshot = await getDocs(
          query(
            collectionGroup(firestoreDb, "userBookmarks"),
            where("follower", "==", user.uid),
            limit(50),
            orderBy("name", "asc" as OrderByDirection)
          )
        );
        let followingList: any[] = [];
        querySnapshot.forEach((doc) => {
          followingList.push(doc.data());
        });
        setFetchedBookmarks(followingList);
        setFetchedBookmarksLoading(false);
      } catch (error) {
        console.error("Error getting document:", error);
        setFetchedBookmarksLoading(false);
      }
    };
    fetchProject();
  }, []);

  // Fetch project info
  useEffect(() => {
    if (widgetTitle === null || widgetTitle === undefined || widgetTitle === "")
      return;
    const fetchProject = async () => {
      try {
        const docRef = doc(firestoreDb, "listings", widgetTitle);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setFetchedRating(docSnap.data().rating);
          setFetchedRatingCount(docSnap.data().ratingCount);
          setFetchedReviewCount(docSnap.data().reviewsCount);
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }
    };
    fetchProject();
  }, [widgetTitle]);

  const styles = {
    formOuter: {
      marginTop: "15px",
      backgroundColor: "transparent",
      color: globalTheme.figmaPrimaryText.color,
      borderColor: globalTheme.figmaPrimaryText.color + " !important",
      "& .MuiInputLabel-root": {
        color: globalTheme.figmaPrimaryText.color + " !important",
        flexDirection: "row",
        "& .Mui-focused ": {
          color: globalTheme.figmaPrimaryText.color,
        },
      },
    },
    select: {
      backgroundColor: "transparent",
      color: globalTheme.figmaPrimaryText.color,
      "& span": { fontSize: "13px" },
      borderColor: globalTheme.figmaPrimaryText.color + " !important",
      "& .MuiSelect-root": {
        "& fieldset": {
          color: globalTheme.figmaPrimaryText.color,
          borderColor: globalTheme.figmaPrimaryText.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.figmaPrimaryText.color,
          borderColor: globalTheme.figmaPrimaryText.color + " !important",
        },
        "&:after": {
          color: globalTheme.figmaPrimaryText.color,
          borderColor: globalTheme.figmaPrimaryText.color + " !important",
        },
      },
      "&.Mui-focused fieldset": {
        color: globalTheme.figmaPrimaryText.color,
        borderColor: globalTheme.figmaPrimaryText.color + " !important",
      },
      "&.Mui-focused ": {
        color: globalTheme.figmaPrimaryText.color,
        borderColor: globalTheme.figmaPrimaryText.color + " !important",
      },
    },
    selectMenu: {
      zIndex: 9999,
      color: globalTheme.figmaPrimaryText.color,
      "& span": { fontSize: "13px" },
      "& .MuiPaper-root": {
        backgroundColor: "#fff",
        color: globalTheme.figmaPrimaryText.color,
        "& .MuiMenuItem-root": {
          "& .Mui-selected": {
            color: globalTheme.figmaPrimaryText.color,
            backgroundColor: globalTheme.altTextColor.color,
          },
        },
        "& .Mui-selected": {
          color: globalTheme.figmaPrimaryText.color,
          backgroundColor: globalTheme.background.backgroundColor,
        },
      },
    },
  };

  return (
    <div
      className="AddWidget"
      style={{
        color: globalTheme.figmaPrimaryText.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        backgroundColor: globalTheme.figmaWhite.color,
        width: isExpandedWidth ? "100%" : "50%",
        alignItems: "flex-start",
      }}
    >
      <ButtonBase
        onClick={onWidgetPress}
        className="ListingsWidget"
        sx={{
          color: globalTheme.figmaPrimaryText.color,
          borderRadius: "8px",
          backgroundColor: "transparent",
          width: "100%",
          marginTop: "-1px",
          marginBottom: "-1px",
        }}
      >
        <div
          className="SingleListingWidget"
          style={{
            color: globalTheme.figmaPrimaryText.color,
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            borderRadius: "8px",
            width: "100%",
            backgroundColor: pickedColor,
            alignItems: "flex-start",
          }}
        >
          <p style={{ marginLeft: "10px" }} className="CountWidgetTitle">
            {fetchedName === "" ? "GATH3R" : fetchedName}
          </p>
          {fetchedRating > 0 ? (
            <>
              <div
                className="ListingsWidgetItemTitleOuter"
                style={{ marginBottom: "0px", alignItems: "center" }}
              >
                {fetchedRatingCount > 0 && (
                  <p
                    className="RatingItemRatingText"
                    style={{
                      color: globalTheme.figmaPrimaryTextSub.color,
                      marginLeft: "10px",
                    }}
                  >
                    {fetchedRatingCount} Ratings
                  </p>
                )}
                {fetchedReviewCount > 0 && (
                  <p
                    className="RatingItemRatingText"
                    style={{
                      color: globalTheme.figmaPrimaryTextSub.color,
                      marginLeft: "3px",
                    }}
                  >
                    {fetchedReviewCount} Reviews
                  </p>
                )}
              </div>
              <div className="RatingItemRating" style={{ marginLeft: "10px" }}>
                <p
                  className="RatingItemRatingText"
                  style={{
                    color: globalTheme.figmaPrimaryTextSub.color,
                  }}
                >
                  {fetchedRating.toFixed(1)}
                </p>
                <Rating
                  value={fetchedRating}
                  readOnly
                  size="small"
                  sx={{
                    "& svg": { color: globalTheme.figmaPrimaryText.color },
                  }}
                />
              </div>
            </>
          ) : widgetTitle === "" ? (
            <>
              <div
                className="ListingsWidgetItemTitleOuter"
                style={{ marginBottom: "0px", alignItems: "center" }}
              >
                <p
                  className="RatingItemRatingText"
                  style={{
                    color: globalTheme.figmaPrimaryTextSub.color,
                    marginLeft: "10px",
                  }}
                >
                  326 Ratings
                </p>
                <p
                  className="RatingItemRatingText"
                  style={{
                    color: globalTheme.figmaPrimaryTextSub.color,
                    marginLeft: fetchedRatingCount > 0 ? "3px" : "10px",
                  }}
                >
                  827 Reviews
                </p>
              </div>
              <div className="RatingItemRating" style={{ marginLeft: "10px" }}>
                <p
                  className="RatingItemRatingText"
                  style={{
                    color: globalTheme.figmaPrimaryTextSub.color,
                  }}
                >
                  4.9
                </p>
                <Rating
                  value={4.9}
                  readOnly
                  size="small"
                  sx={{
                    "& svg": { color: globalTheme.figmaPrimaryText.color },
                  }}
                />
              </div>
            </>
          ) : (
            <p
              className="RatingItemRatingText"
              style={{
                color: globalTheme.figmaPrimaryTextSub.color,
                marginLeft: "10px",
                width: "calc(100% - 20px)",
                textAlign: "left",
                whiteSpace: "normal",
              }}
            >
              Be the first to rate or review this project
            </p>
          )}
        </div>
      </ButtonBase>
      <div
        className="AddWidgetSettingsOuter"
        style={{
          maxHeight: isExpanded ? "500px" : "0px",
        }}
      >
        <div className="AddWidgetSettings">
          <div className="AddWidgetSettingsInner">
            {!fetchedBookmarksLoading ? (
              <FormControl fullWidth size="small" sx={styles.formOuter}>
                <InputLabel>
                  {fetchedBookmarks.length === 0
                    ? "No Bookmarks"
                    : "Select a Project"}
                </InputLabel>
                <Select
                  value={widgetTitle}
                  size="small"
                  label={
                    fetchedBookmarks.length === 0
                      ? "No Bookmarks"
                      : "Select a Project"
                  }
                  disabled={fetchedBookmarks.length === 0}
                  placeholder={
                    fetchedBookmarks.length === 0
                      ? "No Bookmarks"
                      : "Select a Project"
                  }
                  onChange={handleChange}
                  sx={{ ...styles.select }}
                  MenuProps={{
                    sx: styles.selectMenu,
                  }}
                >
                  {fetchedBookmarks.map((page, index) => (
                    <MenuItem
                      value={page.following}
                      key={page.following + index}
                    >
                      <ListItemText>
                        {page.name}{" "}
                        <span
                          style={{
                            marginLeft: "3px",
                            color: globalTheme.figmaLinks.color,
                            fontSize: "80%",
                          }}
                        >
                          {page.category}
                        </span>
                      </ListItemText>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Skeleton
                variant="rectangular"
                style={{
                  marginTop: "15px",
                  width: "100%",
                  height: "30px",
                  borderRadius: "8px",
                  maxWidth: "800px",
                }}
              />
            )}
          </div>
          <ColorPicker
            setPickedColor={setPickedColor}
            pickedColor={pickedColor}
          />
        </div>
        <ButtonBase
          disabled={widgetTitle === ""}
          onClick={onAddWidgetPress}
          className="AddWidgetButton"
          style={{
            backgroundColor: globalTheme.figmaPrimaryText.color,
            color: globalTheme.figmaWhite.color,
            width: "100%",
            padding: "2px",
            borderRadius: "0px 0px 6px 6px",
            justifySelf: "flex-end",
          }}
        >
          <p>ADD WIDGET</p>
        </ButtonBase>
      </div>
    </div>
  );
}
