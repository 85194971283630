/**
 * Renders the user listings page.
 *
 * author: @jimmybengtsson (itchy-fingers)
 */

import React, { useState, useEffect } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";
import { CircularProgress, Avatar } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import { Helmet } from "react-helmet-async";
// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Radio from "@mui/material/Radio";

import {
  useSnackbarErrorState,
  useUserListingsSortState,
  useHeaderSettingsState,
  useTabSettingsState,
  useThemeState,
  useDeviceState,
} from "../../../components/utils/globalStates";

import {
  algoliaListingsCreatedAtDesc,
  algoliaListingsViewsTotalDesc,
  algoliaListingsRatingDesc,
} from "../../../components/search/algoliaSearch";
import UserListingsSearch from "../../../components/ui/topBoxes/UserListingsSearch";
import ProjectListItem from "../../../components/ui/listItems/ProjectListItem";
import PickersCardView from "../../../components/ui/pickers/PickerCardView";
import "./UserListings.css";
import "../list/UserList.css";

moment().format();

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserListings() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const snackbarError = useSnackbarErrorState();
  const userListingsSort = useUserListingsSortState();
  const headerSettingsState = useHeaderSettingsState();
  const tabSettingsState = useTabSettingsState();
  const deviceState = useDeviceState();
  const globalTheme = useThemeState((state) => state.globalTheme);

  const [loading, setLoading] = useState(true);
  const [listings, setListings] = useState<any[]>([]);
  const [sortDialogOpen, setSortDialogOpen] = useState(false);

  useEffect(() => {
    if (userId === undefined || userId === null) {
      snackbarError.setMessage("User not found");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return navigate("/users");
    }
  }, [userId]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    userListingsSort.setPage(value - 1);
  };

  const fetchTemplate = (fetchObj: any) => {
    setLoading(true);
    fetchObj
      .search(userListingsSort.search, {
        hitsPerPage: userListingsSort.hitsPerPage,
        page: userListingsSort.page,
        filters: `createdBy:${userId}`,
      })
      .then((res: any) => {
        userListingsSort.setListingPages(res.nbPages);
        userListingsSort.setListingCount(res.nbHits);
        setListings(res.hits);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setLoading(false);
      });
  };

  // Load all listings made by user
  useEffect(() => {
    if (
      userListingsSort.sortType === "rating" &&
      userListingsSort.sortOrder === "desc"
    ) {
      fetchTemplate(algoliaListingsRatingDesc);
    } else if (
      userListingsSort.sortType === "viewsTotal" &&
      userListingsSort.sortOrder === "desc"
    ) {
      fetchTemplate(algoliaListingsViewsTotalDesc);
    } else {
      fetchTemplate(algoliaListingsCreatedAtDesc);
    }
  }, [
    userListingsSort.sortOrder,
    userListingsSort.sortType,
    userListingsSort.hitsPerPage,
    userListingsSort.page,
    userListingsSort.search,
  ]);

  useEffect(() => {
    headerSettingsState.setIsBackButtonOpen(true);
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.backgroundMainBeige.color);
    return () => {
      headerSettingsState.setIsBackButtonOpen(false);
      tabSettingsState.setColor(globalTheme.backgroundMainYellow.color);
      tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      headerSettingsState.setIsLogoOpen(false);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  const styles = {
    dialogRoot: {
      zIndex: 9999,
      backgroundColor: "rgba(253, 247, 234, 0.5)",
      "& .MuiDialog-paper": {
        backgroundColor: globalTheme.backgroundMainBeige.color,
        color: globalTheme.backgroundMainOffBeige.color,
        width: "94%",
        maxWidth: "600px",
        margin: 0,
        paddingBottom: "70px",
        paddingTop: "20px",
        position: "absolute",
        bottom: "-10px",
      },
    },
    dialogTitle: {
      color: globalTheme.backgroundMainOffBeige.color,
      textAlign: "center",
      fontSize: "16px",
      marginTop: "20px",
      fontWeight: 600,
    },
    radio: {
      color: globalTheme.backgroundMainOffBeige.color,
      "&.Mui-checked": {
        color: globalTheme.backgroundMainOffBeige.color,
      },
    },
  };

  return (
    <div
      className="UserListings"
      style={{ backgroundColor: globalTheme.backgroundMainBeige.color, paddingTop: "90px" }}
    >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Projects by User</title>
      </Helmet>
      <UserListingsSearch userId={userId} />
      <div className="UserListSort" style={{marginTop: '0px', maxWidth: '1200px'}}>
        <ButtonBase
          className="UserListSortButton"
          onClick={() => {
            setSortDialogOpen(true);
          }}
        >
          <div className="UserListSortInner">
            <p
              className="UserListSortTitle"
              style={{ color: globalTheme.textMainGrey.color }}
            >
              Sort by
            </p>
            <p
              className="UserListSortTitle"
              style={{
                color: globalTheme.backgroundMainOffBeige.color,
                marginLeft: "5px",
                fontWeight: 500,
              }}
            >
              {userListingsSort.sortType === "createdAt"
                ? "Latest"
                : userListingsSort.sortType === "rating"
                ? "Rating"
                : "Views"}
            </p>
          </div>
        </ButtonBase>
        {/*<IconButton
          size="small"
          onClick={() => {
            userListingsSort.setSortOrder(
              userListingsSort.sortOrder === "asc" ? "desc" : "asc"
            );
            userListingsSort.setPage(0);
          }}
        >
          <SwapVertOutlinedIcon
            sx={{
              color: globalTheme.figmaSecondaryText.color,
              fontSize: "22px",
            }}
          />
          </IconButton>*/}
        <PickersCardView iconColor={globalTheme.backgroundMainOffYellow.color} />
      </div>
      {loading ? (
        <div className="UserListLoading">
          <CircularProgress
            sx={{ color: globalTheme.backgroundMainOffBeige.color }}
          />
        </div>
      ) : listings.length > 0 ? (
        <div className="UserListInnerNew" >
          {listings.map((listingsItem, index) => (
            <ProjectListItem
                  listItem={listingsItem}
                  indexKey={index}
                  itemSize={deviceState.viewSizeBox}
                  clickAdmin={false}
                  key={listingsItem.id}
                />
          ))}
        </div>
      ) : (
        <div className="UserListLoading">
          <p
            className="UserListSortTitle"
            style={{ color: globalTheme.backgroundMainOffBeige.color }}
          >
            No listings
          </p>
          <Link to={`/users/page/${userId}`} style={{ textDecoration: "none" }}>
            <p
              className="UserReviewsLinkBack"
              style={{ color: globalTheme.figmaHighlight.color }}
            >
              Back to users profile
            </p>
          </Link>
        </div>
      )}
      {!loading &&
        listings.length > 0 &&
        userListingsSort.listingPages !== 0 && (
          <Pagination
            count={userListingsSort.listingPages}
            page={userListingsSort.page + 1}
            onChange={handlePageChange}
            sx={{ marginTop: "20px", marginBottom: "0px" }}
            size="small"
          />
        )}
      <Link to={`/users/page/${userId}`} style={{ textDecoration: "none" }}>
        <p
          className="UserReviewsLinkBack"
          style={{ color: globalTheme.figmaHighlight.color }}
        >
          Back to users profile
        </p>
      </Link>
      <Dialog
        open={sortDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSortDialogOpen(false)}
        sx={styles.dialogRoot}
      >
        <DialogTitle sx={styles.dialogTitle}>{"Sort by"}</DialogTitle>
        <DialogContent>
          <div className="UserListSortDialog">
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                userListingsSort.setSortType("createdAt");
                userListingsSort.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Latest
                </p>
                <Radio
                  checked={userListingsSort.sortType === "createdAt"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                userListingsSort.setSortType("rating");
                userListingsSort.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Rating
                </p>
                <Radio
                  checked={userListingsSort.sortType === "rating"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                userListingsSort.setSortType("viewsTotal");
                userListingsSort.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Views
                </p>
                <Radio
                  checked={userListingsSort.sortType === "viewsTotal"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
