import React, { useEffect, useState } from "react";

import {
  useThemeState,
  useUserSearchSortState,
  useAdminState
} from "../../components/utils/globalStates";
import Pagination from "@mui/material/Pagination";
import { CircularProgress, ButtonBase } from "@mui/material";
import moment from "moment";
import { Helmet } from "react-helmet-async";

// Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Radio from "@mui/material/Radio";

import AdminUserDialog from "./dialogs/AdminUserDialog";
import AdminUserSearch from "./components/AdminUserSearch";
import AdminUserItem from "./items/AdminUserItem";
import {
  algoliaUsersCreatedAtDesc,
  algoliaUsersRelevance,
  algoliaUsersPointsWeekDesc,
  algoliaUsersPointsDayDesc,
  algoliaUsersPointsHourDesc,
  algoliaUsersPointsDesc,
  algoliaUsersLastActiveDesc,
} from "../../components/search/algoliaSearch";
import "./Admin.css";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

moment().format();

export default function AdminUsers() {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const userSearchState = useUserSearchSortState();
  const adminState = useAdminState();
  const [userList, setUserList] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [sortDialogOpen, setSortDialogOpen] = useState(false);
  const [userDialogOpen, setUserDialogOpen] = useState(false);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    userSearchState.setPage(value - 1);
  };

   useEffect(() => {
    if (adminState.activeUser !== '') {
      setUserDialogOpen(true);
    } else {
      setUserDialogOpen(false);
    }
  }
  , [adminState.activeUser]);

  /**
   * Fetches points template based on the provided fetch object and from date.
   * @param fetchObj - The fetch object used to search for user points.
   * @param fromDate - The from date in milliseconds since the Unix epoch.
   *
   * author: @jimmybengtsson (itchy-fingers)
   */
  const fetchPointsTemplate = (fetchObj: any, fromDate: number) => {
    setLoading(true);
    fetchObj
      .search(userSearchState.search, {
        hitsPerPage: userSearchState.hitsPerPage,
        page: userSearchState.page,
        numericFilters: [`pointsUpdated > ${fromDate}`],
      })
      .then((res: any) => {
        userSearchState.setListingPages(res.nbPages);
        userSearchState.setListingCount(res.nbHits);
        setUserList(res.hits);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setLoading(false);
      });
  };

  /**
   * Fetches the template based on the provided fetch object.
   * @param fetchObj - The fetch object used to search for users.
   *
   * author: @jimmybengtsson (itchy-fingers)
   */
  const fetchTemplate = (fetchObj: any) => {
    setLoading(true);
    fetchObj
      .search(userSearchState.search, {
        hitsPerPage: userSearchState.hitsPerPage,
        page: userSearchState.page,
      })
      .then((res: any) => {
        userSearchState.setListingPages(res.nbPages);
        userSearchState.setListingCount(res.nbHits);
        setUserList(res.hits);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userSearchState.sortType === "lastActive") {
      fetchTemplate(algoliaUsersLastActiveDesc);
    } else if (userSearchState.sortType === "points") {
      fetchTemplate(algoliaUsersPointsDesc);
    } else if (userSearchState.sortType === "pointsHour") {
      fetchPointsTemplate(algoliaUsersPointsHourDesc, Date.now() - 3600000);
    } else if (userSearchState.sortType === "pointsDay") {
      fetchPointsTemplate(algoliaUsersPointsDayDesc, Date.now() - 86400000);
    } else if (userSearchState.sortType === "pointsWeek") {
      fetchPointsTemplate(algoliaUsersPointsWeekDesc, Date.now() - 604800000);
    } else if (userSearchState.sortType === "relevance") {
      fetchTemplate(algoliaUsersRelevance);
    } else {
      fetchTemplate(algoliaUsersCreatedAtDesc);
    }
  }, [
    userSearchState.sortOrder,
    userSearchState.sortType,
    userSearchState.hitsPerPage,
    userSearchState.page,
    userSearchState.search,
  ]);

  const styles = {
    dialogRoot: {
      zIndex: 9999,
      backgroundColor: "rgba(253, 247, 234, 0.5)",
      "& .MuiDialog-paper": {
        backgroundColor: globalTheme.backgroundMainBeige.color,
        color: globalTheme.backgroundMainOffBeige.color,
        width: "94%",
        maxWidth: "600px",
        margin: 0,
        paddingBottom: "70px",
        paddingTop: "20px",
        position: "absolute",
        bottom: "-10px",
      },
    },
    dialogTitle: {
      color: globalTheme.backgroundMainOffBeige.color,
      textAlign: "center",
      fontSize: "16px",
      marginTop: "20px",
      fontWeight: 600,
    },
    radio: {
      color: globalTheme.backgroundMainOffBeige.color,
      "&.Mui-checked": {
        color: globalTheme.backgroundMainOffBeige.color,
      },
    },
  };

  return (
    <div
      className="AdminInner"
      style={{ ...globalTheme.backgroundMainOffBeige }}
    >
      <p
        className="AdminDescription"
        style={{ ...globalTheme.backgroundMainOffBeige }}
      >
       STILL IN PROGRESS
      </p>
      <p
        className="AdminDescription"
        style={{ ...globalTheme.backgroundMainOffBeige }}
      >
        Search for users, edit users information and roles, disable or delete.
      </p>
      <AdminUserSearch />
      <div className="AdminListingSortOuter">
        <ButtonBase
          className="UserListSortButton"
          onClick={() => {
            setSortDialogOpen(true);
          }}
        >
          <div className="UserListSortInner">
            <p
              className="UserListSortTitle"
              style={{ color: globalTheme.textMainGrey.color }}
            >
              Sort by
            </p>
            <p
              className="UserListSortTitle"
              style={{
                color: globalTheme.backgroundMainOffBeige.color,
                marginLeft: "5px",
                fontWeight: 500,
              }}
            >
              {userSearchState.sortType === "createdAt"
                ? "Latest"
                : userSearchState.sortType === "points"
                ? "Points"
                : userSearchState.sortType === "lastActive"
                ? "Last Active"
                : userSearchState.sortType === "pointsHour"
                ? "Points per hour"
                : userSearchState.sortType === "pointsDay"
                ? "Points per day"
                : userSearchState.sortType === "pointsWeek"
                ? "Points per week"
                : "Relevance"}
            </p>
          </div>
        </ButtonBase>
      </div>
      {loading ? (
        <div className="UserListLoading">
          <CircularProgress
            sx={{ color: globalTheme.backgroundMainOffBeige.color }}
          />
        </div>
      ) : userList.length > 0 ? (
        <div className="AdminListingsOuter" style={{ marginTop: "0px" }}>
          {userList.map((user, index) => (
            <div
                onClick={() => adminState.setActiveUser(user.id)}
                className="AdminListItemOuter"
                key={index}
              >
            <AdminUserItem
              listItem={user}
              key={index}
            />
            </div>
          ))}
        </div>
      ) : (
        <div className="UserListLoading">
          <p
            className="UserListSortTitle"
            style={{ color: globalTheme.backgroundMainOffBeige.color }}
          >
            No users found
          </p>
        </div>
      )}
      {!loading &&
        userList.length > 0 &&
        userSearchState.listingPages !== 0 && (
          <Pagination
            count={userSearchState.listingPages}
            page={userSearchState.page + 1}
            onChange={handlePageChange}
            sx={{
              marginTop: "20px",
              marginBottom: "0px",
              "& button": {
                color: globalTheme.backgroundMainOffBeige.color,
              },

              "& .MuiPaginationItem-root": {
                color: globalTheme.backgroundMainOffBeige.color,
              },
              "& .MuiPaginationItem-page.Mui-selected": {
                backgroundColor: globalTheme.backgroundMainOffBeige.color,
                color: globalTheme.backgroundMainBeige.color,
              },
            }}
            size="small"
          />
        )}
        <Dialog
        open={sortDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSortDialogOpen(false)}
        sx={styles.dialogRoot}
      >
        <DialogTitle sx={styles.dialogTitle}>{"Sort by"}</DialogTitle>
        <DialogContent>
          <div className="UserListSortDialog">
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                userSearchState.setSortType("relevance");
                userSearchState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Relevance
                </p>
                <Radio
                  checked={userSearchState.sortType === "relevance"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                userSearchState.setSortType("createdAt");
                userSearchState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Latest
                </p>
                <Radio
                  checked={userSearchState.sortType === "createdAt"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                userSearchState.setSortType("lastActive");
                userSearchState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Last Active
                </p>
                <Radio
                  checked={userSearchState.sortType === "lastActive"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                userSearchState.setSortType("points");
                userSearchState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Points
                </p>
                <Radio
                  checked={userSearchState.sortType === "points"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                userSearchState.setSortType("pointsHour");
                userSearchState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Points per hour
                </p>
                <Radio
                  checked={userSearchState.sortType === "pointsHour"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                userSearchState.setSortType("pointsDay");
                userSearchState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Points per day
                </p>
                <Radio
                  checked={userSearchState.sortType === "pointsDay"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                userSearchState.setSortType("pointsWeek");
                userSearchState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Points per week
                </p>
                <Radio
                  checked={userSearchState.sortType === "pointsWeek"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
            sx={{
              zIndex: 9997,
              "& .MuiPaper-root": {
                width: "90vw",
                maxWidth: "600px",
                height: "70vh",
                backgroundColor: globalTheme.backgroundMainBeige.color,
              },
            }}
            onClose={() => adminState.setActiveUser('')}
            open={userDialogOpen}
          >
            {adminState.activeUser !== '' && (
              <AdminUserDialog

                userId={adminState.activeUser}
              />
            )}
          </Dialog>
    </div>
  );
}
