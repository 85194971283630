import { ButtonBase, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useAuthStates,
  useTokensListState,
  useThemeState,
} from "../../utils/globalStates";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../firebase/auth";

import { signOut } from "firebase/auth";

import Socials from "../misc/Socials";
import LogoMaskOnly from "../img/LogoMaskOnly";
import "./Drawer.css";

export default function MenuDrawer({ setIsDrawerOpen }: any) {
  const authStates = useAuthStates((state) => state);
  const tokensListState = useTokensListState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [user, loading, error] = useAuthState(firebaseAuth);

  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    setIsDrawerOpen(false);
    navigate(path);
  };

  const styles = {
    signoutButton: {
      color: globalTheme.backgroundMainOffYellow.color,
      marginBottom: "20px",
      marginTop: "30px",
      border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
      padding: "3px",
      paddingLeft: "10px",
      paddingRight: "10px",
      "&:hover": {
        color: globalTheme.dashboardBackground.color,
        backgroundColor: globalTheme.backgroundMainOffYellow.color,
      },
    },
  };
  return (
    <div className="MenuDrawer">
      <div className="appMenuHeaderLogo">
        <LogoMaskOnly
          maskFill={globalTheme.dashboardBackground.color}
          maskStroke={globalTheme.backgroundMainOffYellow.color}
        />
      </div>
      <div className="appMenuLinksOuter">
        <ButtonBase
          sx={{ padding: 0, marginTop: "0px", width: "80%" }}
          onClick={() => handleNavigation("/")}
        >
          <p
            className="appMenuLinkTitle"
            style={{ color: globalTheme.backgroundMainOffYellow.color }}
          >
            DASHBOARD
          </p>
        </ButtonBase>
        <ButtonBase
          sx={{ padding: 0, marginTop: "0px", width: "80%" }}
          onClick={() => handleNavigation("/search")}
        >
          <p
            className="appMenuLinkTitle"
            style={{ color: globalTheme.backgroundMainOffYellow.color }}
          >
            SEARCH
          </p>
        </ButtonBase>
        <ButtonBase
          sx={{ padding: 0, marginTop: "0px", width: "80%" }}
          onClick={() => handleNavigation("/reviews")}
        >
          <p
            className="appMenuLinkTitle"
            style={{ color: globalTheme.backgroundMainOffYellow.color }}
          >
            REVIEWS
          </p>
        </ButtonBase>
        <ButtonBase
          sx={{ padding: 0, marginTop: "0px", width: "80%" }}
          onClick={() => handleNavigation("/users")}
        >
          <p
            className="appMenuLinkTitle"
            style={{ color: globalTheme.backgroundMainOffYellow.color }}
          >
            USERS
          </p>
        </ButtonBase>
        <ButtonBase
          sx={{ padding: 0, marginTop: "0px", width: "80%" }}
          onClick={() => {
            tokensListState.setTokenPage(1);
            handleNavigation("/cryptos");
          }}
        >
          <p
            className="appMenuLinkTitle"
            style={{ color: globalTheme.backgroundMainOffYellow.color }}
          >
            CRYPTOCURRENCIES
          </p>
        </ButtonBase>
        {/*<ButtonBase
          sx={{ padding: 0, marginTop: "0px", width: "80%" }}
          onClick={() => handleNavigation("/wallets")}
        >
         <p
              className="appMenuLinkTitle"
              style={{ color: globalTheme.figmaPrimaryText.color }}
            >
              WALLETS
            </p>
        </ButtonBase>*/}
        {/*<ButtonBase
          sx={{ padding: 0, marginTop: "0px", width: "100%" }}
          onClick={() => handleNavigation("/tokens")}
        >
          <div className="appMenuLink">
            <div
              className="appMenuLinkIcon"
              style={{
                color: globalTheme.figmaPrimaryText.color,
                backgroundColor: globalTheme.figmaPrimaryColor.color,
                borderBottom: "3px solid " + globalTheme.figmaPrimaryText.color,
              }}
            >
              <TokenOutlinedIcon
                sx={{
                  fontSize: "26px",
                  color: globalTheme.figmaPrimaryText.color,
                  "@media (min-width: 350px)": {
                    fontSize: "35px",
                  },
                  "@media (min-width: 500px)": {
                    fontSize: "30px",
                  },
                }}
              />
            </div>
            <p
              className="appMenuLinkTitle"
              style={{ color: globalTheme.figmaPrimaryText.color }}
            >
              Tokens
            </p>
          </div>
              </ButtonBase>*/}
        <ButtonBase
          sx={{ padding: 0, marginTop: "0px", width: "80%" }}
          onClick={() => handleNavigation("/profile/bookmarks")}
        >
          <p
            className="appMenuLinkTitle"
            style={{ color: globalTheme.backgroundMainOffYellow.color }}
          >
            BOOKMARKS
          </p>
        </ButtonBase>
        <ButtonBase
          sx={{ padding: 0, marginTop: "0px", width: "80%" }}
          onClick={() => handleNavigation("/achievements")}
        >
          <p
            className="appMenuLinkTitle"
            style={{ color: globalTheme.backgroundMainOffYellow.color }}
          >
            ACHIEVEMENTS
          </p>
        </ButtonBase>
        <ButtonBase
          sx={{ padding: 0, marginTop: "0px", width: "80%" }}
          onClick={() => handleNavigation("/profile")}
        >
          <p
            className="appMenuLinkTitle"
            style={{ color: globalTheme.backgroundMainOffYellow.color }}
          >
            PROFILE
          </p>
        </ButtonBase>
        <ButtonBase
          sx={{ padding: 0, marginTop: "0px", width: "80%" }}
          onClick={() => handleNavigation("/profile/settings")}
        >
          <p
            className="appMenuLinkTitle"
            style={{ color: globalTheme.backgroundMainOffYellow.color }}
          >
            SETTINGS
          </p>
        </ButtonBase>

        {authStates.role === "admin" || authStates.role === "owner" ? (
          <ButtonBase
            sx={{ padding: 0, marginTop: "50px", width: "80%" }}
            onClick={() => handleNavigation("/admin")}
          >
            <p
              className="appMenuLinkTitle"
              style={{ color: globalTheme.backgroundMainOffYellow.color }}
            >
              ADMIN
            </p>
          </ButtonBase>
        ) : null}
      </div>
      <Button
        size="large"
        sx={styles.signoutButton}
        variant="text"
        onClick={() => signOut(firebaseAuth)}
      >
        Sign out
      </Button>
      <div className="appMenuSocials">
        <Socials customColor={globalTheme.backgroundMainOffYellow.color} />
      </div>
      <div className="appMenuBottomLinks">
        <ButtonBase
          sx={{
            padding: 0,
            margin: 0,
            marginRight: "5px",
            marginLeft: "5px",
          }}
          onClick={() => handleNavigation("/issues")}
        >
          <p
            className="appMenuLinkTitle"
            style={{ color: globalTheme.backgroundMainOffYellow.color, margin: 0, padding: 0 }}
          >
            <span style={{ fontSize: "60%", fontWeight: 500, margin: 0, }}>
              Report Issues
            </span>
          </p>
        </ButtonBase>
        <a
          href="https://gath3r.co/terms"
          target="_blank"
          rel="noreferrer"
          style={{
            padding: 0,
            margin: 0,
            textDecoration: "none",
            marginRight: "5px",
            marginLeft: "5px",
            color: globalTheme.backgroundMainOffYellow.color,
          }}
        >
          <p
            className="appMenuLinkTitle"
            style={{
              color: globalTheme.backgroundMainOffYellow.color,
              textDecoration: "none",
              cursor: "pointer",
              margin: 0,
            }}
          >
            <span
              style={{
                fontSize: "60%",
                textDecoration: "none",
                fontWeight: 500,
                margin: 0,
              }}
            >
              Terms
            </span>
          </p>
        </a>
        <a
          href="https://gath3r.co/cookies"
          target="_blank"
          rel="noreferrer"
          style={{
            padding: 0,
            margin: 0,
            textDecoration: "none",
            marginRight: "5px",
            marginLeft: "5px",
            color: globalTheme.backgroundMainOffYellow.color,
          }}
        >
          <p
            className="appMenuLinkTitle"
            style={{
              color: globalTheme.backgroundMainOffYellow.color,
              textDecoration: "none",
              cursor: "pointer",
              margin: 0,
            }}
          >
            <span
              style={{
                fontSize: "60%",
                textDecoration: "none",
                fontWeight: 500,
                margin: 0,
              }}
            >
              Cookies
            </span>
          </p>
        </a>
      </div>
    </div>
  );
}
