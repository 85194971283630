import { LazyLoadImg } from "../../../../components/ui/misc/LazyLoadImg";

import { useThemeState } from "../../../../components/utils/globalStates";
import "./ProfileStartComp.css";

export default function AchievementsSliderItem({
  achievement,
}: {
  achievement: any;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  return (
    <div
      className="ProfileAchivementItem"
      style={{
        backgroundColor: globalTheme.backgroundMainBeigeDark.color,
        border: `1px solid ${globalTheme.solidMainBlack.color}`,
      }}
    >
      {achievement.url !== null &&
      achievement.url !== undefined &&
      achievement.url !== "" ? (
        <div className="AchievementsListItemImg">
          <LazyLoadImg imgUrl={achievement.url} />
        </div>
      ) : (
        <div className="AchievementsListItemImg">
          <span
            style={{ color: globalTheme.backgroundMainOffBeigeDark.color }}
            className="material-symbols-outlined"
          >
            {achievement.icon}
          </span>
        </div>
      )}
    </div>
  );
}
