import React, { useState } from "react";
import { AsyncImage } from "loadable-image";
import { CircularProgress } from "@mui/material";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import { Fade } from "transitions-kit";
import {useThemeState} from "../../utils/globalStates";
import "./Misc.css";

export const LazyLoadImg = ({ imgUrl, radiusStyle }: any) => {
  const globalTheme = useThemeState((state) => state.globalTheme);
  return (
    <AsyncImage
      src={imgUrl}
      style={{ width: "100%", aspectRatio: "1/1", borderRadius: radiusStyle}}
      Transition={Fade}
      loader={
        <div className="LazyLoadImgLoading">
          <CircularProgress
          size={'20%'}
            sx={{
              color: globalTheme.figmaSecondaryText.color,
            }}
          />
        </div>
      }
      error={
        <div className="LazyLoadImgLoading">
          <InsertPhotoOutlinedIcon
            sx={{
              color: globalTheme.figmaSecondaryText.color,
              width: "30%",
              height: "30%",
              opacity: 0.5,
            }}
          />
        </div>
      }
    />
  );
};
