import React from "react";
import '../Img.css';

function OpenseaLogo({customColor}: {customColor?: string;}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 23"
      viewBox="0 0 500 499.93"
      className='socialsLogoSvg'
    >
      <path
        fill={customColor ? customColor:'#39393C'}
        d="M987.67 672.65c42.7 131.27-29.09 272.29-160.36 315s-272.29-29.1-315-160.36 29.09-272.29 160.36-315a249.92 249.92 0 01315 160.36zm-237.6-150.12c-125.61.17-227.3 102.14-227.13 227.75s102.14 227.3 227.75 227.12S978 875.27 977.82 749.65a227.43 227.43 0 00-227.75-227.12z"
        transform="translate(-500 -500.03)"
      ></path>
      <path
        fill={customColor ? customColor:'#39393C'}
        d="M824 794.81c-6.59 8.84-20.46 23.5-28.18 23.5H760v-23.52h28.56a15 15 0 0010.79-4.56c14.67-15.2 23.14-33.29 23.14-52.68 0-33.1-24.74-62.36-62.51-80v-15.8a12.31 12.31 0 00-24.62 0v6.55a202.53 202.53 0 00-33.5-6.8 118.37 118.37 0 015.85 153.33h27.65v23.56h-40.48a9.92 9.92 0 01-9.92-9.92v-10.3a3.28 3.28 0 00-3.29-3.28h-53.35a1.94 1.94 0 00-1.94 1.9c0 42.14 33.31 73.77 73.19 73.77h112.58c27 0 39-34.58 54.85-56.58 6.14-8.51 20.86-15.35 25.33-17.25a2.1 2.1 0 001.29-2v-13.67a2.16 2.16 0 00-2.72-2.06s-65.07 15-65.83 15.17a1.92 1.92 0 00-1.05.66z"
        transform="translate(-500 -500.03)"
      ></path>
      <path
        fill={customColor ? customColor:'#39393C'}
        d="M712.22 721.49a90.58 90.58 0 00-19.43-56.2l-61.24 106h65.87a90.43 90.43 0 0014.82-49.74z"
        transform="translate(-500 -500.03)"
      ></path>
    </svg>
  );
}

export default OpenseaLogo;
