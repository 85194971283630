import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../components/firebase/auth";
import { useAuthStates } from "../../components/utils/globalStates";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Helmet } from "react-helmet-async";

import AdminStats from "./AdminStats";
import AdminFeaturedNews from "./AdminFeaturedNews";
import AdminListingsFeatured from "./AdminListingsFeatured";
import AdminListingsRugged from "./AdminListingsRugged";
import AdminListings from "./AdminListings";
import AdminErrorListings from "./AdminErrorListings";
import AdminReviews from "./AdminReviews";
import AdminReports from "./AdminReports";
import AdminUsers from "./AdminUsers";
import AdminNewsletter from "./AdminNewsletter";
import AdminNews from "./AdminNews";
import AdminIssueReports from "./AdminIssueReports";

import { useThemeState, useHeaderSettingsState, useTabSettingsState } from "../../components/utils/globalStates";
import "./Admin.css";

export default function AdminStart() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const authStates = useAuthStates((state) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const headerSettingsState = useHeaderSettingsState((state) => state);
  const tabSettingsState = useTabSettingsState((state) => state);

  const [tabValue, setTabValue] = useState("listings");

  useEffect(() => {
    if (loading) {
      return;
    }
    if (authStates.role === "") return;
    if (authStates.role !== "admin" && authStates.role !== "owner") {
      return navigate("/");
    }
  }, [authStates.role, loading]);

  useEffect(() => {
    /*if (location.pathname === "/admin") {
      setTabValue("stats");
    } else*/ if (location.pathname === "/admin") {
      setTabValue("listings");
    } else if (location.pathname === "/admin/quarantine") {
      setTabValue("errorlistings");
    } else if (location.pathname === "/admin/reviews") {
      setTabValue("reviews");
    } else if (location.pathname === "/admin/users") {
      setTabValue("users");
    } else if (location.pathname === "/admin/newsletter") {
      setTabValue("newsletter");
    } else if (location.pathname === "/admin/news") {
      setTabValue("news");
    } else if (location.pathname === "/admin/reports") {
      setTabValue("reports");
    } else if (location.pathname === "/admin/issues") {
      setTabValue("issues");
    } else if (location.pathname === "/admin/featurednews") {
      setTabValue("featurednews");
    } else if (location.pathname === "/admin/featured") {
      setTabValue("featured");
    } else if (location.pathname === "/admin/rugged") {
      setTabValue("rugged");
    }
  }, [location.pathname]);

  useEffect(() => {
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
    headerSettingsState.setLogoBGColor(globalTheme.backgroundMainBeige.color);
    headerSettingsState.setLogoColor(globalTheme.backgroundMainOffBeige.color);
    headerSettingsState.setIsLogoOpen(true);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.backgroundMainBeige.color);
    return () => {
      tabSettingsState.setColor(globalTheme.backgroundMainYellow.color);
      tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      headerSettingsState.setIsLogoOpen(false);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  const styles = {
    tebMenuOuter: {
      maxWidth: "98%",
      color: globalTheme.backgroundMainOffBeige.color,
      "& .MuiTab-root": {
        color: globalTheme.backgroundMainOffBeige.color + " !important",
        opacity: 0.6,
      },
      "& .Mui-selected": {
        color: globalTheme.backgroundMainOffBeige.color + " !important",
        opacity: 1,
      },
      "& .MuiTabs-indicator": {
        backgroundColor: globalTheme.backgroundMainOffBeige.color + " !important",
      },
      "& .MuiSvgIcon-root": {
        color: globalTheme.backgroundMainOffBeige.color + " !important",
      },
    },
  };

  return (
    <div className="AdminOuter" style={{ backgroundColor: globalTheme.backgroundMainBeige.color }}>
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Admin</title>
      </Helmet>
      <Tabs
        value={tabValue}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        sx={styles.tebMenuOuter}
      >
        {/*<Tab label="Stats" value={"stats"} onClick={() => navigate("/admin")} />*/}
        <Tab
          label="Listings"
          value={"listings"}
          onClick={() => navigate("/admin")}
        />
        {/*<Tab
          label="Pre Listings"
          value={"prelistings"}
          onClick={() => navigate("/admin/prelistings")}
  />*/}
        <Tab
          label="Quarantine"
          value={"quarantine"}
          onClick={() => navigate("/admin/quarantine")}
        />
        <Tab
          label="Featured"
          value={"featured"}
          onClick={() => navigate("/admin/featured")}
        />
        <Tab
          label="Rugged"
          value={"rugged"}
          onClick={() => navigate("/admin/rugged")}
        />
        <Tab
          label="Reports"
          value={"reports"}
          onClick={() => navigate("/admin/reports")}
        />
        <Tab
          label="Issues"
          value={"issues"}
          onClick={() => navigate("/admin/issues")}
        />
        <Tab
          label="Reviews"
          value={"reviews"}
          onClick={() => navigate("/admin/reviews")}
        />
        {/*<Tab
          label="Pre Reviews"
          value={"prereviews"}
          onClick={() => navigate("/admin/prereviews")}
/>*/}
        <Tab
          label="Users"
          value={"users"}
          onClick={() => navigate("/admin/users")}
        />
        <Tab
          label="Featured News"
          value={"featurednews"}
          onClick={() => navigate("/admin/featurednews")}
        />
        <Tab
          label="Newsletter"
          value={"newsletter"}
          onClick={() => navigate("/admin/newsletter")}
        />
        <Tab
          label="News RSS"
          value={"news"}
          onClick={() => navigate("/admin/news")}
        />
      </Tabs>
      <Routes>
        <Route path="/*" element={<AdminListings />} />
        <Route path="/quarantine" element={<AdminErrorListings />} />
        <Route path="/reports" element={<AdminReports />} />
        <Route path="/issues" element={<AdminIssueReports />} />
        <Route path="/reviews" element={<AdminReviews />} />
        <Route path="/users" element={<AdminUsers />} />
        <Route path="/newsletter" element={<AdminNewsletter />} />
        <Route path="/news" element={<AdminNews />} />
        <Route path="/featurednews" element={<AdminFeaturedNews />} />
        <Route path="/featured" element={<AdminListingsFeatured />} />
        <Route path="/rugged" element={<AdminListingsRugged />} />
        {/*<Route path="*" element={<AdminStats />} />*/}
      </Routes>
    </div>
  );
}
