import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import styles from "./news.module.css";
import { useRssState, useThemeState } from "../../../../components/utils/globalStates";
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  orderBy,
  limit,
  doc,
} from "firebase/firestore";
import { firestoreDb } from "../../../../components/firebase/firestore";
import RssDbItem from "./RssDbItem";

interface ListingDialogProps {
  activeListing: string;
}

moment().format();
let isFetching = false;

export default function RssDBList({ activeListing }: ListingDialogProps) {
  const { listings, setListings, isFetched, setIsFetched } = useRssState();
  const [loading, setLoading] = useState(false);
  const globalTheme = useThemeState((state) => state.globalTheme);

  useEffect(() => {
    const fetchListings = async () => {
      if (isFetching) return;
      if (activeListing === "") return;
      if (isFetched) return;
      try {
        setLoading(true);
        isFetching = true;
        let rssArray: any[] = [];

        let q = query(
          collection(firestoreDb, `listings/${activeListing}/rss`),
          orderBy("date", "desc"),
          limit(10)
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          let tempDoc = doc.data();
          let tempDate = tempDoc.date;
          rssArray.push({ ...doc.data(), timestamp: tempDate, proider: "RSS" });
        });

        setListings(rssArray);
        setIsFetched(true);
        isFetching = false;
        setLoading(false);
      } catch (error) {
        console.error(error);
        setIsFetched(true);
        isFetching = false;
        setLoading(false);
      }
    };
    fetchListings();
  }, [activeListing]);

  return !loading ? (
    listings.length > 0 ? (
      <div className={styles.newsListingsOuter}>
        {listings.map((listing: any, index: number) => {
          return (
            <div className={styles.outsideKeyListing} key={index}>
              <RssDbItem listing={listing} />
            </div>
          );
        })}
      </div>
    ) : (
      <div className={styles.loadingDialog}>
        <p className={styles.newsListingsText} style={{ color: globalTheme.backgroundMainOffBeige.color }}>No other articles found.</p>
      </div>
    )
  ) : (
    <div className={styles.loadingDialog}>
      <CircularProgress color="inherit" sx={{ color: globalTheme.backgroundMainOffBeige.color }}/>
    </div>
  );
}
