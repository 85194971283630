import React from "react";
import "../Img.css";

function XLogo({ customColor }: { customColor?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 20"
      viewBox="0 0 500 499.93"
      className="socialsLogoSvg"
    >
      <path
        fill={customColor ? customColor : "#39393C"}
        d="M987.67 672.65c42.7 131.27-29.09 272.29-160.36 315s-272.29-29.1-315-160.36 29.09-272.29 160.36-315a249.92 249.92 0 01315 160.36zm-237.6-150.12c-125.61.17-227.3 102.14-227.13 227.75s102.14 227.3 227.75 227.12S978 875.27 977.82 749.65a227.43 227.43 0 00-227.75-227.12z"
        transform="translate(-500 -500.03)"
      ></path>
      <g>
        <path
          fill={customColor ? customColor : "#39393C"}
          d="M612.94 621.09l106.35 142.2-107 115.62h24.08l93.71-101.22 75.7 101.22h82l-112.39-150.2L875 621.09h-24.07l-86.3 93.22-69.73-93.22zm35.42 17.74H686l166.31 222.34h-37.66z"
          transform="translate(-500 -500.03)"
        ></path>
      </g>
    </svg>
  );
}

export default XLogo;
