import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import BottomNavigation from "@mui/material/BottomNavigation";
import Paper from "@mui/material/Paper";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import Face6OutlinedIcon from '@mui/icons-material/Face6Outlined';
import Face3OutlinedIcon from '@mui/icons-material/Face3Outlined';
import TokenOutlinedIcon from "@mui/icons-material/TokenOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";

import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import ErrorUrl from "../error/ErrorUrl";
import AuthVerify from "../auth/AuthVerify";
import AuthStart from "../auth/AuthStart";
import LoginView from "../auth/LoginView";
import RegisterView from "../auth/RegisterView";
import PickDisplayname from "../auth/PickDisplayname";
import AdminStart from "../admin/AdminStart";
import Achievements from "../achievements/Achievemnets";
import Dashboard from "../dashboard/Dashboard";
import ProjectsStart from "../listings/ProjectsStart";
import Listings from "../listings/Listings";
import Explore from "../explore/Explore";
import WalletsStart from "../wallets/WalletsStart";
import ReviewStart from "../reviews/ReviewStart";
import UserStart from "../user/UserStart";
import TokensStart from "../tokens/TokensStart";
import IssueReport from "../misc/issueReport/IssueReport";

import ProfileStart from "../profile/ProfileStart";

import { firebaseAuth } from "../../components/firebase/auth";
import {
  useSettingsStates,
  useScrollStates,
  useTabSettingsState,
  useThemeState,
} from "../../components/utils/globalStates";
import "./Start.css";

const tabEnums = [
  {
    value: "explore",
    label: "Explore",
    icon: <ExploreOutlinedIcon />,
    path: "/explore",
    uidRequired: false,
  },
  {
    value: "search",
    label: "Search",
    icon: <SearchOutlinedIcon />,
    path: "/search",
    uidRequired: false,
  },
  {
    value: "reviews",
    label: "Reviews",
    icon: <RateReviewOutlinedIcon />,
    path: "/reviews",
    uidRequired: false,
  },
  {
    value: "users",
    label: "Users",
    icon: <GroupOutlinedIcon />,
    path: "/users",
    uidRequired: false,
  },
  {
    value: "wallets",
    label: "Wallets",
    icon: <TokenOutlinedIcon />,
    path: "/wallets",
    uidRequired: false,
  },
  {
    value: "profile",
    label: "Profile",
    icon: <AccountCircleOutlinedIcon />,
    path: "/profile",
    uidRequired: false,
  },
  {
    value: "bookmarks",
    label: "Bookmarks",
    icon: <BookmarkBorderOutlinedIcon />,
    path: "/profile/bookmarks",
    uidRequired: false,
  },
  {
    value: "achievements",
    label: "Achievements",
    icon: <EmojiEventsOutlinedIcon />,
    path: "/achievements/list",
    uidRequired: false,
  },
  {
    value: "my-achievements",
    label: "My Achievements",
    icon: <EmojiEventsOutlinedIcon />,
    path: "/achievements",
    uidRequired: false,
  },
  {
    value: "my-listings",
    label: "My Listings",
    icon: <FolderOutlinedIcon />,
    path: "/users/listings/",
    uidRequired: true,
  },
  {
    value: "my-reviews",
    label: "My Reviews",
    icon: <RateReviewOutlinedIcon />,
    path: "/reviews/user/",
    uidRequired: true,
  },
  {
    value: "my-ratings",
    label: "My Ratings",
    icon: <StarBorderOutlinedIcon />,
    path: "/reviews/user/rating/",
    uidRequired: true,
  },
  {
    value: "following",
    label: "Following",
    icon: <Face6OutlinedIcon />,
    path: "/users/follow/out/",
    uidRequired: true,
  },
  {
    value: "followers",
    label: "Followers",
    icon: <Face3OutlinedIcon />,
    path: "/users/follow/in/",
    uidRequired: true,
  },
  {
    value: "settings",
    label: "Settings",
    icon: <SettingsOutlinedIcon />,
    path: "/profile/settings",
  },
];

function Start() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const [tabValue, setTabValue] = useState("dashboard");
  const [tabValueSettings, setTabValueSettings] = useState<string[]>([]);

  const navigate = useNavigate();
  const location = useLocation();

  const settingsStates = useSettingsStates((state) => state);
  const scrollStates = useScrollStates((state) => state);
  const tabSettingsState = useTabSettingsState((state) => state);
  const globalTheme = useThemeState(state => state.globalTheme);

  useEffect(() => {
    if (
      user === null ||
      user === undefined ||
      loading
    ) {
      return;
    }
    // @ts-ignore
    setTabValue(location.pathname.replace(user.uid, ""));
    settingsStates.setTabHighlight(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (settingsStates.tab.length > 3) {
      setTabValueSettings(settingsStates.tab);
    } else {
      setTabValueSettings([
        "/explore",
        "/profile/bookmarks",
        "/wallets",
        "/users",
      ]);
    }
  }, [settingsStates.tab]);

  const materialTabMenuStyles = {
  "& .MuiBottomNavigationAction-root.Mui-selected": {
    color: globalTheme.tabSelected.color,
  },
  "& .MuiBottomNavigationAction-root": {
    color: tabSettingsState.iconColor,
  },
  '& .MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label': {
    fontSize: "10px !important",
  },
  '& .MuiBottomNavigationAction-label': {
    fontSize: "10px !important",
  },
};

  return (
    <div className="Start">
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/search" element={<Explore />} />
        <Route path="/wallets/*" element={<WalletsStart />} />
        <Route path="/reviews/*" element={<ReviewStart />} />
        <Route path="/listings/*" element={<Listings />} />
        <Route path="/projects/*" element={<ProjectsStart />} />
        <Route path="/achievements/*" element={<Achievements />} />
        <Route path="/users/*" element={<UserStart />} />
        <Route path="/cryptos/*" element={<TokensStart />} />
        <Route path="/auth" element={<AuthStart />} />
        <Route path="/auth/login" element={<LoginView />} />
        <Route path="/auth/register" element={<RegisterView />} />
        <Route path="/auth/displayname" element={<PickDisplayname />} />
        <Route path="/auth/verify" element={<AuthVerify />} />
        <Route path="/admin/*" element={<AdminStart />} />
        <Route path="/profile/*" element={<ProfileStart />} />
        <Route path="/issues" element={<IssueReport />} />
        <Route path="/error" element={<ErrorUrl />} />
        <Route path="*" element={<ErrorUrl />} />
      </Routes>
      {/*<Fab
        size="small"
        sx={{
          backgroundColor: globalTheme.figmaLightBG.color,
          position: "fixed",
          right: "5%",
          bottom: "65px",
          borderRadius: "8px",
          border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          opacity: scrollStates.toTop && !scrollStates.isScrolling ? 1 : 0,
          transition: "opacity 0.25s ease-in-out",
          color: globalTheme.figmaPrimaryText.color,
          padding: "3px",
          height: "35px",
          width: "35px",
          zIndex: 9980,
          "&:hover": {
            backgroundColor: globalTheme.figmaLightBG.color,
          },
          "&:focus": {
            backgroundColor: globalTheme.figmaLightBG.color,
          },
          "@media (min-width: 900px)": {
            right: "20px",
          },
        }}
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <KeyboardArrowUpOutlinedIcon />
      </Fab>*/}
      {user !== null && (
        <Paper
          sx={{
            position: "fixed",
            backgroundColor: tabSettingsState.color,
            opacity: scrollStates.isScrolling ? 0.5 : 1,
            margin: "auto",
            bottom: "10px",
            zIndex: 1000,
            width: "calc(90%)",
            borderRadius: "8px",
            maxWidth: "800px",
            transition: "opacity 0.2s ease-in-out",
              border: `1px solid ${tabSettingsState.iconColor}`,
              boxShadow: globalTheme.boxShadow,
          }}
          elevation={0}
        >
          <BottomNavigation
            showLabels={false}
            value={tabValue}
            sx={{
              ...materialTabMenuStyles,
              backgroundColor: 'transparent',
              width: "100%",
            }}
          >
            <BottomNavigationAction
              value={"/"}
              label="Dashboard"
              icon={<DashboardOutlinedIcon />}
              onClick={() => navigate("/")}
              sx={{width: "20%", minWidth: "unset"}}
            />
            {tabValueSettings.map((tab, index) => {
              if (index < 3) {
                return (
                  <BottomNavigationAction
                    key={index}
                    value={tab}
                    label={
                      tabEnums.find((tabEnum) => tabEnum.path === tab)?.label
                    }
                    icon={
                      tabEnums.find((tabEnum) => tabEnum.path === tab)?.icon
                    }
                    // @ts-ignore
                    onClick={() => navigate(tab + (tabEnums.find((tabEnum) => tabEnum.path === tab)?.uidRequired ? user.uid : ""))}
                    sx={{width: "20%", minWidth: "unset"}}
                  />
                );
              } else {
                return null;
              }
            })}
            {scrollStates.toTop ? (
              <BottomNavigationAction
                    label={'To Top'}
                    icon={<KeyboardArrowUpOutlinedIcon />}
                    onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                    sx={{width: "20%", minWidth: "unset"}}
                  />
            ):(
              <BottomNavigationAction
                    value={tabValueSettings[3]}
                    sx={{width: "20%", minWidth: "unset"}}
                    label={
                      tabEnums.find((tabEnum) => tabEnum.path === tabValueSettings[3])?.label
                    }
                    icon={
                      tabEnums.find((tabEnum) => tabEnum.path === tabValueSettings[3])?.icon
                    }
                    // @ts-ignore
                    onClick={() => navigate(tabValueSettings[3] + (tabEnums.find((tabEnum) => tabEnum.path === tabValueSettings[3])?.uidRequired ? user.uid : ""))}
                  />
            )}
          </BottomNavigation>
        </Paper>
      )}
    </div>
  );
}

export default Start;

//<img src={logo} className="App-logo" alt="logo" />
