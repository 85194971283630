/**
 * Renders a list of users with sorting and pagination functionality.
 * @returns {JSX.Element} JSX.Element
 *
 * author: @jimmybengtsson (itchy-fingers)
 */

import React, { useState, useEffect } from "react";

import Pagination from "@mui/material/Pagination";
import { CircularProgress, ButtonBase } from "@mui/material";
import moment from "moment";
import { Helmet } from "react-helmet-async";
// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Radio from "@mui/material/Radio";
import {
  useUserSearchSortState,
  useTabSettingsState,
  useHeaderSettingsState,
  useThemeState,
  useDeviceState,
} from "../../../components/utils/globalStates";
import {
  algoliaUsersCreatedAtDesc,
  algoliaUsersLastActiveDesc,
  algoliaUsersPointsDayDesc,
  algoliaUsersPointsWeekDesc,
  algoliaUsersPointsDesc,
  algoliaUsersPointsHourDesc,
  algoliaUsersRelevance,
} from "../../../components/search/algoliaSearch";
import UserSearchInfo from "../../../components/ui/topBoxes/UserSearchInfo";
import UserListItemNew from "../../../components/ui/listItems/UserListItemNew";
import PickersCardView from "../../../components/ui/pickers/PickerCardView";
import "./UserList.css";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

moment().format();

export default function UserList() {
  const headerSettingsState = useHeaderSettingsState();
  const tabSettingsState = useTabSettingsState();
  const userSearchState = useUserSearchSortState();
  const deviceState = useDeviceState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [userList, setUserList] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [sortDialogOpen, setSortDialogOpen] = useState(false);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    userSearchState.setPage(value - 1);
  };

  /**
   * Fetches points template based on the provided fetch object and from date.
   * @param fetchObj - The fetch object used to search for user points.
   * @param fromDate - The from date in milliseconds since the Unix epoch.
   *
   * author: @jimmybengtsson (itchy-fingers)
   */
  const fetchPointsTemplate = (fetchObj: any, fromDate: number) => {
    setLoading(true);
    fetchObj
      .search(userSearchState.search, {
        hitsPerPage: userSearchState.hitsPerPage,
        page: userSearchState.page,
        numericFilters: [`pointsUpdated > ${fromDate}`],
      })
      .then((res: any) => {
        userSearchState.setListingPages(res.nbPages);
        userSearchState.setListingCount(res.nbHits);
        setUserList(res.hits);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setLoading(false);
      });
  };

  /**
   * Fetches the template based on the provided fetch object.
   * @param fetchObj - The fetch object used to search for users.
   *
   * author: @jimmybengtsson (itchy-fingers)
   */
  const fetchTemplate = (fetchObj: any) => {
    setLoading(true);
    fetchObj
      .search(userSearchState.search, {
        hitsPerPage: userSearchState.hitsPerPage,
        page: userSearchState.page,
      })
      .then((res: any) => {
        userSearchState.setListingPages(res.nbPages);
        userSearchState.setListingCount(res.nbHits);
        setUserList(res.hits);
        setLoading(false);
      })
      .catch((err: any) => {
        console.error(err);
        setLoading(false);
      });
  };

  /**
   * Fetches the user list based on the sort type.
   *
   * author: @jimmybengtsson (itchy-fingers)
   */
  useEffect(() => {
    if (userSearchState.sortType === "lastActive") {
      fetchTemplate(algoliaUsersLastActiveDesc);
    } else if (userSearchState.sortType === "points") {
      fetchTemplate(algoliaUsersPointsDesc);
    } else if (userSearchState.sortType === "pointsHour") {
      fetchPointsTemplate(algoliaUsersPointsHourDesc, Date.now() - 3600000);
    } else if (userSearchState.sortType === "pointsDay") {
      fetchPointsTemplate(algoliaUsersPointsDayDesc, Date.now() - 86400000);
    } else if (userSearchState.sortType === "pointsWeek") {
      fetchPointsTemplate(algoliaUsersPointsWeekDesc, Date.now() - 604800000);
    } else if (userSearchState.sortType === "relevance") {
      fetchTemplate(algoliaUsersRelevance);
    } else {
      fetchTemplate(algoliaUsersCreatedAtDesc);
    }
  }, [
    userSearchState.sortOrder,
    userSearchState.sortType,
    userSearchState.hitsPerPage,
    userSearchState.page,
    userSearchState.search,
  ]);

  /**
   * Sets the header settings and tab settings state when the component mounts.
   *
   * author: @jimmybengtsson (itchy-fingers)
   */
  useEffect(() => {
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
    headerSettingsState.setLogoBGColor(globalTheme.backgroundMainBeige.color);
    headerSettingsState.setLogoColor(globalTheme.backgroundMainOffBeige.color);
    headerSettingsState.setIsLogoOpen(true);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.backgroundMainBeige.color);
    return () => {
      headerSettingsState.setIsBackButtonOpen(false);
      tabSettingsState.setColor(globalTheme.backgroundMainYellow.color);
      tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      headerSettingsState.setIsLogoOpen(false);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  const calculateSortText = (projectObj: any) => {
    if (userSearchState.sortType === "lastActive") {
      return `Active ${moment(projectObj.lastActive).fromNow()}`;
    } else if (userSearchState.sortType === "points") {
      return `${projectObj.points} Points`;
    } else if (userSearchState.sortType === "pointsHour") {
      return `${projectObj.latestHourPoints} Points last hour`;
    } else if (userSearchState.sortType === "pointsDay") {
      return `${projectObj.latestDayPoints} Points last day`;
    } else if (userSearchState.sortType === "pointsWeek") {
      return `${projectObj.latestWeekPoints} Points last week`;
    } else if (userSearchState.sortType === "relevance") {
      return "";
    } else {
      return `Joined ${moment(projectObj.createdAt).fromNow()}`;
    }
  };

  const styles = {
    dialogRoot: {
      zIndex: 9999,
      backgroundColor: "rgba(253, 247, 234, 0.5)",
      "& .MuiDialog-paper": {
        backgroundColor: globalTheme.backgroundMainBeige.color,
        color: globalTheme.backgroundMainOffBeige.color,
        width: "94%",
        maxWidth: "600px",
        margin: 0,
        paddingBottom: "70px",
        paddingTop: "20px",
        position: "absolute",
        bottom: "-10px",
      },
    },
    dialogTitle: {
      color: globalTheme.backgroundMainOffBeige.color,
      textAlign: "center",
      fontSize: "16px",
      marginTop: "20px",
      fontWeight: 600,
    },
    radio: {
      color: globalTheme.backgroundMainOffBeige.color,
      "&.Mui-checked": {
        color: globalTheme.backgroundMainOffBeige.color,
      },
    },
  };

  return (
    <div
      className="UserList"
      style={{ backgroundColor: globalTheme.backgroundMainBeige.color }}
    >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - User List</title>
      </Helmet>
      <UserSearchInfo />
      <div className="UserListSort" style={{
                marginTop: "0px",
                marginBottom: "10px",
              }}>
        <ButtonBase
          className="UserListSortButton"
          onClick={() => {
            setSortDialogOpen(true);
          }}
        >
          <div className="UserListSortInner">
            <p
              className="UserListSortTitle"
              style={{ color: globalTheme.textMainGrey.color }}
            >
              Sort by
            </p>
            <p
              className="UserListSortTitle"
              style={{
                color: globalTheme.backgroundMainOffBeige.color,
                marginLeft: "5px",
                fontWeight: 500,
              }}
            >
              {userSearchState.sortType === "createdAt"
                ? "Latest"
                : userSearchState.sortType === "points"
                ? "Points"
                : userSearchState.sortType === "lastActive"
                ? "Last Active"
                : userSearchState.sortType === "pointsHour"
                ? "Points per hour"
                : userSearchState.sortType === "pointsDay"
                ? "Points per day"
                : userSearchState.sortType === "pointsWeek"
                ? "Points per week"
                : "Relevance"}
            </p>
          </div>
        </ButtonBase>
        <PickersCardView iconColor={globalTheme.backgroundMainOffYellow.color} />
      </div>
      {loading ? (
        <div className="UserListLoading">
          <CircularProgress
            sx={{ color: globalTheme.backgroundMainOffBeige.color }}
          />
        </div>
      ) : userList.length > 0 ? (
        <div className="UserListInner" style={{ marginTop: "0px" }}>
          {userList.map((user, index) => (
            <UserListItemNew
              listItem={user}
                indexKey={index}
                itemSize={deviceState.viewSizeBox}
                clickAdmin={false}
                key={user.id}
            />
          ))}
        </div>
      ) : (
        <div className="UserListLoading">
          <p
            className="UserListSortTitle"
            style={{ color: globalTheme.backgroundMainOffBeige.color }}
          >
            No users found
          </p>
        </div>
      )}
      {!loading &&
        userList.length > 0 &&
        userSearchState.listingPages !== 0 && (
          <Pagination
            count={userSearchState.listingPages}
            page={userSearchState.page + 1}
            onChange={handlePageChange}
            sx={{
              marginTop: "20px",
              marginBottom: "0px",
              "& button": {
                color: globalTheme.backgroundMainOffBeige.color,
              },

              "& .MuiPaginationItem-root": {
                color: globalTheme.backgroundMainOffBeige.color,
              },
              "& .MuiPaginationItem-page.Mui-selected": {
                backgroundColor: globalTheme.backgroundMainOffBeige.color,
                color: globalTheme.backgroundMainBeige.color,
              },
            }}
            size="small"
          />
        )}
      <Dialog
        open={sortDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSortDialogOpen(false)}
        sx={styles.dialogRoot}
      >
        <DialogTitle sx={styles.dialogTitle}>{"Sort by"}</DialogTitle>
        <DialogContent>
          <div className="UserListSortDialog">
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                userSearchState.setSortType("relevance");
                userSearchState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Relevance
                </p>
                <Radio
                  checked={userSearchState.sortType === "relevance"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                userSearchState.setSortType("createdAt");
                userSearchState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Latest
                </p>
                <Radio
                  checked={userSearchState.sortType === "createdAt"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                userSearchState.setSortType("lastActive");
                userSearchState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Last Active
                </p>
                <Radio
                  checked={userSearchState.sortType === "lastActive"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                userSearchState.setSortType("points");
                userSearchState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Points
                </p>
                <Radio
                  checked={userSearchState.sortType === "points"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                userSearchState.setSortType("pointsHour");
                userSearchState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Points per hour
                </p>
                <Radio
                  checked={userSearchState.sortType === "pointsHour"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                userSearchState.setSortType("pointsDay");
                userSearchState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Points per day
                </p>
                <Radio
                  checked={userSearchState.sortType === "pointsDay"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                userSearchState.setSortType("pointsWeek");
                userSearchState.setPage(0);
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Points per week
                </p>
                <Radio
                  checked={userSearchState.sortType === "pointsWeek"}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
