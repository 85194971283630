import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useThemeState } from "../../../components/utils/globalStates";
import "./DashWidgets.css";

export default function ShortCutWidget({
  shortcutTitle,
  shortcutPath,
  shortcutIcon,
  isEditing,
  deleteWidget,
  fulldata,
  elementIndex,
  rowIndex,
}: {
  shortcutTitle: string;
  shortcutPath: string;
  shortcutIcon: string;
  isEditing: boolean;
  deleteWidget: Function;
  fulldata: any;
  elementIndex: number;
  rowIndex: number;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const navigate = useNavigate();
  const [isDeleteing, setIsDeleting] = useState(false);
  // On delete
  const onDelete = () => {
    setIsDeleting(true);
    deleteWidget(rowIndex, elementIndex);
  };

  const onShortcutClick = () => {
    if (isEditing) return;
    navigate(shortcutPath);
  };

  return (
    <div
      onClick={onShortcutClick}
      className="ShortCutWidget"
      style={{
        color: globalTheme.figmaPrimaryText.color,
        border: isEditing
          ? `1px dashed ${globalTheme.backgroundMainOffYellow.color}`
          : `1px solid ${globalTheme.figmaPrimaryText.color}`,
        borderRadius: isEditing ? "0px" : "8px",
        backgroundColor:
          fulldata.bgColorCode !== null &&
          fulldata.bgColorCode !== undefined &&
          fulldata.bgColorCode !== ""
            ? fulldata.bgColorCode
            : "transparent",
      }}
    >
      <span style={{ marginTop: "5px" }} className="material-symbols-outlined">
        {shortcutIcon}
      </span>
      <p style={{ marginBottom: "5px" }} className="ShortCutWidgetTitle">
        {shortcutTitle}
      </p>
      {isEditing && (
        <IconButton
          size="small"
          sx={{
            position: "absolute",
            right: "-1px",
            top: "-1px",
            padding: "2px",
            borderRadius: "0px 0px 0px 5px",
            width: "45%",
            color: globalTheme.backgroundMainOffYellow.color,
            backgroundColor: globalTheme.backgroundMainYellow.color,
            border: `1px dashed ${globalTheme.backgroundMainOffYellow.color}`,
            "&:hover": {
              backgroundColor: globalTheme.backgroundMainYellow.color,
            },
          }}
          onClick={onDelete}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      )}
    </div>
  );
}
