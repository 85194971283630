import React, { useState, useEffect } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import { useThemeState } from "../../utils/globalStates";
import "./TopBoxes.css";

export default function AchievementsProfileInfo() {
  const [showInfo, setShowInfo] = useState(false);
  const globalTheme = useThemeState((state) => state.globalTheme);

  // Check local storage if user has seen the info
  useEffect(() => {
    if (localStorage.getItem("achievementsProfileInfo") === "true") {
      setShowInfo(false);
    } else {
      setShowInfo(true);
    }
  }, []);

  const onInfoClose = () => {
    setShowInfo(false);
    localStorage.setItem("achievementsProfileInfo", "true");
  };

  const onInfoOpen = () => {
    localStorage.removeItem("achievementsProfileInfo");
    setShowInfo(true);
  };
  return (
    <div
      className="SearchAndExplore"
      style={{
        backgroundColor: globalTheme.figmaCoreOrange.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        ...globalTheme.boxShadow,
        maxWidth: "770px",
      }}
    >
      {!showInfo && (
        <InfoIcon
          onClick={onInfoOpen}
          sx={{
            color: globalTheme.figmaPrimaryText.color,
            cursor: "pointer",
            position: "absolute",
            right: "6px",
            top: "6px",
            fontSize: "17px",
            "@media (min-width: 350px)": {
              fontSize: "18px",
            },
            "@media (min-width: 550px)": {
              fontSize: "19px",
            },
            "@media (min-width: 700px)": {
              fontSize: "20px",
            },
          }}
        />
      )}
      <div className="SearchAndExploreHeader">
        <p
          className="SearchAndExploreHeaderTitle"
          style={{ color: globalTheme.figmaPrimaryText.color, maxWidth: "95%" }}
        >
          My Achievements
        </p>
        <p
          className="SearchAndExploreHeaderSub"
          style={{
            color: globalTheme.figmaPrimaryText.color,
            maxWidth: "95%",
            marginBottom: "-10px",
          }}
        >
          View your earned achievements
        </p>
      </div>
      {showInfo && (
        <div
          className="ExploreInfo"
          style={{
            backgroundColor: globalTheme.figmaYellowAlt.color,
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            marginBottom: "5px",
          }}
        >
          <p
            className="ExploreInfoText"
            style={{ color: globalTheme.figmaPrimaryText.color }}
          >
            This is your personal achievements page. Here you can view all the
            achievements you have earned and see the progress of the ones you
            are working on. You can also see the achievements of other users by
            visiting their profile.
          </p>
          <CancelIcon
            onClick={onInfoClose}
            sx={{
              color: globalTheme.figmaPrimaryText.color,
              cursor: "pointer",
              fontSize: "17px",
              "@media (min-width: 350px)": {
                fontSize: "18px",
              },
              "@media (min-width: 550px)": {
                fontSize: "19px",
              },
              "@media (min-width: 700px)": {
                fontSize: "20px",
              },
            }}
          />
        </div>
      )}
    </div>
  );
}
