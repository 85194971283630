import React, { useState, useEffect } from "react";

import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import AchievementsList from "./list/AchievementsList";
import AchievementsProfile from "./profile/AchievementsProfile";
import AchievementsUser from "./user/AchievementsUser";

import "./Achievements.css";

export default function Achievements() {
  return (
    <div className="Achievements">
      <Routes>
        <Route path="/" element={<AchievementsProfile />} />
        <Route path="/user/:userId" element={<AchievementsUser />} />
        <Route path="/all" element={<AchievementsList />} />
        <Route path="*" element={<AchievementsList />} />
      </Routes>
    </div>
  );
}
