import { LazyLoadImg } from "../../../../components/ui/misc/LazyLoadImg";
import Tooltip from "@mui/material/Tooltip";
import { useThemeState } from "../../../../components/utils/globalStates";
import "./UserPageComp.css";

export default function UserPageAchievementsItem({
  achievement,
}: {
  achievement: any;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  return (
    <div
      className="ProfileAchivementItem"
      style={{
        backgroundColor: globalTheme.backgroundMainBeigeDark.color,
        border: `1px solid ${globalTheme.solidMainBlack.color}`,
      }}
    >
      {achievement.url !== null &&
      achievement.url !== undefined &&
      achievement.url !== "" ? (
        <Tooltip
          title={
            achievement.name +
            " - " +
            achievement.subtitle +
            " - " +
            achievement.points +
            " points"
          }
        >
          <div className="UserPageV2AchievementsListItemImg">
            <LazyLoadImg imgUrl={achievement.url} />
          </div>
        </Tooltip>
      ) : (
        /*<div className="UserPageV2AchievementsListItemImg">
          <LazyLoadImg
            imgUrl={`https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Fachievements%2F${achievement.achievement}.png?alt=media`}
          />
        </div>*/
        <Tooltip
          title={
            achievement.name +
            " - " +
            achievement.subtitle +
            " - " +
            achievement.points +
            " points"
          }
        >
          <div className="UserPageV2AchievementsListItemImg">
            <span
              style={{ color: globalTheme.backgroundMainOffBeigeDark.color }}
              className="material-symbols-outlined"
            >
              {achievement.icon}
            </span>
          </div>
        </Tooltip>
      )}
    </div>
  );
}
