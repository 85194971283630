import React, {useState, useEffect} from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import UserFollowers from './UserFollowers';
import UserFollowing from './UserFollowing';
import './UserFollow.css';

export default function UserFollowStart() {

  return (
    <div className="UserFollowStart" >
      <Routes>
        <Route path="/out/:userId" element={<UserFollowing />} />
        <Route path="/in/:userId" element={<UserFollowers />} />
      </Routes>
    </div>
  );
}
