import React, { useEffect, useState } from "react";

import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { getDocs, collection } from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { saveAs } from "file-saver";
import { useThemeState } from "../../../components/utils/globalStates";
import "./AdminComponents.css";

export default function AdminNewsletterExportList() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("Pick type");
  const [exportType, setExportType] = useState("");

  const globalTheme = useThemeState((state) => state.globalTheme);

  const onExport = async () => {
    if (exportType === "") return;
    try {
      setLoading(true);
      let emails: string[] = [];
      const querySnapshot = await getDocs(
        collection(firestoreDb, "newsletter")
      );
      querySnapshot.forEach((doc) => {
        emails.push(doc.data().email);
      });

      if (exportType === "json") {
        onJsonExport(emails);
      } else if (exportType === "csv") {
        onCsvExport(emails);
      } else if (exportType === "txt") {
        onTxtExport(emails);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const onJsonExport = async (tempEmails: string[]) => {
    let jsonString = JSON.stringify(tempEmails);
    let tempDate = new Date(Date.now());
    var blob = new Blob([jsonString], { type: "application/json" });
    saveAs(
      blob,
      `GATH3R-newsletter-email-list-${tempDate.getFullYear()}-${
        tempDate.getMonth() + 1
      }-${tempDate.getDate()}.json`
    );
    setLoading(false);
  };

  const onCsvExport = async (tempEmails: string[]) => {
    let csvString = tempEmails.join("\n");
    let tempDate = new Date(Date.now());
    var blob = new Blob([csvString], { type: "text/csv" });
    saveAs(
      blob,
      `GATH3R-newsletter-email-list-${tempDate.getFullYear()}-${
        tempDate.getMonth() + 1
      }-${tempDate.getDate()}.csv`
    );
    setLoading(false);
  };

  const onTxtExport = async (tempEmails: string[]) => {
    let txtString = tempEmails.join("\n");
    let tempDate = new Date(Date.now());
    var blob = new Blob([txtString], { type: "text/plain" });
    saveAs(
      blob,
      `GATH3R-newsletter-email-list-${tempDate.getFullYear()}-${
        tempDate.getMonth() + 1
      }-${tempDate.getDate()}.txt`
    );
    setLoading(false);
  };

  useEffect(() => {
    if (exportType === "") {
      setError("Pick type");
    } else {
      setError("Export " + exportType);
    }
  }, [exportType]);

 const styles = {
    input: {
      backgroundColor: "transparent",
      color: globalTheme.backgroundMainOffBeige.color,
      borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      "& .MuiInputLabel-root": {
        color: globalTheme.backgroundMainOffBeige.color + " !important",
        "& .Mui-focused ": {
          color: globalTheme.backgroundMainOffBeige.color,
        },
      },
    },
    select: {
      backgroundColor: "transparent",
      color: globalTheme.backgroundMainOffBeige.color,
      borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      "& .MuiSelect-root": {
        "& fieldset": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
        "&:after": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
      },
      "&.Mui-focused fieldset": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      },
      "&.Mui-focused ": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      },
    },

    selectMenu: {
      color: globalTheme.backgroundMainOffBeige.color,
      "& .MuiPaper-root": {
        backgroundColor: globalTheme.backgroundMainBeige.color,
        color: globalTheme.backgroundMainOffBeige.color,
        "& .MuiMenuItem-root": {
          opacity: 0.6,
          "& .Mui-selected": {
            color: globalTheme.backgroundMainOffBeige.color,
            backgroundColor: globalTheme.backgroundMainBeige.color,
            opacity: 1,
          },
        },
        "& .Mui-selected": {
          color: globalTheme.backgroundMainOffBeige.color,
          backgroundColor: globalTheme.backgroundMainBeige.color,
          opacity: 1,
        },
      },
    },
  };

  return (
    <div
      className="AdminNewsletterAddEmail"
      style={{
        borderBottom: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
        color: globalTheme.backgroundMainOffBeige.color,
        marginTop: "0px",
        paddingTop: "10px",
      }}
    >
      <FormControl fullWidth sx={{ ...styles.input, textAlign: "left" }}>
        <InputLabel>Export list to</InputLabel>
        <Select
          value={exportType}
          label="Export list to"
          onChange={(e) => setExportType(e.target.value as string)}
          sx={{ ...styles.select, marginRight: "20px" }}
          MenuProps={{
            sx: styles.selectMenu,
          }}
        >
          <MenuItem value={"json"}>JSON</MenuItem>
          <MenuItem value={"csv"}>CSV</MenuItem>
          <MenuItem value={"txt"}>TXT</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        color="inherit"
        size="small"
        sx={{ fontSize: "14px", minWidth: "30%" }}
        onClick={onExport}
        disabled={exportType === "" || loading}
      >
        {loading ? <CircularProgress color="inherit" size={"20px"} /> : error}
      </Button>
    </div>
  );
}
