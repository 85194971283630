import { create } from "zustand";
import { globalThemeLight } from "../theme/globalTheme";

interface DeviceState {
  isMobile: boolean;
  setMobile: (bool: boolean) => void;
  isNotificationOpen: boolean;
  setIsNotificationOpen: (bool: boolean) => void;
  deviceWidth: number;
  setDeviceWidth: (width: number) => void;
  viewSizeBox: 'small' | 'medium' | 'large';
  setViewSizeBox: (size: 'small' | 'medium' | 'large') => void;
}

interface AdminState {
  activeListing: string;
  setActiveListing: (listing: string) => void;
  activeUser: string;
  setActiveUser: (user: string) => void;
  activeListingName: string;
  setActiveListingName: (listing: string) => void;
  activeReview: string;
  setActiveReview: (review: string) => void;
  activeReviewName: string;
  setActiveReviewName: (review: string) => void;
  activeReviewUser: string;
  setActiveReviewUser: (review: string) => void;
}

interface SnackbarState {
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
  message: string;
  setMessage: (message: string) => void;
  actionText: string;
  setActionText: (text: string) => void;
  openLength: number | null;
  setOpenLength: (length: number | null) => void;
  isActionLoading: boolean;
  setIsActionLoading: (bool: boolean) => void;
}

interface SnackbarErrorState {
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
  message: string;
  setMessage: (message: string) => void;
  actionText: string;
  setActionText: (text: string) => void;
  openLength: number | null;
  setOpenLength: (length: number | null) => void;
}

interface AuthStates {
  role: string;
  setRole: (role: string) => void;
  userData: any | null;
  setUserData: (userData: any) => void;
  userDataLoaded: boolean;
  setUserDataLoaded: (bool: boolean) => void;
}

interface SettingsStates {
  tab: string[];
  setTab: (tab: string[]) => void;
  tabHighlight: string;
  setTabHighlight: (tab: string) => void;
  dashboard: any[];
  setDashboard: (dashboard: any[]) => void;
  dashboardInitialLoaded: boolean;
  setDashboardInitialLoaded: (bool: boolean) => void;
}

interface ScrollStates {
  isScrolling: boolean;
  setIsScrolling: (bool: boolean) => void;
  toTop: boolean;
  setToTop: (bool: boolean) => void;
  isScrollingDown: boolean;
  setIsScrollingDown: (bool: boolean) => void;
}

interface NewsState {
  listings: any[];
  setListings: (listings: any[]) => void;
  isFetched: boolean;
  setIsFetched: (bool: boolean) => void;
}

interface ExploreSortState {
  search: string;
  setSearch: (search: string) => void;
  sortType: string;
  setSortType: (sort: string) => void;
  sortOrder: string;
  setSortOrder: (order: string) => void;
  hitsPerPage: number;
  setHitsPerPage: (number: number) => void;
  page: number;
  setPage: (number: number) => void;
  listingCount: number;
  setListingCount: (number: number) => void;
  listingPages: number;
  setListingPages: (number: number) => void;
  results: any[];
  setResults: (results: any[]) => void;
}

interface HeaderSettingsState {
  isBackButtonOpen: boolean;
  setIsBackButtonOpen: (bool: boolean) => void;
  isSearchbarOpen: boolean;
  setIsSearchbarOpen: (bool: boolean) => void;
  isLogoOpen: boolean;
  setIsLogoOpen: (bool: boolean) => void;
  logoBGColor: string;
  setLogoBGColor: (color: string) => void;
  logoColor: string;
  setLogoColor: (color: string) => void;
  isSettingsButtonOpen: boolean;
  setIsSettingsButtonOpen: (bool: boolean) => void;
  isProfileButtonOpen: boolean;
  setIsProfileButtonOpen: (bool: boolean) => void;
}

interface TabSettingsState {
  color: string;
  setColor: (color: string) => void;
  iconColor: string;
  setIconColor: (color: string) => void;
}

interface AchievementsState {
  earnedAchievements: any[];
  setEarnedAchievements: (achievements: any[]) => void;
  earnedAchievementsLength: number;
  setEarnedAchievementsLength: (number: number) => void;
  earnedAchievementsLoaded: boolean;
  setEarnedAchievementsLoaded: (bool: boolean) => void;
  hasNewAchievements: boolean;
  setHasNewAchievements: (bool: boolean) => void;
  newAchievementsLength: number;
  setNewAchievementsLength: (number: number) => void;
  allAchievements: any[];
  setAllAchievements: (achievements: any[]) => void;
  allAchievementsLoaded: boolean;
  setAllAchievementsLoaded: (bool: boolean) => void;
  progressAchievements: any[];
  setProgressAchievements: (achievements: any[]) => void;
  progressAchievementsLoaded: boolean;
  setProgressAchievementsLoaded: (bool: boolean) => void;
}

interface TokensListState {
  tokens: any[];
  setTokens: (tokens: any[]) => void;
  tokensTotalSupply: number;
  setTokensTotalSupply: (number: number) => void;
  tokenList: any[];
  setTokenList: (tokenList: any[]) => void;
  tokenPage: number;
  setTokenPage: (number: number) => void;
  tokenListPage: number;
  setTokenListPage: (number: number) => void;
  pageSize: number;
  setPageSize: (number: number) => void;
}

interface ThemeState {
  isDark: boolean;
  setIsDark: (bool: boolean) => void;
  globalTheme: any;
  setGlobalTheme: (theme: any) => void;
}

export const useDeviceState = create<DeviceState>((set) => ({
  isMobile: true,
  setMobile: (newState) => set((state) => ({ isMobile: newState })),
  isNotificationOpen: false,
  setIsNotificationOpen: (newState) =>
    set((state) => ({ isNotificationOpen: newState })),
  deviceWidth: 600,
  setDeviceWidth: (width) => set((state) => ({ deviceWidth: width })),
  viewSizeBox: 'medium',
  setViewSizeBox: (size) => set((state) => ({ viewSizeBox: size })),
}));

export const useAdminState = create<AdminState>((set) => ({
  activeListing: "",
  setActiveListing: (listing) => set((state) => ({ activeListing: listing })),
  activeUser: "",
  setActiveUser: (user) => set((state) => ({ activeUser: user })),
  activeListingName: "",
  setActiveListingName: (listing) =>
    set((state) => ({ activeListingName: listing })),
  activeReview: "",
  setActiveReview: (review) => set((state) => ({ activeReview: review})),
  activeReviewName: "",
  setActiveReviewName: (review) =>
    set((state) => ({ activeReviewName: review })),
  activeReviewUser: "",
  setActiveReviewUser: (review) =>
    set((state) => ({ activeReviewUser: review })),
}));

export const useSnackbarState = create<SnackbarState>((set) => ({
  isOpen: false,
  setIsOpen: (newState) => set((state) => ({ isOpen: newState })),
  message: "",
  setMessage: (message) => set((state) => ({ message: message })),
  actionText: "",
  setActionText: (text) => set((state) => ({ actionText: text })),
  openLength: 5000,
  setOpenLength: (length) => set((state) => ({ openLength: length })),
  isActionLoading: false,
  setIsActionLoading: (bool) => set((state) => ({ isActionLoading: bool })),
}));

export const useSnackbarErrorState = create<SnackbarErrorState>((set) => ({
  isOpen: false,
  setIsOpen: (newState) => set((state) => ({ isOpen: newState })),
  message: "",
  setMessage: (message) => set((state) => ({ message: message })),
  actionText: "",
  setActionText: (text) => set((state) => ({ actionText: text })),
  openLength: 5000,
  setOpenLength: (length) => set((state) => ({ openLength: length })),
}));

export const useAuthStates = create<AuthStates>((set) => ({
  role: "",
  setRole: (role) => set((state) => ({ role: role })),
  userData: null,
  setUserData: (userData) => set((state) => ({ userData: userData })),
  userDataLoaded: false,
  setUserDataLoaded: (bool) => set((state) => ({ userDataLoaded: bool })),
}));

export const useSettingsStates = create<SettingsStates>((set) => ({
  tab: ["/explore", "/users", "/profile", "/profile/settings"],
  setTab: (tab) => set((state) => ({ tab: tab })),
  tabHighlight: "",
  setTabHighlight: (tab) => set((state) => ({ tabHighlight: tab })),
  dashboard: [],
  setDashboard: (dashboard) => {
    set((state) => ({ dashboard: dashboard }));
  },
  dashboardInitialLoaded: false,
  setDashboardInitialLoaded: (bool) =>
    set((state) => ({ dashboardInitialLoaded: bool })),
}));

export const useScrollStates = create<ScrollStates>((set) => ({
  isScrolling: false,
  setIsScrolling: (bool) => set((state) => ({ isScrolling: bool })),
  toTop: false,
  setToTop: (number) => set((state) => ({ toTop: number })),
  isScrollingDown: false,
  setIsScrollingDown: (bool) => set((state) => ({ isScrollingDown: bool })),
}));

export const useMediumState = create<NewsState>((set) => ({
  listings: [],
  setListings: (newState) => set((state) => ({ listings: newState })),
  isFetched: false,
  setIsFetched: (newState) => set((state) => ({ isFetched: newState })),
}));

export const useRssState = create<NewsState>((set) => ({
  listings: [],
  setListings: (newState) => set((state) => ({ listings: newState })),
  isFetched: false,
  setIsFetched: (newState) => set((state) => ({ isFetched: newState })),
}));

export const useRss3State = create<NewsState>((set) => ({
  listings: [],
  setListings: (newState) => set((state) => ({ listings: newState })),
  isFetched: false,
  setIsFetched: (newState) => set((state) => ({ isFetched: newState })),
}));

export const useExploreSortState = create<ExploreSortState>((set) => ({
  search: "",
  setSearch: (search) => set((state) => ({ search: search })),
  sortType: "createdAt",
  setSortType: (sort) => set((state) => ({ sortType: sort })),
  sortOrder: "desc",
  setSortOrder: (order) => set((state) => ({ sortOrder: order })),
  hitsPerPage: 24,
  setHitsPerPage: (number) => set((state) => ({ hitsPerPage: number })),
  page: 0,
  setPage: (number) => set((state) => ({ page: number })),
  listingCount: 0,
  setListingCount: (number) => set((state) => ({ listingCount: number })),
  listingPages: 0,
  setListingPages: (number) => set((state) => ({ listingPages: number })),
  results: [],
  setResults: (results) => set((state) => ({ results: results })),
}));

export const useUserListingsSortState = create<ExploreSortState>((set) => ({
  search: "",
  setSearch: (search) => set((state) => ({ search: search })),
  sortType: "createdAt",
  setSortType: (sort) => set((state) => ({ sortType: sort })),
  sortOrder: "desc",
  setSortOrder: (order) => set((state) => ({ sortOrder: order })),
  hitsPerPage: 24,
  setHitsPerPage: (number) => set((state) => ({ hitsPerPage: number })),
  page: 0,
  setPage: (number) => set((state) => ({ page: number })),
  listingCount: 0,
  setListingCount: (number) => set((state) => ({ listingCount: number })),
  listingPages: 0,
  setListingPages: (number) => set((state) => ({ listingPages: number })),
  results: [],
  setResults: (results) => set((state) => ({ results: results })),
}));

export const useUserSearchSortState = create<ExploreSortState>((set) => ({
  search: "",
  setSearch: (search) => set((state) => ({ search: search })),
  sortType: "relevance",
  setSortType: (sort) => set((state) => ({ sortType: sort })),
  sortOrder: "desc",
  setSortOrder: (order) => set((state) => ({ sortOrder: order })),
  hitsPerPage: 24,
  setHitsPerPage: (number) => set((state) => ({ hitsPerPage: number })),
  page: 0,
  setPage: (number) => set((state) => ({ page: number })),
  listingCount: 0,
  setListingCount: (number) => set((state) => ({ listingCount: number })),
  listingPages: 0,
  setListingPages: (number) => set((state) => ({ listingPages: number })),
  results: [],
  setResults: (results) => set((state) => ({ results: results })),
}));

export const useReviewSearchSortState = create<ExploreSortState>((set) => ({
  search: "",
  setSearch: (search) => set((state) => ({ search: search })),
  sortType: "relevance",
  setSortType: (sort) => set((state) => ({ sortType: sort })),
  sortOrder: "desc",
  setSortOrder: (order) => set((state) => ({ sortOrder: order })),
  hitsPerPage: 24,
  setHitsPerPage: (number) => set((state) => ({ hitsPerPage: number })),
  page: 0,
  setPage: (number) => set((state) => ({ page: number })),
  listingCount: 0,
  setListingCount: (number) => set((state) => ({ listingCount: number })),
  listingPages: 0,
  setListingPages: (number) => set((state) => ({ listingPages: number })),
  results: [],
  setResults: (results) => set((state) => ({ results: results })),
}));

export const useHeaderSettingsState = create<HeaderSettingsState>((set) => ({
  isBackButtonOpen: false,
  setIsBackButtonOpen: (bool) => set((state) => ({ isBackButtonOpen: bool })),
  isSearchbarOpen: false,
  setIsSearchbarOpen: (bool) => set((state) => ({ isSearchbarOpen: bool })),
  isLogoOpen: false,
  setIsLogoOpen: (bool) => set((state) => ({ isLogoOpen: bool })),
  isSettingsButtonOpen: false,
  setIsSettingsButtonOpen: (bool) =>
    set((state) => ({ isSettingsButtonOpen: bool })),
  isProfileButtonOpen: false,
  setIsProfileButtonOpen: (bool) =>
    set((state) => ({ isProfileButtonOpen: bool })),
  logoBGColor: globalThemeLight.dashboardBackground.color,
  setLogoBGColor: (color) => set((state) => ({ logoBGColor: color })),
  logoColor: globalThemeLight.backgroundMainOffYellow.color,
  setLogoColor: (color) => set((state) => ({ logoColor: color })),
}));

export const useTabSettingsState = create<TabSettingsState>((set) => ({
  color: globalThemeLight.backgroundMainYellow.color,
  setColor: (color) => set((state) => ({ color: color })),
  iconColor: globalThemeLight.backgroundMainOffYellow.color,
  setIconColor: (color) => set((state) => ({ iconColor: color })),
}));

export const useAchievementsState = create<AchievementsState>((set) => ({
  earnedAchievements: [],
  setEarnedAchievements: (achievements) =>
    set((state) => ({ earnedAchievements: achievements })),
  earnedAchievementsLength: 0,
  setEarnedAchievementsLength: (number) =>
    set((state) => ({ earnedAchievementsLength: number })),
  earnedAchievementsLoaded: false,
  setEarnedAchievementsLoaded: (bool) =>
    set((state) => ({ earnedAchievementsLoaded: bool })),
  hasNewAchievements: false,
  setHasNewAchievements: (bool) =>
    set((state) => ({ hasNewAchievements: bool })),
  newAchievementsLength: 0,
  setNewAchievementsLength: (number) =>
    set((state) => ({ newAchievementsLength: number })),
  allAchievements: [],
  setAllAchievements: (achievements) =>
    set((state) => ({ allAchievements: achievements })),
  allAchievementsLoaded: false,
  setAllAchievementsLoaded: (bool) =>
    set((state) => ({ allAchievementsLoaded: bool })),
  progressAchievements: [],
  setProgressAchievements: (achievements) =>
    set((state) => ({ progressAchievements: achievements })),
  progressAchievementsLoaded: false,
  setProgressAchievementsLoaded: (bool) =>
    set((state) => ({ progressAchievementsLoaded: bool })),
}));

export const useTokensListState = create<TokensListState>((set) => ({
  tokens: [],
  setTokens: (tokens) => set((state) => ({ tokens: tokens })),
  tokenList: [],
  setTokenList: (tokenList) => set((state) => ({ tokenList: tokenList })),
  tokenPage: 1,
  setTokenPage: (number) => set((state) => ({ tokenPage: number })),
  tokenListPage: 1,
  setTokenListPage: (number) => set((state) => ({ tokenListPage: number })),
  pageSize: 100,
  setPageSize: (number) => set((state) => ({ pageSize: number })),
  tokensTotalSupply: 1000,
  setTokensTotalSupply: (number) =>
    set((state) => ({ tokensTotalSupply: number })),
}));

export const useThemeState = create<ThemeState>((set) => ({
  isDark: false,
  setIsDark: (bool) => set((state) => ({ isDark: bool })),
  globalTheme: globalThemeLight,
  setGlobalTheme: (theme) => set((state) => ({ globalTheme: theme })),
}));
