import React, { useState, useEffect } from "react";

import { IconButton } from "@mui/material";
import {
  algoliaListingsCreatedAtDesc,
  algoliaListingsRatingDesc,
  algoliaListingsTrendingHourDesc,
  algoliaListingsViewsTotalDesc,
} from "../../../components/search/algoliaSearch";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ListingsWidgetItem from "./components/ListingsWidgetItem";
import { categoryEnums } from "../../../components/utils/enums";
import { useThemeState } from "../../../components/utils/globalStates";
import "./DashWidgets.css";

export default function ListingsWidget({
  bgColorCode,
  isEditing,
  deleteWidget,
  fulldata,
  elementIndex,
  rowIndex,
}: {
  bgColorCode: string;
  isEditing: boolean;
  deleteWidget: Function;
  fulldata: any;
  elementIndex: number;
  rowIndex: number;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [widgetTitle, setWidgetTitle] = useState("Listings");
  const [isDeleteing, setIsDeleting] = useState(false);
  const [widgetList, setWidgetList] = useState([null, null, null, null, null]);
  // On delete
  const onDelete = () => {
    setIsDeleting(true);
    deleteWidget(rowIndex, elementIndex);
  };

  // Update widget title on widgetType change
  useEffect(() => {
    if (fulldata.filter === "createdAt") {
      setWidgetTitle("New Projects");
    } else if (fulldata.filter === "rating") {
      setWidgetTitle("Top Projects");
    } else if (fulldata.filter === "trendingHour") {
      setWidgetTitle("Trending Projects");
    } else if (fulldata.filter === "viewsTotal") {
      setWidgetTitle("Project Views");
    } else {
      setWidgetTitle("Projects");
    }
  }, [fulldata.filter]);

  const fetchTrendingHourDesc = () => {
    const fromDate = Date.now() - 60 * 60 * 1000 * 200;
    algoliaListingsTrendingHourDesc
      .search("", {
        hitsPerPage: 5,
        numericFilters: [`trendingHourUpdated > ${fromDate}`],
        facetFilters: `category:${
          fulldata.category !== null &&
          fulldata.category !== undefined &&
          fulldata.category !== "" &&
          fulldata.category !== "all"
            ? categoryEnums.find(
                (category) => category.value === fulldata.category
              )?.displayname
            : ""
        }`,
      })
      .then((res: any) => {
        let tempArray: any[] = [];
        res.hits.forEach((hit: any) => {
          tempArray.push({ ...hit, type: fulldata.filter });
        });
        setWidgetList(tempArray);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const fetchTemplate = (fetchObj: any) => {
    fetchObj
      .search("", {
        hitsPerPage: 5,
        facetFilters: `category:${
          fulldata.category !== null &&
          fulldata.category !== undefined &&
          fulldata.category !== "" &&
          fulldata.category !== "all"
            ? categoryEnums.find(
                (category) => category.value === fulldata.category
              )?.displayname
            : ""
        }`,
      })
      .then((res: any) => {
        let tempArray: any[] = [];
        res.hits.forEach((hit: any) => {
          tempArray.push({ ...hit, type: fulldata.filter });
        });
        setWidgetList(tempArray);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (fulldata.filter === "rating") {
      fetchTemplate(algoliaListingsRatingDesc);
    } else if (fulldata.filter === "trendingHour") {
      fetchTrendingHourDesc();
    } else if (fulldata.filter === "viewsTotal") {
      fetchTemplate(algoliaListingsViewsTotalDesc);
    } else {
      fetchTemplate(algoliaListingsCreatedAtDesc);
    }
  }, [fulldata.filter]);

  return (
    <div
      className="ListingsWidget"
      style={{
        color: globalTheme.figmaPrimaryText.color,
        border: isEditing
          ? `1px dashed ${globalTheme.backgroundMainOffYellow.color}`
          : `1px solid ${globalTheme.figmaPrimaryText.color}`,
        borderRadius: isEditing ? "0px" : "8px",
        backgroundColor: bgColorCode,
      }}
    >
      <div
        className="ListingsWidgetHeader"
        style={{
          color: globalTheme.figmaPrimaryText.color,
          borderBottom: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        }}
      >
        <p className="ListingsWidgetTitle">{widgetTitle}</p>
        <p
          className="ListingsWidgetCategory"
          style={{ border: `1px solid ${globalTheme.figmaPrimaryText.color}` }}
        >
          {fulldata.category !== null &&
          fulldata.category !== undefined &&
          fulldata.category !== "" &&
          fulldata.category !== "all"
            ? categoryEnums.find(
                (category) => category.value === fulldata.category
              )?.displayname
            : "All"}
        </p>
      </div>
      <div className="ListingsWidgetInner">
        {widgetList.map((item, index) => (
          <ListingsWidgetItem isLink={true} dataToRender={item} key={index} />
        ))}
      </div>
      {isEditing && (
        <IconButton
          size="small"
          sx={{
            position: "absolute",
            right: "-1px",
            top: "-1px",
            padding: "2px",
            borderRadius: "0px 0px 0px 5px",
            width: "20%",
            color: globalTheme.backgroundMainOffYellow.color,
            backgroundColor: globalTheme.backgroundMainYellow.color,
            border: `1px dashed ${globalTheme.backgroundMainOffYellow.color}`,
            "&:hover": {
              backgroundColor: globalTheme.backgroundMainYellow.color,
            },
          }}
          onClick={onDelete}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      )}
    </div>
  );
}
