import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import RatingListItemPreview from "../../../../components/ui/listItems/RatingListItemPreview";
import moment from "moment";
import {
  onSnapshot,
  query,
  collection,
  where,
  limit,
  orderBy,
} from "firebase/firestore";
import { firestoreDb } from "../../../../components/firebase/firestore";

import AddReviewItem from "../../components/AddReviewItem";
import RatingListItem from "../../../reviews/components/RatingListItem";
import ReviewItem from "../../../reviews/components/ReviewItem";
import { useThemeState } from "../../../../components/utils/globalStates";
import "./ListingPageReviewPreviews.css";
import { ButtonBase, CircularProgress, Skeleton } from "@mui/material";

moment().format();

export default function ProjectPageRatingsPreview({
  listing,
}: {
  listing: any;
}) {
    const navigate = useNavigate();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [isLoading, setIsLoading] = useState(true);
  const [reviews, setReviews] = useState<any[]>([]);

  useEffect(() => {
    if (listing === null || listing.id === null || listing.id === undefined) {
      return;
    }
    setIsLoading(true);
    const reviewsQ = query(
      collection(firestoreDb, "reviews"),
      where("listing", "==", listing.id),
      where("review", "==", false),
      orderBy("createdAt", "desc"),
      limit(4)
    );
    const unsubscribe = onSnapshot(
      reviewsQ,
      (snapshot) => {
        const reviewsData: any[] = [];
        snapshot.forEach((doc) => {
          reviewsData.push(doc.data());
        });
        setReviews(reviewsData);
        setIsLoading(false);
      },
      (error) => {
        console.error(error);
        setIsLoading(false);
      }
    );
    return () => unsubscribe();
  }, [listing]);
  return (
    <div className="ProjectPageReviewsPreviewOuter">
      <p className="ProjectPageReviewsPreviewTitle" style={{ color: globalTheme.textMainBlack.color }}>Recent Ratings</p>
      {isLoading ? (
        <div className="ProjectPageReviewsPreviewInner">
          <Skeleton
            variant="rectangular"
            sx={{
              width: "calc(25% - 20px)",
              height: "100px",
              borderRadius: "8px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              width: "calc(25% - 20px)",
              height: "100px",
              borderRadius: "8px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              width: "calc(25% - 20px)",
              height: "100px",
              borderRadius: "8px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              width: "calc(25% - 20px)",
              height: "100px",
              borderRadius: "8px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
        </div>
      ) : reviews.length > 0 ? (
        <div className="ProjectPageReviewsPreviewInner" >
          {reviews.map((review, index) => (
            <RatingListItemPreview key={index} listItem={review} isListing={false} />
          ))}
          {reviews.length > 3 && (
            <p
            className="ProjectPageReviewsPreviewInnerLink"
            onClick={() => navigate(`/reviews/listing/rating/${listing.id}`)}
            style={{ color: globalTheme.links.color }}
          >
            See all ratings
          </p>
          )}
        </div>
      ) : (
        <div className="ProjectPageReviewsPreviewInner">
          <p style={{ color: globalTheme.textMainBlack.color }}>No ratings yet</p>
        </div>
      )}
    </div>
  );
}
