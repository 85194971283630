import React, { useEffect, useState } from "react";
import moment from "moment";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { categoryEnums } from "../../../components/utils/enums";
import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "../../../components/firebase/analytics";
import AdminLogoUpload from "../components/AdminLogoUpload";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import CircularProgress from "@mui/material/CircularProgress";

import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../components/firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import {
  doc,
  updateDoc,
  deleteDoc,
  setDoc,
  onSnapshot,
} from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  useSnackbarState,
  useSnackbarErrorState,
  useThemeState,
  useAdminState,
  useAuthStates,
} from "../../../components/utils/globalStates";
import "./ListingDialogs.css";

moment().format();

export default function AdminUserDialog({ userId }: any) {
  const [user] = useAuthState(firebaseAuth);
  const snackbarState = useSnackbarState();
  const snackbarErrorState = useSnackbarErrorState();
  const adminState = useAdminState();
  const authStates = useAuthStates((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [userData, setUserData] = useState<any>({});
  const [userRole, setUserRole] = useState<string>("");
  const [tempUserRole, setTempUserRole] = useState<string>("user");
  const [isUserDisabled, setIsUserDisabled] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmMove, setConfirmMove] = useState(false);

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempUserRole((event.target as HTMLInputElement).value);
  };

  // Fetch user data from privateUserInfo collection
  useEffect(() => {
    if (userId === undefined || userId === "" || userId === null) {
      snackbarErrorState.setMessage("User not found");
      snackbarErrorState.setOpenLength(4000);
      snackbarErrorState.setIsOpen(true);
      return adminState.setActiveUser("");
    }

    setIsLoading(true);
    const userRef = doc(firestoreDb, "privateUserInfo", userId);
    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        console.log(data);
        setUserData(data);
        setIsLoading(false);
      } else {
        snackbarErrorState.setMessage("User not found");
        snackbarErrorState.setOpenLength(4000);
        snackbarErrorState.setIsOpen(true);
        adminState.setActiveUser("");
        setIsLoading(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [userId]);

  // Fetch user role from admin-check_user_role callable function
  const fetchUserRole = async () => {
    if (userId === undefined || userId === "" || userId === null) return;
    if (user === null || user === undefined) return;
    const userToken = await user.getIdToken();
    const checkUserRole = httpsCallable(
      firebaseFunctions,
      "admin-check_user_role"
    );
    checkUserRole({ uid: userId, token: userToken })
      .then((result: any) => {
        console.log(result.data);
        setUserRole(result.data.role);
        setTempUserRole(result.data.role);
      })
      .catch((error) => {
        console.error(error);
        snackbarErrorState.setMessage("Error fetching user role");
        snackbarErrorState.setOpenLength(4000);
        snackbarErrorState.setIsOpen(true);
      });
  };

  useEffect(() => {
    fetchUserRole();
  }, [userId, user]);

  // Update user role
  const onUpdateUserRole = async () => {
    if (userId === undefined || userId === "" || userId === null) return;
    if (user === null || user === undefined) return;
    setIsUpdating(true);
    snackbarState.setMessage("Updating user role...");
    snackbarState.setOpenLength(null);
    snackbarState.setIsActionLoading(true);
    snackbarState.setIsOpen(true);
    const userToken = await user.getIdToken();
    const updateUserRole = httpsCallable(
      firebaseFunctions,
      "admin-set_user_role"
    );
    updateUserRole({ uid: userId, token: userToken, role: tempUserRole })
      .then((result: any) => {
        console.log(result.data);
        setIsUpdating(false);
        setUserRole(tempUserRole);

        snackbarState.setIsActionLoading(false);
        snackbarState.setMessage("User role updated");
        snackbarState.setOpenLength(4000);
      })
      .catch((error) => {
        console.error(error);
        setIsUpdating(false);
        snackbarState.setIsOpen(false);
        snackbarState.setIsActionLoading(false);
        snackbarState.setMessage("");
        snackbarErrorState.setMessage(error.message);
        snackbarErrorState.setOpenLength(4000);
        snackbarErrorState.setIsOpen(true);
      });
  };

  // Delete user
    const onDeleteUser = async () => {
        if (userId === undefined || userId === "" || userId === null) return;
        if (user === null || user === undefined) return;
        setIsDeleting(true);
        snackbarState.setMessage("Deleting user...");
        snackbarState.setOpenLength(null);
        snackbarState.setIsActionLoading(true);
        snackbarState.setIsOpen(true);
        const userToken = await user.getIdToken();
        const deleteUser = httpsCallable(firebaseFunctions, "admin-delete_user");
        deleteUser({ uid: userId, token: userToken })
        .then((result: any) => {
            console.log(result.data);
            setIsDeleting(false);
            adminState.setActiveUser("");
            snackbarState.setIsActionLoading(false);
            snackbarState.setMessage("User deleted");
            snackbarState.setOpenLength(4000);
        })
        .catch((error) => {
            console.error(error);
            setIsDeleting(false);
            snackbarState.setIsOpen(false);
            snackbarState.setIsActionLoading(false);
            snackbarState.setMessage("");
            snackbarErrorState.setMessage(error.message);
            snackbarErrorState.setOpenLength(4000);
            snackbarErrorState.setIsOpen(true);
        });
    };

  const materialListingFormStyles = {
    "& .MuiOutlinedInput-root": {
      color: globalTheme.backgroundMainOffBeige.color,
      borderColor: globalTheme.backgroundMainOffBeige.color,
      "& fieldset": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color,
      },
      "&:hover fieldset": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color,
      },
      "&.Mui-focused fieldset": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color,
      },
    },
    "& label.Mui-focused": {
      color: globalTheme.backgroundMainOffBeige.color,
    },
    "& label": {
      color: globalTheme.backgroundMainOffBeige.color,
    },
  };

  return isLoading ? (
    <div className="AdminListDialog">
      <CircularProgress
        size={30}
        style={{
          color: globalTheme.backgroundMainOffBeige.color,
          margin: "auto",
        }}
      />
    </div>
  ) : (
    <div className="AdminListDialog">
      <p
        style={{ ...globalTheme.backgroundMainOffBeige }}
        className="AdminListDialogTitle"
      >
        {userData.displayName === "" ? "Anonymous user" : userData.displayName}
        <OpenInNewOutlinedIcon
          style={{
            marginLeft: "5px",
            cursor: "pointer",
            fontSize: "20px",
            marginBottom: "-3px",
          }}
          onClick={() => {
            window.open(`/users/page/${userId}`, "_blank");
          }}
        />
      </p>
      <p
        style={{ ...globalTheme.backgroundMainOffBeige }}
        className="AdminDialogTitleSub"
      >
        {userData.id}
      </p>
      <p
        style={{ ...globalTheme.backgroundMainOffBeige }}
        className="AdminDialogTitleSub"
      >
        {userData.email}
      </p>
      {userData.about !== null &&
        userData.about !== undefined &&
        userData.about !== "" && (
          <p
            style={{
              ...globalTheme.backgroundMainOffBeige,
              marginTop: "10px",
              marginBottom: "10px",
            }}
            className="AdminDialogTitleSub"
          >
            {userData.about}
          </p>
        )}
      <div className="AdminUserDialogInfoOuter">
        <p className="AdminUserDialogInfo">
          <b>Name: </b>
          {userData.privateName === null ||
          userData.privateName === undefined ||
          userData.privateName === ""
            ? "Not set"
            : userData.privateName}
        </p>
        <p className="AdminUserDialogInfo">
          <b>Surname: </b>
          {userData.privateSurname === null ||
          userData.privateSurname === undefined ||
          userData.privateSurname === ""
            ? "Not set"
            : userData.privateSurname}
        </p>
        <p className="AdminUserDialogInfo">
          <b>Gender: </b>
          {userData.privateGender === null ||
          userData.privateGender === undefined ||
          userData.privateGender === ""
            ? "Not set"
            : userData.privateGender}
        </p>
        <p className="AdminUserDialogInfo">
          <b>Age: </b>
          {userData.privateAge === null ||
          userData.privateAge === undefined ||
          userData.privateAge === ""
            ? "Not set"
            : userData.privateAge +
              " (" +
              moment().diff(userData.privateAge, "years") +
              ")"}
        </p>
        <p className="AdminUserDialogInfo">
          <b>Contry: </b>
          {userData.privateCountry === null ||
          userData.privateCountry === undefined ||
          userData.privateCountry === ""
            ? "Not set"
            : userData.privateCountry}
        </p>
        <p className="AdminUserDialogInfo">
          <b>Created: </b>
          {moment(userData.createdAt).format("MMM Do YYYY")}
        </p>
        {userData.lastActive !== null && userData.lastActive !== undefined && (
          <p className="AdminUserDialogInfo">
            <b>Last active: </b>
            {moment(userData.lastActive).fromNow()}
          </p>
        )}
        <p className="AdminUserDialogInfo">
          <b>Points: </b>
          {userData.points}
        </p>
        <p className="AdminUserDialogInfo">
          <b>Listings: </b>
          {userData.listingCount}
          <OpenInNewOutlinedIcon
            style={{
              marginLeft: "2px",
              cursor: "pointer",
              fontSize: "12px",
              marginBottom: "-2px",
            }}
            onClick={() => {
              window.open(`/users/listings/${userId}`, "_blank");
            }}
          />
        </p>
        <p className="AdminUserDialogInfo">
          <b>Reviews: </b>
          {userData.reviewsCount}
          <OpenInNewOutlinedIcon
            style={{
              marginLeft: "2px",
              cursor: "pointer",
              fontSize: "12px",
              marginBottom: "-2px",
            }}
            onClick={() => {
              window.open(`/reviews/user/${userId}`, "_blank");
            }}
          />
        </p>
        <p className="AdminUserDialogInfo">
          <b>Ratings: </b>
          {userData.ratingCount}
          <OpenInNewOutlinedIcon
            style={{
              marginLeft: "2px",
              cursor: "pointer",
              fontSize: "12px",
              marginBottom: "-2px",
            }}
            onClick={() => {
              window.open(`/reviews/user/rating/${userId}`, "_blank");
            }}
          />
        </p>
        <p className="AdminUserDialogInfo">
          <b>Referrals: </b>
          {userData.referralCount === null ||
          userData.referralCount === undefined ||
          userData.referralCount === ""
            ? 0
            : userData.referralCount}
        </p>
        <p className="AdminUserDialogInfo">
          <b>Followers: </b>
          {userData.followersCount === null ||
          userData.followersCount === undefined ||
          userData.followersCount === ""
            ? 0
            : userData.followersCount}
          <OpenInNewOutlinedIcon
            style={{
              marginLeft: "2px",
              cursor: "pointer",
              fontSize: "12px",
              marginBottom: "-2px",
            }}
            onClick={() => {
              window.open(`/users/follow/in/${userId}`, "_blank");
            }}
          />
        </p>
        <p className="AdminUserDialogInfo">
          <b>Following: </b>
          {userData.followingCount === null ||
          userData.followingCount === undefined ||
          userData.followingCount === ""
            ? 0
            : userData.followingCount}
          <OpenInNewOutlinedIcon
            style={{
              marginLeft: "2px",
              cursor: "pointer",
              fontSize: "12px",
              marginBottom: "-2px",
            }}
            onClick={() => {
              window.open(`/users/follow/out/${userId}`, "_blank");
            }}
          />
        </p>
      </div>
      <div className="AdminUserDialogInfoOuter" style={{ marginTop: "20px" }}>
        <b style={{ marginBottom: "2px", marginRight: "10px" }}>Role: </b>
        <span>
          {authStates.role === "owner" ? (
            <FormControlLabel
              value="admin"
              control={
                <Radio
                  checked={tempUserRole === "admin"}
                  onChange={handleRoleChange}
                  size="small"
                  sx={{
                    color: globalTheme.textMainBlack.color,
                    "&.Mui-checked": {
                      color: globalTheme.textMainBlack.color,
                    },
                  }}
                />
              }
              label="Admin"
              sx={
                tempUserRole === "admin"
                  ? {
                      ...globalTheme.backgroundMainOffBeige,
                      "& .MuiFormControlLabel-label": { fontSize: "10px" },
                    }
                  : {
                      ...globalTheme.backgroundMainOffBeige,
                      opacity: 0.6,
                      "& .MuiFormControlLabel-label": { fontSize: "10px" },
                    }
              }
            />
          ) : null}
          <FormControlLabel
            value="alpha"
            control={
              <Radio
                checked={tempUserRole === "alpha"}
                onChange={handleRoleChange}
                size="small"
                sx={{
                  color: globalTheme.textMainBlack.color,
                  "&.Mui-checked": {
                    color: globalTheme.textMainBlack.color,
                  },
                }}
              />
            }
            label="Alpha"
            sx={
              tempUserRole === "alpha"
                ? {
                    ...globalTheme.backgroundMainOffBeige,
                    "& .MuiFormControlLabel-label": { fontSize: "10px" },
                  }
                : {
                    ...globalTheme.backgroundMainOffBeige,
                    opacity: 0.6,
                    "& .MuiFormControlLabel-label": { fontSize: "10px" },
                  }
            }
          />
          <FormControlLabel
            value="beta"
            control={
              <Radio
                checked={tempUserRole === "beta"}
                onChange={handleRoleChange}
                size="small"
                sx={{
                  color: globalTheme.textMainBlack.color,
                  "&.Mui-checked": {
                    color: globalTheme.textMainBlack.color,
                  },
                }}
              />
            }
            label="Beta"
            sx={
              tempUserRole === "beta"
                ? {
                    ...globalTheme.backgroundMainOffBeige,
                    "& .MuiFormControlLabel-label": { fontSize: "10px" },
                  }
                : {
                    ...globalTheme.backgroundMainOffBeige,
                    opacity: 0.6,
                    "& .MuiFormControlLabel-label": { fontSize: "10px" },
                  }
            }
          />
          <FormControlLabel
            value="user"
            control={
              <Radio
                checked={tempUserRole === "user"}
                onChange={handleRoleChange}
                size="small"
                sx={{
                  color: globalTheme.textMainBlack.color,
                  "&.Mui-checked": {
                    color: globalTheme.textMainBlack.color,
                  },
                }}
              />
            }
            label="User"
            sx={
              tempUserRole === "user"
                ? {
                    ...globalTheme.backgroundMainOffBeige,
                    "& .MuiFormControlLabel-label": { fontSize: "10px" },
                  }
                : {
                    ...globalTheme.backgroundMainOffBeige,
                    opacity: 0.6,
                    "& .MuiFormControlLabel-label": { fontSize: "10px" },
                  }
            }
          />
          <IconButton
            aria-label="Sve"
            color="inherit"
            sx={{
              marginleft: "10px",
              color: globalTheme.textMainBlack.color,
            }}
            size="small"
            onClick={onUpdateUserRole}
            disabled={
              isUpdating || userRole === tempUserRole || userRole === ""
            }
          >
            <SaveOutlinedIcon />
          </IconButton>
        </span>
      </div>
      <div className="AdminUserDialogInfoOuter" style={{ marginTop: "0px" }}>
        {confirmDelete ? (
          <div
            className="AdminListDialogButtons"
            style={{ ...globalTheme.backgroundMainOffBeige }}
          >
            <p className="AdminListDialogConfirmTitle">
              Confirm deletion of user "{userData.displayName}"?
            </p>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ marginRight: "10px", fontSize: "12px" }}
              onClick={() => setConfirmDelete(false)}
              disabled={isUpdating || isMoving || isDeleting}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ marginRight: "0px", fontSize: "12px" }}
                onClick={onDeleteUser}
              disabled={isUpdating || isDeleting}
            >
              {isDeleting ? "Deleting..." : "Delete"}
            </Button>
          </div>
        ) : (
          <div
            className="AdminListDialogButtons"
            style={{
              ...globalTheme.backgroundMainOffBeige,
              marginBottom: "0px",
            }}
          >
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ fontSize: "12px", width: "30%" }}
              disabled={true}
            >
              Report User
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ fontSize: "12px", width: "30%" }}
              disabled={true}
            >
              {isUserDisabled ? "Activate user" : "Disable user"}
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ fontSize: "12px", width: "30%" }}
              onClick={() => setConfirmDelete(true)}
              disabled={isUpdating || isDeleting}
            >
              {isDeleting ? "Deleting user..." : "Delete User"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
