import algoliasearch from "algoliasearch/lite";
import aa from 'search-insights';
import { algoliaId, algoliaKey } from "../../.config/config";

const searchClient = algoliasearch(algoliaId, algoliaKey);

aa("init", {
  appId: algoliaId,
  apiKey: algoliaKey,
});

export const algoliaListingsRelevance = searchClient.initIndex("gath3r_listings");
export const algoliaListingsCreatedAtDesc = searchClient.initIndex(
  "gath3r_listings_desc_createdAt"
);
export const algoliaListingsRatingDesc = searchClient.initIndex("gath3r_listings_desc_rating");
export const algoliaListingsTrendingHourDesc = searchClient.initIndex(
  "gath3r_listings_desc_trendingHour"
);
export const algoliaListingsViewsTotalDesc = searchClient.initIndex(
  "gath3r_listings_desc_viewsTotal"
);

export const algoliaUsersRelevance = searchClient.initIndex("gath3r_users");
export const algoliaUsersCreatedAtDesc = searchClient.initIndex("gath3r_users_desc_createdAt");
export const algoliaUsersLastActiveDesc = searchClient.initIndex("gath3r_users_desc_last_active");
export const algoliaUsersPointsDesc = searchClient.initIndex("gath3r_users_desc_points");
export const algoliaUsersPointsHourDesc = searchClient.initIndex("gath3r_users_desc_points_hour");
export const algoliaUsersPointsDayDesc = searchClient.initIndex("gath3r_users_desc_points_day");
export const algoliaUsersPointsWeekDesc = searchClient.initIndex("gath3r_users_desc_points_week");

export const algoliaReviewsRelevance = searchClient.initIndex("gath3r_reviews");
export const algoliaReviewsCreatedAtDesc = searchClient.initIndex("gath3r_reviews_desc_createdAt");

export const algoliaUsedDisplayNames = searchClient.initIndex("gath3r_display_names");