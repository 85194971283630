import React, {useState, useEffect} from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import TokensList from './list/TokensList';
import TokensPage from './page/TokensPage';
import './TokensStart.css';

export default function TokensStart() {

  return (
    <div className="TokensStart" >
      <Routes>
        <Route path="/" element={<TokensList />} />
        <Route path="/page/:tokenId" element={<TokensPage />} />
      </Routes>
    </div>
  );
}
