import "./Img.css"

function LogoMaskOnly({maskFill, maskStroke}: {maskFill?: string; maskStroke?: string;}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 95.23 124.56"
      className="LogoMask"
    >
      <path
        fill={maskStroke ? maskStroke : "#3a3a3d"}
        d="M583.3 536.74a28.6 28.6 0 00-6.43-7.07 22.19 22.19 0 00-2-1.41 37.72 37.72 0 009.63-11.87 31.18 31.18 0 003.43-14.15c0-20.82-21.36-37.76-47.62-37.76a60 60 0 00-13.54 1.52c-19.23 4.48-32.89 18.19-34 34.08v.24c0 .63-.06 1.26-.06 1.9v.2a32.45 32.45 0 007.55 20.28c-.31.54-.59 1.1-.85 1.68a23.86 23.86 0 00-2 9.64 21.07 21.07 0 003.71 12.46 19.86 19.86 0 00-2.6 4 24 24 0 00-1.73 4.63 21.28 21.28 0 00-.7 5.51 23.22 23.22 0 003.18 11.78 27.54 27.54 0 009 9.17 44.94 44.94 0 0013.47 5.57 73.09 73.09 0 0017.65 1.86 78.23 78.23 0 0018.46-2 49.07 49.07 0 0015.35-6.41A33.43 33.43 0 00584 569.3a31 31 0 004-15.68 35.29 35.29 0 00-1-8.42 28.76 28.76 0 00-3.7-8.46z"
        transform="translate(-492.71 -464.48)"
      ></path>
      <path
        fill={maskFill ? maskFill : "#ffd379"}
        d="M577.6 540.35a21.87 21.87 0 00-4.91-5.39 20.12 20.12 0 00-7.41-3.55 42.07 42.07 0 01-8.87 2.45 60.4 60.4 0 01-10.64.92 24.4 24.4 0 01-6-.59.48.48 0 01.12-.94h.4a52.72 52.72 0 0011.35-1.25c12.34-2.69 22.29-9.68 26.85-18.69a24.55 24.55 0 002.7-11.11c0-17.13-18.3-31-40.87-31a52.62 52.62 0 00-12 1.37c-16 3.74-27.89 14.78-28.79 28a1.09 1.09 0 000 .18c0 .49-.05 1-.05 1.46v.16c.05 7.7 3.8 14.74 10 20.14a10.17 10.17 0 00-3.84 4.56 17.06 17.06 0 00-1.47 6.94 14.22 14.22 0 002.68 8.83 19.78 19.78 0 007.11 5.76 35.59 35.59 0 0010.23 3.11 75.79 75.79 0 0012 .92c1.42 0 3-.07 4.77-.22a36.78 36.78 0 004.39-.59l.29.63a2.23 2.23 0 01.14.84c0 1-.7 1.72-2.12 2.09a35.64 35.64 0 01-7.7.55 63.76 63.76 0 01-17.08-2 40.16 40.16 0 01-6.15-2.22 19.46 19.46 0 01-4.34-2.69 7.3 7.3 0 00-2.09 1.72 13.17 13.17 0 00-1.77 2.67 17.87 17.87 0 00-1.24 3.34 14.61 14.61 0 00-.47 3.78 16.46 16.46 0 002.27 8.4 20.81 20.81 0 006.85 6.89 38.36 38.36 0 0011.44 4.69 66.16 66.16 0 0016 1.72 71.19 71.19 0 0016.86-1.87 41.89 41.89 0 0013.23-5.5 26.55 26.55 0 008.62-9 24.28 24.28 0 003.08-12.32 28.24 28.24 0 00-.85-6.82 21.38 21.38 0 00-2.72-6.37zm-29.06-42.58a5.32 5.32 0 017.53 0 5.32 5.32 0 11-9.08 3.77 5.36 5.36 0 011.55-3.77zm-20.2 9.09a5.32 5.32 0 115.32-5.32 5.33 5.33 0 01-5.32 5.32z"
        transform="translate(-492.71 -464.48)"
      ></path>
    </svg>
  );
}

export default LogoMaskOnly;
