import Skeleton from "@mui/material/Skeleton";
import { Link } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../../components/firebase/auth";

import { useThemeState } from "../../../../components/utils/globalStates";
import "./UserPageComp.css";
import "../UserPage.css";

export default function UserPageCountV2({
  userId,
  followingCount,
  followersCount,
  userPoints,
  userListingCount,
  userReviewsCount,
  userRatingsCount,
  updateFollowers,
}: {
  userId: string | undefined;
  updateFollowers: boolean;
  userPoints: number;
  userListingCount: number;
  userReviewsCount: number;
  userRatingsCount: number;
  followingCount: number;
  followersCount: number;
}) {
  const [user] = useAuthState(firebaseAuth);
  const globalTheme = useThemeState((state) => state.globalTheme);

  return (
    <>
      <div className="UserPageCountV2">
        {userListingCount === null || userListingCount === undefined ? (
          <div
            className="UserPageCountV2Item"
            style={{
              backgroundColor: globalTheme.secondarySurface.color,
            }}
          >
            <Skeleton variant="text" sx={{ fontSize: "20px", width: "30%" }} />
            <Skeleton variant="text" sx={{ fontSize: "15px", width: "60%" }} />
          </div>
        ) : (
          <Link
            to={`/users/listings/${userId}`}
            className="UserPageCountV2Item"
            style={{
              backgroundColor: globalTheme.secondarySurface.color,
            }}
          >
            <p
              className="UserPageCountV2Title"
              style={{ color: globalTheme.backgroundMainOffBeigeDark.color }}
            >
              {userListingCount}
            </p>
            <p
              className="UserPageCountV2Sub"
              style={{ color: globalTheme.backgroundMainOffBeigeDark.color }}
            >
              Listings
            </p>
          </Link>
        )}
        {userReviewsCount === null || userReviewsCount === undefined ? (
          <div
            className="UserPageCountV2Item"
            style={{
              backgroundColor: globalTheme.secondarySurface.color,
            }}
          >
            <Skeleton variant="text" sx={{ fontSize: "20px", width: "30%" }} />
            <Skeleton variant="text" sx={{ fontSize: "15px", width: "60%" }} />
          </div>
        ) : (
          <Link
            to={`/reviews/user/${userId}`}
            className="UserPageCountV2Item"
            style={{
              backgroundColor: globalTheme.secondarySurface.color,
            }}
          >
            <p
              className="UserPageCountV2Title"
              style={{ color: globalTheme.backgroundMainOffBeigeDark.color }}
            >
              {userReviewsCount}
            </p>
            <p
              className="UserPageCountV2Sub"
              style={{ color: globalTheme.backgroundMainOffBeigeDark.color }}
            >
              Reviews
            </p>
          </Link>
        )}
        {userRatingsCount === null || userRatingsCount === undefined ? (
          <div
            className="UserPageCountV2Item"
            style={{
              backgroundColor: globalTheme.secondarySurface.color,
            }}
          >
            <Skeleton variant="text" sx={{ fontSize: "20px", width: "30%" }} />
            <Skeleton variant="text" sx={{ fontSize: "15px", width: "60%" }} />
          </div>
        ) : (
          <Link
            to={`/reviews/user/rating/${userId}`}
            className="UserPageCountV2Item"
            style={{
              backgroundColor: globalTheme.secondarySurface.color,
            }}
          >
            <p
              className="UserPageCountV2Title"
              style={{ color: globalTheme.backgroundMainOffBeigeDark.color }}
            >
              {userRatingsCount}
            </p>
            <p
              className="UserPageCountV2Sub"
              style={{ color: globalTheme.backgroundMainOffBeigeDark.color }}
            >
              Ratings
            </p>
          </Link>
        )}
        {followingCount === null || followingCount === undefined ? (
          <div
            className="UserPageCountV2Item"
            style={{
              backgroundColor: globalTheme.secondarySurface.color,
            }}
          >
            <Skeleton variant="text" sx={{ fontSize: "20px", width: "30%" }} />
            <Skeleton variant="text" sx={{ fontSize: "15px", width: "60%" }} />
          </div>
        ) : (
          <Link
            to={`/users/follow/out/${userId}`}
            className="UserPageCountV2Item"
            style={{
              backgroundColor: globalTheme.secondarySurface.color,
            }}
          >
            <p
              className="UserPageCountV2Title"
              style={{ color: globalTheme.backgroundMainOffBeigeDark.color }}
            >
              {followingCount}
            </p>
            <p
              className="UserPageCountV2Sub"
              style={{ color: globalTheme.backgroundMainOffBeigeDark.color }}
            >
              Following
            </p>
          </Link>
        )}
        {followersCount === null || followersCount === undefined ? (
          <div
            className="UserPageCountV2Item"
            style={{
              backgroundColor: globalTheme.secondarySurface.color,
            }}
          >
            <Skeleton variant="text" sx={{ fontSize: "20px", width: "30%" }} />
            <Skeleton variant="text" sx={{ fontSize: "15px", width: "60%" }} />
          </div>
        ) : (
          <Link
            to={`/users/follow/in/${userId}`}
            className="UserPageCountV2Item"
            style={{
              backgroundColor: globalTheme.secondarySurface.color,
            }}
          >
            <p
              className="UserPageCountV2Title"
              style={{ color: globalTheme.backgroundMainOffBeigeDark.color }}
            >
              {followersCount}
            </p>
            <p
              className="UserPageCountV2Sub"
              style={{ color: globalTheme.backgroundMainOffBeigeDark.color }}
            >
              Followers
            </p>
          </Link>
        )}
      </div>
    </>
  );
}
