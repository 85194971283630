import React, { useEffect, useState } from "react";
import { firebaseAuth } from "../firebase/auth";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../components/firebase/functions";

import { useAuthState } from "react-firebase-hooks/auth";

let initLastActive = false;
export default function FirebaseHandlers() {
  const [user] = useAuthState(firebaseAuth);

  const updateIntervalHandler = async () => {
    if (user === null || user === undefined) return;
    const updateInterval = httpsCallable(
      firebaseFunctions,
      "profile-last_active_update"
    );
    const tokenCheck = await user.getIdToken();
    try {
      await updateInterval({ token: tokenCheck });
      console.log("Updated last active");
    }
    catch (e) {
      console.log(e);
    }
  }
  // Set interval to update users last active time
  useEffect(() => {
    if (user === null || user === undefined) return;
    const interval = setInterval(() => {
      console.log("Updating last active");
      updateIntervalHandler();
    }, 300000);

    return () => clearInterval(interval);
  }, [user]);

  useEffect(() => {
    if (user === null || user === undefined) return;
    if (initLastActive) return;
    console.log("Init updating last active");
    updateIntervalHandler();
    initLastActive = true;
  }, []);

  return <></>;
}
