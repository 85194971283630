import React, { useState, useEffect } from "react";
import aa from "search-insights";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import Skeleton from "@mui/material/Skeleton";
import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import { ButtonBase } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../components/firebase/functions";
import moment from "moment";
import { doc, getDoc } from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import {
  useSnackbarErrorState,
  useSnackbarState,
  useThemeState,
  useExploreSortState,
} from "../../../components/utils/globalStates";
import "./ListingsComponents.css";

moment().format();

export default function AddReviewItem({ listing }: { listing: any }) {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const exploreSortState = useExploreSortState((state) => state);
  const snackbarStates = useSnackbarState((state) => state);
  const snackbarError = useSnackbarErrorState((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [ratingLoading, setRatingLoading] = useState(true);
  const [rating, setRating] = useState(0);
  const [reviewLoading, setReviewLoading] = useState(true);
  const [reviewData, setReviewData] = useState<any | null>(null);
  const [ratingExpanded, setRatingExpanded] = useState(false);
  const [reviewExpanded, setReviewExpanded] = useState(false);
  const [isRatingUpdating, setIsRatingUpdating] = useState(false);
  const [isReviewUpdating, setIsReviewUpdating] = useState(false);
  const [reviewTitle, setReviewTitle] = useState("");
  const [reviewBody, setReviewBody] = useState("");

  // Check if user has reviewed listing
  useEffect(() => {
    if (listing.id === null || listing.id === undefined) {
      return;
    }
    if (user?.uid === null || user?.uid === undefined) {
      return;
    }
    const getData = async () => {
      try {
        const reviewDoc = await getDoc(
          doc(firestoreDb, "reviews", user?.uid + "--" + listing.id)
        );
        if (reviewDoc.exists()) {
          setReviewData(reviewDoc.data());
          setRating(reviewDoc.data().rating);
          setReviewLoading(false);
          setRatingLoading(false);
        } else {
          setReviewLoading(false);
          setRatingLoading(false);
        }
      } catch (error) {
        console.error("Error getting review:", error);
        setReviewData(null);
      }
    };
    getData();
  }, []);

  const onRatingChange = async (newRating: number | null) => {
    if (reviewExpanded && newRating !== null) {
      setRating(newRating);
      return;
    }
    if (newRating === null) {
      return;
    }
    if (newRating === rating) {
      return;
    }
    if (user?.uid === null || user?.uid === undefined) {
      return;
    }
    if (listing.id === null || listing.id === undefined) {
      return;
    }
    const oldRating = rating;
    setIsRatingUpdating(true);
    setRating(newRating);
    const userAuthToken = await user.getIdToken();
    const data = {
      id: listing.id,
      rating: newRating,
      token: userAuthToken,
      category: listing.category,
      name: listing.name,
    };
    try {
      const updateRating = httpsCallable(
        firebaseFunctions,
        "reviews-new_rating"
      );
      await updateRating(data);
      setIsRatingUpdating(false);
      if (oldRating === 0) {
        snackbarStates.setMessage("Rating added");
        snackbarStates.setOpenLength(3000);
        snackbarStates.setIsOpen(true);
      } else {
        snackbarStates.setMessage("Rating updated");
        snackbarStates.setOpenLength(3000);
        snackbarStates.setIsOpen(true);
      }
    } catch (err: any) {
      console.error(err);
      setIsRatingUpdating(false);
      setRating(oldRating);
      snackbarError.setMessage("Error: " + err.message);
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
    }

    // Send event to algolia
    let isFound = false;
    for (let i = 0; i < exploreSortState.results.length; i++) {
      if (exploreSortState.results[i].id === listing.id) {
        isFound = true;
        break;
      }
    }
    if (isFound && exploreSortState.results[0].searchId !== undefined) {
      try {
        await aa("convertedObjectIDsAfterSearch", {
          index: "gath3r_listings",
          eventName: "Rated listing after Search",
          queryID: exploreSortState.results[0].searchId,
          objectIDs: [listing.id],
        });
      } catch (error) {
        console.error(error);
        console.log(error);
      }
    } else {
      console.log("convertedObjectIDs");
      try {
        await aa("convertedObjectIDs", {
          index: "gath3r_listings",
          eventName: "Rated listing",
          objectIDs: [listing.id],
        });
      } catch (error) {
        console.error(error);
        console.log(error);
      }
    }
  };

  const onReviewCancel = () => {
    setReviewExpanded(false);
    if (reviewData !== null && reviewData !== undefined) {
      if (reviewData.title !== null && reviewData.title !== undefined) {
        setReviewTitle(reviewData.title);
      } else {
        setReviewTitle("");
      }
      if (reviewData.text !== null && reviewData.text !== undefined) {
        setReviewBody(reviewData.text);
      } else {
        setReviewBody("");
      }
      if (reviewData.rating !== null && reviewData.rating !== undefined) {
        setRating(reviewData.rating);
      } else {
        setRating(0);
      }
    }
  };

  const onReviewSubmit = async () => {
    if (user?.uid === null || user?.uid === undefined) {
      return;
    }
    if (listing.id === null || listing.id === undefined) {
      return;
    }
    if (reviewTitle === "" || reviewBody === "") {
      return;
    }
    if (reviewTitle.length < 5 || reviewBody.length < 8) {
      return;
    }
    if (reviewTitle.length > 100 || reviewBody.length > 1000) {
      return;
    }
    const isWhitespaceString = (str: string) => !str.replace(/\s/g, '').length
    if (isWhitespaceString(reviewTitle) || isWhitespaceString(reviewBody)) {
      return;
    }
    const oldTitle = reviewTitle;
    const oldBody = reviewBody;
    setIsReviewUpdating(true);
    const userAuthToken = await user.getIdToken();
    const data = {
      id: listing.id,
      title: reviewTitle,
      text: reviewBody,
      token: userAuthToken,
      category: listing.category,
      name: listing.name,
      rating: rating,
    };
    try {
      const updateReview = httpsCallable(
        firebaseFunctions,
        "reviews-new_review"
      );
      await updateReview(data);
      setIsReviewUpdating(false);
      setReviewExpanded(false);
      setReviewData({ review: true, title: reviewTitle, text: reviewBody });
      snackbarStates.setMessage("Review added");
      snackbarStates.setOpenLength(3000);
      snackbarStates.setIsOpen(true);
    } catch (err: any) {
      console.error(err);
      setIsReviewUpdating(false);
      setReviewTitle(oldTitle);
      setReviewBody(oldBody);
      snackbarError.setMessage("Error: " + err.message);
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
    }
    // Send event to algolia
    let isFound = false;
    for (let i = 0; i < exploreSortState.results.length; i++) {
      if (exploreSortState.results[i].id === listing.id) {
        isFound = true;
        break;
      }
    }
    if (isFound && exploreSortState.results[0].searchId !== undefined) {
      console.log("convertedObjectIDsAfterSearch");
      try {
        await aa("convertedObjectIDsAfterSearch", {
          index: "gath3r_listings",
          eventName: "Reviewed listing after Search",
          queryID: exploreSortState.results[0].searchId,
          objectIDs: [listing.id],
        });
      } catch (error) {
        console.error(error);
        console.log(error);
      }
    } else {
      console.log("convertedObjectIDs");
      try {
        await aa("convertedObjectIDs", {
          index: "gath3r_listings",
          eventName: "Reviewed listing",
          objectIDs: [listing.id],
        });
      } catch (error) {
        console.error(error);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (reviewExpanded) {
      setRatingExpanded(true);
    } else {
      setRatingExpanded(false);
    }
  }, [reviewExpanded]);

  useEffect(() => {
    if (
      reviewData !== null &&
      reviewData !== undefined &&
      reviewData.review === true
    ) {
      if (reviewData.title !== null && reviewData.title !== undefined) {
        setReviewTitle(reviewData.title);
      }
      if (reviewData.text !== null && reviewData.text !== undefined) {
        setReviewBody(reviewData.text);
      }
    }
  }, [reviewData]);

  const primaryColorTextField = {
    "& .MuiOutlinedInput-root": {
      color: globalTheme.textMainBlack.color,
      borderColor: globalTheme.textMainBlack.color,
      borderWidth: "1px",
      backgroundColor: globalTheme.boxBackgroundWhite.color,
      boxShadow: "1px 1px 5px rgba(35, 35, 35, 0.05)",
      borderRadius: "9px",
      "& fieldset": {
        borderColor: globalTheme.textMainBlack.color,
        borderWidth: "1px",
        color: globalTheme.textMainBlack.color,
        borderRadius: "8px",
      },
      "&:hover fieldset": {
        borderColor: globalTheme.textMainBlack.color,
        borderRadius: "8px",
        borderWidth: "1px",
        color: globalTheme.textMainBlack.color,
      },
      "&.Mui-focused fieldset": {
        borderColor: globalTheme.textMainBlack.color,
        borderRadius: "8px",
        borderWidth: "2px",
        color: globalTheme.textMainBlack.color,
      },
    },
    "& label.Mui-focused": {
      color: globalTheme.textMainBlack.color,
    },
    "& label": {
      color: globalTheme.textMainBlack.color,
    },
  };

  return (
    <div
      className="AddReviewItemOuter"
      style={{
        backgroundColor: globalTheme.boxBackgroundWhite.color,
      }}
    >
      <div className="AddReviewItem">
        <div className="AddReviewItemInner">
          <Rating
            value={rating}
            onChange={(event, newRating) => onRatingChange(newRating)}
            readOnly={!ratingExpanded || isRatingUpdating}
            size="small"
            sx={{
              transform: reviewExpanded
                ? "translateY(16px) translateX(-6px)"
                : "translateY(0px) translateX(0px)",
              transition: "all 0.5s ease-in-out",
              "& span": {
                transition: "all 0.5s ease-in-out",
                width: ratingExpanded ? "1em" : "0px",
              },
              "& svg": {
                transition: "all 0.5s ease-in-out",
                width: "18px",
                height: "18px",
                "@media (min-width: 350px)": {
                  width: "21px",
                  height: "21px",
                },
                "@media (min-width: 550px)": {
                  width: "25px",
                  height: "25px",
                },
                "@media (min-width: 700px)": {
                  width: "28px",
                  height: "28px",
                },
              },
            }}
          />
          {ratingLoading ? (
            <Skeleton
              variant="text"
              sx={{
                width: "60px",
                fontSize: "14px",
                marginBottom: "0px",
                marginLeft: "25px",
              }}
            />
          ) : (
            !reviewExpanded && (
              <ButtonBase
                disableRipple
                disabled={isRatingUpdating}
                onClick={() => setRatingExpanded(!ratingExpanded)}
                sx={{
                  padding: "0px",
                  margin: "0px",
                  borderRadius: "5px",
                  marginLeft: ratingExpanded ? "5px" : "23px",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  "@media (min-width: 350px)": {
                    marginLeft: ratingExpanded ? "5px" : "25px",
                  },
                  "@media (min-width: 550px)": {
                    marginLeft: ratingExpanded ? "6px" : "28px",
                  },
                  "@media (min-width: 700px)": {
                    marginLeft: ratingExpanded ? "7px" : "33px",
                  },
                }}
              >
                <p
                  className="AddReviewItemInnerText"
                  style={{ color: globalTheme.textMainBlack.color }}
                >
                  {isRatingUpdating
                    ? "Updating"
                    : ratingExpanded
                    ? "Close"
                    : rating !== 0
                    ? "Update"
                    : "Rate"}
                </p>
                {!ratingExpanded && (
                  <p
                    className="AddReviewItemInnerText"
                    style={{
                      color: globalTheme.figmaLinks.color,
                      marginLeft: "5px",
                      marginRight: "10px",
                    }}
                  >
                    {ratingExpanded
                      ? listing.name.length > 8
                        ? listing.name.slice(0, 8) + "..."
                        : listing.name
                      : listing.name.length > 20
                      ? listing.name.slice(0, 20) + "..."
                      : listing.name}
                  </p>
                )}
              </ButtonBase>
            )
          )}
        </div>
        <div className="AddReviewItemInner">
          <RateReviewOutlinedIcon
            sx={{
              color: globalTheme.textMainBlack.color,
              fontSize: "16px",
              marginTop: "3px",
              "@media (min-width: 350px)": {
                fontSize: "19px",
              },
              "@media (min-width: 550px)": {
                fontSize: "22px",
              },
              "@media (min-width: 700px)": {
                fontSize: "24px",
              },
            }}
          />
          {reviewLoading ? (
            <Skeleton
              variant="text"
              sx={{
                width: "40px",
                fontSize: "14px",
                marginBottom: "0px",
                marginLeft: "5px",
              }}
            />
          ) : (
            <ButtonBase
              disableRipple
              onClick={() => setReviewExpanded(!reviewExpanded)}
              disabled={isReviewUpdating}
              sx={{
                padding: "0px",
                margin: "0px",
                borderRadius: "5px",
                marginLeft: "5px",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <p
                className="AddReviewItemInnerText"
                style={{ color: globalTheme.textMainBlack.color }}
              >
                {reviewExpanded
                  ? "Close"
                  : reviewData?.review === true
                  ? "Update review"
                  : "Write a review"}
              </p>
            </ButtonBase>
          )}
        </div>
      </div>

      <div
        className="AddReviewItemNewReviewOuter"
        style={{
          height: reviewExpanded ? "250px" : "0px",
          marginTop: reviewExpanded ? "10px" : "0px",
        }}
      >
        <TextField
          id="outlined-multiline-static"
          label="Title"
          multiline
          rows={1}
          size="small"
          value={reviewTitle}
          onChange={(e) => setReviewTitle(e.target.value)}
          variant="outlined"
          sx={{
            ...primaryColorTextField,
            width: "100%",
            borderRadius: "8px",
            marginTop: "15px",
          }}
        />
        <TextField
          id="outlined-multiline-static"
          label="Review"
          size="small"
          multiline
          rows={4}
          value={reviewBody}
          onChange={(e) => setReviewBody(e.target.value)}
          variant="outlined"
          sx={{
            ...primaryColorTextField,
            width: "100%",
            borderRadius: "8px",
            marginTop: "13px",
          }}
        />
        <div className="AddReviewItemNewReviewButtons">
          <ButtonBase
            onClick={onReviewCancel}
            disabled={isReviewUpdating}
            sx={{
              minWidth: "80px",
              padding: "5px",
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <p
              className="AddReviewItemInnerText"
              style={{ color: globalTheme.textMainBlack.color }}
            >
              Cancel
            </p>
          </ButtonBase>
          <ButtonBase
            onClick={onReviewSubmit}
            disabled={
              isReviewUpdating ||
              reviewTitle === "" ||
              reviewBody === "" ||
              reviewTitle.length < 5 ||
              reviewBody.length < 8 ||
              reviewTitle.length > 100 ||
              reviewBody.length > 1000 ||
              rating === 0 ||
              rating === null ||
              rating === undefined ||
              (reviewData !== null &&
                reviewData.title !== null &&
                reviewData.title !== undefined &&
                reviewTitle === reviewData.title &&
                reviewData !== null &&
                reviewData.text !== null &&
                reviewData.text !== undefined &&
                reviewBody === reviewData.text)
            }
            sx={{
              minWidth: "80px",
              padding: "5px",
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <p
              className="AddReviewItemInnerText"
              style={{ color: globalTheme.textMainBlack.color }}
            >
              {isReviewUpdating ? "Submitting..." : "Submit"}
            </p>
          </ButtonBase>
        </div>
      </div>
    </div>
  );
}
