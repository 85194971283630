import React, { useState, useEffect } from "react";
import { getDatabase, ref, set } from "firebase/database";
import { firebaseRlDb } from "../../../../components/firebase/database";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../../components/firebase/auth";

import { IconButton, ButtonBase } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import Face6OutlinedIcon from "@mui/icons-material/Face6Outlined";
import Face3OutlinedIcon from "@mui/icons-material/Face3Outlined";
import TokenOutlinedIcon from "@mui/icons-material/TokenOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";

import { useSettingsStates, useThemeState } from "../../../../components/utils/globalStates";
import "./ProfileSettingsComp.css";

const tabEnums = [
  {
    value: "explore",
    label: "Explore",
    icon: <ExploreOutlinedIcon />,
    path: "/explore",
    uidRequired: false,
  },
  {
    value: "search",
    label: "Search",
    icon: <SearchOutlinedIcon />,
    path: "/search",
    uidRequired: false,
  },
  {
    value: "reviews",
    label: "Reviews",
    icon: <RateReviewOutlinedIcon />,
    path: "/reviews",
    uidRequired: false,
  },
  {
    value: "users",
    label: "Users",
    icon: <GroupOutlinedIcon />,
    path: "/users",
    uidRequired: false,
  },
  {
    value: "wallets",
    label: "Wallets",
    icon: <TokenOutlinedIcon />,
    path: "/wallets",
    uidRequired: false,
  },
  {
    value: "profile",
    label: "Profile",
    icon: <AccountCircleOutlinedIcon />,
    path: "/profile",
    uidRequired: false,
  },
  {
    value: "bookmarks",
    label: "Bookmarks",
    icon: <BookmarkBorderOutlinedIcon />,
    path: "/profile/bookmarks",
    uidRequired: false,
  },
  {
    value: "achievements",
    label: "Achievements",
    icon: <EmojiEventsOutlinedIcon />,
    path: "/achievements/list",
    uidRequired: false,
  },
  {
    value: "my-achievements",
    label: "My Achievements",
    icon: <EmojiEventsOutlinedIcon />,
    path: "/achievements",
    uidRequired: false,
  },
  {
    value: "my-listings",
    label: "My Listings",
    icon: <FolderOutlinedIcon />,
    path: "/users/listings/",
    uidRequired: true,
  },
  {
    value: "my-reviews",
    label: "My Reviews",
    icon: <RateReviewOutlinedIcon />,
    path: "/reviews/user/",
    uidRequired: true,
  },
  {
    value: "my-ratings",
    label: "My Ratings",
    icon: <StarBorderOutlinedIcon />,
    path: "/reviews/user/rating/",
    uidRequired: true,
  },
  {
    value: "following",
    label: "Following",
    icon: <Face6OutlinedIcon />,
    path: "/users/follow/out/",
    uidRequired: true,
  },
  {
    value: "followers",
    label: "Followers",
    icon: <Face3OutlinedIcon />,
    path: "/users/follow/in/",
    uidRequired: true,
  },
  {
    value: "settings",
    label: "Settings",
    icon: <SettingsOutlinedIcon />,
    path: "/profile/settings",
  },
];

export default function BottomNavigatorConfigurator() {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [savingMessage, setSavingMessage] = useState<string>(" ");
  const settingsStates = useSettingsStates((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [user, loading, error] = useAuthState(firebaseAuth);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const onTabClick = async (tab: string) => {
    let tempTabValues = [...settingsStates.tab];
    if (tempTabValues.includes(tab)) {
      let tabIndex = tempTabValues.findIndex((tabValue) => tabValue === tab);
      tempTabValues.splice(tabIndex, 1);
      tempTabValues.splice(tabIndex - 1, 0, tab);
    } else {
      tempTabValues.pop();
      tempTabValues.push(tab);
    }
    setIsSaving(true);
    setSavingMessage("Saving...");
    try {
      await set(
        ref(firebaseRlDb, `public/settings/user/${user?.uid}/tab`),
        tempTabValues.join(",")
      );

      setIsSaving(false);
      setTimeout(() => {
        setSavingMessage(" ");
      }, 1000);
    } catch (error) {
      console.error(error);
      setIsSaving(false);
      setSavingMessage("Error saving settings");
    }
  };

  const moveUp = (tab: string) => {
    let tempTabValues = [...settingsStates.tab];
    let tabIndex = tempTabValues.findIndex((tabValue) => tabValue === tab);
    if (tabIndex !== 0) {
      let temp = tempTabValues[tabIndex];
      tempTabValues[tabIndex] = tempTabValues[tabIndex - 1];
      tempTabValues[tabIndex - 1] = temp;
      setIsSaving(true);
      setSavingMessage("Saving...");
      try {
        set(
          ref(firebaseRlDb, `public/settings/user/${user?.uid}/tab`),
          tempTabValues.join(",")
        );
        setIsSaving(false);
        setTimeout(() => {
          setSavingMessage(" ");
        }, 1000);
      } catch (error) {
        console.error(error);
        setIsSaving(false);
        setSavingMessage("Error saving settings");
      }
    }
  };

  const moveDown = (tab: string) => {
    let tempTabValues = [...settingsStates.tab];
    let tabIndex = tempTabValues.findIndex((tabValue) => tabValue === tab);
    if (tabIndex !== tempTabValues.length - 1) {
      let temp = tempTabValues[tabIndex];
      tempTabValues[tabIndex] = tempTabValues[tabIndex + 1];
      tempTabValues[tabIndex + 1] = temp;
      setIsSaving(true);
      setSavingMessage("Saving...");
      try {
        set(
          ref(firebaseRlDb, `public/settings/user/${user?.uid}/tab`),
          tempTabValues.join(",")
        );
        setIsSaving(false);
        setTimeout(() => {
          setSavingMessage(" ");
        }, 1000);
      } catch (error) {
        console.error(error);
        setIsSaving(false);
        setSavingMessage("Error saving settings");
      }
    }
  };

  return (
    <div className="BottomNavigatorConfigurator" >
      <div
        className="BottomNavigatorPreview"
        style={{
          backgroundColor: globalTheme.figmaBeigeDark.color,
          border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          boxShadow: globalTheme.boxShadow.boxShadow
        }}
      >
        <div className="BottomNavigatorPreviewItem">
          <DashboardOutlinedIcon
            sx={{ color: globalTheme.figmaGOrange.color }}
          />
        </div>
        {settingsStates.tab.map((tab) => {
          return (
            <div
              className="BottomNavigatorPreviewItem"
              key={tab}
              style={{ color: globalTheme.figmaPrimaryText.color }}
            >
              {tabEnums.find((tabEnum) => tabEnum.path === tab)?.icon}
            </div>
          );
        })}
      </div>
      <div className="BottomNavigatorPicker">
        {settingsStates.tab.map((tab, index) => {
          return (
            <div
              key={tab}
              className="BottomNavigatorPickerButton"
              style={{
                border: `1px solid ${globalTheme.pickerOrangeYellow.color}`,
              }}
            >
              <p
                className="BottomNavigatorPreviewItemLabel"
                style={{ color: globalTheme.backgroundMainOffYellow.color }}
              >
                {tabEnums.find((tabEnum) => tabEnum.path === tab)?.label}
              </p>
              <div
                className="BottomNavigatorPickerButtonEnd"
                style={{ backgroundColor: globalTheme.pickerOrangeYellow.color }}
              >
                <IconButton
                  disabled={index === 0 || isSaving || !isExpanded}
                  onClick={() => moveUp(tab)}
                  size="small"
                  sx={{
                    color: globalTheme.figmaPrimaryText.color,
                    padding: "0px",
                    margin: "0px",
                  }}
                >
                  {index !== 0 && (
                    <KeyboardArrowUpOutlinedIcon
                      sx={{
                        color: globalTheme.figmaPrimaryText.color,
                        margin: "0px",
                        fontSize: "16px",
                        "@media (min-width: 350px)": {
                          fontSize: "18px",
                        },
                        "@media (min-width: 550px)": {
                          fontSize: "20px",
                        },
                        "@media (min-width: 700px)": {
                          fontSize: "22px",
                        },
                      }}
                    />
                  )}
                </IconButton>
                <IconButton
                  disabled={index === settingsStates.tab.length - 1 || isSaving || !isExpanded}
                  onClick={() => moveDown(tab)}
                  size="small"
                  sx={{
                    color: globalTheme.figmaPrimaryText.color,
                    padding: "0px",
                    margin: "0px",
                  }}
                >
                  {index !== settingsStates.tab.length - 1 && (
                    <KeyboardArrowDownOutlinedIcon
                      sx={{
                        color: globalTheme.figmaPrimaryText.color,
                        margin: "0px",
                        fontSize: "16px",
                        "@media (min-width: 350px)": {
                          fontSize: "18px",
                        },
                        "@media (min-width: 550px)": {
                          fontSize: "20px",
                        },
                        "@media (min-width: 700px)": {
                          fontSize: "22px",
                        },
                      }}
                    />
                  )}
                </IconButton>
              </div>
            </div>
          );
        })}
        <div
          className="BottomNavigatorPickerExpand"
          style={{ maxHeight: isExpanded ? "1200px" : "0px" }}
        >
          {tabEnums.map((tabEnum) => {
            if (!settingsStates.tab.includes(tabEnum.path)) {
              return (
                <div
                key={tabEnum.path}
                  className="BottomNavigatorPickerButton"
                  style={{
                    border: `1px solid ${globalTheme.pickerGreenYellow.color}`,
                  }}
                >
                  <p
                    className="BottomNavigatorPreviewItemLabel"
                    style={{ color: globalTheme.backgroundMainOffYellow.color }}
                  >
                    {tabEnum.label}
                  </p>
                  <div
                    className="BottomNavigatorPickerButtonEnd"
                    style={{ backgroundColor: globalTheme.pickerGreenTransparent.color, borderLeft: `1px solid ${globalTheme.pickerGreenYellow.color}`} }
                  >
                    <IconButton
                      disabled={isSaving || !isExpanded}
                      onClick={() => onTabClick(tabEnum.path)}
                      size="small"
                      sx={{
                        color: globalTheme.backgroundMainOffYellow.color,
                        padding: "0px",
                        margin: "0px",
                      }}
                    >
                      <AddOutlinedIcon
                        sx={{
                          color: globalTheme.backgroundMainOffYellow.color,
                          margin: "0px",
                          fontSize: "16px",
                          "@media (min-width: 350px)": {
                            fontSize: "18px",
                          },
                          "@media (min-width: 550px)": {
                            fontSize: "20px",
                          },
                          "@media (min-width: 700px)": {
                            fontSize: "22px",
                          },
                        }}
                      />
                    </IconButton>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
      <ButtonBase
        sx={{
          color: globalTheme.figmaPrimaryText.color,
          backgroundColor: globalTheme.figmaAlt2.color,
          borderRadius: "5px",
          border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          padding: "8px",
          margin: "0px",
          marginTop: "2px",
          width: "100%",
        }}
        onClick={() => {
          setIsExpanded(!isExpanded)
        }}
      >
        <p
          className="BottomNavigatorPreviewItemLabel"
          style={{ color: globalTheme.figmaPrimaryText.color }}
        >
          {savingMessage.length > 3 ? ('Saving...'):(isExpanded ? "Stop Editing" : "Edit")}
        </p>
      </ButtonBase>
    </div>
  );
}

// /profile/settings,/explore,/profile/bookmarks,/listings
