import React, { useEffect, useState } from "react";
import { useThemeState } from "../utils/globalStates";
import { globalThemeLight, globalThemeDark } from "../theme/globalTheme";
import LogoMaskOnly from "../ui/img/LogoMaskOnly";
import "./Listeners.css";

export default function ThemeListeners() {
  const themeState = useThemeState();

  // Add event listener for local storage isSystemTheme and update theme to system theme if set to true, listen for changes

  useEffect(() => {
    const listener = () => {
      if (localStorage.getItem("isSystemTheme") === "true") {
        console.log("isSystemTheme is true");
        // Check if system theme is dark or light
        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
          console.log("SystemTheme is dark");
          themeState.setIsDark(true);
        } else {
          console.log("SystemTheme is light");
          themeState.setIsDark(false);
        }
      } else {
        console.log("isSystemTheme is false or null");
        if (localStorage.getItem("isDarkTheme") === "true") {
          console.log("isDarkTheme is true");
          themeState.setIsDark(true);
        } else {
          console.log("isDarkTheme is false or null");
          themeState.setIsDark(false);
        }
      }
    };
    listener();
    window.addEventListener("storage", listener);
    return () => {
      window.removeEventListener("storage", listener);
    };
  }, []);

  // Evenet listener for MediaQueryListEvent prefers-color-scheme: dark and light, update theme accordingly if isSystemTheme is true
    useEffect(() => {
        const listener = (e: MediaQueryListEvent) => {
            console.log('media query event')
        if (localStorage.getItem("isSystemTheme") === "true") {
            if (e.matches) {
                console.log('update theme to dark')
            themeState.setIsDark(true);
            } else {
                console.log('update theme to light')
            themeState.setIsDark(false);
            }
        }
        };
        const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
        mediaQuery.addEventListener("change", listener);
        return () => {
        mediaQuery.removeEventListener("change", listener);
        };
    }, []);

    // Event listener themeState.isDark, update local storage isDarkTheme
    useEffect(() => {
        if (themeState.isDark) {
            themeState.setGlobalTheme(globalThemeDark);
        } else {
            themeState.setGlobalTheme(globalThemeLight);
        }
    }, [themeState.isDark]);

  return <></>;
}
