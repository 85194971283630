import React, { useState, useEffect } from "react";
import {
  getDocs,
  where,
  limit,
  orderBy,
  OrderByDirection,
  collection,
  doc,
  query,
  addDoc,
} from "firebase/firestore";
import { Link, useParams, useNavigate } from "react-router-dom";
import { CircularProgress, Avatar } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import ButtonBase from "@mui/material/ButtonBase";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import TokenOutlinedIcon from "@mui/icons-material/TokenOutlined";
import moment from "moment";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Radio from "@mui/material/Radio";

import {
  useSnackbarErrorState,
  useTabSettingsState,
  useHeaderSettingsState,
  useThemeState,
} from "../../../components/utils/globalStates";

import WalletTradeItem from "../components/WalletTradeItem";
import WalletMintItem from "../components/WalletMintItem";
import WalletsInfo from "../../../components/ui/topBoxes/WalletsInfo";
import { alchemyEth } from "../../../components/web3/alchemy";
import { firestoreDb } from "../../../components/firebase/firestore";
import "./Wallets.css";
import "../../user/list/UserList.css";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

moment().format();

const initWallets = [
  {
    wallet: "vitalik.eth",
    createdAt: Date.now(),
    name: "Vitalik Buterin",
  },
  {
    wallet: "0x679d5162BaD71990ABCA0f18095948c12a2756B0",
    createdAt: Date.now(),
    name: "AriZonaNFTea",
  },
];

const initWalletIds = [
  "vitalik.eth",
  "0x679d5162BaD71990ABCA0f18095948c12a2756B0",
];

let externaLLoaded = false;
let externalWalletLoaded = false;

export default function WalletsLatest() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const headerSettingsState = useHeaderSettingsState();
  const tabSettingsState = useTabSettingsState();
  const globalTheme = useThemeState((state) => state.globalTheme);

  const navigate = useNavigate();
  const snackbarError = useSnackbarErrorState((state) => state);

  const [isLoading, setIsLoading] = useState(true);
  const [listings, setListings] = useState<any[]>([]);
  const [walletIds, setWalletIds] = useState<any[]>([]);

  const [combinedFeed, setCombinedFeed] = useState<any[]>([]);
  const [rss3SocialFeed, setRss3SocialFeed] = useState<any[]>([]);
  const [nftMintFeed, setNftMintFeed] = useState<any[]>([]);
  const [nftBuyFeed, setNftBuyFeed] = useState<any[]>([]);
  const [nftSellFeed, setNftSellFeed] = useState<any[]>([]);

  const [loadingMessage, setLoadingMessage] = useState("");

  const [filterString, setFilterString] = useState("trade");
  const [filterStringSend, setFilterStringSend] = useState("trade");
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);

  const filterNames = () => {
    const filterArr = filterString.split(",");
    if (
      filterArr.includes("mint") &&
      filterArr.includes("donate") &&
      filterArr.includes("post") &&
      filterArr.includes("comment") &&
      filterArr.includes("trade")
    ) {
      return "All";
    }

    if (filterArr.length === 1) {
      return filterArr[0].charAt(0).toUpperCase() + filterArr[0].slice(1);
    }
    let returnString = "";
    for (let i = 0; i < filterArr.length; i++) {
      if (i === filterArr.length - 1) {
        returnString +=
          " & " + filterArr[i].charAt(0).toUpperCase() + filterArr[i].slice(1);
      } else if (i === filterArr.length - 2) {
        returnString +=
          filterArr[i].charAt(0).toUpperCase() + filterArr[i].slice(1);
      } else {
        returnString +=
          filterArr[i].charAt(0).toUpperCase() + filterArr[i].slice(1) + ", ";
      }
    }
    return returnString;
  };

  const onFilterClick = (filter: string) => {
    const filterArr = filterString.split(",");
    if (filterArr.length === 1 && filterArr[0] === filter) {
      return;
    }
    if (filterArr.includes(filter)) {
      const newFilterArr = filterArr.filter((item) => item !== filter);
      setFilterString(newFilterArr.join(","));
    } else {
      filterArr.push(filter);
      setFilterString(filterArr.join(","));
    }
  };

  const onFilterSend = () => {
    setFilterStringSend(filterString);
    setFilterDialogOpen(false);
  };

  useEffect(() => {
    if (user === null || user === undefined) {
      return;
    }
    if (externalWalletLoaded === true) {
      return;
    }
    externalWalletLoaded = true;
    setLoadingMessage("Fetching Wallets...");
    const loadListings = async () => {
      try {
        const q = query(
          collection(firestoreDb, "follow", user.uid, "wallets"),
          limit(100)
        );
        const querySnapshot = await getDocs(q);
        let tempArray: any[] = [];
        let tempWalletIds: any[] = [];
        querySnapshot.forEach((doc) => {
          tempArray.push(doc.data());
          tempWalletIds.push(doc.data().wallet);
        });
        if (tempArray.length === 0) {
          const docRef = collection(firestoreDb, "follow", user.uid, "wallets");
          for (let i = 0; i < initWallets.length; i++) {
            await addDoc(docRef, initWallets[i]);
          }
          setListings(initWallets);
          setWalletIds(initWalletIds);
          externalWalletLoaded = false;
        } else {
          setListings(tempArray);
          setWalletIds(tempWalletIds);
          externalWalletLoaded = false;
        }
      } catch (error) {
        console.error("Error getting documents: ", error);
      }
    };
    loadListings();
  }, []);

  useEffect(() => {
    if (walletIds.length === 0) {
      return;
    }
    if (externaLLoaded === true) {
      return;
    }
    externaLLoaded = true;
    setIsLoading(true);
    setLoadingMessage("Fetching Data from Blockchain...");
    Promise.all([
      fetchRss3SocialFeedInit(),
      fetchNftMintsInit(),
      fetchNftBuyInit(),
      fetchNftSalesInit(),
    ])
      .then((res) => {
        setCombinedFeed(
          [...res[0], ...res[1], ...res[2], ...res[3]].sort(
            (a, b) => b.timestamp - a.timestamp
          )
        );
        setIsLoading(false);
        externaLLoaded = false;
      })
      .catch((error) => {
        console.error(error);
        snackbarError.setMessage(
          "Error fetching wallet data, please reload the page!"
        );
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
        setIsLoading(false);
        externaLLoaded = false;
      });
  }, [filterStringSend, walletIds]);

  const fetchRss3SocialFeedInit = async () => {
    let tempTypeArray: any[] = [];
    let tempTypeString = filterStringSend.split(",");
    for (let i = 0; i < tempTypeString.length; i++) {
      if (
        tempTypeString[i] === "donate" ||
        tempTypeString[i] === "post" ||
        tempTypeString[i] === "comment"
      ) {
        tempTypeArray.push(tempTypeString[i]);
      }
    }
    if (tempTypeArray.length === 0) {
      return [];
    }
    if (walletIds.length === 0) {
      return [];
    }
    try {
      const rss3Response = await Promise.all(
        walletIds.map((walletId) => fetchRss3SocialFeed(walletId))
      );
      const tempArray: any[] = [];
      for (let i = 0; i < rss3Response.length; i++) {
        for (let j = 0; j < rss3Response[i].length; j++) {
          tempArray.push(rss3Response[i][j]);
        }
      }
      setRss3SocialFeed(tempArray);
      return tempArray;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const fetchRss3SocialFeed = async (walletId: string) => {
    if (walletId === undefined || walletId === null || walletId === "") {
      return [];
    }

    let tempTypeArray: any[] = [];
    let tempTypeString = filterStringSend.split(",");
    for (let i = 0; i < tempTypeString.length; i++) {
      if (
        tempTypeString[i] === "donate" ||
        tempTypeString[i] === "post" ||
        tempTypeString[i] === "comment"
      ) {
        tempTypeArray.push(tempTypeString[i]);
      }
    }
    if (tempTypeArray.length === 0) {
      return [];
    }

    try {
      const options = {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          action_limit: 10,
          limit: 5,
          account: [walletId],
          direction: "out",
          type: tempTypeArray,
          tag: ["donation", "social"],
        }),
      };

      const response = await fetch(
        "https://testnet.rss3.io/data/accounts/activities",
        options
      );
      const data = await response.json();
      let tempArray: any[] = [];
      for (let i = 0; i < data.data.length; i++) {
        for (let j = 0; j < data.data[i].actions.length; j++) {
          if (data.data[i].actions[j].type === "donate") {
            tempArray.push({
              type: "donate",
              timestamp: data.data[i].timestamp * 1000,
              metadata: data.data[i].actions[j].metadata,
              relatedUrls: data.data[i].actions[j].related_urls,
              network: data.data[i].network,
              tag: data.data[i].tag,
              platform: data.data[i].actions[j].platform,
              ownerWallet: walletId,
            });
          } else if (data.data[i].actions[j].type === "post") {
            tempArray.push({
              type: "post",
              timestamp: data.data[i].timestamp * 1000,
              metadata: data.data[i].actions[j].metadata,
              relatedUrls: data.data[i].actions[j].related_urls,
              network: data.data[i].network,
              tag: data.data[i].tag,
              platform: data.data[i].actions[j].platform,
              ownerWallet: walletId,
            });
          } else if (data.data[i].actions[j].type === "comment") {
            tempArray.push({
              type: "comment",
              timestamp: data.data[i].timestamp * 1000,
              metadata: data.data[i].actions[j].metadata,
              relatedUrls: data.data[i].actions[j].related_urls,
              network: data.data[i].network,
              tag: data.data[i].tag,
              platform: data.data[i].actions[j].platform,
              ownerWallet: walletId,
            });
          }
        }
      }
      return tempArray;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const fetchNftMintsInit = async () => {
    if (filterStringSend.includes("mint") === false) {
      return [];
    }
    if (nftMintFeed.length > 0) {
      return nftMintFeed;
    }
    if (walletIds.length === 0) {
      return [];
    }
    try {
      const mintResponse = await Promise.all(
        walletIds.map((walletId) => fetchNftMints(walletId))
      );
      const tempArray: any[] = [];
      for (let i = 0; i < mintResponse.length; i++) {
        for (let j = 0; j < mintResponse[i].length; j++) {
          tempArray.push(mintResponse[i][j]);
        }
      }
      setNftMintFeed(tempArray);
      return tempArray;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const fetchNftMints = async (walletId: string) => {
    if (walletId === undefined || walletId === null || walletId === "") {
      return [];
    }

    try {
      const options = {
        tokenType: "ERC721",
        limit: 10,
      };
      // @ts-ignore
      const mintedNfts = await alchemyEth.nft.getMintedNfts(walletId, options);
      const tempArray: any[] = [];
      for (let i = 0; i < mintedNfts.nfts.length; i++) {
        if (
          mintedNfts.nfts[i].image.cachedUrl !== undefined &&
          mintedNfts.nfts[i].contract.isSpam !== true &&
          mintedNfts.nfts[i].raw.metadata !== undefined &&
          mintedNfts.nfts[i].raw.metadata.name !== undefined &&
          mintedNfts.nfts[i].blockNumber !== undefined
        ) {
          let tempDate = new Date(mintedNfts.nfts[i].timeLastUpdated).getTime();
          tempArray.push({
            type: "mint",
            metadata: mintedNfts.nfts[i].raw.metadata,
            collection: mintedNfts.nfts[i].collection,
            contract: mintedNfts.nfts[i].contract,
            image: mintedNfts.nfts[i].image.cachedUrl,
            tokenUri: mintedNfts.nfts[i].tokenUri,
            name: mintedNfts.nfts[i].name,
            description: mintedNfts.nfts[i].description,
            blockNumber: Number(mintedNfts.nfts[i].blockNumber),
            timestamp: tempDate,
            tokenId: mintedNfts.nfts[i].tokenId,
            ownerWallet: walletId,
          });
        }
      }
      return tempArray;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const fetchNftBuyInit = async () => {
    if (filterStringSend.includes("trade") === false) {
      return [];
    }
    if (nftBuyFeed.length > 0) {
      return nftBuyFeed;
    }
    if (walletIds.length === 0) {
      return [];
    }
    try {
      const buyResponse = await Promise.all(
        walletIds.map((walletId) => fetchNftBuy(walletId))
      );
      const tempArray: any[] = [];
      for (let i = 0; i < buyResponse.length; i++) {
        for (let j = 0; j < buyResponse[i].length; j++) {
          tempArray.push(buyResponse[i][j]);
        }
      }
      setNftBuyFeed(tempArray);
      return tempArray;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const fetchNftBuy = async (walletId: string) => {
    if (walletId === undefined || walletId === null || walletId === "") {
      return [];
    }

    try {
      // @ts-ignore
      const boughtNft = await alchemyEth.nft.getNftSales({
        buyerAddress: walletId,
        limit: 5,
        // @ts-ignore
        order: "desc",
      });
      let tempArray: any[] = [];
      let contractArray: any[] = [];
      for (let i = 0; i < boughtNft.nftSales.length; i++) {
        contractArray.push({
          contractAddress: boughtNft.nftSales[i].contractAddress,
          tokenId: boughtNft.nftSales[i].tokenId,
        });
      }
      const nftMetadata = await alchemyEth.nft.getNftMetadataBatch(
        contractArray
      );
      for (let i = 0; i < boughtNft.nftSales.length; i++) {
        let tempDate = new Date(nftMetadata.nfts[i].timeLastUpdated).getTime();
        tempArray.push({
          ...boughtNft.nftSales[i],
          type: "trade",
          metadata: nftMetadata.nfts[i],
          seller: false,
          blockNumber: Number(boughtNft.nftSales[i].blockNumber),
          timestamp: tempDate,
          ownerWallet: walletId,
        });
      }
      return tempArray;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const fetchNftSalesInit = async () => {
    if (filterStringSend.includes("trade") === false) {
      return [];
    }

    if (nftSellFeed.length > 0) {
      return nftSellFeed;
    }
    if (walletIds.length === 0) {
      return [];
    }
    try {
      const sellResponse = await Promise.all(
        walletIds.map((walletId) => fetchNftSales(walletId))
      );
      const tempArray: any[] = [];
      for (let i = 0; i < sellResponse.length; i++) {
        for (let j = 0; j < sellResponse[i].length; j++) {
          tempArray.push(sellResponse[i][j]);
        }
      }
      setNftSellFeed(tempArray);
      return tempArray;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const fetchNftSales = async (walletId: string) => {
    if (walletId === undefined || walletId === null || walletId === "") {
      return [];
    }

    try {
      // @ts-ignore
      const soldNfts = await alchemyEth.nft.getNftSales({
        sellerAddress: walletId,
        limit: 5,
        // @ts-ignore
        order: "desc",
      });
      let tempArray: any[] = [];
      let contractArray: any[] = [];
      for (let i = 0; i < soldNfts.nftSales.length; i++) {
        contractArray.push({
          contractAddress: soldNfts.nftSales[i].contractAddress,
          tokenId: soldNfts.nftSales[i].tokenId,
        });
      }
      const nftMetadata = await alchemyEth.nft.getNftMetadataBatch(
        contractArray
      );
      for (let i = 0; i < soldNfts.nftSales.length; i++) {
        tempArray.push({
          ...soldNfts.nftSales[i],
          type: "trade",
          metadata: nftMetadata.nfts[i],
          seller: true,
          blockNumber: Number(soldNfts.nftSales[i].blockNumber),
          ownerWallet: walletId,
        });
      }
      return tempArray;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    headerSettingsState.setIsLogoOpen(true);
    tabSettingsState.setColor(globalTheme.figmaBeigeDark.color);
    return () => {
      headerSettingsState.setIsLogoOpen(false);
      tabSettingsState.setColor(globalTheme.figmaBeigeLight.color);
    };
  }, []);

  const styles = {
    dialogRoot: {
      zIndex: 9999,
      backgroundColor: "rgba(253, 247, 234, 0.5)",
      "& .MuiDialog-paper": {
        backgroundColor: globalTheme.figmaBeigeBG.color,
        color: globalTheme.figmaPrimaryText.color,
        width: "94%",
        maxWidth: "600px",
        margin: 0,
        paddingBottom: "70px",
        paddingTop: "20px",
        position: "absolute",
        bottom: "-10px",
      },
    },
    dialogTitle: {
      color: globalTheme.figmaPrimaryText.color,
      textAlign: "center",
      fontSize: "16px",
      marginTop: "20px",
      fontWeight: 600,
    },
    radio: {
      color: globalTheme.figmaPrimaryText.color,
      "&.Mui-checked": {
        color: globalTheme.figmaPrimaryText.color,
      },
    },
    timelineOuter: {
      width: "104%",
      padding: "0px",
      margin: "0px",
    },
  };

  return (
    <div
      className="WalletOuter"
      style={{
        backgroundColor: globalTheme.figmaBeigeDark.color,
        marginTop: "-10px",
      }}
    >
      <WalletsInfo />
      <div className="WalletHeaderLinks">
        <div
          className="WalletHeaderLinksInner"
          style={{
            width: "50%",
            backgroundColor: globalTheme.figmaPurplePastel.color,
            paddingTop: "0px",
            paddingBottom: "0px",
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            borderRightWidth: "0.5px",
            borderRadius: "8px 0px 0px 8px",
          }}
        >
          <NewspaperOutlinedIcon
            color="inherit"
            sx={{
              fontSize: "16px",
              color: globalTheme.figmaPrimaryText.color,
              marginRight: "5px",
              "@media (min-width: 350px)": {
                fontSize: "17px",
              },
              "@media (min-width: 550px)": {
                fontSize: "18px",
              },
              "@media (min-width: 700px)": {
                fontSize: "19px",
              },
            }}
          />
          <p
            className="WalletHeaderLinksText"
            style={{ color: globalTheme.figmaPrimaryText.color }}
          >
            Latest
          </p>
        </div>

        <Link
          to="/wallets/list"
          className="WalletHeaderLinksInner"
          style={{
            textDecoration: "none",
            width: "50%",
            paddingTop: "0px",
            paddingBottom: "0px",
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            borderLeftWidth: "0.5px",
            borderRadius: "0px 8px 8px 0px",
          }}
        >
          <TokenOutlinedIcon
            color="inherit"
            sx={{
              fontSize: "16px",
              marginRight: "5px",
              color: globalTheme.figmaPrimaryText.color,
              "@media (min-width: 350px)": {
                fontSize: "17px",
              },
              "@media (min-width: 550px)": {
                fontSize: "18px",
              },
              "@media (min-width: 700px)": {
                fontSize: "19px",
              },
            }}
          />
          <p
            className="WalletHeaderLinksText"
            style={{ color: globalTheme.figmaPrimaryText.color }}
          >
            Wallets
          </p>
        </Link>
      </div>
      <div className="UserListSort">
        <ButtonBase
          className="UserListSortButton"
          onClick={() => {
            setFilterDialogOpen(true);
          }}
        >
          <div className="UserListSortInner">
            <p
              className="UserListSortTitle"
              style={{ color: globalTheme.figmaSecondaryText.color }}
            >
              Filter by
            </p>
            <p
              className="UserListSortTitle"
              style={{
                color: globalTheme.figmaDashboardIcon.color,
                marginLeft: "5px",
                fontWeight: 500,
              }}
            >
              {filterNames()}
            </p>
          </div>
        </ButtonBase>
      </div>
      {isLoading ? (
        <div className="WalletBodyLoading">
          <CircularProgress
            sx={{ color: globalTheme.figmaSecondaryText.color }}
          />
          <p
            className="WalletListItemTitle"
            style={{
              color: globalTheme.figmaSecondaryText.color,
              textAlign: "center",
              marginTop: "15px",
            }}
          >
            {loadingMessage}
          </p>
        </div>
      ) : combinedFeed.length === 0 ? (
        <div className="WalletBodyLoading">
          <p
            className="WalletListItemTitle"
            style={{
              color: globalTheme.figmaSecondaryText.color,
              textAlign: "center",
            }}
          >
            No activity found
          </p>
        </div>
      ) : (
        <div
          className="WalletBody"
          style={{ width: "calc(90% + 14px)", maxWidth: "814px" }}
        >
          {combinedFeed.map((item, index) => {
            return item.type === "mint" ? (
              <div key={index} className="WalletItemOuter">
                <WalletMintItem mintItem={item} index={index} />
              </div>
            ) : item.type === "trade" ? (
              <div key={index} className="WalletItemOuter">
                <WalletTradeItem tradeItem={item} index={index} />
              </div>
            ) : null;
          })}
        </div>
      )}
      <Dialog
        open={filterDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={onFilterSend}
        sx={styles.dialogRoot}
      >
        <DialogTitle sx={styles.dialogTitle}>{"Filter by"}</DialogTitle>
        <DialogContent>
          <div className="UserListSortDialog">
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => onFilterClick("mint")}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  Mints
                </p>
                <Radio
                  checked={filterString.includes("mint")}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => onFilterClick("trade")}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  Trades
                </p>
                <Radio
                  checked={filterString.includes("trade")}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => onFilterClick("donate")}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  Donations
                </p>
                <Radio
                  checked={filterString.includes("donate")}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => onFilterClick("post")}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  Posts
                </p>
                <Radio
                  checked={filterString.includes("post")}
                  onChange={() => {}}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => onFilterClick("comment")}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  Comments
                </p>
                <Radio
                  checked={filterString.includes("comment")}
                  onChange={() => {}}
                  sx={styles.radio}
                />
              </div>
            </ButtonBase>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
