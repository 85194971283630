import React, { useState, useEffect } from "react";
import { IconButton, ButtonBase } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../../components/firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../../components/firebase/auth";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TransitionProps } from "@mui/material/transitions";
import moment from "moment";
import Slide from "@mui/material/Slide";
import {
  useThemeState,
  useAuthStates,
  useSnackbarErrorState,
  useSnackbarState,
} from "../../../../components/utils/globalStates";
import {
  standardDialogRoot,
  standardDialogPaper,
  standardDialogTitle,
  standardDialogText,
} from "../../../../components/theme/globalMuiTheme";
import "./ProfileSettingsComp.css";

moment().format();

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ReferralsCount() {
  const authStates = useAuthStates();
  const [user] = useAuthState(firebaseAuth);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [referrals, setReferrals] = useState([]);
  const [referralsLoading, setReferralsLoading] = useState(true);

  const globalTheme = useThemeState((state) => state.globalTheme);
  const snackbarState = useSnackbarState();
  const snackbarErrorState = useSnackbarErrorState();

  // Function to fetch referrals from referral-list_by_user callable function
  const fetchReferrals = async () => {
    const callable = httpsCallable(firebaseFunctions, "referral-list_by_user");
    try {
      const result: any = await callable();
      console.log(result.data);
      setReferrals(result.data.referrals);
      setReferralsLoading(false);
    } catch (error) {
      console.error(error);
      setReferralsLoading(false);
    }
  };

  useEffect(() => {
    if (
      authStates.userData !== null &&
      authStates.userData !== undefined &&
      referrals.length === 0 &&
      isDialogOpen &&
      referralsLoading
    ) {
      fetchReferrals();
    }
  }, [authStates.userData, isDialogOpen, referrals, referralsLoading]);

  const styles = {
    button: {
      color: globalTheme.figmaPrimaryText.color,
      backgroundColor: globalTheme.figmaAlt2.color,
      borderRadius: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  };
  
  return authStates.userData !== null &&
    authStates.userData.referralCount !== null &&
    authStates.userData.referralCount !== undefined &&
    authStates.userData.referralCount > 0 ? (
    <div className="ReferralsCount">
      <p className="ReferralsCountText">
        You have referred <b>{authStates.userData.referralCount}</b> users to
        GATH3R.
      </p>
      <ButtonBase style={styles.button} onClick={() => setIsDialogOpen(true)}>
        <p
          className="SocialConfigButtonText"
          style={{
            color: globalTheme.figmaPrimaryText.color,
          }}
        >
          View Users
        </p>
      </ButtonBase>
      <Dialog
        TransitionComponent={Transition}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        sx={{
          ...standardDialogRoot,
          "& .MuiDialog-paper": {
            ...standardDialogPaper,
            backgroundColor: globalTheme.backgroundMainBeige.color,
            color: globalTheme.backgroundMainOffBeige.color,
            border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
          },
        }}
      >
        <IconButton
          sx={{
            color: globalTheme.textMainGrey.color,
            width: "30px",
            height: "30px",
            position: "absolute",
            top: "25px",
            right: "15px",
          }}
          size="small"
          onClick={() => setIsDialogOpen(false)}
        >
          <CloseOutlined />
        </IconButton>
        <DialogTitle sx={standardDialogTitle}>
          Referrals
          <br />
          <span style={{ fontSize: "55%", fontWeight: 400 }}>
            A list of users you have referred to GATH3R.
          </span>
        </DialogTitle>
        <DialogContent>
          {!referralsLoading ? (
            referrals.length > 0 ? (
              referrals.map((referral: any) => {
                return (
                  <div
                    key={referral.uid}
                    className="ReferralDialogItem"
                    onClick={() => {
                      window.open(`/users/page/${referral.uid}`, "_blank");
                    }}
                  >
                    <DialogContentText
                      sx={{
                        ...standardDialogText,
                        fontWeight: 600,
                        color: globalTheme.textMainGrey.color,
                      }}
                    >
                      {referral.name}
                    </DialogContentText>
                    <DialogContentText
                      sx={{
                        ...standardDialogText,
                        fontWeight: 400,
                        color: globalTheme.textMainGrey.color,
                      }}
                    >
                      {moment(referral.date).format("MMMM Do YYYY")}
                    </DialogContentText>
                  </div>
                );
              })
            ) : (
              <DialogContentText
                sx={{
                  ...standardDialogText,
                  textAlign: "center",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                No referrals found...
              </DialogContentText>
            )
          ) : (
            // Create array with skeletons with same amount as referrals
            Array.from({ length: authStates.userData.referralCount }).map(
              (item, index) => {
                return (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    height="50px"
                    width="100%"
                    sx={{
                      marginBottom: "0px",
                      marginTop: "10px",
                      borderRadius: "5px",
                    }}
                  />
                );
              }
            )
          )}
        </DialogContent>
      </Dialog>
    </div>
  ) : null;
}
