import React, { useState, useEffect } from "react";

import { getDoc, doc } from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";

import { IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ReviewWidgetItem from "./components/ReviewWidgetItem";
import { useThemeState } from "../../../components/utils/globalStates";
import { algoliaReviewsCreatedAtDesc } from "../../../components/search/algoliaSearch";
import "./DashWidgets.css";

export default function ReviewWidget({
  bgColorCode,
  isEditing,
  deleteWidget,
  fulldata,
  elementIndex,
  rowIndex,
}: {
  bgColorCode: string;
  isEditing: boolean;
  deleteWidget: Function;
  fulldata: any;
  elementIndex: number;
  rowIndex: number;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [isDeleteing, setIsDeleting] = useState(false);
  const [widgetList, setWidgetList] = useState([null, null, null, null, null]);
  // On delete
  const onDelete = () => {
    setIsDeleting(true);
    deleteWidget(rowIndex, elementIndex);
  };

  const fetchTemplate = async() => {
    algoliaReviewsCreatedAtDesc
      .search("", {
        hitsPerPage: 5,
        facetFilters: 'review:true'
      })
      .then( async (res: any) => {
        let tempArray: any[] = [];
        for (let i = 0; i < res.hits.length; i++) {
          const fetchedDoc = await getDoc(doc(firestoreDb, "listings", res.hits[i].listing))
          if (fetchedDoc.exists()) {
            const tempDoc = {
              logo: fetchedDoc.data().logo,
              name: fetchedDoc.data().name,
              createdAt: res.hits[i].createdAt,
              rating: res.hits[i].rating,
              category: fetchedDoc.data().category,
              listingId: res.hits[i].listing,
              listingRating: fetchedDoc.data().rating,
              reviewCount: fetchedDoc.data().reviewsCount,
              ratingCount: fetchedDoc.data().ratingCount,
            };
            tempArray.push(tempDoc);
          }
        }

        setWidgetList(tempArray);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchTemplate();
  }, []);

  return (
    <div
      className="ListingsWidget"
      style={{
        color: globalTheme.figmaPrimaryText.color,
        border: isEditing
          ? `1px dashed ${globalTheme.backgroundMainOffYellow.color}`
          : `1px solid ${globalTheme.figmaPrimaryText.color}`,
        borderRadius: isEditing ? "0px" : "8px",
        backgroundColor: bgColorCode,
      }}
    >
      <div
        className="ListingsWidgetHeader"
        style={{
          color: globalTheme.figmaPrimaryText.color,
          borderBottom: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        }}
      >
        <p className="ListingsWidgetTitle">New Reviews</p>
      </div>
      <div className="ListingsWidgetInner">
        {widgetList.map((item, index) => (
          <ReviewWidgetItem isLink={true} dataToRender={item} key={index} />
        ))}
      </div>
      {isEditing && (
        <IconButton
          size="small"
          sx={{
            position: "absolute",
            right: "-1px",
            top: "-1px",
            padding: "2px",
            borderRadius: "0px 0px 0px 5px",
            width: "20%",
            color: globalTheme.backgroundMainOffYellow.color,
            backgroundColor: globalTheme.backgroundMainYellow.color,
            border: `1px dashed ${globalTheme.backgroundMainOffYellow.color}`,
            "&:hover": {
              backgroundColor: globalTheme.backgroundMainYellow.color,
            },
          }}
          onClick={onDelete}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      )}
    </div>
  );
}
