import Tooltip from '@mui/material/Tooltip';
import { useThemeState } from "../../../components/utils/globalStates";

import moment from "moment";
import "../../listings/item/ListingsItem.css";
import "./ListingItems.css";

moment().format();

export default function AdminUserItem({ listItem }: { listItem: any }) {
  const globalTheme = useThemeState((state) => state.globalTheme);

  return (
    <div
      className="ListingsItem"
      style={{
        backgroundColor: globalTheme.boxBackgroundWhite.color,
        border: `1px solid ${globalTheme.solidMainBlack.color}`,
        cursor: "pointer",
        ...globalTheme.boxShadow,
      }}
    >
      <div className="UserListInnerItemInner" style={{ margin: 0 }}>
        <p
          className="UserListItemTitle"
          style={{ color: globalTheme.textMainBlack.color }}
        >
          {listItem.displayName === ""
            ? "Anonymous user"
            : listItem.displayName}
        </p>
        <div className="AdminUserListItemSubOuter">
          <p className="AdminUserListItemSub">
            <b>Created: </b>
            {moment(listItem.createdAt).format("MMM Do YYYY")}
          </p>
          {listItem.lastActive !== null && listItem.lastActive !== undefined && (
            <p className="AdminUserListItemSub">
            <b>Last active: </b>
            {moment(listItem.lastActive).fromNow()}
          </p>
          )}
          <p className="AdminUserListItemSub">
            <b>Points: </b>
            {listItem.points}
          </p>
          <p className="AdminUserListItemSub">
            <b>Listings: </b>
            {listItem.listingCount}
          </p>
          <p className="AdminUserListItemSub">
            <b>Reviews: </b>
            {listItem.reviewsCount}
          </p>
          <p className="AdminUserListItemSub">
            <b>Ratings: </b>
            {listItem.ratingCount}
          </p>
          <p className="AdminUserListItemSub">
            <b>UID: </b>
            {listItem.id}
          </p>
        </div>
      </div>
      <Tooltip title={listItem.lastActive > Date.now() - 1200000 ? "Online" : "Offline"}>
        <div
          className="AdminUserListItemActive"
          style={{ backgroundColor: listItem.lastActive > Date.now() - 1200000 ? "limegreen" : "red" }}
        >
        </div>
        </Tooltip>
      
    </div>
  );
}
