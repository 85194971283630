import React, { useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

import Rss3List from "./news/Rss3List";
import RssDBList from "./news/RssDbList";
import MediumList from "./news/MediumList";
import NewsList from "./news/NewsList";

import { useThemeState } from "../../../components/utils/globalStates";
import "./ListingPage.css";

export default function NewsOuter({ listing }: { listing: any }) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [selectedValue, setSelectedValue] = useState("news");

  const radioButtonStyle = {
    color: globalTheme.backgroundMainOffBeige.color,
    padding: 0,
    "& svg": {
      width: "16px",
      height: "16px",
    },
    "&.Mui-checked": {
      color: globalTheme.backgroundMainOffBeige.color,
    },
    "@media (min-width: 350px)": {
      "& svg": {
        width: "18px",
        height: "18px",
      },
    },
    "@media (min-width: 550px)": {
      "& svg": {
        width: "20px",
        height: "20px",
      },
    },
    "@media (min-width: 701px)": {
      "& svg": {
        width: "22px",
        height: "22px",
      },
    },
  };

  const RadioLabelStyle = {
    margin: 0,
    "& .MuiFormControlLabel-label": {
      color: globalTheme.backgroundMainOffBeige.color,
      fontSize: "8px",
      fontWeight: 500,
      marginLeft: "2px",
      "@media (min-width: 350px)": { fontSize: "10px", marginLeft: "3px" },
      "@media (min-width: 550px)": { fontSize: "12px", marginLeft: "4px" },
      "@media (min-width: 701px)": { fontSize: "14px", marginLeft: "5px" },
    },
  };

  return (
    <div className="NewsOuter">
      <div className="radioButtons">
        <FormControlLabel
          value="news"
          control={
            <Radio
              checked={selectedValue === "news"}
              onChange={(e) => setSelectedValue(e.target.value)}
              inputProps={{ "aria-label": "news" }}
              sx={{
                ...radioButtonStyle,
              }}
            />
          }
          label="All News"
          sx={{
            ...RadioLabelStyle,
          }}
        />
        {listing.mediumHandle !== null &&
        listing.mediumHandle !== undefined &&
        listing.mediumHandle !== "" ? (
          <FormControlLabel
            value="medium"
            control={
              <Radio
                checked={selectedValue === "medium"}
                onChange={(e) => setSelectedValue(e.target.value)}
                value="medium"
                inputProps={{ "aria-label": "medium" }}
                sx={{
                  ...radioButtonStyle,
                }}
              />
            }
            label="Medium"
            sx={{
              ...RadioLabelStyle,
            }}
          />
        ) : null}
        <FormControlLabel
          value="rss3"
          control={
            <Radio
              checked={selectedValue === "rss3"}
              onChange={(e) => setSelectedValue(e.target.value)}
              inputProps={{ "aria-label": "rss3" }}
              sx={{
                ...radioButtonStyle,
              }}
            />
          }
          label="RSS3"
          sx={{
            ...RadioLabelStyle,
          }}
        />
        <FormControlLabel
          value="rssdb"
          control={
            <Radio
              checked={selectedValue === "rssdb"}
              onChange={(e) => setSelectedValue(e.target.value)}
              value="rssdb"
              inputProps={{ "aria-label": "rss" }}
              sx={{
                ...radioButtonStyle,
              }}
            />
          }
          label="Other"
          sx={{
            ...RadioLabelStyle,
          }}
        />
      </div>
      {selectedValue === "news" && (
        <NewsList
          activeListingName={listing.name}
          mediumActive={
            listing.mediumHandle !== null &&
            listing.mediumHandle !== undefined &&
            listing.mediumHandle !== ""
          }
          activeListingId={listing.id}
          mediumName={listing.mediumHandle}
        />
      )}
      {selectedValue === "rss3" && <Rss3List activeListing={listing.name} />}
      {selectedValue === "rssdb" && <RssDBList activeListing={listing.id} />}
      {selectedValue === "medium" &&
        listing.mediumHandle !== null &&
        listing.mediumHandle !== undefined &&
        listing.mediumHandle !== "" && (
          <MediumList activeListing={listing.mediumHandle} />
        )}
    </div>
  );
}
