import React, { useState, useEffect } from "react";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import { ButtonBase, Button, CircularProgress } from "@mui/material";

import Face3OutlinedIcon from "@mui/icons-material/Face3Outlined";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../../components/firebase/auth";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../../components/firebase/functions";

import { pfpEnums } from "../../../../components/utils/enums";
import { PfpViewer } from "../../../../components/ui/img/PfpViewer";
import {
  useAuthStates,
  useSnackbarErrorState,
  useSnackbarState,
  useThemeState,
} from "../../../../components/utils/globalStates";
import "./ProfileStartComp.css";

export default function PfpCreator() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const authStates = useAuthStates((state) => state);
  const snackbarErrorState = useSnackbarErrorState((state) => state);
  const snackbarState = useSnackbarState((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [initialPfpString, setInitialPfpString] = useState("");
  const [pfpString, setPfpString] = useState("");
  const [pfpType, setPfpType] = useState("boy");
  const [bgIndex, setBgIndex] = useState(pfpEnums.background.low);
  const [bodyIndex, setBodyIndex] = useState(pfpEnums.bodyBoy.low);
  const [faceIndex, setFaceIndex] = useState(pfpEnums.faceBoy.low);
  const [headIndex, setHeadIndex] = useState(pfpEnums.headBoy.low);
  const [isBoy, setIsBoy] = useState(true);

  const [pfpLoading, setPfpLoading] = useState(true);
  const [pfpUpdating, setPfpUpdating] = useState(false);

  const randomizeNumber = (low: number, high: number) => {
    return Math.floor(Math.random() * (high - low + 1) + low);
  };

  const setType = (typeBolean: boolean) => {
    setPfpLoading(true);
    if (typeBolean) {
      setPfpType("boy");
      setIsBoy(true);
      setBodyIndex(
        randomizeNumber(pfpEnums.bodyBoy.low, pfpEnums.bodyBoy.high)
      );
      setFaceIndex(
        randomizeNumber(pfpEnums.faceBoy.low, pfpEnums.faceBoy.high)
      );
      setHeadIndex(
        randomizeNumber(pfpEnums.headBoy.low, pfpEnums.headBoy.high)
      );
    } else {
      setPfpType("girl");
      setIsBoy(false);
      setBodyIndex(
        randomizeNumber(pfpEnums.bodyGirl.low, pfpEnums.bodyGirl.high)
      );
      setFaceIndex(
        randomizeNumber(pfpEnums.faceGirl.low, pfpEnums.faceGirl.high)
      );
      setHeadIndex(
        randomizeNumber(pfpEnums.headGirl.low, pfpEnums.headGirl.high)
      );
    }
  };

  const randomizeBg = () => {
    setBgIndex(
      randomizeNumber(pfpEnums.background.low, pfpEnums.background.high)
    );
  };

  const randomizeBody = () => {
    if (isBoy) {
      setBodyIndex(
        randomizeNumber(pfpEnums.bodyBoy.low, pfpEnums.bodyBoy.high)
      );
    } else {
      setBodyIndex(
        randomizeNumber(pfpEnums.bodyGirl.low, pfpEnums.bodyGirl.high)
      );
    }
  };

  const randomizeFace = () => {
    if (isBoy) {
      setFaceIndex(
        randomizeNumber(pfpEnums.faceBoy.low, pfpEnums.faceBoy.high)
      );
    } else {
      setFaceIndex(
        randomizeNumber(pfpEnums.faceGirl.low, pfpEnums.faceGirl.high)
      );
    }
  };

  const randomizeHead = () => {
    if (isBoy) {
      setHeadIndex(
        randomizeNumber(pfpEnums.headBoy.low, pfpEnums.headBoy.high)
      );
    } else {
      setHeadIndex(
        randomizeNumber(pfpEnums.headGirl.low, pfpEnums.headGirl.high)
      );
    }
  };

  // Save pfp string  to database
  const savePfpString = async () => {
    if (user === null || user === undefined) return;
    if (pfpString === null || pfpString === undefined || pfpString === "")
      return;
    if (pfpString === initialPfpString) return;
    setPfpUpdating(true);
    snackbarState.setMessage("Updating PFP...");
    snackbarState.setOpenLength(null);
    snackbarState.setIsActionLoading(true);
    snackbarState.setIsOpen(true);
    const savePfp = httpsCallable(firebaseFunctions, "pfp-update_user");
    try {
      const token = await user?.getIdToken();
      await savePfp({ pfpString: pfpString, token: token });
      setInitialPfpString(pfpString);
      setPfpUpdating(false);
      snackbarState.setMessage("PFP updated successfully");
      snackbarState.setIsActionLoading(false);
      snackbarState.setOpenLength(5000);
      snackbarState.setIsOpen(true);
    } catch (error: any) {
      setPfpUpdating(false);
      snackbarState.setIsOpen(false);
      snackbarState.setIsActionLoading(false);
      snackbarState.setMessage("");
      snackbarErrorState.setMessage(error.message);
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setIsOpen(true);
    }
  };

  // Init pfp string
  useEffect(() => {
    setPfpLoading(true);
    if (
      authStates.userData === null ||
      authStates.userData === undefined ||
      authStates.userData.pfpString === null ||
      authStates.userData.pfpString === undefined ||
      authStates.userData.pfpString === ""
    ) {
      // Randomize pfp
      setBgIndex(
        randomizeNumber(pfpEnums.background.low, pfpEnums.background.high)
      );
      setBodyIndex(
        randomizeNumber(pfpEnums.bodyBoy.low, pfpEnums.bodyBoy.high)
      );
      setFaceIndex(
        randomizeNumber(pfpEnums.faceBoy.low, pfpEnums.faceBoy.high)
      );
      setHeadIndex(
        randomizeNumber(pfpEnums.headBoy.low, pfpEnums.headBoy.high)
      );
      setInitialPfpString(
        `boy;${bgIndex};${bodyIndex};${faceIndex};${headIndex}`
      );
      setPfpLoading(false);
    } else {
      if (pfpString === authStates.userData.pfpString) return;
      const tempArray = authStates.userData.pfpString.split(";");
      setPfpType(tempArray[0]);
      setBgIndex(parseInt(tempArray[1]));
      setBodyIndex(parseInt(tempArray[2]));
      setFaceIndex(parseInt(tempArray[3]));
      setHeadIndex(parseInt(tempArray[4]));
      setInitialPfpString(authStates.userData.pfpString);
      setPfpLoading(false);
    }
  }, []);

  // Update pfp string
  useEffect(() => {
    setPfpString(
      `${pfpType};${bgIndex};${bodyIndex};${faceIndex};${headIndex}`
    );
    setPfpLoading(false);
  }, [pfpType, bgIndex, bodyIndex, faceIndex, headIndex, pfpLoading]);

  // Initial set of isBoy
  useEffect(() => {
    if (pfpType === 'boy') {
      setIsBoy(true);
    } else {
      setIsBoy(false);
    }
  }
  , [pfpType]);


  return (
    <div
      className="PfpCreator"
      style={{ border: `1px solid ${globalTheme.backgroundMainOffYellow.color}` }}
    >
      <div
        className="PfpCreatorImgOuter"
        style={{
          borderBottom: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
        }}
      >
        <div className="PfpCreatorImg">
          {pfpLoading ? (
            <CircularProgress
              style={{ color: globalTheme.backgroundMainOffYellow.color }}
            />
          ) : (
            <PfpViewer pfpString={pfpString} randomize={false} />
          )}
        </div>
      </div>
      <div
        className="PfpCreatorButtons"
        style={{
          borderBottom: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
        }}
      >
        <ButtonBase
          onClick={() => setType(true)}
          sx={{
            width: "50%",
            backgroundColor: isBoy
              ? globalTheme.backgroundMainOffYellow.color
              : "transparent",
            paddingTop: "4px",
            paddingBottom: "5px",
            borderRight: `0.5px solid ${globalTheme.backgroundMainOffYellow.color}`,
          }}
        >
          <div
            className="ListingsPageTabsInner"
            style={{
              color: pfpType === 'boy' ? globalTheme.backgroundMainYellow.color:globalTheme.backgroundMainOffYellow.color,
            }}
          >
            <FaceOutlinedIcon
              color="inherit"
              sx={{
                fontSize: "17px",
                marginRight: "5px",
                "@media (min-width: 350px)": {
                  fontSize: "18px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "19px",
                },
                "@media (min-width: 700px)": {
                  fontSize: "20px",
                },
              }}
            />
            <p className="ListingsPageTabsText">Boy</p>
          </div>
        </ButtonBase>
        <ButtonBase
          onClick={() => setType(false)}
          sx={{
            textDecoration: "none",
            width: "50%",
            paddingTop: "5px",
            paddingBottom: "5px",
            borderLeft: `0.5px solid ${globalTheme.backgroundMainOffYellow.color}`,
            backgroundColor: !isBoy
              ? globalTheme.backgroundMainOffYellow.color
              : "transparent",
          }}
        >
          <div
            className="ListingsPageTabsInner"
            style={{
              color: pfpType === 'girl' ? globalTheme.backgroundMainYellow.color:globalTheme.backgroundMainOffYellow.color,
              backgroundColor: "transparent",
            }}
          >
            <Face3OutlinedIcon
              color="inherit"
              sx={{
                fontSize: "16px",
                marginRight: "5px",
                "@media (min-width: 350px)": {
                  fontSize: "17px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "18px",
                },
                "@media (min-width: 700px)": {
                  fontSize: "19px",
                },
              }}
            />
            <p
              className="ListingsPageTabsText"
              style={{ textDecoration: "none" }}
            >
              Girl
            </p>
          </div>
        </ButtonBase>
      </div>
      <div
        className="PfpCreatorButtons"
        style={{
          borderBottom: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
        }}
      >
        <ButtonBase
          onClick={randomizeBg}
          sx={{
            color: globalTheme.backgroundMainOffYellow.color,
            width: "50%",
            fontSize: "10px",
            paddingTop: "12px",
            paddingBottom: "12px",
            borderRight: `0.5px solid ${globalTheme.backgroundMainOffYellow.color}`,
            "@media (min-width: 350px)": {
              fontSize: "12px",
            },
            "@media (min-width: 550px)": {
              width: "25%",
            },
            "@media (min-width: 700px)": {
              fontSize: "14px",
            },
          }}
        >
          Random Background
        </ButtonBase>
        <ButtonBase
          onClick={randomizeBody}
          sx={{
            color: globalTheme.backgroundMainOffYellow.color,
            width: "50%",
            fontSize: "10px",
            paddingTop: "12px",
            paddingBottom: "12px",
            borderLeft: `0.5px solid ${globalTheme.backgroundMainOffYellow.color}`,
            "@media (min-width: 350px)": {
              fontSize: "12px",
            },
            "@media (min-width: 550px)": {
              width: "25%",
              borderRight: `0.5px solid ${globalTheme.backgroundMainOffYellow.color}`,
            },
            "@media (min-width: 700px)": {
              fontSize: "14px",
            },
          }}
        >
          Random Body
        </ButtonBase>
        <ButtonBase
          onClick={randomizeFace}
          sx={{
            color: globalTheme.backgroundMainOffYellow.color,
            width: "50%",
            fontSize: "10px",
            paddingTop: "12px",
            paddingBottom: "12px",
            borderRight: `0.5px solid ${globalTheme.backgroundMainOffYellow.color}`,
            borderTop: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
            "@media (min-width: 350px)": {
              fontSize: "12px",
            },
            "@media (min-width: 550px)": {
              width: "25%",
              borderLeft: `0.5px solid ${globalTheme.backgroundMainOffYellow.color}`,
              borderTop: `none`,
            },
            "@media (min-width: 700px)": {
              fontSize: "14px",
            },
          }}
        >
          Random Face
        </ButtonBase>
        <ButtonBase
          onClick={randomizeHead}
          sx={{
            color: globalTheme.backgroundMainOffYellow.color,
            width: "50%",
            fontSize: "10px",
            paddingTop: "12px",
            paddingBottom: "12px",
            borderLeft: `0.5px solid ${globalTheme.backgroundMainOffYellow.color}`,
            borderTop: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
            "@media (min-width: 350px)": {
              fontSize: "12px",
            },
            "@media (min-width: 550px)": {
              width: "25%",
              borderTop: `none`,
            },
            "@media (min-width: 700px)": {
              fontSize: "14px",
            },
          }}
        >
          Random Head
        </ButtonBase>
      </div>
      <ButtonBase
        disabled={pfpUpdating || pfpString === initialPfpString}
        onClick={savePfpString}
        sx={{
          width: "100%",
          fontSize: "15px",
          paddingTop: "15px",
          paddingBottom: "15px",
          backgroundColor: globalTheme.backgroundMainOffYellow.color,
          borderRadius: "0px 0px 7px 7px",
          color: globalTheme.solidMainYellow.color,
        }}
      >
        Save
      </ButtonBase>
    </div>
  );
}
