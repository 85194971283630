import React, { useEffect, useState } from "react";
import { firebaseRlDb } from "../../components/firebase/database";
import { getDatabase, ref, child, get, set, onValue } from "firebase/database";
import { CircularProgress } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import AdminNewsLinkItem from "./items/AdminNewsLinkItem";
import AdminAddNewsLink from "./components/AdminAddNewsLink";
import { useThemeState } from "../../components/utils/globalStates";
import "./Admin.css";

export default function AdminNews() {
  const [sort, setSort] = useState("date");
  const [sortDirection, setSortDirection] = useState("desc");
  const [rssLinks, setRssLinks] = useState<any[]>([]);
  const [rssLinksBefore, setRssLinksBefore] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const globalTheme = useThemeState((state) => state.globalTheme);

  const handleChange = (event: SelectChangeEvent) => {
    setSort(event.target.value as string);
  };
  const handleSortDirection = () => {
    if (sortDirection === "desc") {
      setSortDirection("asc");
    } else {
      setSortDirection("desc");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setRssLinks([]);
    if (rssLinksBefore.length > 0) {
      let tempRssLinks = [...rssLinksBefore];
      if (sort === "date") {
        tempRssLinks.sort(function (a: any, b: any) {
          if (sortDirection === "desc") {
            return b.key - a.key;
          } else {
            return a.key - b.key;
          }
        });
      } else if (sort === "link") {
        tempRssLinks.sort(function (a: any, b: any) {
          if (sortDirection === "desc") {
            return b.link.localeCompare(a.link);
          } else {
            return a.link.localeCompare(b.link);
          }
        });
      }
      setRssLinks(tempRssLinks);
      setIsLoading(false);
    }
  }, [rssLinksBefore, sort, sortDirection]);

  useEffect(() => {
    const dbRef = ref(firebaseRlDb);
    const unsubscribe = onValue(
      child(dbRef, `private/rssLinks`),
      (snapshot) => {
        if (snapshot.exists()) {
          let tempRssLinks: any[] = [];
          snapshot.forEach((childSnapshot) => {
            tempRssLinks.push({
              link: childSnapshot.val(),
              key: childSnapshot.key,
            });
          });
          setRssLinksBefore(tempRssLinks);
        } else {
          console.error("No data available");
          setIsLoading(false);
        }
      }
    );
    return () => unsubscribe();
  }, []);

   const styles = {
    input: {
      backgroundColor: "transparent",
      color: globalTheme.backgroundMainOffBeige.color,
      borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      "& .MuiInputLabel-root": {
        color: globalTheme.backgroundMainOffBeige.color + " !important",
        "& .Mui-focused ": {
          color: globalTheme.backgroundMainOffBeige.color,
        },
      },
    },
    select: {
      backgroundColor: "transparent",
      color: globalTheme.backgroundMainOffBeige.color,
      borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      "& .MuiSelect-root": {
        "& fieldset": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
        "&:after": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
      },
      "&.Mui-focused fieldset": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      },
      "&.Mui-focused ": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      },
    },

    selectMenu: {
      color: globalTheme.backgroundMainOffBeige.color,
      "& .MuiPaper-root": {
        backgroundColor: globalTheme.backgroundMainBeige.color,
        color: globalTheme.backgroundMainOffBeige.color,
        "& .MuiMenuItem-root": {
          opacity: 0.6,
          "& .Mui-selected": {
            color: globalTheme.backgroundMainOffBeige.color,
            backgroundColor: globalTheme.backgroundMainBeige.color,
            opacity: 1,
          },
        },
        "& .Mui-selected": {
          color: globalTheme.backgroundMainOffBeige.color,
          backgroundColor: globalTheme.backgroundMainBeige.color,
          opacity: 1,
        },
      },
    },
  };

  return (
    <div className="AdminInner">
      <p
        className="AdminDescription"
        style={{ ...globalTheme.backgroundMainOffBeige }}
      >
        RSS links which news are fetched from every hour. Add more or delete.
      </p>
      <AdminAddNewsLink />
      <div
        className="AdminListingSortOuter"
        style={{ ...globalTheme.backgroundMainOffBeige }}
      >
        <div className="AdminListingSortInner">
          <FormControl fullWidth sx={{ ...styles.input }}>
            <InputLabel>Sort</InputLabel>
            <Select
              size="small"
              value={sort}
              label="Sort"
              disabled={isLoading}
              onChange={handleChange}
              sx={{ ...styles.select }}
              MenuProps={{
                sx: styles.selectMenu,
              }}
            >
              <MenuItem value={"link"}>Link</MenuItem>
              <MenuItem value={"date"}>Latest</MenuItem>
            </Select>
          </FormControl>
          <IconButton
            color="inherit"
            size="small"
            sx={{ marginLeft: "2px" }}
            onClick={handleSortDirection}
          >
            {sortDirection === "desc" ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowUpIcon />
            )}
          </IconButton>
        </div>
      </div>
      {isLoading ? (
        <div
          className="AdminListingsLoading"
          style={{ ...globalTheme.backgroundMainOffBeige }}
        >
          <CircularProgress size={30} color="inherit" />
        </div>
      ) : rssLinks.length > 0 ? (
        <div className="AdminListingsOuter">
          {rssLinks.map((newsLink: any, index: number) => {
            return (
              <div className="AdminNewsLinkOuter" key={index}>
                <AdminNewsLinkItem rssLink={newsLink} />
              </div>
            );
          })}
        </div>
      ) : (
        <div className="AdminListingsLoading">
          <p
            className="AdminDescription"
            style={{ ...globalTheme.backgroundMainOffBeige }}
          >
            No RSS links found.
          </p>
        </div>
      )}
    </div>
  );
}
