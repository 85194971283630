import React, {useState, useEffect} from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import UserList from './list/UserList';
import UserPage from './page/UserPage';
import UserPageV2 from './page/UserPageV2';
import UserListings from './listings/UserListings';
import UserFollowStart from './follow/UserFollowStart';
import './UserStart.css';

export default function UserStart() {

  return (
    <div className="UserStart" >
      <Routes>
        <Route path="/" element={<UserList />} />
        <Route path="/page/:userId" element={<UserPageV2 />} />
        <Route path="/listings/:userId" element={<UserListings />} />
        <Route path="/follow/*" element={<UserFollowStart />} />
        <Route path="*" element={<UserList />} />
      </Routes>
    </div>
  );
}
