import React, { useState, useEffect } from "react";
import { IconButton, ButtonBase } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../../components/firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../../components/firebase/auth";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

import {
  useThemeState,
  useAuthStates,
  useSnackbarErrorState,
  useSnackbarState,
} from "../../../../components/utils/globalStates";
import {
  standardDialogRoot,
  standardDialogPaper,
  standardDialogTitle,
  standardDialogText,
} from "../../../../components/theme/globalMuiTheme";
import "./ProfileSettingsComp.css";

export default function TutorialsSettings() {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const snackbarState = useSnackbarState();
  const snackbarErrorState = useSnackbarErrorState();


  // Delete all tutorials from local storage
  const restoreTutorials = async () => {
    localStorage.removeItem("achievementsInfo");
    localStorage.removeItem("achievementsProfileInfo");
    localStorage.removeItem("bookmarksInfo");
    localStorage.removeItem("dashboardInfo");
    localStorage.removeItem("dashboardInfoNew");
    localStorage.removeItem("pfpCreatorInfo");
    localStorage.removeItem("reviewSearchInfo");
    localStorage.removeItem("exploreInfo");
    localStorage.removeItem("settingsInfo");
    localStorage.removeItem("tokensInfo");
    localStorage.removeItem("userSearchInfo");
    localStorage.removeItem("walletsLatestInfo");
    localStorage.removeItem("walletsListInfo");
    localStorage.removeItem("issueReportInfo");
    snackbarState.setMessage("Tutorials restored.");
    snackbarState.setOpenLength(3000);
    snackbarState.setIsOpen(true);
    
  }

  const styles = {
    button: {
      color: globalTheme.figmaPrimaryText.color,
      backgroundColor: globalTheme.figmaAlt2.color,
      borderRadius: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  };

  return (
    <div className="ReferralsCount" style={{marginBottom: '40px'}}>
      <p className="ReferralsCountText">
        Click to restore tutorials on all pages.
      </p>
      <ButtonBase style={styles.button} onClick={restoreTutorials} >
        <p
          className="SocialConfigButtonText"
          style={{
            color: globalTheme.figmaPrimaryText.color,
          }}
        >
          Restore Tutorials
        </p>
      </ButtonBase>
    </div>
  );
}
