import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import {
  Tooltip,
  IconButton,
  Skeleton,
  Rating,
  ButtonBase,
} from "@mui/material";
import copy from "copy-to-clipboard";
import { useThemeState } from "../../utils/globalStates";
import {
  useSnackbarErrorState,
  useSnackbarState,
  useExploreSortState,
  useAdminState,
} from "../../utils/globalStates";
import moment from "moment";
import { doc, getDoc } from "firebase/firestore";
import UserListItemNumber from "./components/UserListItemNumbers";
import ProjectRuggedBadge from "../img/badges/ProjectRuggedBadge";
import ProjectVerifiedBadge from "../img/badges/ProjectVerifiedBadge";
import UserVerifiedBadge from "../img/badges/UserVerifiedBadge";
import { LazyLoadImg } from "../misc/LazyLoadImg";
import { firestoreDb } from "../../firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../firebase/auth";
import { PfpViewer } from "../img/PfpViewer";
import ProjectItemRating from "../../../views/listings/components/ProjectItemRating";
import "./ListItems.css";
import { figmaColorVariables } from "../../theme/globalTheme";

moment().format();

export default function RatingListItemPreview({ listItem, isListing }: any) {
  const [user] = useAuthState(firebaseAuth);
  const navigate = useNavigate();
  const exploreSortState = useExploreSortState();
  const snackbarStates = useSnackbarState((state) => state);
  const snackbarError = useSnackbarErrorState((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);
  const adminState = useAdminState((state) => state);

  const [authorData, setAuthorData] = useState<any | null>(null);
  const [authorLoading, setAuthorLoading] = useState(true);
  const [listingData, setListingData] = useState<any | null>(null);
  const [listingLoading, setListingLoading] = useState(true);

  const onShareClick = () => {
    copy(`https://app.gath3r.co/reviews/listing/${listItem.listing}`);
    snackbarStates.setMessage("Link copied to clipboard");
    snackbarStates.setOpenLength(3000);
    snackbarStates.setIsOpen(true);
  };

  // Get author
  useEffect(() => {
    if (
      listItem.uid === null ||
      listItem.uid === undefined ||
      listItem.uid === ""
    ) {
      return;
    }
    const getData = async () => {
      setAuthorLoading(true);
      try {
        const userDoc = await getDoc(
          doc(firestoreDb, "publicUserInfo", listItem.uid)
        );
        if (userDoc.exists()) {
          setAuthorData(userDoc.data());
          setAuthorLoading(false);
        } else {
          console.log("no user data");
          console.log(listItem.uid);
          setAuthorData(null);
          setAuthorLoading(false);
        }
      } catch (error) {
        console.error("Error getting author name:", error);
        setAuthorData(null);
        setAuthorLoading(false);
      }
    };
    getData();
  }, [listItem.uid, isListing]);

  // Get listing
  useEffect(() => {
    if (
      listItem.listing === null ||
      listItem.listing === undefined ||
      listItem.listing === ""
    ) {
      return;
    }
    const getData = async () => {
      try {
        const listingDoc = await getDoc(
          doc(firestoreDb, "listings", listItem.listing)
        );
        if (listingDoc.exists()) {
          if (
            listingDoc.data().name !== undefined &&
            listingDoc.data().name !== null
          ) {
            setListingData(listingDoc.data());
            setListingLoading(false);
          } else {
            setListingData(null);
            setListingLoading(false);
          }
        } else {
          setListingData(null);
          setListingLoading(false);
        }
      } catch (error) {
        console.error("Error getting listing name:", error);
        setListingData(null);
        setListingLoading(false);
      }
    };
    getData();
  }, [listItem.listing, isListing]);

  const styles = {
    avatar: {
      bgcolor: globalTheme.iconNeutral.color,
      color: globalTheme.iconNeutral.color,
      width: "20px",
      height: "20px",
      minWidth: "20px",
      minHeight: "20px",
      fontSize: "7px",
      borderRadius: "50%",
      textDecoration: "none",
      overFlow: "hidden",
      "@media (min-width: 400px)": {
        width: "28px",
        height: "28px",
        minWidth: "28px",
        minHeight: "28px",
      },
      "@media (min-width: 600px)": {
        width: "20px",
        height: "20px",
        minWidth: "20px",
        minHeight: "20px",
      },
      "@media (min-width: 800px)": {
        width: "28px",
        height: "28px",
        minWidth: "28px",
        minHeight: "28px",
      },
    },
    avatarMedium: {
      bgcolor: globalTheme.iconNeutral.color,
      color: globalTheme.iconNeutral.color,
      width: "32px",
      height: "32px",
      minWidth: "32px",
      minHeight: "32px",
      fontSize: "7px",
      borderRadius: "50%",
      textDecoration: "none",
      overFlow: "hidden",
      "@media (min-width: 400px)": {
        width: "40px",
        height: "40px",
        minWidth: "40px",
        minHeight: "40px",
      },
      "@media (min-width: 600px)": {
        width: "32px",
        height: "32px",
        minWidth: "32px",
        minHeight: "32px",
      },
      "@media (min-width: 800px)": {
        width: "40px",
        height: "40px",
        minWidth: "40px",
        minHeight: "40px",
      },
    },
    avatarLarge: {
      bgcolor: globalTheme.iconNeutral.color,
      color: globalTheme.iconNeutral.color,
      width: "40px",
      height: "40px",
      minWidth: "40px",
      minHeight: "40px",
      fontSize: "7px",
      borderRadius: "50%",
      textDecoration: "none",
      overFlow: "hidden",
      "@media (min-width: 400px)": {
        width: "50px",
        height: "50px",
        minWidth: "50px",
        minHeight: "50px",
      },
      "@media (min-width: 600px)": {
        width: "40px",
        height: "40px",
        minWidth: "40px",
        minHeight: "40px",
      },
      "@media (min-width: 800px)": {
        width: "50px",
        height: "50px",
        minWidth: "50px",
        minHeight: "50px",
      },
    },
    bookmarkIcon: {
      fontSize: "16px",
      padding: 0,
      color: globalTheme.iconNeutral.color,
      "@media (min-width: 400px)": {
        fontSize: "20px",
      },
      "@media (min-width: 600px)": {
        fontSize: "16px",
      },
      "@media (min-width: 800px)": {
        fontSize: "20px",
      },
    },
    shareIcon: {
      fontSize: "14px",
      color: globalTheme.iconNeutral.color,
      "@media (min-width: 400px)": {
        fontSize: "18px",
      },
      "@media (min-width: 600px)": {
        fontSize: "14px",
      },
      "@media (min-width: 800px)": {
        fontSize: "18px",
      },
    },

    shareIconSmall: {
      fontSize: "12px",
      color: globalTheme.iconNeutral.color,
      "@media (min-width: 400px)": {
        fontSize: "15px",
      },
      "@media (min-width: 600px)": {
        fontSize: "12px",
      },
      "@media (min-width: 800px)": {
        fontSize: "15px",
      },
    },
    starIcon: {
      fontSize: "16px",
      marginTop: "-2px",
      color: figmaColorVariables.yellow500,
      "@media (min-width: 400px)": {
        fontSize: "19px",
      },
      "@media (min-width: 600px)": {
        fontSize: "16px",
      },
      "@media (min-width: 800px)": {
        fontSize: "19px",
      },
    },

    expandLessIcon: {
      fontSize: "22px",
      color: globalTheme.iconNeutral.color,
      transform: "rotate(45deg)",
    },
    reviewTitle: {
      fontSize: "14px",
      width: "40px",
      marginLeft: "5px",
      backgroundColor: globalTheme.iconNeutral.color,
      "@media (min-width: 400px)": {
        fontSize: "17px",
        width: "60px",
      },
      "@media (min-width: 600px)": {
        fontSize: "14px",
        width: "40px",
      },
      "@media (min-width: 800px)": {
        fontSize: "17px",
        width: "60px",
      },
    },
    reviewSub: {
      fontSize: "8px",
      width: "30px",
      backgroundColor: globalTheme.iconNeutral.color,
      "@media (min-width: 400px)": {
        fontSize: "11px",
        width: "40px",
      },
      "@media (min-width: 600px)": {
        fontSize: "8px",
        width: "30px",
      },
      "@media (min-width: 800px)": {
        fontSize: "11px",
        width: "40px",
      },
    },
  };

  return (
    <div
      className="ProjectListItemLargePreview"
      style={{
        backgroundColor: globalTheme.primarySurface.color,
        ...globalTheme.primaryBoxshadow,
      }}
    >
      {isListing ? (
        <div className="ProjectListItemHeader">
          <Link
            to={`/projects/${listItem.listing}`}
            className="ProjectListItemHeaderClickable"
            style={{
              padding: "15px 0px",
              maxWidth: "100%",
              textDecoration: "none",
            }}
          >
            {listingLoading ? (
              <Skeleton variant="rectangular" sx={styles.avatarMedium} />
            ) : listingData.logo !== undefined &&
              listingData.logo !== null &&
              listingData.logo !== "" ? (
              <div className="ProjectListItemAvatarMedium">
                <LazyLoadImg imgUrl={listingData.logo} />
              </div>
            ) : (
              <Avatar sx={styles.avatarMedium} alt={listingData.name}>
                {listingData.name.substr(0, 2)}
              </Avatar>
            )}
            <div className="ProjectListItemHeaderTextOuterDivMedium">
              <div className="ProjectListItemHeaderTextOuterDiv">
                {listingLoading ? (
                  <div className="ProjectListItemHeaderTitleOuter">
                    <Skeleton variant="text" sx={styles.reviewTitle} />
                  </div>
                ) : (
                  <div className="ProjectListItemHeaderTitleOuter">
                    <div className="ProjectListItemHeaderTitleOuter">
                      <p
                        className="ProjectListItemHeaderTitle"
                        style={{
                          color: globalTheme.primaryText.color,
                        }}
                      >
                        {listingData.name}
                      </p>
                      {listingData.manualVerified === true &&
                        listingData.rugged !== true && (
                          <Tooltip title="Verified by GATH3R">
                            <div className="ProjectListItemHeaderBadge">
                              <ProjectVerifiedBadge />
                            </div>
                          </Tooltip>
                        )}
                      {listingData.rugged === true && (
                        <Tooltip title="Rugged">
                          <div className="ProjectListItemHeaderBadgeRugged">
                            <ProjectRuggedBadge />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div
                className="ProjectListItemHeaderRatingOuter"
                style={{ marginLeft: "8px" }}
              >
                {listingLoading ? (
                  <Skeleton
                    variant="text"
                    sx={{ ...styles.reviewSub, marginLeft: "3px" }}
                  />
                ) : (
                  <ProjectItemRating listItem={listingData} noViews={true} />
                )}
              </div>
            </div>
          </Link>
        </div>
      ) : (
        <div className="ProjectListItemHeader">
          <Link
            to={`/users/page/${listItem.uid}`}
            className="ProjectListItemHeaderClickable"
            style={{ padding: "15px 0px", textDecoration: "none" }}
          >
            {authorLoading ? (
              <Skeleton variant="rectangular" sx={styles.avatarMedium} />
            ) : authorData !== null &&
              authorData.pfpString !== null &&
              authorData.pfpString !== undefined &&
              authorData.pfpString !== "" ? (
              <div className="ProjectListItemAvatarMedium">
                <PfpViewer pfpString={authorData.pfpString} randomize={false} />
              </div>
            ) : (
              <div className="ProjectListItemAvatarMedium">
                <PfpViewer pfpString={""} randomize={true} />
              </div>
            )}
            <div className="ProjectListItemHeaderTextOuterDivMedium">
              <div className="ProjectListItemHeaderTextOuterDiv">
                {authorLoading ? (
                  <div className="ProjectListItemHeaderTitleOuter">
                    <Skeleton variant="text" sx={styles.reviewTitle} />
                  </div>
                ) : (
                  <div className="ProjectListItemHeaderTitleOuter">
                    <div className="ProjectListItemHeaderTitleOuter">
                      <p
                        className="ProjectListItemHeaderTitle"
                        style={{
                          color: globalTheme.primaryText.color,
                        }}
                      >
                        {authorData === null ||
                        authorData.displayName === null ||
                        authorData.displayName === undefined ||
                        authorData.displayName === ""
                          ? "Anonymous user"
                          : authorData.displayName}
                      </p>
                      {authorData !== null &&
                        authorData.verified !== null &&
                        authorData.verified !== undefined &&
                        authorData.verified === true &&
                        authorData.disabled !== true && (
                          <Tooltip title="Verified User">
                            <div className="ProjectListItemHeaderBadge">
                              <UserVerifiedBadge />
                            </div>
                          </Tooltip>
                        )}
                    </div>
                  </div>
                )}
              </div>
              <div
                className="ProjectListItemHeaderRatingOuter"
                style={{ marginLeft: "10px" }}
              >
                {authorLoading ? (
                  <Skeleton
                    variant="text"
                    sx={{ ...styles.reviewSub, marginLeft: "3px" }}
                  />
                ) : (
                  authorData !== null && (
                    <UserListItemNumber listItem={authorData} />
                  )
                )}
              </div>
            </div>
          </Link>
        </div>
      )}
      {isListing ? (
        <div className="RatingsListItemFooterText">
          {authorLoading ? (
            <Skeleton
              variant="text"
              sx={{
                ...styles.reviewSub,
                margin: 0,
                marginRight: "3px",
                width: "60px",
              }}
            />
          ) : authorData === null ||
            authorData.displayName === null ||
            authorData.displayName === undefined ||
            authorData.displayName === "" ? (
            <p
              className="RatingsListItemFooterLinkText"
              style={{
                color: globalTheme.primaryText.color,
                marginRight: "5px",
              }}
            >
              Anonymous user
            </p>
          ) : (
            <Link
              to={`/users/page/${listItem.uid}`}
              className="RatingsListItemFooterLinkText"
              style={{
                color: globalTheme.links.color,
                margin: 0,
                marginRight: "4px",
              }}
            >
              {authorData.displayName}
            </Link>
          )}
          <p
            className="RatingsListItemFooterLinkText"
            style={{
              color: globalTheme.primaryText.color,
              marginRight: "4px",
            }}
          >
            rated {listItem.rating}
          </p>
          <Rating
            value={listItem.rating}
            sx={{ marginBottom: "-1px" }}
            readOnly
            size="small"
          />
        </div>
      ) : (
        <div className="RatingsListItemFooterText">
            <p
            className="RatingsListItemFooterLinkText"
            style={{
              color: globalTheme.primaryText.color,
              marginRight: "4px",
            }}
          >
            Rated
          </p>
          {listingLoading ? (
            <Skeleton
              variant="text"
              sx={{
                ...styles.reviewSub,
                margin: 0,
                marginRight: "3px",
                width: "60px",
              }}
            />
          ) : (
            <Link
              to={`/projects/${listingData.id}`}
              className="RatingsListItemFooterLinkText"
              style={{
                color: globalTheme.links.color,
                margin: 0,
                marginRight: "4px",
              }}
            >
              {listingData.name}
            </Link>
          )}
          <p
            className="RatingsListItemFooterLinkText"
            style={{
              color: globalTheme.primaryText.color,
              marginRight: "4px",
            }}
          >
            {listItem.rating}
          </p>
          <Rating
            value={listItem.rating}
            sx={{ marginBottom: "-1px" }}
            readOnly
            size="small"
          />
        </div>
      )}
      <div className="RatingsListItemFooter">
        <p
          className="ProjectListItemHeaderCategory"
          style={{
            color: globalTheme.iconNeutral.color,
            marginLeft: "0.5px",
            marginRight: "2.5px",
          }}
        >
          {moment(listItem.createdAt).fromNow()}
        </p>
        <Tooltip title="Share">
          <IconButton
            sx={{
              margin: 0,
              padding: 0,
            }}
            size="small"
            onClick={onShareClick}
          >
            <ShareOutlinedIcon sx={styles.shareIconSmall} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}
