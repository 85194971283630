import React, { useState, useEffect } from "react";

import { doc, onSnapshot, updateDoc, setDoc, getDoc } from "firebase/firestore";

import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../../components/firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../../components/firebase/auth";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { isValidUsername } from "../../../../components/utils/parsers";

import { ButtonBase, CircularProgress } from "@mui/material";
import {
  useSnackbarErrorState,
  useSnackbarState,
  useAuthStates,
  useThemeState,
} from "../../../../components/utils/globalStates";
import "./ProfileSettingsComp.css";

moment().format();

export default function UserNameSettings() {
  const [gath3rHandle, setGath3rHandle] = useState<string>("");
  const [gath3rHandleError, setGath3rHandleError] = useState<boolean>(false);
  const [gath3rHandleHelperText, setGath3rHandleHelperText] = useState<string>(
    "Your Public Display Name can only be changed once every 10 days."
  );
  const [gath3rHandleUpdating, setGHandleUpdating] = useState<boolean>(false);
  const [initialGath3rHandle, setInitialGath3rHandle] = useState<string>("");

  const [user, loading, error] = useAuthState(firebaseAuth);
  const snackbarStates = useSnackbarState((state) => state);
  const snackbarErrorStates = useSnackbarErrorState((state) => state);
  const authStates = useAuthStates((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);

  // Listener for valid gath3rHandle
  useEffect(() => {
    let tempText =
      "Your Public Display Name can only be changed once every 10 days.";

    if (
      authStates.userData !== undefined &&
      authStates.userData !== null &&
      authStates.userData.displayNameUpdated !== undefined &&
      authStates.userData.displayNameUpdated !== null &&
      authStates.userData.displayNameUpdated > Date.now() - 86400000 * 10
    ) {
      tempText = `You can change your Public Display Name again ${moment(
        authStates.userData.displayNameUpdated + 86400000 * 10
      ).fromNow()}`;
    }
    if (gath3rHandle === "") {
      setGath3rHandleError(false);
      setGath3rHandleHelperText(tempText);
      return;
    }
    if (gath3rHandle.length < 3) {
      setGath3rHandleError(true);
      setGath3rHandleHelperText(
        "Display Name must be at least 3 characters long"
      );
      return;
    }
    if (isValidUsername(gath3rHandle.toLowerCase())) {
      setGath3rHandleError(false);
      setGath3rHandleHelperText(tempText);
    } else {
      setGath3rHandleError(true);
      setGath3rHandleHelperText("Invalid Display Name");
    }
  }, [gath3rHandle, authStates.userData]);

  useEffect(() => {
    if (
      authStates.userData !== undefined &&
      authStates.userData !== null &&
      authStates.userData.displayName !== undefined &&
      authStates.userData.displayName !== null &&
      authStates.userData.displayName !== "" &&
      authStates.userData.displayName !== initialGath3rHandle
    ) {
      setGath3rHandle(authStates.userData.displayName);
      setInitialGath3rHandle(authStates.userData.displayName);
    }
  }, [authStates.userData]);

  const onGath3rHandleUpdate = async () => {
    if (user === null || user === undefined) {
      return;
    }
    if (gath3rHandle === initialGath3rHandle) {
      return;
    }
    if (gath3rHandle === "") {
      return;
    }

    if (
      authStates.userData !== undefined &&
      authStates.userData !== null &&
      authStates.userData.displayNameUpdated !== undefined &&
      authStates.userData.displayNameUpdated !== null &&
      authStates.userData.displayNameUpdated > Date.now() - 86400000 * 10
    ) {
      return;
    }

    setGHandleUpdating(true);

    const updateName = httpsCallable(
      firebaseFunctions,
      "profile-display_name_update"
    );
    const tokenCheck = await user.getIdToken();

    try {
      await updateName({
        name: gath3rHandle,
        token: tokenCheck,
      });

      setGHandleUpdating(false);
      snackbarStates.setMessage("Display name updated");
      snackbarStates.setOpenLength(4000);
      snackbarStates.setIsOpen(true);
    } catch (error) {
      console.log(error);
      setGHandleUpdating(false);
      snackbarErrorStates.setMessage("Error updating display name");
      snackbarErrorStates.setOpenLength(4000);
      snackbarErrorStates.setIsOpen(true);
    }
  };

  const styles = {
    textField: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        color: globalTheme.solidMainBlack.color,
        backgroundColor: globalTheme.solidMainWhite.color,
        boxShadow: "none",
        borderRadius: "5px",
        padding: "0px",
        fontSize: "14px",
        paddingLeft: "8px",
        paddingRight: "8px",
        border: "none",
        "& fieldset": {
          border: "none",
          color: globalTheme.solidMainBlack.color,
        },
        "&:hover fieldset": {
          border: "none",
          color: globalTheme.solidMainBlack.color,
        },
        "&.Mui-focused fieldset": {
          border: "none",
          color: globalTheme.solidMainBlack.color,
        },
      },
      "& label.Mui-focused": {
        border: "none",
        color: globalTheme.solidMainBlack.color,
      },
      "& label": {
        border: "none",
        color: globalTheme.solidMainBlack.color,
      },
    },
  };

  return (
    <div className="SocialConfig">
      <p
        className="UsernameTitle"
        style={{ color: globalTheme.textMainBlack.color }}
      >
        Username • Public
      </p>
      <div className="SocialConfigInner">
        <div className="SocialConfigTextField">
          <TextField
            sx={styles.textField}
            size="small"
            placeholder="......"
            value={gath3rHandle}
            onChange={(event) => {
              setGath3rHandle(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  {gath3rHandle !== initialGath3rHandle ? (
                    <CloseOutlinedIcon
                      onClick={() => {
                        setGath3rHandle(initialGath3rHandle);
                      }}
                      color="inherit"
                      sx={{
                        fontSize: "20px",
                        marginLeft: "5px",
                        opacity: 0.4,
                        cursor: "pointer",
                      }}
                    />
                  ) : null}
                </React.Fragment>
              ),
            }}
          />
        </div>
        <ButtonBase
          onClick={onGath3rHandleUpdate}
          disabled={
            gath3rHandle === "" ||
            gath3rHandle === initialGath3rHandle ||
            gath3rHandleError ||
            gath3rHandleUpdating ||
            (authStates.userData !== undefined &&
              authStates.userData !== null &&
              authStates.userData.displayNameUpdated !== undefined &&
              authStates.userData.displayNameUpdated !== null &&
              authStates.userData.displayNameUpdated >
                Date.now() - 86400000 * 10)
          }
          style={{
            color: globalTheme.figmaPrimaryText.color,
            backgroundColor: globalTheme.figmaGOrange.color,
            width: "60px",
            marginLeft: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {gath3rHandleUpdating ? (
            <CircularProgress
              size={13}
              color="inherit"
              sx={{ color: globalTheme.figmaPrimaryText.color }}
            />
          ) : (
            <p
              className="SocialConfigButtonText"
              style={{
                color:
                  gath3rHandle === "" ||
                  gath3rHandleError ||
                  gath3rHandleUpdating ||
                  (authStates.userData !== undefined &&
                    authStates.userData !== null &&
                    authStates.userData.displayNameUpdated !== undefined &&
                    authStates.userData.displayNameUpdated !== null &&
                    authStates.userData.displayNameUpdated >
                      Date.now() - 86400000 * 10)
                    ? globalTheme.figmaPrimaryTextSub.color
                    : globalTheme.figmaPrimaryText.color,
              }}
            >
              Change
            </p>
          )}
        </ButtonBase>
      </div>
      <p
        className="SocialConfigHelperText"
        style={{
          color: globalTheme.textMainBlack.color,
        }}
      >
        {gath3rHandleHelperText}
      </p>
    </div>
  );
}
// /profile/settings,/explore,/profile/bookmarks,/listings
