import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import './AchievementsUser.css';

export default function AchievementsUser() {

  return (
    <div className="AchievementsUser">
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Achievements</title>
      </Helmet>
      User Achievements
    </div>
  );
}
