import React from "react";
import '../Img.css';

function DiscordLogo({customColor}: {customColor?: string;}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 22"
      viewBox="0 0 500 499.93"
      className='socialsLogoSvg'
    >
      <path
        fill={customColor ? customColor:'#39393C'}
        d="M854.13 653.33a244.66 244.66 0 00-61.89-19.44 180.74 180.74 0 00-7.93 16.46 227.33 227.33 0 00-68.6 0 173.82 173.82 0 00-8-16.46 243.54 243.54 0 00-61.94 19.49c-39.19 59.19-49.77 116.91-44.5 173.81a247.47 247.47 0 0075.91 38.9 189.4 189.4 0 0016.26-26.77 160.7 160.7 0 01-25.6-12.46c2.15-1.6 4.25-3.26 6.27-5 49.37 23.09 103 23.09 151.77 0 2.06 1.71 4.16 3.37 6.28 5a160 160 0 01-25.65 12.48 187.75 187.75 0 0016.26 26.77 246.62 246.62 0 0076-38.92C905 761.23 888.08 704 854.13 653.33zm-154 138.87c-14.81 0-27-13.83-27-30.68s11.9-30.7 27-30.7 27.23 13.83 27 30.7c.03 16.85-11.89 30.68-26.97 30.68zm99.67 0c-14.81 0-27-13.83-27-30.68s11.9-30.7 27-30.7 27.23 13.83 27 30.7c0 16.85-11.89 30.68-26.97 30.68z"
        transform="translate(-500 -500.03)"
      ></path>
      <path
        fill={customColor ? customColor:'#39393C'}
        d="M987.67 672.65c42.7 131.27-29.09 272.29-160.36 315s-272.29-29.1-315-160.36 29.09-272.29 160.36-315a249.92 249.92 0 01315 160.36zm-237.6-150.12c-125.61.17-227.3 102.14-227.13 227.75s102.14 227.3 227.75 227.12S978 875.27 977.82 749.65a227.43 227.43 0 00-227.75-227.12z"
        transform="translate(-500 -500.03)"
      ></path>
    </svg>
  );
}

export default DiscordLogo;
