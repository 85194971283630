import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Skeleton } from "@mui/material";
import {
  where,
  limit,
  orderBy,
  OrderByDirection,
  getDoc,
  doc,
  query,
  collectionGroup,
  onSnapshot,
} from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";
import BookmarksInfo from "../../../components/ui/topBoxes/BookmarksInfo";
import {
  useHeaderSettingsState,
  useTabSettingsState,
  useThemeState,
  useDeviceState,
} from "../../../components/utils/globalStates";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import CircularProgress from "@mui/material/CircularProgress";
import ProjectListItem from "../../../components/ui/listItems/ProjectListItem";
import PickersCardView from "../../../components/ui/pickers/PickerCardView";

import "./Bookmarks.css";

export default function Bookmarks() {
  const [user] = useAuthState(firebaseAuth);
  const headerSettingsState = useHeaderSettingsState();
  const tabSettingsState = useTabSettingsState();
  const deviceState = useDeviceState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [listings, setListings] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const [listingsInfo, setListingsInfo] = useState<any[]>([]);
  const [loadingInfo, setLoadingInfo] = useState(true);

  useEffect(() => {
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
    headerSettingsState.setLogoBGColor(globalTheme.backgroundMainBeige.color);
    headerSettingsState.setLogoColor(globalTheme.backgroundMainOffBeige.color);
    headerSettingsState.setIsLogoOpen(true);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.backgroundMainBeige.color);
    return () => {
      tabSettingsState.setColor(globalTheme.backgroundMainYellow.color);
      tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      headerSettingsState.setIsLogoOpen(false);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  const fetchListingsInfo = async (infoId: string) => {
    const docRef = doc(firestoreDb, "listings", infoId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  };
  // Fetch user bookmarks and fetch info for each bookmark
  useEffect(() => {
    if (user === null || user === undefined) return;
    const unsubscribe = onSnapshot(
      query(
        collectionGroup(firestoreDb, "userBookmarks"),
        where("follower", "==", user.uid),
        limit(200),
        orderBy("followedAt", "desc" as OrderByDirection)
      ),
      async (snapshot) => {
        const tempArray: any[] = [];
        snapshot.forEach((doc) => {
          tempArray.push(doc.data());
        });
        setListings(tempArray);
        setLoading(false);
        if (tempArray.length !== 0) {
          const tempInfoArray: any[] = [];
          setLoadingInfo(true);
          Promise.all(
            tempArray.map(async (item) => {
              return await fetchListingsInfo(item.following);
            })
          )
            .then((res) => {
              for (let i = 0; i < res.length; i++) {
                tempInfoArray.push(res[i]);
              }
              setListingsInfo(tempInfoArray);
              setLoadingInfo(false);
            })
            .catch((err) => {
              console.error(err);
              setLoadingInfo(false);
              setLoading(false);
            });
        } else {
          setLoadingInfo(false);
        }
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div
      className="Bookmarks"
      style={{ backgroundColor: globalTheme.backgroundMainBeige.color }}
    >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - My Bookmarks</title>
      </Helmet>
      <BookmarksInfo />
      {loading ? (
        <div className="UserListLoading">
          <CircularProgress
            sx={{ color: globalTheme.backgroundMainOffBeige.color }}
          />
        </div>
      ) : listings.length === 0 ? (
        <div className="UserListLoading">
          <p
            className="UserListSortTitle"
            style={{ color: globalTheme.backgroundMainOffBeige.color }}
          >
            No bookmarks yet
          </p>
        </div>
      ) : (
        <div className="ExploreListInner">
          <div
            className="ExploreSort"
            style={{
              width: "100%",
              maxWidth: "1200px",
              justifyContent: "flex-end",
              paddingRight: "0px",
              marginRight: "0px",
              marginTop: "5px",
            }}
          >
            
        <PickersCardView iconColor={globalTheme.backgroundMainOffYellow.color} />
          </div>
          {listings.map((listingsItem, index) => {
            if (loadingInfo) {
              return (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  sx={{
                    marginTop: "10px",
                    borderRadius: "8px",
                    width: "100%",
                    height: "60px",
                    "@media (min-width: 600px)": {
                      width: "calc(50% - 10px)",
                    },
                    "@media (min-width: 1200px)": {
                      width: "calc(33% - 10px)",
                    },
                  }}
                />
              );
            } else {
              if (listingsInfo[index] === null) {
                return null;
              }
              return (
                <ProjectListItem
                  listItem={listingsInfo[index]}
                  indexKey={index}
                  itemSize={deviceState.viewSizeBox}
                  clickAdmin={false}
                  key={listingsItem.id}
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
}
