import React, { useState, useEffect } from "react";
import { marked } from "marked";
import CircularProgress from "@mui/material/CircularProgress";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../../components/firebase/functions";
import { searchClient } from "@rss3/js-sdk";
import {
  parseIpfsLink,
  testIfMarkdown,
} from "../../../../components/utils/parsers";
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  orderBy,
  limit,
  doc,
} from "firebase/firestore";
import { firestoreDb } from "../../../../components/firebase/firestore";
import moment from "moment";
import {
  useRssState,
  useMediumState,
  useRss3State,
  useThemeState,
} from "../../../../components/utils/globalStates";
import Rss3Item from "./Rss3Item";
import MediumItem from "./MediumItem";
import RssDbItem from "./RssDbItem";
import styles from "./news.module.css";

moment().format();

interface ListingDialogProps {
  activeListingName: string;
  activeListingId: string;
  mediumActive: boolean;
  mediumName: string;
}

let isFetchingRss3 = false;
let isFetchingRssDb = false;
let isFetchingMedium = false;
let isFetching = false;
export default function NewsList({
  activeListingName,
  mediumActive,
  activeListingId,
  mediumName,
}: ListingDialogProps) {
  const rss3State = useRss3State();
  const rssDbState = useRssState();
  const mediumState = useMediumState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [loading, setLoading] = useState(false);
  const [newsListings, setNewsListings] = useState<any[]>([]);

  const fetchRss3Listings = async () => {
    if (isFetchingRss3) return;
    if (activeListingName === "") return;
    if (rss3State.isFetched) return;
    isFetchingRss3 = true;
    try {
      const searchResults = await searchClient().activities({
        keyword: activeListingName,
        limit: 5,
        platform: ["CROSSBELL", "MIRROR"],
      });
      if (
        searchResults.data !== undefined &&
        searchResults.data.docs !== undefined &&
        searchResults.data.docs.length > 0
      ) {
        let tempArray: any[] = [];
        for (let i = 0; i < searchResults.data.docs.length; i++) {
          const tempListing = searchResults.data.docs[i];
          if (
            (tempListing.platform === "Crossbell" ||
              tempListing.platform === "Mirror") &&
            tempListing.actions !== undefined &&
            (tempListing.actions[0].platform === "Crossbell" ||
              tempListing.actions[0].platform === "Mirror") &&
            tempListing.actions[0].metadata !== undefined &&
            tempListing.actions[0].metadata !== null &&
            tempListing.actions[0].metadata.title !== undefined &&
            tempListing.actions[0].metadata.title !== null &&
            tempListing.actions[0].metadata.title !== "" &&
            tempListing.actions[0].metadata.body !== undefined &&
            tempListing.actions[0].metadata.body !== null &&
            tempListing.actions[0].metadata.body !== ""
          ) {
            let tempImg = "";
            if (
              tempListing.actions[0].metadata.media !== null &&
              tempListing.actions[0].metadata.media !== undefined &&
              tempListing.actions[0].metadata.media.length > 0 &&
              tempListing.actions[0].metadata.media[0].address !== null &&
              tempListing.actions[0].metadata.media[0].address
            ) {
              tempImg = parseIpfsLink(
                tempListing.actions[0].metadata.media[0].address
              );
            }
            tempArray.push({
              provider: "RSS3",
              title: tempListing.actions[0].metadata.title,
              body: testIfMarkdown(tempListing.actions[0].metadata.body)
                ? marked.parse(tempListing.actions[0].metadata.body)
                : tempListing.actions[0].metadata.body,
              handle: tempListing.actions[0].metadata.handle,
              timestamp: Number(tempListing.timestamp) * 1000,
              platform: tempListing.platform,
              img: tempImg,
              isMd: testIfMarkdown(tempListing.actions[0].metadata.body),
              link:
                tempListing.platform === "Crossbell"
                  ? `https://crossbell.io/notes/${tempListing.actions[0].metadata.profile_id}-${tempListing.actions[0].metadata.publication_id}`
                  : `${tempListing.actions[0].metadata.author_url}/${tempListing.actions[0].metadata.publication_id}`,
            });
          }
        }
        tempArray.sort((a, b) => {
          return b.timestamp - a.timestamp;
        });
        rss3State.setListings(tempArray);
      }
      isFetchingRss3 = false;
      rss3State.setIsFetched(true);
    } catch (error) {
      console.error(error);
      rss3State.setIsFetched(true);
      isFetchingRss3 = false;
    }
  };

  const fetchRssListings = async () => {
    if (isFetchingRssDb) return;
    if (activeListingId === "") return;
    if (rssDbState.isFetched) return;
    try {
      isFetchingRssDb = true;
      let rssArray: any[] = [];

      let q = query(
        collection(firestoreDb, `listings/${activeListingId}/rss`),
        orderBy("date", "desc"),
        limit(10)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let tempDoc = doc.data();
        let tempDate = tempDoc.date;
        rssArray.push({ ...doc.data(), timestamp: tempDate, proider: "RSS" });
      });

      rssDbState.setListings(rssArray);
      rssDbState.setIsFetched(true);
      isFetchingRssDb = false;
    } catch (error) {
      console.error(error);
      rssDbState.setIsFetched(true);
      isFetchingRssDb = false;
    }
  };

  const fetchMediumListings = async () => {
    if (isFetchingMedium) return;
    if (mediumActive === false) return;
    if (mediumName === "") return;
    if (mediumState.isFetched) return;
    try {
      isFetchingMedium = true;
      const getFeed = httpsCallable(firebaseFunctions, "rss-medium");
      const res: any = await getFeed({ name: mediumName });
      let feedJson = res.data;
      let rssArray: any[] = [];
      feedJson.items.forEach((item: any) => {
        if (
          item.creator != null &&
          item.creator !== undefined &&
          item.creator !== "" &&
          item.title != null &&
          item.title !== undefined &&
          item.title !== "" &&
          item.link != null &&
          item.link !== undefined &&
          item.link !== "" &&
          item.isoDate != null &&
          item.isoDate !== undefined &&
          item.isoDate !== "" &&
          item["content:encoded"] != null &&
          item["content:encoded"] !== undefined &&
          item["content:encoded"] !== "" &&
          item["content:encodedSnippet"] != null &&
          item["content:encodedSnippet"] !== undefined &&
          item["content:encodedSnippet"] !== ""
        ) {
          let tempTime = new Date(item.isoDate);
          let tempRss = {
            provider: "Medium",
            title: item.title,
            link: item.link,
            timestamp: tempTime.getTime(),
            content: item["content:encodedSnippet"],
            author: item.creator,
            snippet: item["content:encoded"],
            img: feedJson.image.url !== undefined ? feedJson.image.url : "",
          };
          rssArray.push(tempRss);
        }
      });
      rssArray.sort((a, b) => b.date - a.date);
      mediumState.setListings(rssArray);
      mediumState.setIsFetched(true);
      isFetchingMedium = false;
    } catch (error) {
      console.error(error);
      mediumState.setIsFetched(true);
      isFetchingMedium = false;
    }
  };
  useEffect(() => {
    if (mediumActive && mediumState.isFetched === false) {
      fetchMediumListings();
    } else {
      mediumState.setIsFetched(true);
    }
    if (rssDbState.isFetched === false) {
      fetchRssListings();
    }
    if (rss3State.isFetched === false) {
      fetchRss3Listings();
    }
  }, [activeListingId]);

  useEffect(() => {
    if (isFetching) return;
    if (newsListings.length > 0) return;
    isFetching = true;
    setLoading(true);
    let tempArray: any[] = [];

    if (mediumState.isFetched && rssDbState.isFetched && rss3State.isFetched) {
      tempArray = [
        ...mediumState.listings,
        ...rssDbState.listings,
        ...rss3State.listings,
      ];
      tempArray.sort((a, b) => {
        return b.timestamp - a.timestamp;
      });
      setNewsListings(tempArray);
      setLoading(false);
    }
    isFetching = false;
  }, [mediumState.isFetched, rssDbState.isFetched, rss3State.isFetched]);

  return !loading ? (
    newsListings.length > 0 ? (
      <div className={styles.newsListingsOuter}>
        {newsListings.map((listing: any, index: number) => {
          return (
            <div className={styles.outsideKeyListing} key={index}>
              {listing.provider === "RSS3" ? (
                <Rss3Item listing={listing} />
              ) : listing.provider === "Medium" ? (
                <MediumItem listing={listing} />
              ) : (
                <RssDbItem listing={listing} />
              )}
            </div>
          );
        })}
      </div>
    ) : (
      <div className={styles.loadingDialog}>
        <p
          className={styles.newsListingsText}
          style={{ color: globalTheme.backgroundMainOffBeige.color }}
        >
          No News articles found.
        </p>
      </div>
    )
  ) : (
    <div className={styles.loadingDialog}>
      <CircularProgress
        color="inherit"
        sx={{ color: globalTheme.backgroundMainOffBeige.color }}
      />
    </div>
  );
}
