import React, { useState, useEffect } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import {useThemeState} from "../../utils/globalStates";
import "./TopBoxes.css";

export default function AchievementsInfo() {
  const [showInfo, setShowInfo] = useState(false);
  const globalTheme = useThemeState((state) => state.globalTheme);

  // Check local storage if user has seen the info
  useEffect(() => {
    if (localStorage.getItem("achievementsInfo") === "true") {
      setShowInfo(false);
    } else {
      setShowInfo(true);
    }
  }, []);

  const onInfoClose = () => {
    setShowInfo(false);
    localStorage.setItem("achievementsInfo", "true");
  };

  const onInfoOpen = () => {
    localStorage.removeItem("achievementsInfo");
    setShowInfo(true);
  }
  return (
    <div
      className="SearchAndExplore"
      style={{
        backgroundColor: globalTheme.figmaCoreOrange.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        ...globalTheme.boxShadow,
        maxWidth: "770px",
      }}
    >
        {!showInfo && (
            <InfoIcon
            onClick={onInfoOpen}
            sx={{
              color: globalTheme.figmaPrimaryText.color,
              cursor: "pointer",
              position: "absolute",
              right: "6px",
              top: "6px",
              fontSize: "17px",
              "@media (min-width: 350px)": {
                fontSize: "18px",
              },
              "@media (min-width: 550px)": {
                fontSize: "19px",
              },
              "@media (min-width: 700px)": {
                fontSize: "20px",
              },
            }}
          />
        )}
      <div className="SearchAndExploreHeader">
        <p
          className="SearchAndExploreHeaderTitle"
          style={{ color: globalTheme.figmaPrimaryText.color, maxWidth: "95%" }}
        >
          Achievements
        </p>
        <p
          className="SearchAndExploreHeaderSub"
          style={{
            color: globalTheme.figmaPrimaryText.color,
            maxWidth: "95%",
            marginBottom: "-10px",
          }}
        >
            Be active and earn achievements
        </p>
      </div>
      {showInfo && (
        <div
          className="ExploreInfo"
          style={{
            backgroundColor: globalTheme.figmaYellowAlt.color,
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            marginBottom: "5px",
          }}
        >
          <p
            className="ExploreInfoText"
            style={{ color: globalTheme.figmaPrimaryText.color }}
          >
                Achievements are earned by being active on GATH3R. You can earn achievements by listing projects, creating reviews and ratings, and more. Even some easter eggs, can you find them? 
          </p>
          <CancelIcon
            onClick={onInfoClose}
            sx={{
              color: globalTheme.figmaPrimaryText.color,
              cursor: "pointer",
              fontSize: "17px",
              "@media (min-width: 350px)": {
                fontSize: "18px",
              },
              "@media (min-width: 550px)": {
                fontSize: "19px",
              },
              "@media (min-width: 700px)": {
                fontSize: "20px",
              },
            }}
          />
        </div>
      )}
    </div>
  );
}
