import React, { useState, useEffect } from "react";

import { ButtonBase } from "@mui/material";
import handPickLogo from "../../../static/img/handPickLogo.png";
import { Link } from "react-router-dom";
import { useThemeState } from "../../utils/globalStates";
import { figmaColorVariables } from "../../theme/globalTheme";
import "./TopBoxes.css";

export default function DashboardInfoNew() {
  const [showInfo, setShowInfo] = useState(false);
  const globalTheme = useThemeState((state) => state.globalTheme);


  // Check local storage if user has seen the info
  useEffect(() => {
    const localInfo = localStorage.getItem("dashboardInfoNew");
    if (localInfo !== null) {
      const dateNow = Date.now();
      const dateThen = parseInt(localInfo);
      if (isNaN(dateThen)) {
        console.error("Invalid date in local storage");
        setShowInfo(true);
      } else {
        if (dateNow - dateThen > 60 * 60 * 1000 * 24 * 7) {
          setShowInfo(true);
        } else {
          setShowInfo(false);
        }
      }
    } else {
      setShowInfo(true);
    }
  }, []);

  const onInfoClose = () => {
    setShowInfo(false);
    const dateNow = Date.now();
    localStorage.setItem("dashboardInfoNew", dateNow.toString());
  };

  /*const onInfoOpen = () => {
    localStorage.removeItem("dashboardInfo");
    setShowInfo(true);
  };*/
  const buttonStyles = {
    color: "#1A1A1A",
    backgroundColor: figmaColorVariables.red100,
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    border: "1px solid #1A1A1A",
    margin: "5px",
    cursor: "pointer",
    '& span': {
        marginRight: "5px",
        fontSize: '15px',
        },
    "&:hover": {
      opacity: 0.9,
    },
  };

  return showInfo ? (
    <div
      className="SearchAndExplore"
      style={{
        backgroundColor: globalTheme.primarySurface.color,
        border: `1px solid ${globalTheme.solidMainBlack.color}`,
        ...globalTheme.boxShadow,
        marginTop: "20px",
        marginBottom: "5px",
        maxWidth: "770px",
      }}
    >
      <div className="DashboardInfoNew">
        <div className="DashboardInfoInnerNew">
          <p
            className="DashboardInfoTitle"
            style={{
              color: globalTheme.textMainBlack.color,
            }}
          >
            Welcome to your dashboard
          </p>
          <p
            className="DashboardInfoSub"
            style={{
              color: globalTheme.textMainBlack.color,
            }}
          >
            Here you can customise and control everything that’s important to
            you.
          </p>
          <p
            className="DashboardInfoText"
            style={{
              color: globalTheme.textMainBlack.color,
            }}
          >
            Follow others on GATH3R or projects they have listed.
          </p>
          <ul className="DashboardInfoUL">
            <li className="DashboardInfoLI">
              <p
                className="DashboardInfoText"
                style={{
                  color: globalTheme.textMainBlack.color,
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                See trending listings and what’s just been added.
              </p>
            </li>
            <li className="DashboardInfoLI">
              <p
                className="DashboardInfoText"
                style={{
                  color: globalTheme.textMainBlack.color,
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                Track leaderboards and top users.
              </p>
            </li>
            <li className="DashboardInfoLI">
              <p
                className="DashboardInfoText"
                style={{
                  color: globalTheme.textMainBlack.color,
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                Keep tabs on reviews on bookmarked projects.
              </p>
            </li>
            <li className="DashboardInfoLI">
              <p
                className="DashboardInfoText"
                style={{
                  color: globalTheme.textMainBlack.color,
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                and so much more...
              </p>
            </li>
          </ul>
          <ButtonBase
            onClick={onInfoClose}
            sx={{
              ...buttonStyles,
            }}
          >
            <span className="material-symbols-outlined">cancel</span>
            Close
          </ButtonBase>
          <Link
          to="/profile/settings"
                className="DashboardInfoText"
                style={{
                  color: figmaColorVariables.blue300,
                  marginTop: "10px",
                  marginBottom: "0px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <span style={{ fontSize: "80%" }}>
                You can find this tutorial in the settings if you need it.
                </span> 
              </Link>
        </div>
        <div className="DashboardInfoInnerLogo">
          <img
            src={handPickLogo}
            alt="handPickLogo"
            className="DashboardInfoLogo"
          />
        </div>
      </div>
    </div>
  ) : null;
}
