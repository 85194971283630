import React, { useEffect, useState } from "react";
import moment from "moment";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { categoryEnums } from "../../../components/utils/enums";
import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "../../../components/firebase/analytics";
import AdminErrorLogoUpload from "../components/AdminErrorLogoUpload";

import { doc, updateDoc, deleteDoc, setDoc, getDoc } from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../components/firebase/functions";
import {
  useSnackbarState,
  useSnackbarErrorState,
  useThemeState,
} from "../../../components/utils/globalStates";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import { isValidUrl } from "../../../components/utils/parsers";
import { materialListingFormStyles } from "../../../components/theme/globalMuiTheme";
import "./ListingDialogs.css";

moment().format();
export default function AdminErrorListingDialog({
  listing,
  setActiveListing,
  lastUpdatedAt,
}: any) {
  const [user] = useAuthState(firebaseAuth);
  const snackbarState = useSnackbarState();
  const snackbarErrorState = useSnackbarErrorState();
  const globalTheme = useThemeState((state) => state.globalTheme);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [website, setWebsite] = useState("");
  const [category, setCategory] = useState("");
  const [xHandle, setXHandle] = useState("");
  const [instagramHandle, setInstagramHandle] = useState("");
  const [telegramHandle, setTelegramHandle] = useState("");
  const [mediumHandle, setMediumHandle] = useState("");
  const [substackHandle, setSubstackHandle] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [logo, setLogo] = useState("");

  const [isUpdating, setIsUpdating] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmMove, setConfirmMove] = useState(false);
  const [isAiLoading, setIsAiLoading] = useState(false);

  // Update changes to listing properties, set to useState

  useEffect(() => {
    onClear();
  }, [lastUpdatedAt]);

  const onClear = () => {
    setName(listing.name);
    setDescription(listing.description);
    setWebsite(listing.website);
    setCategory(listing.category);
    setXHandle(listing.xHandle);
    setInstagramHandle(listing.instagramHandle);
    setTelegramHandle(listing.telegramHandle);
    setMediumHandle(listing.mediumHandle);
    setSubstackHandle(listing.substackHandle);
    setFacebookUrl(listing.facebookUrl);
    setLogo(listing.logo);
  };

  // Update listing in database
  const onUpdate = async () => {
    try {
      setIsUpdating(true);
      await updateDoc(doc(firestoreDb, "errorListings", listing.id), {
        name: name,
        description: description,
        website: website,
        category: category,
        xHandle: xHandle,
        instagramHandle: instagramHandle,
        telegramHandle: telegramHandle,
        mediumHandle: mediumHandle,
        substackHandle: substackHandle,
        facebookUrl: facebookUrl,
        logo: logo,
        updatedAt: Date.now(),
      });
      setIsUpdating(false);
      snackbarState.setOpenLength(5000);
      snackbarState.setMessage('Error listing "' + listing.id + '" updated.');
      snackbarState.setIsOpen(true);
      setActiveListing();
    } catch (err) {
      console.error(err);
      setIsUpdating(false);
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage(
        "Error updating error listing " + listing.id
      );
      snackbarErrorState.setIsOpen(true);
    }
  };

  const onDelete = async () => {
    try {
      setIsDeleting(true);
      await deleteDoc(doc(firestoreDb, "errorListings", listing.id));
      setIsDeleting(false);
      snackbarState.setOpenLength(5000);
      snackbarState.setMessage('Error listing "' + listing.id + '" deleted.');
      snackbarState.setIsOpen(true);
      setActiveListing();
    } catch (err) {
      console.error(err);
      setIsDeleting(false);
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage(
        "Error deleting error listing " + listing.id
      );
      snackbarErrorState.setIsOpen(true);
    }
  };

  const onMove = async () => {
    try {
      setIsMoving(true);

      // Check if listing exists
      const listingRef = doc(firestoreDb, "listings", name.replace(/\s/g, "-").toLowerCase());
      const docSnap = await getDoc(listingRef);
      if (docSnap.exists()) {
        setIsMoving(false);
        snackbarErrorState.setOpenLength(5000);
        snackbarErrorState.setMessage("Listing " + name.replace(/\s/g, "-").toLowerCase() + " already exists.");
        snackbarErrorState.setIsOpen(true);
        return;
      }
      await setDoc(listingRef, {
        ...listing,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        isVerified: true,
        id: name.replace(/\s/g, "-").toLowerCase(),
        name: name,
        description: description,
        website: website,
        category: category,
        xHandle: xHandle,
        instagramHandle: instagramHandle,
        telegramHandle: telegramHandle,
        mediumHandle: mediumHandle,
        substackHandle: substackHandle,
        facebookUrl: facebookUrl,
        logo: logo,

      });
      await deleteDoc(doc(firestoreDb, "errorListings", listing.id));
      setIsMoving(false);
      snackbarState.setOpenLength(5000);
      snackbarState.setMessage('Error listing "' + name + '" moved.');
      snackbarState.setIsOpen(true);
      setActiveListing();
    } catch (err) {
      console.error(err);
      setIsMoving(false);
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage("Error moving error listing " + listing.id);
      snackbarErrorState.setIsOpen(true);
    }
  };

  // On AI auto fill send to backend
  const autoFillAi = async () => {
    if (name === "") {
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage("Name are required.");
      snackbarErrorState.setIsOpen(true);
      return;
    }

    setIsAiLoading(true);
    snackbarState.setMessage("Performing Ai auto fill.");
    snackbarState.setOpenLength(null);
    snackbarState.setIsActionLoading(true);
    snackbarState.setIsOpen(true);

    try {
      const token = await user?.getIdToken();
      const autofillCallable = httpsCallable(firebaseFunctions, "ai-auto_fill_v2");
      const result = await autofillCallable({
        name: name,
        description: description,
        website: !isValidUrl(website.toLocaleLowerCase()) ? "" : website,
        category: category,
        xHandle: xHandle,
        instagramHandle: instagramHandle,
        telegramHandle: telegramHandle,
        mediumHandle: mediumHandle,
        substackHandle: substackHandle,
        facebookUrl: facebookUrl,
        logoUrl: logo,
        token: token,
      });
      // @ts-ignore
      let resultString = result.data.choices[0].message.tool_calls[0].function.arguments
      const parsedResult = JSON.parse(resultString);
      setName(parsedResult.name);
      setDescription(parsedResult.description);
      setWebsite(parsedResult.website);
      setXHandle(parsedResult.xHandle);
      setInstagramHandle(parsedResult.instagramHandle);
      setTelegramHandle(parsedResult.telegramHandle);
      setMediumHandle(parsedResult.mediumHandle);
      setSubstackHandle(parsedResult.substackHandle);
      setFacebookUrl(parsedResult.facebookUrl);
      setLogo(parsedResult.logoUrl);
      setIsAiLoading(false);
      snackbarState.setMessage("Ai auto fill completed.");
      snackbarState.setIsActionLoading(false);
      snackbarState.setOpenLength(5000);
      snackbarState.setIsOpen(true);
    } catch (error) {
      console.error(error);
      setIsAiLoading(false);
      snackbarState.setIsOpen(false);
      snackbarState.setIsActionLoading(false);
      snackbarState.setMessage("");
      snackbarErrorState.setMessage("Error performing Ai auto fill.");
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setIsOpen(true);
    }
  };

  return (
    <div className="AdminListDialog">
      <p
        style={{ ...globalTheme.altTextColorDarker }}
        className="AdminListDialogTitle"
      >
        {name === "" ? "Error Listing" : name}
      </p>
      <p
        className="AdminDialogDescription"
        style={{ ...globalTheme.altTextColorDarker }}
      >
        Update errors and Verify to move to verified listings. Name,
        description, website, and category are required.
      </p>
      <div className="AdminListDialogForms">
        <TextField
          label="Category"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={category}
          SelectProps={{ MenuProps: { sx: { zIndex: 9999 } } }}
          onChange={(e) => setCategory(e.target.value)}
          select
          helperText={category === "" ? "Error" : ""}
          InputProps={{
            endAdornment: category !== listing.category && (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.altTextColorDarker, marginRight: "5%" }}
                onClick={() => setCategory(listing.category)}
              >
                RESET
              </Button>
            ),
          }}
        >
          {categoryEnums.map((catEnum) => (
            <MenuItem key={catEnum.value} value={catEnum.displayname}>
              {catEnum.displayname}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={name}
          placeholder="Name"
          onChange={(e) => setName(e.target.value)}
          helperText={name.length < 2 || name.length > 50 ? "Error" : ""}
          InputProps={{
            endAdornment: name !== listing.name && (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.altTextColorDarker }}
                onClick={() => setName(listing.name)}
              >
                RESET
              </Button>
            ),
          }}
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={description}
          placeholder="Description"
          multiline
          minRows={4}
          onChange={(e) => setDescription(e.target.value)}
          helperText={
            description.length < 15 || description.length > 2000 ? "Error" : ""
          }
          InputProps={{
            endAdornment: description !== listing.description && (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.altTextColorDarker }}
                onClick={() => setDescription(listing.description)}
              >
                RESET
              </Button>
            ),
          }}
        />
        <TextField
          label="Website"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={website}
          placeholder="Website"
          onChange={(e) => setWebsite(e.target.value)}
          helperText={!isValidUrl(website.toLocaleLowerCase()) ? "Error" : ""}
          InputProps={{
            endAdornment:
              website !== listing.website ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{ ...globalTheme.altTextColorDarker }}
                  onClick={() => setWebsite(listing.website)}
                >
                  RESET
                </Button>
              ) : (
                listing.website !== "" && (
                  <Button
                    variant="text"
                    size="small"
                    sx={{
                      ...globalTheme.altTextColorDarker,
                    }}
                    href={website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    GO TO
                  </Button>
                )
              ),
          }}
        />
        <TextField
          label="Logo URL"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={logo}
          onChange={(e) => setLogo(e.target.value)}
          InputProps={{
            endAdornment:
              logo !== listing.logo ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{ ...globalTheme.altTextColorDarker }}
                  onClick={() => setLogo(listing.logo)}
                >
                  RESET
                </Button>
              ) : listing.logo !== "" ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    ...globalTheme.altTextColorDarker,
                  }}
                  href={logo}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO
                </Button>
              ) : null,
          }}
        />
        <TextField
          label="X Handle"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={xHandle}
          onChange={(e) => setXHandle(e.target.value)}
          InputProps={{
            endAdornment:
              xHandle !== listing.xHandle ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{ ...globalTheme.altTextColorDarker }}
                  onClick={() => setXHandle(listing.xHandle)}
                >
                  RESET
                </Button>
              ) : listing.xHandle !== "" ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    ...globalTheme.altTextColorDarker,
                  }}
                  href={"https://twitter.com/" + xHandle.replace("@", "")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO
                </Button>
              ) : null,
          }}
        />
        <TextField
          label="Instagram Handle"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={instagramHandle}
          onChange={(e) => setInstagramHandle(e.target.value)}
          InputProps={{
            endAdornment:
              instagramHandle !== listing.instagramHandle ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{ ...globalTheme.altTextColorDarker }}
                  onClick={() => setInstagramHandle(listing.instagramHandle)}
                >
                  RESET
                </Button>
              ) : listing.instagramHandle !== "" ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    ...globalTheme.altTextColorDarker,
                  }}
                  href={
                    "https://instagram.com/" + instagramHandle.replace("@", "")
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO
                </Button>
              ) : null,
          }}
        />
        <TextField
          label="Medium Handle"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={mediumHandle}
          onChange={(e) => setMediumHandle(e.target.value)}
          InputProps={{
            endAdornment:
              mediumHandle !== listing.mediumHandle ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{ ...globalTheme.altTextColorDarker }}
                  onClick={() => setMediumHandle(listing.mediumHandle)}
                >
                  RESET
                </Button>
              ) : listing.mediumHandle !== "" ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    ...globalTheme.altTextColorDarker,
                  }}
                  href={"https://medium.com/@" + mediumHandle.replace("@", "")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO
                </Button>
              ) : null,
          }}
        />
        <TextField
          label="Telegram Handle"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={telegramHandle}
          onChange={(e) => setTelegramHandle(e.target.value)}
          InputProps={{
            endAdornment:
              telegramHandle !== listing.telegramHandle ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{ ...globalTheme.altTextColorDarker }}
                  onClick={() => setTelegramHandle(listing.telegramHandle)}
                >
                  RESET
                </Button>
              ) : listing.telegramHandle !== "" ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    ...globalTheme.altTextColorDarker,
                  }}
                  href={"https://t.me/" + telegramHandle.replace("@", "")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO
                </Button>
              ) : null,
          }}
        />
        <TextField
          label="Facebook URL"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={facebookUrl}
          onChange={(e) => setFacebookUrl(e.target.value)}
          InputProps={{
            endAdornment:
              facebookUrl !== listing.facebookUrl ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{ ...globalTheme.altTextColorDarker }}
                  onClick={() => setFacebookUrl(listing.facebookUrl)}
                >
                  RESET
                </Button>
              ) : listing.facebookUrl !== "" ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    ...globalTheme.altTextColorDarker,
                  }}
                  href={facebookUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO
                </Button>
              ) : null,
          }}
        />
        <TextField
          label="Substack Handle"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={substackHandle}
          onChange={(e) => setSubstackHandle(e.target.value)}
          InputProps={{
            endAdornment:
              substackHandle !== listing.substackHandle ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{ ...globalTheme.altTextColorDarker }}
                  onClick={() => setSubstackHandle(listing.substackHandle)}
                >
                  RESET
                </Button>
              ) : listing.substackHandle !== "" ? (
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    ...globalTheme.altTextColorDarker,
                  }}
                  href={
                    "https://substack.com/" + substackHandle.replace("@", "")
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO
                </Button>
              ) : null,
          }}
        />
        <AdminErrorLogoUpload listingId={listing.id} tempListingId={name.replace(/\s/g, "-").toLowerCase()} />
        {confirmDelete ? (
          <div
            className="AdminListDialogButtons"
            style={{ ...globalTheme.textColor }}
          >
            <p className="AdminListDialogConfirmTitle">
              Confirm deletion of listing "{listing.id}"?
            </p>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ marginRight: "10px", fontSize: "12px" }}
              onClick={() => setConfirmDelete(false)}
              disabled={isUpdating || isMoving || isDeleting}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ marginRight: "0px", fontSize: "12px" }}
              onClick={onDelete}
              disabled={isUpdating || isMoving || isDeleting}
            >
              {isDeleting ? "Deleting..." : "Delete"}
            </Button>
          </div>
        ) : confirmMove ? (
          <div
            className="AdminListDialogButtons"
            style={{ ...globalTheme.textColor }}
          >
            <p className="AdminListDialogConfirmTitle">
              Confirm move of error listing "{name.replace(/\s/g, "-").toLowerCase()}" to verified listings?
            </p>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ marginRight: "10px", fontSize: "12px" }}
              onClick={() => setConfirmMove(false)}
              disabled={isUpdating || isMoving || isDeleting}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ marginRight: "0px", fontSize: "12px" }}
              onClick={onMove}
              disabled={isUpdating || isMoving || isDeleting}
            >
              {isMoving ? "Moving..." : "Move"}
            </Button>
          </div>
        ) : (
          <div
            className="AdminListDialogButtons"
            style={{ ...globalTheme.textColor }}
          >
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ marginRight: "10px", fontSize: "12px" }}
              onClick={() => setConfirmDelete(true)}
              disabled={isUpdating || isMoving || isDeleting}
            >
              {isDeleting ? "Deleting..." : "Delete"}
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ marginRight: "10px", fontSize: "12px" }}
              onClick={onClear}
              disabled={isUpdating || isMoving || isDeleting}
            >
              Reset
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ marginRight: "10px", fontSize: "12px" }}
              onClick={onUpdate}
              disabled={isUpdating || isMoving || isDeleting}
            >
              {isUpdating ? "Updating..." : "Update"}
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ marginRight: "0px", fontSize: "12px" }}
              onClick={autoFillAi}
              disabled={
                name === "" ||
                isAiLoading ||
                isUpdating ||
                isMoving ||
                isDeleting
              }
            >
              Ai Auto Fill
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{ marginRight: "0px", fontSize: "12px" }}
              onClick={() => setConfirmMove(true)}
              disabled={
                isUpdating ||
                isMoving ||
                isDeleting ||
                name === "" ||
                description === "" ||
                website === "" ||
                category === ""
              }
            >
              Verify
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
