import { Routes, Route } from "react-router-dom";

import Profile from "./start/Profile";
import ProfileSettings from "./settings/ProfileSettings";
import Bookmarks from "./bookmarks/Bookmarks";
import ProfileAddImage from "./start/components/ProfileAddImage";
import { useThemeState } from "../../components/utils/globalStates";
import "./Profile.css";

export default function ProfileStart() {
  const globalTheme = useThemeState((state) => state.globalTheme);
  return (
    <div className="ProfileStart" style={{ backgroundColor: globalTheme.backgroundMainBeige.color }}>
      <Routes>
        <Route path="/" element={<Profile />} />
        <Route path="/edit/image" element={<ProfileAddImage />} />
        <Route path="/settings" element={<ProfileSettings />} />
        <Route path="/bookmarks" element={<Bookmarks />} />
        <Route path="*" element={<Profile />} />
      </Routes>
    </div>
  );
}
