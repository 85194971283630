import React, { useEffect, useState } from "react";
import {
  query,
  orderBy,
  limit,
  collection,
  onSnapshot,
  OrderByDirection,
} from "firebase/firestore";

import { CircularProgress } from "@mui/material";
import { firestoreDb } from "../../components/firebase/firestore";
import { Waypoint } from "react-waypoint";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import AdminNewsletterExportList from "./components/AdminNewsletterExportList";
import AdminNewsletterItem from "./items/AdminNewsletterItem";
import AdminNewsletterAddEmail from "./components/AdminNewsletterAddEmail";

import { useThemeState } from "../../components/utils/globalStates";
import "./Admin.css";

let outerLoading = false;
const count = 12;

export default function AdminNewsletter() {
  const [sort, setSort] = useState("email");
  const [sortDirection, setSortDirection] = useState<
    OrderByDirection | undefined
  >("asc");

  const [newsletterEmails, setNewsletterEmails] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [lastVisible, setLastVisible] = useState(count);
  const [lastCount, setLastCount] = useState(0);

  const globalTheme = useThemeState((state) => state.globalTheme);

  const handleChange = (event: SelectChangeEvent) => {
    setIsLoading(true);
    setNewsletterEmails([]);
    setLastCount(0);
    setLastVisible(count);
    setSort(event.target.value as string);
  };
  const handleSortDirection = () => {
    setIsLoading(true);
    setNewsletterEmails([]);
    setLastCount(0);
    setLastVisible(count);
    if (sortDirection === "desc") {
      setSortDirection("asc");
    } else {
      setSortDirection("desc");
    }
  };

  const handleEndReached = () => {
    if (lastVisible !== lastCount) {
      return;
    }
    if (lastVisible === lastCount && newsletterEmails.length < lastVisible) {
      return;
    }
    if (isFetchingMore === true) {
      return;
    }
    if (isLoading === true) {
      return;
    }
    setIsFetchingMore(true);
    setLastVisible(lastVisible + count);
  };

  useEffect(() => {
    if (outerLoading === true) {
      return;
    }
    let q = query(
      collection(firestoreDb, "newsletter"),
      orderBy(sort, sortDirection),
      limit(lastVisible)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      outerLoading = true;
      if (lastVisible === count) {
        setIsLoading(true);
      }
      setIsFetchingMore(true);
      let tempEmails: any = [];
      querySnapshot.forEach((doc) => {
        let tempObj = doc.data();
        let pushObj = {
          ...tempObj,
          id: doc.id,
          email:
            tempObj.email !== null &&
            tempObj.email !== undefined &&
            tempObj.email !== ""
              ? tempObj.email
              : "",
          cretaedAt:
            tempObj.cretaedAt !== null &&
            tempObj.cretaedAt !== undefined &&
            tempObj.cretaedAt !== ""
              ? tempObj.cretaedAt
              : 0,
        };
        tempEmails.push(pushObj);
      });
      setNewsletterEmails(tempEmails);
      setLastCount(lastCount + count);
      setIsFetchingMore(false);
      setIsLoading(false);
      outerLoading = false;
    });

    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe();
  }, [sort, sortDirection, lastVisible]);

    const styles = {
    input: {
      backgroundColor: "transparent",
      color: globalTheme.backgroundMainOffBeige.color,
      borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      "& .MuiInputLabel-root": {
        color: globalTheme.backgroundMainOffBeige.color + " !important",
        "& .Mui-focused ": {
          color: globalTheme.backgroundMainOffBeige.color,
        },
      },
    },
    select: {
      backgroundColor: "transparent",
      color: globalTheme.backgroundMainOffBeige.color,
      borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      "& .MuiSelect-root": {
        "& fieldset": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
        "&:after": {
          color: globalTheme.backgroundMainOffBeige.color,
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        },
      },
      "&.Mui-focused fieldset": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      },
      "&.Mui-focused ": {
        color: globalTheme.backgroundMainOffBeige.color,
        borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      },
    },

    selectMenu: {
      color: globalTheme.backgroundMainOffBeige.color,
      "& .MuiPaper-root": {
        backgroundColor: globalTheme.backgroundMainBeige.color,
        color: globalTheme.backgroundMainOffBeige.color,
        "& .MuiMenuItem-root": {
          opacity: 0.6,
          "& .Mui-selected": {
            color: globalTheme.backgroundMainOffBeige.color,
            backgroundColor: globalTheme.backgroundMainBeige.color,
            opacity: 1,
          },
        },
        "& .Mui-selected": {
          color: globalTheme.backgroundMainOffBeige.color,
          backgroundColor: globalTheme.backgroundMainBeige.color,
          opacity: 1,
        },
      },
    },
  };

  return (
    <div className="AdminInner">
      <p
        className="AdminDescription"
        style={{ ...globalTheme.backgroundMainOffBeige }}
      >
        All newsletter emails added by users on the landing page. Add more,
        delete, or export to csv to use in newsletter services.
      </p>
      <AdminNewsletterAddEmail />
      <AdminNewsletterExportList />
      <div
        className="AdminListingSortOuter"
        style={{ ...globalTheme.backgroundMainOffBeige }}
      >
        <div className="AdminListingSortInner">
          <FormControl fullWidth sx={{ ...styles.input }}>
            <InputLabel>Sort</InputLabel>
            <Select
              size="small"
              value={sort}
              label="Sort"
              onChange={handleChange}
              sx={{ ...styles.select }}
              MenuProps={{
                sx: styles.selectMenu,
              }}
            >
              <MenuItem value={"email"}>Email</MenuItem>
              <MenuItem value={"subscribedAt"}>Latest</MenuItem>
            </Select>
          </FormControl>
          <IconButton
            color="inherit"
            size="small"
            sx={{ marginLeft: "2px" }}
            onClick={handleSortDirection}
          >
            {sortDirection === "desc" ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowUpIcon />
            )}
          </IconButton>
        </div>
      </div>
      {isLoading ? (
        <div
          className="AdminListingsLoading"
          style={{ ...globalTheme.backgroundMainOffBeige }}
        >
          <CircularProgress size={30} color="inherit" />
        </div>
      ) : newsletterEmails.length > 0 ? (
        <div className="AdminListingsOuter">
          {newsletterEmails.map((user: any, index: number) => {
            return (
              <div className="AdminListItemOuter" key={index}>
                <AdminNewsletterItem email={user.email} />
              </div>
            );
          })}
          <div className="LoadMoreOuter">
            {newsletterEmails.length > 0 && (
              <Waypoint scrollableAncestor={window} onEnter={handleEndReached}>
                {isFetchingMore ? (
                  <div
                    className="LoadMoreOuter"
                    style={{ ...globalTheme.backgroundMainOffBeige }}
                  >
                    <CircularProgress size={30} color="inherit" />
                  </div>
                ) : (
                  <div className="LoadMoreOuter">
                    <p
                      className="AdminDescription"
                      style={{ ...globalTheme.backgroundMainOffBeige }}
                    >
                       
                    </p>
                  </div>
                )}
              </Waypoint>
            )}
          </div>
        </div>
      ) : (
        <div className="AdminListingsLoading">
          <p
            className="AdminDescription"
            style={{ ...globalTheme.backgroundMainOffBeige }}
          >
            No emails found in the newsletter list.
          </p>
        </div>
      )}
    </div>
  );
}
