import React, { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import Rating from "@mui/material/Rating";
import { useThemeState } from "../../../utils/globalStates";

import moment from "moment";
import "./ListItemsComp.css";

moment().format();

export default function UserListItemNumber({ listItem }: { listItem: any }) {
  const [reviewCountLoading, setReviewCountLoading] = useState(false);
  const [ratingCountLoading, setRatingCountLoading] = useState(false);
  const [pointsLoading, setPointsLoading] = useState(false);
  const [points, setPoints] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [ratingCount, setRatingCount] = useState(0);
  const globalTheme = useThemeState((state) => state.globalTheme);

  // Get review count
  useEffect(() => {
    setReviewCountLoading(true);
    if (
      listItem.reviewsCount === null ||
      listItem.reviewsCount === undefined ||
      listItem.reviewsCount === ""
    ) {
      setReviewCount(0);
      setReviewCountLoading(false);
    } else {
      setReviewCount(listItem.reviewsCount);
      setReviewCountLoading(false);
    }
  }, [listItem.reviewsCount]);

  // Get rating count
  useEffect(() => {
    setRatingCountLoading(true);
    if (
      listItem.ratingCount === null ||
      listItem.ratingCount === undefined ||
      listItem.ratingCount === ""
    ) {
      setRatingCount(0);
      setRatingCountLoading(false);
    } else {
      setRatingCount(listItem.ratingCount);
      setRatingCountLoading(false);
    }
  }, [listItem.ratingCount]);

  // Get points
  useEffect(() => {
    setPointsLoading(true);
    if (
      listItem.points === null ||
      listItem.points === undefined ||
      listItem.points === ""
    ) {
      setPoints(0);
      setPointsLoading(false);
    } else {
      setPoints(listItem.points);
      setPointsLoading(false);
    }
  }, [listItem.points]);

  return (
    <div
      className="ProjectListRatingItem"
      style={{ justifyContent: "flex-start", marginTop: "5px" }}
    >
      <div className="ProjectListRatingItemInner">
        <p
          className="ProjectListRatingItemRatingText"
          style={{
            fontWeight: "500",
            color: globalTheme.primaryText.color,
            marginLeft: "0px",
          }}
        >
          {ratingCount} Ratings
        </p>
        <p
          className="ProjectListRatingItemRatingText"
          style={{
            fontWeight: "500",
            color: globalTheme.primaryText.color,
            marginLeft: "5px",
          }}
        >
          Reviews {reviewCount}
        </p>
        <p
          className="ProjectListRatingItemRatingText"
          style={{
            fontWeight: "500",
            color: globalTheme.primaryText.color,
            marginLeft: "5px",
          }}
        >
          Points {points}
        </p>
      </div>
    </div>
  );
}
