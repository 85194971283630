import React, { useState, useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import InputAdornment from "@mui/material/InputAdornment";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { ButtonBase, TextField } from "@mui/material";
import { categoryEnums } from "../../utils/enums";
import { useReviewSearchSortState, useThemeState } from "../../utils/globalStates";
import "./TopBoxes.css";

export default function ReviewSearchInfo() {
  const [showInfo, setShowInfo] = useState(false);
  const reviewSearchSortState = useReviewSearchSortState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [searchSuggestions, setSearchSuggestions] = useState<string[]>([]);

  // Check local storage if user has seen the info
  useEffect(() => {
    if (localStorage.getItem("reviewSearchInfo") === "true") {
      setShowInfo(false);
    } else {
      setShowInfo(true);
    }
  }, []);

  const onInfoClose = () => {
    setShowInfo(false);
    localStorage.setItem("reviewSearchInfo", "true");
  };

  const onInfoOpen = () => {
    localStorage.removeItem("reviewSearchInfo");
    setShowInfo(true);
  }

  // Randomize 5 search suggestions from category enums names, every suggestion has to be unique
  useEffect(() => {
    const suggestions: string[] = [];
    while (suggestions.length < 5) {
      const random = Math.floor(Math.random() * categoryEnums.length);
      if (!suggestions.includes(categoryEnums[random].displayname)) {
        suggestions.push(categoryEnums[random].displayname);
      }
    }
    setSearchSuggestions(suggestions);
  }, []);


const styles = {
  exploreSearchForm: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      color: globalTheme.figmaPrimaryText.color,
      borderColor: globalTheme.figmaPrimaryText.color,
      borderWidth: "1px",
      backgroundColor: globalTheme.figmaWhite.color,
      boxShadow: "none",
      borderRadius: "8px",
      padding: "6px",
      paddingLeft: "14px",
      paddingRight: "14px",
      "& fieldset": {
        borderColor: globalTheme.figmaPrimaryText.color,
        borderWidth: "1px",
        color: globalTheme.figmaPrimaryText.color,
      },
      "&:hover fieldset": {
        borderColor: globalTheme.figmaPrimaryText.color,
        borderWidth: "1px",
        color: globalTheme.figmaPrimaryText.color,
      },
      "&.Mui-focused fieldset": {
        borderColor: globalTheme.figmaPrimaryText.color,
        color: globalTheme.figmaPrimaryText.color,
      },
    },
    "& label.Mui-focused": {
      color: globalTheme.figmaPrimaryText.color,
    },
    "& label": {
      color: globalTheme.figmaPrimaryText.color,
    },
  },
};

  return (
    <div
      className="SearchAndExplore"
      style={{
        backgroundColor: globalTheme.figmaBluePastel.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        ...globalTheme.boxShadow,
        paddingBottom: "15px",
        maxWidth: "1170px",
      }}
    >
        {!showInfo && (
            <InfoIcon
            onClick={onInfoOpen}
            sx={{
              color: globalTheme.figmaPrimaryText.color,
              cursor: "pointer",
              position: "absolute",
              right: "6px",
              top: "6px",
              fontSize: "17px",
              "@media (min-width: 350px)": {
                fontSize: "18px",
              },
              "@media (min-width: 550px)": {
                fontSize: "19px",
              },
              "@media (min-width: 700px)": {
                fontSize: "20px",
              },
            }}
          />
        )}
      <div className="SearchAndExploreHeader">
        <p
          className="SearchAndExploreHeaderTitle"
          style={{ color: globalTheme.figmaPrimaryText.color }}
        >
          Reviews
        </p>
        <p className="SearchAndExploreHeaderSub">
          Find reviews made by other users
        </p>
      </div>
      <TextField
        id="outlined-basic"
        variant="outlined"
        size="small"
        placeholder="Search"
        value={reviewSearchSortState.search}
        sx={styles.exploreSearchForm}
        onChange={(e) => reviewSearchSortState.setSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon
                style={{ color: globalTheme.figmaExploreSearch.color }}
              />
            </InputAdornment>
          ),
          endAdornment: reviewSearchSortState.search !== "" && (
            <InputAdornment position="end">
              <CloseOutlinedIcon
                style={{
                  color: globalTheme.figmaExploreSearch.color,
                  cursor: "pointer",
                }}
                onClick={() => reviewSearchSortState.setSearch("")}
              />
            </InputAdornment>
          ),
        }}
      />
      {showInfo && (
        <div
          className="ExploreInfo"
          style={{
            backgroundColor: globalTheme.figmaYellowAlt.color,
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          }}
        >
          <p
            className="ExploreInfoText"
            style={{ color: globalTheme.figmaPrimaryText.color }}
          >
            Search for reviews by project names, categories, user names and
            anything else you can think of. Here are some search suggestions to
            get you started:
          </p>
          <CancelIcon
            onClick={onInfoClose}
            sx={{
              color: globalTheme.figmaPrimaryText.color,
              cursor: "pointer",
              fontSize: "17px",
              "@media (min-width: 350px)": {
                fontSize: "18px",
              },
              "@media (min-width: 550px)": {
                fontSize: "19px",
              },
              "@media (min-width: 700px)": {
                fontSize: "20px",
              },
            }}
          />
        </div>
      )}
      <div className="ExploreSearchSuggestionsOuter">
        <p
          className="ExploreSearchSuggestionsText"
          style={{
            padding: "3px",
            paddingLeft: "0px",
            marginRight: "10px",
            color: globalTheme.figmaPrimaryText.color,
          }}
        >
          Search for:
        </p>
        {searchSuggestions.map((suggestion, index) => (
          <ButtonBase
            onClick={() => reviewSearchSortState.setSearch(suggestion)}
            key={index}
            sx={{
              color: globalTheme.figmaPrimaryText.color,
              border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
              marginRight: "10px",
              marginBottom: "10px",
              borderRadius: "12px",
              padding: "3px",
              paddingLeft: "10px",
              paddingRight: "10px",
              transition: "all 0.5s ease-in-out",
            }}
          >
            <p className="ExploreSearchSuggestionsText">{suggestion}</p>
          </ButtonBase>
        ))}
      </div>
    </div>
  );
}
