import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";

import XLogo from "../../../components/ui/img/socials/Xlogo";
import DiscordLogo from "../../../components/ui/img/socials/DiscordLogo";
import OpenseaLogo from "../../../components/ui/img/socials/OpenseaLogo";

import { LazyLoadImg } from "../../../components/ui/misc/LazyLoadImg";

import { removeUrlPrefix } from "../../../components/utils/parsers";
import { useThemeState } from "../../../components/utils/globalStates";
import "./WalletComp.css";

export default function WalletMintItem({
  mintItem,
  index,
}: {
  mintItem: any;
  index: number;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  return (
    <div
      className="TimelineItem"
      style={{ flexDirection: index % 2 === 0 ? "row" : "row-reverse" }}
    >
      <div className="TimelineItemSide">
        <div
          className="WalletItemTitleOuter"
          style={{ alignItems: index % 2 === 0 ? "flex-end" : "flex-start" }}
        >
          <p
            style={{
              color: globalTheme.figmaPrimaryText.color,
              textAlign: index % 2 === 0 ? "right" : "left",
            }}
            className="WalletItemTitle"
          >
            Minted a NFT
          </p>
          <p
            style={{
              color: globalTheme.figmaSecondaryText.color,
              textAlign: index % 2 === 0 ? "right" : "left",
            }}
            className="WalletItemSub"
          >
            Ethereum - Block #{mintItem.blockNumber}
          </p>
        </div>
      </div>
      <div className="TimelineItemSeparator">
        <div
          className="TimelineItemSeparatorLine"
          style={{ backgroundColor: globalTheme.figmaGOrange.color }}
        ></div>
        <div
          className="TimelineItemSeparatorCircle"
          style={{ backgroundColor: globalTheme.figmaGOrange.color }}
        >
          <InsertPhotoOutlinedIcon
            sx={{ color: globalTheme.figmaLightBG.color, fontSize: "26px" }}
          />
        </div>
        <div
          className="TimelineItemSeparatorLine"
          style={{ backgroundColor: globalTheme.figmaGOrange.color }}
        ></div>
      </div>
      <div className="TimelineItemSide">
        <div
          className="WalletItemDiv"
          style={{
            backgroundColor: globalTheme.figmaWhite.color,
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            boxShadow: globalTheme.boxShadow.boxShadow,
          }}
        >
          <>
            <p
              className="WalletItemInnerTitle"
              style={{ color: globalTheme.figmaPrimaryText.color }}
            >
              {mintItem.collection.name}
            </p>
            {mintItem.image !== undefined &&
              mintItem.image !== null &&
              mintItem.image !== "" && <LazyLoadImg imgUrl={mintItem.image} />}
            <p
              className="WalletItemInnerSub"
              style={{ color: globalTheme.figmaPrimaryText.color }}
            >
              {mintItem.name}
            </p>
            <p
              className="WalletItemInnerDesc"
              style={{ color: globalTheme.figmaPrimaryText.color }}
            >
              {mintItem.description !== undefined
                ? mintItem.description
                : mintItem.contract.openSeaMetadata.description !== undefined &&
                  mintItem.contract.openSeaMetadata.description}
            </p>
            {mintItem.contract.openSeaMetadata.floorPrice !== undefined && (
              <p
                className="WalletItemInnerFloorPrice"
                style={{ color: globalTheme.figmaPrimaryText.color }}
              >
                <b>Floor price: </b>
                {mintItem.contract.openSeaMetadata.floorPrice} ETH
              </p>
            )}
          </>
          <div className="WalletItemLinks">
            {mintItem.contract.openSeaMetadata.twitterUsername !==
              undefined && (
              <a
                href={`https://twitter.com/${mintItem.contract.openSeaMetadata.twitterUsername}`}
                target="_blank"
                rel="noopener noreferrer"
                className="WalletItemLinksSvg"
              >
                <XLogo />
              </a>
            )}
            {mintItem.contract.openSeaMetadata.discordUrl !== undefined && (
              <a
                href={mintItem.contract.openSeaMetadata.discordUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="WalletItemLinksSvg"
              >
                <DiscordLogo />
              </a>
            )}
            {mintItem.contract.address !== undefined &&
              mintItem.tokenId !== undefined && (
                <a
                  href={`https://opensea.io/assets/${mintItem.contract.address}/${mintItem.tokenId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="WalletItemLinksSvg"
                >
                  <OpenseaLogo />
                </a>
              )}
          </div>
          {mintItem.contract.openSeaMetadata.externalUrl !== undefined && (
            <a
              href={mintItem.contract.openSeaMetadata.externalUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="WalletItemLinksText"
            >
              {`${removeUrlPrefix(mintItem.contract.openSeaMetadata.externalUrl)
                .charAt(0)
                .toUpperCase()}${removeUrlPrefix(
                mintItem.contract.openSeaMetadata.externalUrl
              ).slice(1)}`}
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
