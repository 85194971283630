import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import styles from "./news.module.css";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../../components/firebase/functions";
import { useMediumState, useThemeState } from "../../../../components/utils/globalStates";
import MediumItem from "./MediumItem";

moment().format();

interface ListingDialogProps {
  activeListing: string;
}
let isFetching = false;

export default function MediumList({ activeListing }: ListingDialogProps) {
  const { listings, setListings, isFetched, setIsFetched } = useMediumState();
  const [loading, setLoading] = useState(false);
  const globalTheme = useThemeState((state) => state.globalTheme);

  useEffect(() => {
    const fetchListings = async () => {
      if (isFetching) return;
      if (activeListing === "") return;
      if (isFetched) return;
      try {
        setLoading(true);
        isFetching = true;
        
        const getFeed = httpsCallable(firebaseFunctions, "rss-medium");
        const res: any = await getFeed({ name: activeListing });
        let feedJson = res.data;
        let rssArray: any[] = [];
        feedJson.items.forEach((item: any) => {
          if (
            item.creator != null &&
            item.creator !== undefined &&
            item.creator !== "" &&
            item.title != null &&
            item.title !== undefined &&
            item.title !== "" &&
            item.link != null &&
            item.link !== undefined &&
            item.link !== "" &&
            item.isoDate != null &&
            item.isoDate !== undefined &&
            item.isoDate !== "" &&
            item["content:encoded"] != null &&
            item["content:encoded"] !== undefined &&
            item["content:encoded"] !== "" &&
            item["content:encodedSnippet"] != null &&
            item["content:encodedSnippet"] !== undefined &&
            item["content:encodedSnippet"] !== ""
          ) {
            let tempTime = new Date(item.isoDate);
            let tempRss = {
              provider: "Medium",
              title: item.title,
              link: item.link,
              timestamp: tempTime.getTime(),
              content: item["content:encodedSnippet"],
              author: item.creator,
              snippet: item["content:encoded"],
              img: feedJson.image.url !== undefined ? feedJson.image.url : "",
            };
            rssArray.push(tempRss);
          }
        });
        rssArray.sort((a, b) => b.date - a.date);
        setListings(rssArray);
        setIsFetched(true);
        isFetching = false;
        setLoading(false);
      } catch (error) {
        console.error(error);
        isFetching = false;
        setIsFetched(true);
        setLoading(false);
      }
    };
    fetchListings();
  }, [activeListing]);

  return !loading ? (
    listings.length > 0 ? (
      <div className={styles.newsListingsOuter}>
        {listings.map((listing: any, index: number) => {
          return (
            <div className={styles.outsideKeyListing} key={index}>
              <MediumItem listing={listing}/>
            </div>
          );
        })}
      </div>
    ) : (
      <div className={styles.loadingDialog}>
        <p className={styles.newsListingsText} style={{ color: globalTheme.backgroundMainOffBeige.color }}>No Medium articles found.</p>
      </div>
    )
  ) : (
    <div className={styles.loadingDialog}>
      <CircularProgress color="inherit" sx={{ color: globalTheme.backgroundMainOffBeige.color }}/>
    </div>
  );
}
