import React, { useState, useEffect } from "react";
import {
  useSnackbarErrorState,
  useHeaderSettingsState,
  useTabSettingsState,
  useDeviceState,
  useTokensListState,
  useThemeState,
} from "../../../components/utils/globalStates";
import useWebSocket from "react-use-websocket";
import { coinGeckoKey } from "../../../.config/config";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Skeleton } from "@mui/material";
import TokensInfo from "../../../components/ui/topBoxes/TokensInfo";
import { Helmet } from "react-helmet-async";
import TokensListItem from "./TokensListItem";
import "./TokensList.css";

export default function TokensList() {
  const headerSettingsState = useHeaderSettingsState();
  const tabSettingsState = useTabSettingsState();
  const snackbarErrorState = useSnackbarErrorState();
  const deviceState = useDeviceState();
  const tokensListState = useTokensListState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [loading, setLoading] = useState<boolean>(true);

  const socketUrl = "wss://ws.coincap.io/prices?assets=ALL";

  const { lastJsonMessage, readyState, getWebSocket }: any = useWebSocket(
    socketUrl,
    {
      onOpen: () => console.log("opened"),
      //Will attempt to reconnect on all close events, such as server shutting down
      shouldReconnect: (closeEvent) => true,
    }
  );

  /*const [webSocket, setWebSocket] = useState(
    new WebSocket("wss://ws.coincap.io/prices?assets=ALL")
  );*/

  useEffect(() => {
    if (loading) {
      return;
    }
    const dataToArr: any[] = Object.entries(lastJsonMessage);
    let tempArr = [...tokensListState.tokenList];
    for (let i = 0; i < dataToArr.length; i++) {
      //Check if the token exists in the array and update the price and the changePercent24Hr
      let index = tempArr.findIndex((x: any) => x.id === dataToArr[i][0]);
      if (
        index > -1 &&
        Number(tempArr[index].current_price) / Number(dataToArr[i][1]) > 0.7 &&
        Number(tempArr[index].current_price) / Number(dataToArr[i][1]) < 1.3
      ) {
        tempArr[index].current_price = dataToArr[i][1];
        tempArr[index].price_change_percentage_24h = percIncrease(
          tempArr[index].price24hAgo,
          dataToArr[i][1]
        );
        tempArr[index].price_change_percentage_1h_in_currency = percIncrease(
          tempArr[index].price1hAgo,
          dataToArr[i][1]
        );
        tempArr[index].price_change_percentage_7d_in_currency = percIncrease(
          tempArr[index].price7dAgo,
          dataToArr[i][1]
        );
        tempArr[index].price_change_percentage_30d_in_currency = percIncrease(
          tempArr[index].price30dAgo,
          dataToArr[i][1]
        );
        tempArr[index].price_change_percentage_1y_in_currency = percIncrease(
          tempArr[index].price1yAgo,
          dataToArr[i][1]
        );
      }
    }

    tokensListState.setTokenList(tempArr);
  }, [lastJsonMessage]);

  function percIncrease(a: number, b: number) {
    let percent;
    if (b !== 0) {
      if (a !== 0) {
        percent = ((b - a) / a) * 100;
      } else {
        percent = b * 100;
      }
    } else {
      percent = -a * 100;
    }
    return percent.toFixed(2);
  }

  useEffect(() => {
    if (loading === false) {
      return;
    }
    if (tokensListState.tokens.length > 0) {
      console.log("using tokens from context");
      tokensListState.setTokensTotalSupply(tokensListState.tokens.length);
      return;
    }
    const options = {
      method: "GET",
      headers: { "x-cg-demo-api-key": coinGeckoKey },
    };
    fetch(`https://api.coingecko.com/api/v3/coins/list`, options)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        tokensListState.setTokensTotalSupply(data.length);
        tokensListState.setTokens(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (
      tokensListState.tokenList.length > 0 &&
      tokensListState.tokenListPage === tokensListState.tokenPage
    ) {
      console.log("using tokens from context");

      setLoading(false);
      return;
    }
    console.log("fetching data");
    const options = {
      method: "GET",
      headers: { "x-cg-demo-api-key": coinGeckoKey },
    };
    fetch(
      `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&per_page=${tokensListState.pageSize}&page=${tokensListState.tokenPage}&sparkline=true&price_change_percentage=1h%2C24h%2C7d%2C30d%2C1y`,
      options
    )
      .then((res) => res.json())
      .then((data) => {
        const tempArr = [];
        for (let i = 0; i < data.length; i++) {
          tempArr.push({
            ...data[i],
            price1hAgo:
              (100 /
                (100 +
                  Number(data[i].price_change_percentage_1h_in_currency))) *
              Number(data[i].current_price),
            price24hAgo:
              (100 / (100 + Number(data[i].price_change_percentage_24h))) *
              Number(data[i].current_price),
            price7dAgo:
              (100 /
                (100 +
                  Number(data[i].price_change_percentage_7d_in_currency))) *
              Number(data[i].current_price),
            price30dAgo:
              (100 /
                (100 +
                  Number(data[i].price_change_percentage_30d_in_currency))) *
              Number(data[i].current_price),
            price1yAgo:
              (100 /
                (100 +
                  Number(data[i].price_change_percentage_1y_in_currency))) *
              Number(data[i].current_price),
          });
        }
        console.log(tempArr);
        tokensListState.setTokenList(tempArr);
        tokensListState.setTokenListPage(tokensListState.tokenPage);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        snackbarErrorState.setMessage("Error fetching data");
        snackbarErrorState.setOpenLength(4000);
        snackbarErrorState.setIsOpen(true);
        tokensListState.setTokenPage(tokensListState.tokenListPage);
        setLoading(false);
      });
  }, [tokensListState.tokenPage, tokensListState.pageSize]);

  /*useEffect(() => {
    if (loading) {
      return;
    }
    webSocket.onopen = (event) => {
      console.log("WebSocket is open now.");
    };

    webSocket.onmessage = function (event) {
      const data = JSON.parse(event.data);
      // Extract key and value to id and price
      const dataToArr: any[] = Object.entries(data);
      let tempArr = [...tokenArray];
      for (let i = 0; i < dataToArr.length; i++) {
        //Check if the token exists in the array and update the price and the changePercent24Hr
        let index = tempArr.findIndex((x: any) => x.id === dataToArr[i][0]);
        if (index > -1) {
          tempArr[index].current_price = dataToArr[i][1];
          tempArr[index].price_change_percentage_24h = percIncrease(tempArr[index].price24hAgo, dataToArr[i][1]);
          tempArr[index].price_change_percentage_1h_in_currency = percIncrease(tempArr[index].price1hAgo, dataToArr[i][1]);
          tempArr[index].price_change_percentage_7d_in_currency = percIncrease(tempArr[index].price7dAgo, dataToArr[i][1]);
          tempArr[index].price_change_percentage_30d_in_currency = percIncrease(tempArr[index].price30dAgo, dataToArr[i][1]);
          tempArr[index].price_change_percentage_1y_in_currency = percIncrease(tempArr[index].price1yAgo, dataToArr[i][1]);
        }
      }

      setTokenArray(tempArr);
    };

    return () => {
      webSocket.close();
    };
  }, [webSocket, loading]);*/

  useEffect(() => {
    headerSettingsState.setIsLogoOpen(true);
    headerSettingsState.setLogoBGColor(globalTheme.dashboardBackground.color);
    headerSettingsState.setLogoColor(globalTheme.backgroundMainOffYellow.color);
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.dashboardBackground.color);
    return () => {
      headerSettingsState.setIsLogoOpen(false);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  const handleChangePage = (event: unknown, newPage: number) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    tokensListState.setTokenPage(newPage + 1);
  };

  const styles = {
    tableCell: {
      color: globalTheme.textMainBlack.color,
      fontSize: "8px",
      fontWeight: "bold",
      padding: "6px",
      paddingTop: "20px",
      "@media (min-width: 350px)": {
        fontSize: "9px",
      },
      "@media (min-width: 550px)": {
        fontSize: "10px",
      },
      "@media (min-width: 700px)": {
        fontSize: "11px",
      },
    },
    tableCellBody: {
      color: globalTheme.textMainBlack.color,
      fontSize: "9px",
      fontWeight: "400",
      padding: "6px",
      paddingTop: "15px",
      paddingBottom: "15px",
      "@media (min-width: 350px)": {
        fontSize: "10px",
      },
      "@media (min-width: 550px)": {
        fontSize: "11px",
      },
      "@media (min-width: 700px)": {
        fontSize: "12px",
      },
    },
    tableCellButton: {
      color: globalTheme.textMainGrey.color,
      paddingLeft: "0px",
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingRight: "0px",
    },
    tableContainer: {
      backgroundColor: globalTheme.boxBackgroundWhite.color,
      border: `1px solid ${globalTheme.textMainBlack.color}`,
      width: "90%",
      maxWidth: "800px",
      borderRadius: "8px",
      boxShadow: globalTheme.boxShadow,
      [`&.${tableCellClasses.head}`]: {
        fontSize: "26px",
      },
    },
  };

  return (
    <div
      className="TokensList"
      style={{ backgroundColor: globalTheme.dashboardBackground.color }}
    >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Tokens</title>
      </Helmet>
      <TokensInfo />
      <TableContainer component={Paper} sx={styles.tableContainer}>
        {loading ? (
          [...new Array(30)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              height="40px"
              sx={{
                borderRadius: "8px",
                marginLeft: "15px",
                width: "calc(100% - 30px)",
                marginBottom: "15px",
                marginTop: "25px",
              }}
            />
          ))
        ) : (
          <Table
            sx={{ width: "calc(100% - 30px)", marginLeft: "15px" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableCell}>#</TableCell>
                <TableCell sx={styles.tableCell}></TableCell>
                <TableCell sx={styles.tableCell}>Name</TableCell>
                <TableCell sx={styles.tableCell} align="right">
                  Price
                </TableCell>
                {deviceState.deviceWidth > 500 && (
                  <TableCell sx={styles.tableCell} align="right">
                    1h %
                  </TableCell>
                )}
                <TableCell sx={styles.tableCell} align="right">
                  24h %
                </TableCell>
                {deviceState.deviceWidth > 500 && (
                  <TableCell sx={styles.tableCell} align="right">
                    7d %
                  </TableCell>
                )}
                {deviceState.deviceWidth > 600 && (
                  <TableCell sx={styles.tableCell} align="right">
                    30d %
                  </TableCell>
                )}
                {deviceState.deviceWidth > 800 && (
                  <TableCell sx={styles.tableCell} align="right">
                    1y %
                  </TableCell>
                )}
                <TableCell align="right" sx={styles.tableCell}>
                  <InfoOutlinedIcon
                    sx={{
                      fontSize: "14px",
                      marginRight: "5px",
                      marginBottom: "-3px",
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tokensListState.tokenList.map((row: any, index: number) => (
                <TokensListItem
                  key={row.name}
                  row={row}
                  screenWidth={deviceState.deviceWidth}
                />
              ))}
            </TableBody>
          </Table>
        )}
        <TablePagination
          sx={{ color: globalTheme.figmaPrimaryText.color, marginRight: "5px" }}
          rowsPerPageOptions={[]}
          component="div"
          count={tokensListState.tokensTotalSupply}
          rowsPerPage={tokensListState.pageSize}
          page={tokensListState.tokenPage - 1}
          onPageChange={handleChangePage}
        />
      </TableContainer>
    </div>
  );
}
