import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../../components/firebase/auth";

import PfpCreatorInfo from "../../../../components/ui/topBoxes/PfpCreatorInfo";
import PfpCreator from "./PfpCreator";
import {
  useHeaderSettingsState,
  useTabSettingsState,
  useThemeState,
} from "../../../../components/utils/globalStates";
import "./ProfileStartComp.css";

export default function ProfileAddImage() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const navigate = useNavigate();
  const headerSettingsState = useHeaderSettingsState((state) => state);
  const tabSettingsState = useTabSettingsState((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);

  useEffect(() => {
    headerSettingsState.setIsProfileButtonOpen(true);
    tabSettingsState.setColor(globalTheme.figmaBeigeDark.color);
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.backgroundMainBeige.color);
    return () => {
      headerSettingsState.setIsProfileButtonOpen(false);
      tabSettingsState.setColor(globalTheme.backgroundMainYellow.color);
      tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      headerSettingsState.setIsLogoOpen(false);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  return (
    <div
      className="ProfileAddImage"
      style={{ backgroundColor: globalTheme.backgroundMainBeige.color, paddingTop: "80px" }}
    >
      <PfpCreatorInfo />
      <PfpCreator />
    </div>
  );
}
