import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import ReviewListItemPreview from "../../../../components/ui/listItems/ReviewListItemPreview";
import moment from "moment";
import {
  onSnapshot,
  query,
  collection,
  where,
  limit,
  orderBy,
} from "firebase/firestore";
import { firestoreDb } from "../../../../components/firebase/firestore";

import { Skeleton } from "@mui/material";
import { useThemeState } from "../../../../components/utils/globalStates";
import "./UserPageComp.css";

moment().format();

export default function UserPageV2ReviewsPreview({
  userData,
}: {
  userData: any;
}) {
  const navigate = useNavigate();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [isLoading, setIsLoading] = useState(true);
  const [reviews, setReviews] = useState<any[]>([]);

  useEffect(() => {
    if (
      userData === null ||
      userData.id === null ||
      userData.id === undefined
    ) {
      return;
    }
    setIsLoading(true);
    const reviewsQ = query(
      collection(firestoreDb, "reviews"),
      where("uid", "==", userData.id),
      where("review", "==", true),
      orderBy("createdAt", "desc"),
      limit(4)
    );
    const unsubscribe = onSnapshot(
      reviewsQ,
      (snapshot) => {
        const reviewsData: any[] = [];
        snapshot.forEach((doc) => {
          reviewsData.push(doc.data());
        });
        setReviews(reviewsData);
        setIsLoading(false);
      },
      (error) => {
        console.error(error);
        setIsLoading(false);
      }
    );
    return () => unsubscribe();
  }, [userData]);
  return (
    <div className="UserPageV2ReviewsPreviewOuter">
      <p
        className="UserPageV2ReviewsPreviewTitle"
        style={{ color: globalTheme.textMainBlack.color }}
      >
        Recent Reviews
      </p>
      {isLoading ? (
        <div className="UserPageV2ReviewsPreviewInner">
          <Skeleton
            variant="rectangular"
            sx={{
              width: "calc(25% - 20px)",
              height: "100px",
              borderRadius: "8px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              width: "calc(25% - 20px)",
              height: "100px",
              borderRadius: "8px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              width: "calc(25% - 20px)",
              height: "100px",
              borderRadius: "8px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              width: "calc(25% - 20px)",
              height: "100px",
              borderRadius: "8px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
        </div>
      ) : reviews.length > 0 ? (
        <div className="UserPageV2ReviewsPreviewInner">
          {reviews.map((review, index) => (
            <ReviewListItemPreview
              key={index}
              listItem={review}
              isListing={true}
            />
          ))}
          {reviews.length > 3 && (
            <p
              className="UserPageV2ReviewsPreviewInnerLink"
              onClick={() => navigate(`/reviews/user/${userData.id}`)}
              style={{ color: globalTheme.links.color }}
            >
              See all reviews from {userData.displayName}
            </p>
          )}
        </div>
      ) : (
        <div className="UserPageV2ReviewsPreviewInner">
          <p
            className="UserPageV2ReviewsPreviewInnerLink"
            style={{
              color: globalTheme.textMainBlack.color,
              textDecoration: "none",
              textAlign: "center",
              fontWeight: '400'
            }}
          >
            {userData.displayName} has not submitted any reviews yet
          </p>
        </div>
      )}
    </div>
  );
}
