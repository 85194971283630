import React, { useEffect, useState } from "react";

import {
  query,
  deleteDoc,
  limit,
  collection,
  onSnapshot,
  doc,
} from "firebase/firestore";

import { CircularProgress, IconButton } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DensitySmallOutlinedIcon from "@mui/icons-material/DensitySmallOutlined";
import DensityLargeOutlinedIcon from "@mui/icons-material/DensityLargeOutlined";

import { firestoreDb } from "../../components/firebase/firestore";
import { Waypoint } from "react-waypoint";
import AdminAddFeaturedNews from "./components/AdminAddFeaturedNews";
import { useThemeState } from "../../components/utils/globalStates";
import { figmaColorVariables } from "../../components/theme/globalTheme";
import "./Admin.css";

const count = 12;

export default function AdminFeaturedNews() {
  const [newsArticles, setNewsArticles] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [lastVisible, setLastVisible] = useState(count);
  const [isPreviewLarge, setIsPreviewLarge] = useState(true);
  const [lastCount, setLastCount] = useState(0);
  const [highlightedArticle, setHighlightedArticle] = useState("");
  const globalTheme = useThemeState((state) => state.globalTheme);

  const handleEndReached = () => {
    if (lastVisible !== lastCount) {
      return;
    }
    if (lastVisible === lastCount && newsArticles.length < lastVisible) {
      return;
    }
    if (isFetchingMore === true) {
      return;
    }
    if (isLoading === true) {
      return;
    }
    setIsFetchingMore(true);
    setLastVisible(lastVisible + count);
  };

  useEffect(() => {
    let q = query(collection(firestoreDb, "featuredNews"), limit(lastVisible));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (lastVisible === count) {
        setIsLoading(true);
      }
      setIsFetchingMore(true);
      let tempNews: any = [];
      querySnapshot.forEach((doc) => {
        let tempObj = doc.data();
        tempNews.push({ ...tempObj, id: doc.id });
      });
      setNewsArticles(tempNews);
      setLastCount(lastCount + count);
      setIsFetchingMore(false);
      setIsLoading(false);
    });

    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe();
  }, [lastVisible]);

  const onDeleteNews = async (id: string) => {
    await deleteDoc(doc(firestoreDb, "featuredNews", id));
  };

  const styles = {};

  return (
    <div className="AdminInner">
      <p
        className="AdminDescription"
        style={{
          ...globalTheme.backgroundMainOffBeige,
          width: "90%",
          maxWidth: "1200px",
        }}
      >
        Add Medium & Substack articles to be featured on the Explore page and
        Landing page.
      </p>
      <div className="AdminExampleImagesOuter">
        <img
          style={{ width: "38.5%", height: "auto" }}
          src="https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Fadmin%2Fadmin-example1.png?alt=media"
          alt="featured news example"
        />
        <img
          style={{ width: "60%", height: "auto" }}
          src="https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Fadmin%2Fadmin-example2.png?alt=media"
          alt="featured news example"
        />
      </div>
      <p
        className="AdminDescription"
        style={{
          ...globalTheme.backgroundMainOffBeige,
          width: "90%",
          maxWidth: "1200px",
          oveflow: "hidden",
          wordWrap: "break-word",
        }}
      >
        <span style={{ fontSize: "80%" }}>
          Due to limitations only the last 20 posts on Substack from a specific
          author and last 10 from Medium can be fetched so make sure the linked
          article is relatively new.
        </span>
        <br />
        <span style={{ fontSize: "70%" }}>
          <b>Medium URL example: </b>https://medium.com/username/post-title
        </span>
        <br />
        <span style={{ fontSize: "70%" }}>
          <b>Substack URL example: </b>
          'https://username.substack.com/p/post-title
        </span>
      </p>
      <div className="AdminExampleImagesOuter">
        <AdminAddFeaturedNews />
      </div>
      {isLoading ? (
        <div
          className="AdminListingsLoading"
          style={{ ...globalTheme.backgroundMainOffBeige }}
        >
          <CircularProgress size={30} color="inherit" />
        </div>
      ) : newsArticles.length > 0 ? (
        <div
          className="AdminListingsOuter"
          style={{ width: "90%", alignItems: "stretch" }}
        >
          <div
            className="AdminArticlePreviewButtons"
            style={{ width: "100%", marginBottom: "10px" }}
          >
            <ToggleButtonGroup
              size="small"
              value={isPreviewLarge}
              exclusive
              onChange={(event, newAlignment) => {
                if (newAlignment !== null) {
                  setIsPreviewLarge(newAlignment);
                }
              }}
            >
              <ToggleButton size="small" value={true}>
                <DensityLargeOutlinedIcon />
              </ToggleButton>
              <ToggleButton size="small" value={false}>
                <DensitySmallOutlinedIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {newsArticles.map((article: any, index: number) => {
            return isPreviewLarge ? (
              <div className="AdminNewsItemOuter" key={index}>
                <div
                  className="AdminArticlePreviewLarge"
                  onClick={() => {
                    if (highlightedArticle !== article.id) {
                      setHighlightedArticle(article.id)
                    }
                }}
                  style={{
                    border: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
                    color: globalTheme.primaryText.color,
                    backgroundColor: globalTheme.primarySurface.color,
                    width: "100%",
                    height: "calc(100% - 40px)",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <div className="AdminArticlePreviewLargeTextOuter">
                    <p className="AdminArticlePreviewLargeSub">
                      {article.title}
                    </p>
                    <b
                      className="AdminArticlePreviewLargeText"
                      style={{ marginTop: "5px", overflow: "visible" }}
                    >
                      {article.sub}
                    </b>
                    <p className="AdminArticlePreviewLargeText">
                      {article.snippet}
                    </p>
                    <a
                      href={article.link}
                      target="_blank"
                      rel="noreferrer"
                      className="AdminArticlePreviewLargeText"
                      style={{
                        color: figmaColorVariables.blue300,
                        marginTop: "0px",
                        marginBottom: "5px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      By {article.creator} @ {article.type} {">"}
                    </a>

                    <div style={{ flex: 1 }}></div>
                  </div>
                  <div className="AdminArticlePreviewLargeImgOuter">
                    <img
                      src={article.img}
                      alt="Article"
                      className="AdminArticlePreviewLargeImg"
                    />
                  </div>
                  {highlightedArticle === article.id && (
                    <div
                      className="AdminArticlePreviewOverlay"
                      style={{
                        backgroundColor: globalTheme.primarySurface.color,
                        border: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
                      }}
                    >
                      <IconButton onClick={() => setHighlightedArticle("")}>
                        <CancelOutlinedIcon
                          sx={{
                            color: globalTheme.primaryText.color,
                            fontSize: "35px",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => onDeleteNews(article.id)}
                        sx={{
                          marginLeft: "20px",
                        }}
                      >
                        <DeleteOutlinedIcon
                          sx={{
                            color: figmaColorVariables.red300,
                            fontSize: "40px",
                          }}
                        />
                      </IconButton>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div
                className="AdminListItemSmallOuter"
                key={index}
                style={{
                  paddingBottom: "20px",
                }}
              >
                <div
                  className="AdminArticlePreviewSmall"
                  onClick={() => {
                    if (highlightedArticle !== article.id) {
                      setHighlightedArticle(article.id)
                    }
                }}
                  style={{
                    height: "100%",
                    maxWidth: "unset",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="AdminArticlePreviewSmallButton"
                    style={{
                      border: `1px solid ${figmaColorVariables.grey800}`,
                      color: figmaColorVariables.grey800,
                      backgroundColor: figmaColorVariables.orange300,
                      ...globalTheme.boxShadow,
                    }}
                  >
                    {article.project_name}
                  </div>
                  <div
                    className="AdminArticlePreviewSmallInner"
                    style={{
                      border: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
                      color: globalTheme.primaryText.color,
                      backgroundColor: globalTheme.primarySurface.color,
                      flex: 1,
                    }}
                  >
                    <div className="AdminArticlePreviewSmallImgOuter">
                      <img
                        src={article.img}
                        alt="Article"
                        className="AdminArticlePreviewSmallImg"
                      />
                    </div>
                    <div className="AdminArticlePreviewSmallTextOuter">
                      <p className="AdminArticlePreviewSmallSub">
                        {article.title}
                      </p>
                      <b
                        className="AdminArticlePreviewSmallText"
                        style={{ marginTop: "5px" }}
                      >
                        {article.sub}
                      </b>
                      <p className="AdminArticlePreviewSmallText">
                        {article.snippet}
                      </p>
                      <div style={{ flex: 1 }}></div>
                      <a
                        href={article.link}
                        target="_blank"
                        rel="noreferrer"
                        className="AdminArticlePreviewSmallText"
                        style={{
                          color: figmaColorVariables.blue300,
                          marginTop: "0px",
                          marginBottom: "5px",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        By {article.creator} @ {article.type} {">"}
                      </a>
                    </div>
                  </div>
                  {highlightedArticle === article.id && (
                    <div
                      className="AdminArticlePreviewOverlay"
                      style={{
                        backgroundColor: globalTheme.primarySurface.color,
                        border: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
                      }}
                    >
                      <IconButton onClick={() => setHighlightedArticle("")}>
                        <CancelOutlinedIcon
                          sx={{
                            color: globalTheme.primaryText.color,
                            fontSize: "35px",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => onDeleteNews(article.id)}
                        sx={{
                          marginLeft: "20px",
                        }}
                      >
                        <DeleteOutlinedIcon
                          sx={{
                            color: figmaColorVariables.red300,
                            fontSize: "40px",
                          }}
                        />
                      </IconButton>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          <div className="LoadMoreOuter">
            {newsArticles.length > 0 && (
              <Waypoint scrollableAncestor={window} onEnter={handleEndReached}>
                {isFetchingMore ? (
                  <div
                    className="LoadMoreOuter"
                    style={{ ...globalTheme.backgroundMainOffBeige }}
                  >
                    <CircularProgress size={30} color="inherit" />
                  </div>
                ) : (
                  <div className="LoadMoreOuter">
                    <p
                      className="AdminDescription"
                      style={{ ...globalTheme.backgroundMainOffBeige }}
                    ></p>
                  </div>
                )}
              </Waypoint>
            )}
          </div>
        </div>
      ) : (
        <div className="AdminListingsLoading">
          <p
            className="AdminDescription"
            style={{ ...globalTheme.backgroundMainOffBeige }}
          >
            No featured news articles found
          </p>
        </div>
      )}
    </div>
  );
}
