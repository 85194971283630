import React, { useState, useEffect } from "react";
import { ButtonBase } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ListIcon from "@mui/icons-material/List";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import { IconButton, InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Resizer from "react-image-file-resizer";
import Autocomplete from "@mui/material/Autocomplete";
import { algoliaListingsRelevance } from "../../../components/search/algoliaSearch";
import {
  useSnackbarErrorState,
  useSnackbarState,
  useAuthStates,
  useThemeState,
} from "../../../components/utils/globalStates";
import DOMPurify from "dompurify";
import {
  isValidUrl,
  isValidXHandle,
  isValidInstagramHandle,
  isValidTelegramHandle,
  isValidMediumHandle,
  isValidFacebookUrl,
} from "../../../components/utils/parsers";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../components/firebase/functions";
import { categoryEnums } from "../../../components/utils/enums";
import "./NewListing.css";

export default function NewListingBox({
  bgColorProps,
  alternativeDisplay,
}: {
  bgColorProps: string;
  alternativeDisplay?: boolean;
}) {
  const [user] = useAuthState(firebaseAuth);

  const snackbarStates = useSnackbarState((state) => state);
  const snackbarError = useSnackbarErrorState((state) => state);
  const authStates = useAuthStates((state) => state);
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [isExpanded, setIsExpanded] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectNameSub, setProjectNameSub] = useState("");
  const [projectNameVerified, setProjectNameVerified] = useState(false);

  const [results, setResults] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const [projectDescription, setProjectDescription] = useState("");
  const [projectDescriptionSub, setProjectDescriptionSub] = useState("");
  const [projectDescriptionVerified, setProjectDescriptionVerified] =
    useState(false);

  const [projectWebsite, setProjectWebsite] = useState("");
  const [projectWebsiteSub, setProjectWebsiteSub] = useState("");
  const [projectWebsiteVerified, setProjectWebsiteVerified] = useState(false);

  const [projectTags, setProjectTags] = useState("");
  const [projectTagsSub, setProjectTagsSub] = useState("");
  const [projectTagsArray, setProjectTagsArray] = useState<string[]>([]);

  const [xHandle, setXHandle] = useState("");
  const [xHandleSub, setXHandleSub] = useState("");
  const [xHandleVerified, setXHandleVerified] = useState(false);

  const [instagramHandle, setInstagramHandle] = useState("");
  const [instagramHandleSub, setInstagramHandleSub] = useState("");
  const [instagramHandleVerified, setInstagramHandleVerified] = useState(false);

  const [telegramHandle, setTelegramHandle] = useState("");
  const [telegramHandleSub, setTelegramHandleSub] = useState("");
  const [telegramHandleVerified, setTelegramHandleVerified] = useState(false);

  const [substackHandle, setSubstackHandle] = useState("");
  const [substackHandleSub, setSubstackHandleSub] = useState("");
  const [substackHandleVerified, setSubstackHandleVerified] = useState(false);

  const [mediumHandle, setMediumHandle] = useState("");
  const [mediumHandleSub, setMediumHandleSub] = useState("");
  const [mediumHandleVerified, setMediumHandleVerified] = useState(false);

  const [facebookUrl, setFacebookUrl] = useState("");
  const [facebookUrlSub, setFacebookUrlSub] = useState("");
  const [facebookUrlVerified, setFacebookUrlVerified] = useState(false);

  const [logoUrl, setLogoUrl] = useState("");
  const [logoObj, setLogoObj] = useState<any>(null);
  const [logoUrlSub, setLogoUrlSub] = useState("");
  const [logoUrlVerified, setLogoUrlVerified] = useState(false);

  const [projectCategory, setProjectCategory] = useState("");

  const [calculatedPoints, setCalculatedPoints] = useState(0);

  const [isSending, setIsSending] = useState(false);
  const [isAiLoading, setIsAiLoading] = useState(false);

  const updateProjectName = (e: any) => {
    setProjectName(e);
    if (e.length < 3) {
      setProjectNameSub("Project name must be at least 3 characters long.");
      setProjectNameVerified(false);
    } else if (e.length > 100) {
      setProjectNameSub("Project name must be less than 100 characters long.");
      setProjectNameVerified(false);
    } else {
      setProjectNameSub("");
      setProjectNameVerified(true);
    }
  };

  const updateDescription = (e: any) => {
    setProjectDescription(e);
    if (e.length < 15) {
      setProjectDescriptionSub(
        "Project description must be at least 15 characters long."
      );
      setProjectDescriptionVerified(false);
    } else if (e.length > 2000) {
      setProjectDescriptionSub(
        "Project description must be less than 2000 characters long."
      );
      setProjectDescriptionVerified(false);
    } else {
      setProjectDescriptionSub("");
      setProjectDescriptionVerified(true);
    }
  };

  const updateWebsite = (e: any) => {
    setProjectWebsite(e);
    if (isValidUrl(e) === false) {
      setProjectWebsiteSub("Project website must be a valid URL.");
      setProjectWebsiteVerified(false);
    } else {
      setProjectWebsiteSub("");
      setProjectWebsiteVerified(true);
    }
  };

  const updateTags = (e: any) => {
    if (projectTagsArray.length > 4) {
      return setProjectTagsSub("Maximum of 5 tags allowed.");
    }
    setProjectTags(e);
    if (projectTagsArray.length < 1) {
      setProjectTagsSub("Separate tags with commas.");
    }
    if (e.includes(",")) {
      const tags = e.split(",");
      if (projectTagsArray.includes(tags[0].trim().toLowerCase())) {
        setProjectTags("");
        return setProjectTagsSub("Tag already added.");
      }
      const tagsArray = [
        ...projectTagsArray,
        ...[tags[0].trim().toLowerCase()],
      ];
      setProjectTagsArray(tagsArray);
      setProjectTags("");
    }
  };

  const updateLogoUrl = (e: any) => {
    setLogoUrl(e);
    setLogoObj(null);
    if (e === "") {
      setLogoUrlSub("");
      setLogoUrlVerified(false);
    } else if (isValidUrl(e) === false) {
      setLogoUrlSub("Must be a valid URL.");
      setLogoUrlVerified(false);
    } else {
      setLogoUrlSub("");
      setLogoUrlVerified(true);
    }
  };

  const updateLogoObj = (e: any) => {
    if (e.target.files[0].size > 1000000) {
      setLogoUrlSub("Image must be less than 1MB.");
      setLogoUrlVerified(false);
    } else {
      setLogoObj(e.target.files[0]);
      setLogoUrl(e.target.files[0].name);
      setLogoUrlSub("");
      setLogoUrlVerified(true);
    }
  };

  const updateXHandle = (e: any) => {
    setXHandle(e);
    if (e === "") {
      setXHandleSub("");
      setXHandleVerified(false);
    } else if (isValidXHandle(e) === false) {
      setXHandleSub("Not a valid X handle.");
      setXHandleVerified(false);
    } else {
      setXHandleSub("");
      setXHandleVerified(true);
    }
  };

  const updatesubstackHandle = (e: any) => {
    setSubstackHandle(e);
    if (e === "") {
      setSubstackHandleSub("");
      setSubstackHandleVerified(false);
    } else if (isValidXHandle(e) === false) {
      setSubstackHandleSub("Not a valid Substack handle.");
      setSubstackHandleVerified(false);
    } else {
      setSubstackHandleSub("");
      setSubstackHandleVerified(true);
    }
  };

  const updateMediumHandle = (e: any) => {
    setMediumHandle(e);
    if (e === "") {
      setMediumHandleSub("");
      setMediumHandleVerified(false);
    } else if (isValidMediumHandle(e) === false) {
      setMediumHandleSub("Not a valid Medium name.");
      setMediumHandleVerified(false);
    } else {
      setMediumHandleSub("");
      setMediumHandleVerified(true);
    }
  };

  const updateFacebookUrl = (e: any) => {
    setFacebookUrl(e);
    if (e === "") {
      setFacebookUrlSub("");
      setFacebookUrlVerified(false);
    } else if (isValidFacebookUrl(e) === false) {
      setFacebookUrlSub("Not a valid Facebook URL.");
      setFacebookUrlVerified(false);
    } else {
      setFacebookUrlSub("");
      setFacebookUrlVerified(true);
    }
  };

  const updateInstagramHandle = (e: any) => {
    setInstagramHandle(e);
    if (e === "") {
      setInstagramHandleSub("");
      setInstagramHandleVerified(false);
    } else if (isValidInstagramHandle(e) === false) {
      setInstagramHandleSub("Not a valid Instagram username.");
      setInstagramHandleVerified(false);
    } else {
      setInstagramHandleSub("");
      setInstagramHandleVerified(true);
    }
  };

  const updateTelegramHandle = (e: any) => {
    setTelegramHandle(e);
    if (e === "") {
      setTelegramHandleSub("");
      setTelegramHandleVerified(false);
    } else if (isValidTelegramHandle(e) === false) {
      setTelegramHandleSub("Not a valid Telegram username.");
      setTelegramHandleVerified(false);
    } else {
      setTelegramHandleSub("");
      setTelegramHandleVerified(true);
    }
  };

  const calculatePoints = () => {
    if (
      !projectNameVerified ||
      !projectDescriptionVerified ||
      !projectWebsiteVerified ||
      projectCategory === ""
    ) {
      return setCalculatedPoints(0);
    }
    let points = 0;
    if (
      projectNameVerified &&
      projectDescriptionVerified &&
      projectWebsiteVerified &&
      projectCategory !== ""
    ) {
      points += 5;
    }
    if (logoUrlVerified) {
      points += 1;
    }
    if (projectTagsArray.length > 0) {
      points += 1;
    }
    if (xHandleVerified) {
      points += 1;
    }
    if (mediumHandleVerified) {
      points += 1;
    }
    if (facebookUrlVerified) {
      points += 1;
    }
    if (instagramHandleVerified) {
      points += 1;
    }
    if (substackHandleVerified) {
      points += 1;
    }
    if (telegramHandleVerified) {
      points += 1;
    }

    if (points === 13) {
      points = 20;
    }
    setCalculatedPoints(points);
  };

  useEffect(() => {
    calculatePoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectNameVerified,
    projectDescriptionVerified,
    projectWebsiteVerified,
    xHandleVerified,
    instagramHandleVerified,
    telegramHandleVerified,
    projectCategory,
    substackHandleVerified,
    mediumHandleVerified,
    facebookUrlVerified,
    logoUrlVerified,
    projectTagsArray,
  ]);

  const onReset = () => {
    setProjectName("");
    setProjectNameSub("");
    setProjectNameVerified(false);

    setProjectDescription("");
    setProjectDescriptionSub("");
    setProjectDescriptionVerified(false);

    setProjectWebsite("");
    setProjectWebsiteSub("");
    setProjectWebsiteVerified(false);

    setLogoUrl("");
    setLogoUrlSub("");
    setLogoUrlVerified(false);
    setLogoObj(null);

    setXHandle("");
    setXHandleSub("");
    setXHandleVerified(false);

    setMediumHandle("");
    setMediumHandleSub("");
    setMediumHandleVerified(false);

    setFacebookUrl("");
    setFacebookUrlSub("");
    setFacebookUrlVerified(false);

    setInstagramHandle("");
    setInstagramHandleSub("");
    setInstagramHandleVerified(false);

    setTelegramHandle("");
    setTelegramHandleSub("");
    setTelegramHandleVerified(false);

    setSubstackHandle("");
    setSubstackHandleSub("");
    setSubstackHandleVerified(false);

    setProjectCategory("");

    setProjectTags("");
    setProjectTagsSub("");
    setProjectTagsArray([]);
  };

  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        400,
        400,
        "jpeg",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const onSend = async () => {
    if (
      !projectNameVerified ||
      !projectDescriptionVerified ||
      !projectWebsiteVerified ||
      projectCategory === ""
    ) {
      snackbarError.setMessage("Please fill in all required fields.");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }
    if (user === null || user === undefined) {
      snackbarError.setMessage("Please sign in to add project");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }

    if (xHandle !== "" && !xHandleVerified) {
      snackbarError.setMessage("Invalid X handle.");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }

    if (instagramHandle !== "" && !instagramHandleVerified) {
      snackbarError.setMessage("Invalid Instagram handle.");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }

    if (telegramHandle !== "" && !telegramHandleVerified) {
      snackbarError.setMessage("Invalid Telegram handle.");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }

    if (mediumHandle !== "" && !mediumHandleVerified) {
      snackbarError.setMessage("Invalid Medium handle.");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }

    if (facebookUrl !== "" && !facebookUrlVerified) {
      snackbarError.setMessage("Invalid Facebook URL.");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }

    if (substackHandle !== "" && !substackHandleVerified) {
      snackbarError.setMessage("Invalid Substack handle.");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }

    setIsSending(true);
    const idToken = await user.getIdToken();
    snackbarStates.setMessage("Adding new project...");
    snackbarStates.setOpenLength(null);
    snackbarStates.setIsActionLoading(true);
    snackbarStates.setIsOpen(true);
    let imageObj: any = null;
    if (logoObj !== null) {
      try {
        const file = logoObj;
        const image = await resizeFile(file);
        imageObj = image;
      } catch (err) {
        console.error(err);
      }
    }
    const data = {
      token: idToken,
      name: projectName,
      description: projectDescription,
      website: projectWebsite,
      tags: projectTagsArray,
      xHandle: xHandle.replace("@", ""),
      instagramHandle,
      telegramHandle,
      category: categoryEnums.find((cat) => cat.value === projectCategory)
        ?.displayname,
      mediumHandle: mediumHandle.replace("@", ""),
      facebookUrl,
      substackHandle: substackHandle.replace("@", ""),
      logoObj: imageObj,
      logoUrl: imageObj === null ? logoUrl : "",
      isVerified: false,
    };

    try {
      const subscribe = httpsCallable(firebaseFunctions, "listings-add_new");
      await subscribe(data);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setOpenLength(7000);
      snackbarStates.setMessage(
        `Project added successfully! Thank you for contributing to GATH3R, ${calculatedPoints} Points will be assigned to your account.`
      );

      setIsExpanded(false);
      setIsSending(false);
      onReset();
    } catch (err: any) {
      console.error(err);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("");
      snackbarError.setMessage(err.message);
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      setIsSending(false);
      setIsExpanded(true);
    }
  };

  // On AI auto fill send to backend
  const autoFillAi = async () => {
    if (projectName === "" || projectCategory === "") {
      snackbarError.setOpenLength(5000);
      snackbarError.setMessage("Name & Category are required.");
      snackbarError.setIsOpen(true);
      return;
    }

    if (user === null || user === undefined) {
      snackbarError.setMessage("Please sign in to add project");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return;
    }

    if (
      authStates.role !== "alpha" &&
      authStates.role !== "owner" &&
      authStates.role !== "admin"
    ) {
      snackbarError.setMessage(
        "This feature is only available for alpha testers and premium users."
      );
      snackbarError.setOpenLength(5000);
      snackbarError.setIsOpen(true);
      return;
    }

    setIsAiLoading(true);
    snackbarStates.setMessage("Performing Ai auto fill.");
    snackbarStates.setOpenLength(null);
    snackbarStates.setIsActionLoading(true);
    snackbarStates.setIsOpen(true);

    try {
      const token = await user?.getIdToken();
      const autofillCallable = httpsCallable(
        firebaseFunctions,
        "ai-auto_fill_v2"
      );
      const result = await autofillCallable({
        name: projectName,
        description: projectDescription,
        website: !isValidUrl(projectWebsite.toLocaleLowerCase())
          ? ""
          : projectWebsite,
        category: projectDescription,
        xHandle: xHandle,
        instagramHandle: instagramHandle,
        telegramHandle: telegramHandle,
        mediumHandle: mediumHandle,
        substackHandle: substackHandle,
        facebookUrl: facebookUrl,
        logoUrl: logoUrl,
        token: token,
        tags: projectTagsArray,
      });
      console.log(result);
      // @ts-ignore
      let resultString = result.data.choices[0].message.tool_calls[0].function.arguments;
      const parsedResult = JSON.parse(resultString);
      console.log(parsedResult);
      updateProjectName(parsedResult.name ? parsedResult.name : projectName);
      updateDescription(
        parsedResult.description ? parsedResult.description : ""
      );
      updateWebsite(parsedResult.website ? parsedResult.website : "");
      updateXHandle(parsedResult.xHandle ? parsedResult.xHandle : "");
      updateInstagramHandle(
        parsedResult.instagramHandle ? parsedResult.instagramHandle : ""
      );
      updateTelegramHandle(
        parsedResult.telegramHandle ? parsedResult.telegramHandle : ""
      );
      updateMediumHandle(
        parsedResult.mediumHandle ? parsedResult.mediumHandle : ""
      );
      updatesubstackHandle(
        parsedResult.substackHandle ? parsedResult.substackHandle : ""
      );
      updateFacebookUrl(
        parsedResult.facebookUrl ? parsedResult.facebookUrl : ""
      );
      updateLogoUrl(parsedResult.logoUrl ? parsedResult.logoUrl : "");
      setProjectTagsArray(parsedResult.tags);
      setIsAiLoading(false);
      snackbarStates.setMessage("Ai auto fill completed.");
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setOpenLength(5000);
      snackbarStates.setIsOpen(true);
    } catch (error: any) {
      console.error(error);
      setIsAiLoading(false);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("");
      if (error.message.includes("Unsuspected token")) {
        snackbarError.setMessage("No data found for Ai auto fill.");
      } else {
        snackbarError.setMessage("Error performing Ai auto fill.");
      }
      snackbarError.setOpenLength(5000);
      snackbarError.setIsOpen(true);
    }
  };

  // Replace <em> tags with <strong> tags
  const replaceEm = (str: string) => {
    return str.replace(/<em>/g, "<strong>").replace(/<\/em>/g, "</strong>");
  };

  useEffect(() => {
    let tempArray: any[] = [];
    if (projectName !== "" && projectName.length > 2) {
      setLoading(true);
      algoliaListingsRelevance
        .search(projectName)
        .then((res) => {
          for (let i = 0; i < res.hits.length; i++) {
            const tempListing = res.hits[i];
            tempArray.push({ data: tempListing, type: "query" });
          }
          if (tempArray.length > 0) {
            tempArray.unshift({
              data: "Projects listed on GATH3R.CO",
              type: "title",
            });
          }
          setResults(tempArray);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    } else {
      setResults([]);
    }
  }, [projectName]);

  const onResultClick = (id: string) => {
    console.log(id);
  };

  const styles = {
    buttonBase: {
      color: globalTheme.figmaPrimaryText.color,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      padding: "15px",
      borderRadius: "8px",
    },
    "& span": {
      fontSize: "16px",
      "@media (min-width: 350px)": {
        fontSize: "18px",
      },
      "@media (min-width: 550px)": {
        fontSize: "20px",
      },
      "@media (min-width: 700px)": {
        fontSize: "22px",
      },
    },
    buttonBaseAlt: {
      color: globalTheme.figmaPrimaryText.color,
      backgroundColor: globalTheme.solidMainYellow.color,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      height: "40px",
      borderRadius: "8px",
      border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
      fontWeight: "bold",
      marginTop: "15px",
      marginBottom: "0px",
    },
    accordion: {
      width: "100%",
      maxWidth: "800px",
      borderRadius: "0px",
      border: "none",
      boxShadow: "none",
      backgroundColor: "transparent",
      color: globalTheme.figmaPrimaryText.color,
      padding: "0px",
      margin: "0px",
      marginTop: "0px",
      marginBottom: "10px",
      borderColor: "transparent",
      "&:before": {
        display: "none",
      },
    },
    accordionSummary: {
      backgroundColor: "transparent",
      color: globalTheme.figmaPrimaryText.color,
      padding: "0px",
      margin: "0px",
      paddingBottom: "10px",
      paddingTop: "10px",
      minHeight: "0px",
      borderBottom: `1px solid ${globalTheme.figmaPrimaryText.color}`,
      "& .MuiAccordionSummary-expandIcon": {
        color: globalTheme.figmaPrimaryText.color,
      },
      "& .MuiAccordionSummary-content": {
        margin: "0px",
      },
    },
    accordionDetails: {
      backgroundColor: "transparent",
      color: globalTheme.figmaPrimaryText.color,
      padding: "0px",
      margin: "0px",
      minHeight: "0px",
      paddingTop: "20px",
    },
    accordionIcon: {
      color: globalTheme.figmaPrimaryText.color,
    },

    autoComplete: {
      transition: "transform 0.3s ease",
      width: "100%",
      "& .MuiOutlinedInput-root": {
        color: "#39393c",
        backgroundColor: globalTheme.figmaWhite.color,
        border: "none",
        "& fieldset": {
          border: "none",
          color: "#39393c",
        },
        "&:hover fieldset": {
          border: "none",
          color: "#39393c",
        },
        "&.Mui-focused fieldset": {
          border: "none",
          color: "#39393c",
        },
      },
      "& label.Mui-focused": {
        display: "none",
      },
      "& legend": { display: "none" },
      "& .MuiInputLabel-shrink": { opacity: 0, transition: "all 0.2s ease-in" },
      "& label": {
        color: "#39393c",
        fontSize: "14px",
        marginTop: "2px",
        zIndex: 1,
      },
      "& span": {
        fontSize: "10px",
      },
    },
  };

  const materialFormsStyles = {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      color: "#39393c",
      backgroundColor: globalTheme.figmaWhite.color,
      border: "none",
      "& fieldset": {
        border: "none",
        color: "#39393c",
      },
      "&:hover fieldset": {
        border: "none",
        color: "#39393c",
      },
      "&.Mui-focused fieldset": {
        border: "none",
        color: "#39393c",
      },
    },
    "& label.Mui-focused": {
      display: "none",
    },
    "& legend": { display: "none" },
    "& .MuiInputLabel-shrink": { opacity: 0, transition: "all 0.2s ease-in" },
    "& label": {
      color: "#39393c",
      fontSize: "14px",
      marginTop: "2px",
      zIndex: 1,
    },
    "& span": {
      fontSize: "10px",
    },
  };

  const buttonStyles = {
    color: "#1A1A1A",
    backgroundColor: "#FFED8F",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    boxShadow: globalTheme.boxShadow,
    border: "1px solid #1A1A1A",
    flex: 1,
    margin: "5px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
    },
  };

  return (
    <div
      className="NewListingBox"
      style={{
        backgroundColor: bgColorProps,
        color: globalTheme.figmaPrimaryText.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        ...globalTheme.boxShadow,
      }}
    >
      {alternativeDisplay === true ? (
        <ButtonBase
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
          sx={{ ...styles.buttonBase, height: "40px" }}
        >
          <div className="NewListingBoxSpanAlt">
            <span className="material-symbols-outlined">contract_edit</span>
          </div>
          <div>
            <p className="NewListingBoxOuterTextNew">Add new listing</p>
          </div>
        </ButtonBase>
      ) : (
        <ButtonBase
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
          sx={styles.buttonBase}
        >
          <div className="NewListingBoxSpan">
            <span className="material-symbols-outlined">list_alt_add</span>
          </div>
          <div>
            <p className="NewListingBoxOuterText">ADD A PROJECT TO GATH3R</p>
            <p className="NewListingBoxOuterSub">
              Every contribution to the GATH3R rewards you with Points.
            </p>
          </div>
        </ButtonBase>
      )}
      <div
        className="NewListingBoxInner"
        style={{
          maxHeight: isExpanded ? "2000px" : "0px",
          opacity: isExpanded ? 1 : 0,
        }}
      >
        <div className="preListProject">
          <div className="preListProjectTop">
            <p className="preListProjectTopText">
              Fill in the form below to list a project on GATH3R.
            </p>
            <div className="preListProjectTopLast">
              <TextField
                placeholder="Category"
                label="Category"
                variant="outlined"
                size="small"
                sx={materialFormsStyles}
                value={projectCategory}
                onChange={(e) => setProjectCategory(e.target.value)}
                select
                disabled={isSending}
              >
                {categoryEnums.map((catEnum) => (
                  <MenuItem key={catEnum.value} value={catEnum.value}>
                    {catEnum.displayname}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <div className="preListProjectInner">
            <div className="formOuter">
              <Autocomplete
                freeSolo
                disableClearable
                fullWidth
                sx={{
                  ...styles.autoComplete,
                }}
                size="small"
                filterOptions={(x) => x}
                loading={loading}
                getOptionLabel={(option: any) =>
                  option.type === "title" ? option.data : option.data.name
                }
                renderOption={(props, option: any) => {
                  return (
                    results.length > 0 &&
                    (option.type === "title" ? (
                      <p
                        key={option.data}
                        className="AutocompleteResultHeaderNewListing"
                      >
                        {" "}
                        {option.data}
                      </p>
                    ) : (
                      <div
                        key={option.data.id}
                        onClick={() => onResultClick(option.data.id)}
                        className="AutocompleteResultOuterNewListing"
                      >
                        <p
                          className="AutocompleteResultTitleNewListing"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              replaceEm(
                                option.data._highlightResult.name.value
                              ),
                              {
                                FORBID_ATTR: ["style"],
                                FORBID_TAGS: ["style", "a"],
                              }
                            ),
                          }}
                        ></p>
                      </div>
                    ))
                  );
                }}
                options={results}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Project Name"
                    variant="outlined"
                    size="small"
                    placeholder="GATH3R.CO"
                    value={projectName}
                    onChange={(e) => updateProjectName(e.target.value)}
                    disabled={isSending}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={"20px"} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              <p className="formDescription">{projectNameSub}</p>
            </div>
            <div className="formOuter">
              <TextField
                label="Project Description"
                variant="outlined"
                size="small"
                sx={materialFormsStyles}
                value={projectDescription}
                multiline
                minRows={4}
                maxRows={4}
                placeholder="Navigate the Web3 maze with ease using Gath3r, your curated Web3 yellowpages. Make informed decisions, maximize your Web3 experience, and avoid the hype."
                onChange={(e) => updateDescription(e.target.value)}
                disabled={isSending}
              />
              <p className="formDescription">{projectDescriptionSub}</p>
            </div>
            <div className="formOuter">
              <TextField
                label="Project Website"
                variant="outlined"
                size="small"
                sx={materialFormsStyles}
                value={projectWebsite}
                placeholder="https://gath3r.co"
                onChange={(e) => updateWebsite(e.target.value)}
                disabled={isSending}
              />
              <p className="formDescription">{projectWebsiteSub}</p>
            </div>
            <div className="formOuter">
              <TextField
                label="Logo (optional)"
                variant="outlined"
                size="small"
                sx={materialFormsStyles}
                value={logoUrl}
                placeholder="URL or upload"
                onChange={(e) => updateLogoUrl(e.target.value)}
                disabled={isSending}
                InputProps={{
                  endAdornment:
                    logoObj === null ? (
                      <InputAdornment position="end">
                        <Button
                          sx={{
                            color: "#39393c",
                            marginRight: "-6px",
                            fontSize: "70%",
                            "&:hover": {
                              opacity: 0.9,
                            },
                          }}
                          color="inherit"
                          size="small"
                          component="label"
                          disabled={isSending}
                        >
                          Upload
                          <input
                            type="file"
                            hidden
                            accept="image/png, image/jpeg"
                            onChange={updateLogoObj}
                          />
                        </Button>
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{
                            color: "#39393c",
                            marginRight: "-6px",
                            fontSize: "70%",
                            "&:hover": {
                              opacity: 0.9,
                            },
                          }}
                          color="inherit"
                          size="small"
                          component="label"
                          disabled={isSending}
                          onClick={() => {
                            setLogoObj(null);
                            setLogoUrl("");
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                }}
              />
              <p className="formDescription">{logoUrlSub}</p>
            </div>

            <div className="formOuter">
              <TextField
                label="Tags (optional)"
                variant="outlined"
                size="small"
                sx={materialFormsStyles}
                value={projectTags}
                placeholder="blockchain, defi, nft"
                onChange={(e) => updateTags(e.target.value)}
                disabled={isSending}
              />
              <p className="formDescription">{projectTagsSub}</p>
              {projectTagsArray.length > 0 && (
                <div className="tagsOuter">
                  {projectTagsArray.map((tag, index) => (
                    <div
                      key={index}
                      className="tagItem"
                      style={{
                        color: globalTheme.figmaPrimaryText.color,
                        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
                      }}
                    >
                      <p className="tagItemText">{tag}</p>
                      <IconButton
                        size="small"
                        onClick={() => {
                          const tags = projectTagsArray.filter(
                            (tagItem) => tagItem !== tag
                          );
                          setProjectTagsArray(tags);
                        }}
                      >
                        <CloseIcon
                          sx={{
                            fontSize: "16px",
                            "@media (min-width: 350px)": {
                              fontSize: "18px",
                            },
                            "@media (min-width: 550px)": {
                              fontSize: "20px",
                            },
                            "@media (min-width: 700px)": {
                              fontSize: "22px",
                            },
                          }}
                        />
                      </IconButton>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <Accordion sx={styles.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={styles.accordionIcon} />}
                sx={styles.accordionSummary}
              >
                <p className="preListProjectTopText">Social Links (optional)</p>
              </AccordionSummary>
              <AccordionDetails sx={styles.accordionDetails}>
                <div className="formOuter">
                  <TextField
                    label="X handle (optional)"
                    variant="outlined"
                    size="small"
                    sx={materialFormsStyles}
                    value={xHandle}
                    placeholder="@gath3rco"
                    onChange={(e) => updateXHandle(e.target.value)}
                    disabled={isSending}
                  />
                  <p className="formDescription">{xHandleSub}</p>
                </div>
                <div className="formOuter">
                  <TextField
                    label="Medium handle (optional)"
                    variant="outlined"
                    size="small"
                    sx={materialFormsStyles}
                    value={mediumHandle}
                    placeholder="@gath3rco"
                    onChange={(e) => updateMediumHandle(e.target.value)}
                    disabled={isSending}
                  />
                  <p className="formDescription">{mediumHandleSub}</p>
                </div>
                <div className="formOuter">
                  <TextField
                    label="Substack handle (optional)"
                    variant="outlined"
                    size="small"
                    sx={materialFormsStyles}
                    value={substackHandle}
                    placeholder="@gath3rco"
                    onChange={(e) => updatesubstackHandle(e.target.value)}
                    disabled={isSending}
                  />
                  <p className="formDescription">{substackHandleSub}</p>
                </div>
                <div className="formOuter">
                  <TextField
                    label="Telegram handle (optional)"
                    variant="outlined"
                    size="small"
                    sx={materialFormsStyles}
                    value={telegramHandle}
                    placeholder="gath3rco"
                    onChange={(e) => updateTelegramHandle(e.target.value)}
                    disabled={isSending}
                  />
                  <p className="formDescription">{telegramHandleSub}</p>
                </div>
                <div className="formOuter">
                  <TextField
                    label="Instagram handle (optional)"
                    variant="outlined"
                    size="small"
                    sx={materialFormsStyles}
                    value={instagramHandle}
                    placeholder="gath3rco"
                    onChange={(e) => updateInstagramHandle(e.target.value)}
                    disabled={isSending}
                  />
                  <p className="formDescription">{instagramHandleSub}</p>
                </div>
                <div className="formOuter">
                  <TextField
                    label="Facebook URL (optional)"
                    variant="outlined"
                    size="small"
                    sx={materialFormsStyles}
                    value={facebookUrl}
                    placeholder="https://facebook.com/gath3rco"
                    onChange={(e) => updateFacebookUrl(e.target.value)}
                    disabled={isSending}
                  />
                  <p className="formDescription">{facebookUrlSub}</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={styles.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={styles.accordionIcon} />}
                sx={styles.accordionSummary}
              >
                <p className="preListProjectTopText">GPT-4 Features</p>
              </AccordionSummary>
              <AccordionDetails sx={styles.accordionDetails}>
                <div className="NewListingGpt">
                  <p
                    className="preListProjectTopText"
                    style={{ width: "100%", fontWeight: 400 }}
                  >
                    Add at at least name and category for the project you want
                    to list and let GPT-4 do the rest. The more information you
                    provide, the better the results. (Only available for alpha
                    testers and premium users.)
                  </p>
                  <Button
                    variant="outlined"
                    color="inherit"
                    size="small"
                    sx={{
                      marginRight: "0px",
                      fontSize: "12px",
                      width: "100%",
                      marginTop: "15px",
                    }}
                    onClick={autoFillAi}
                    disabled={
                      projectCategory === "" ||
                      projectName === "" ||
                      isAiLoading ||
                      isSending
                    }
                  >
                    Ai Auto Fill
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="preListProjectPointsOuter">
            <div className="preListProjectPointsFirst">
              <p className="preListProjectPointsNumber">{calculatedPoints}</p>
            </div>
            <div className="preListProjectPointsText">
              <p className="preListProjectPointsTitle">POINTS</p>
            </div>
            <div className="preListProjectPointsLast">
              <p className="preListProjectPointsSub">
                WILL BE ASSIGNED TO YOUR ACCOUNT
              </p>
            </div>
          </div>
          <div className="preListProjectButtons">
            <Button
              variant="text"
              startIcon={<DeleteIcon />}
              sx={{
                ...buttonStyles,
                backgroundColor: globalTheme.colorPaletteButtonRed.color,
                flex: "unset",
                minWidth: "100px",
              }}
              color="inherit"
              size="small"
              onClick={onReset}
              disabled={isSending}
            >
              RESET
            </Button>
            <Button
              variant="text"
              startIcon={!isSending ? <ListIcon /> : null}
              sx={{
                ...buttonStyles,
                backgroundColor: globalTheme.colorPaletteButtonGreen.color,
                flex: "unset",
                marginLeft: "20px",
                minWidth: "100px",
              }}
              color="inherit"
              size="small"
              onClick={onSend}
              /*disabled={
                !projectNameVerified ||
                !projectDescriptionVerified ||
                !projectWebsiteVerified ||
                projectCategory === "" ||
                isSending
              }*/
            >
              {isSending ? (
                <CircularProgress size={18} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
