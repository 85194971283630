import React, { useState } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { ButtonBase } from "@mui/material";
import ColorPicker from "./ColorPicker";
import { useThemeState } from "../../../components/utils/globalStates";
import "./DashboardAddWidget.css";

export const pageTempEnums = [
  {
    value: "points",
    label: "Points",
    icon: "score",
    path: "/achievements",
    uidRequired: false,
  },
  {
    value: "listingCount",
    label: "Listings",
    icon: "list_alt",
    path: "/users/listings",
    uidRequired: true,
  },
  {
    value: "reviewsCount",
    label: "Reviews",
    icon: "rate_review",
    path: "/reviews/user",
    uidRequired: true,
  },
  {
    value: "ratingCount",
    label: "Ratings",
    icon: "stars",
    path: "/reviews/user/rating",
    uidRequired: true,
  },
  {
    value: "referralCount",
    label: "Referrals",
    icon: "people",
    path: "/profile/settings",
    uidRequired: false,
  },
];

export default function AddCountWidget({
  addWidget,
  addwidgetRow,
}: {
  addWidget: Function;
  addwidgetRow: number;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedWidth, setIsExpandedWidth] = useState(false);
  const [widgetTitle, setWidgetTitle] = useState("points");
  const [widgetPath, setWidgetPath] = useState("/user/points");
  const [widgetLabel, setWidgetLabel] = useState("Points");
  const [tempCount, setTempCount] = useState(124);
  const [pickedColor, setPickedColor] = useState(
    globalTheme.figmaPurplePastel.color
  );

  const handleChange = (event: SelectChangeEvent) => {
    setWidgetTitle(event.target.value as string);
    setWidgetPath(
      pageTempEnums.find((page) => page.value === event.target.value)!.path
    );
    setWidgetLabel(
      pageTempEnums.find((page) => page.value === event.target.value)!.label
    );
    setWidgetTitle(event.target.value);
  };

  const onWidgetExpand = () => {
    setIsExpandedWidth(true);
    setTimeout(() => {
      setIsExpanded(true);
    }, 350);
  };

  const onWidgetCollapse = () => {
    setIsExpanded(false);
    setTimeout(() => {
      setIsExpandedWidth(false);
    }, 350);
  };

  const onWidgetPress = () => {
    if (isExpanded) {
      onWidgetCollapse();
    } else {
      onWidgetExpand();
    }
  };

  const onAddWidgetPress = () => {
    const widgetData = {
      type: "count",
      rowLength: 1,
      path: widgetPath,
      title: widgetLabel,
      bgColorCode: pickedColor,
      filter: widgetTitle,
    };
    addWidget(widgetData, addwidgetRow);
  };

  const styles = {
    formOuter: {
      marginTop: "15px",
      backgroundColor: "transparent",
      color: globalTheme.figmaPrimaryText.color,
      borderColor: globalTheme.figmaPrimaryText.color + " !important",
      "& .MuiInputLabel-root": {
        color: globalTheme.figmaPrimaryText.color + " !important",
        flexDirection: "row",
        "& .Mui-focused ": {
          color: globalTheme.figmaPrimaryText.color,
        },
      },
    },
    select: {
      backgroundColor: "transparent",
      color: globalTheme.figmaPrimaryText.color,
      "& span": { fontSize: "13px" },
      borderColor: globalTheme.figmaPrimaryText.color + " !important",
      "& .MuiSelect-root": {
        "& fieldset": {
          color: globalTheme.figmaPrimaryText.color,
          borderColor: globalTheme.figmaPrimaryText.color + " !important",
        },
        "&:hover fieldset": {
          color: globalTheme.figmaPrimaryText.color,
          borderColor: globalTheme.figmaPrimaryText.color + " !important",
        },
        "&:after": {
          color: globalTheme.figmaPrimaryText.color,
          borderColor: globalTheme.figmaPrimaryText.color + " !important",
        },
      },
      "&.Mui-focused fieldset": {
        color: globalTheme.figmaPrimaryText.color,
        borderColor: globalTheme.figmaPrimaryText.color + " !important",
      },
      "&.Mui-focused ": {
        color: globalTheme.figmaPrimaryText.color,
        borderColor: globalTheme.figmaPrimaryText.color + " !important",
      },
    },
    selectMenu: {
      zIndex: 9999,
      color: globalTheme.figmaPrimaryText.color,
      "& span": { fontSize: "13px" },
      "& .MuiPaper-root": {
        backgroundColor: "#fff",
        color: globalTheme.figmaPrimaryText.color,
        "& .MuiMenuItem-root": {
          "& .Mui-selected": {
            color: globalTheme.figmaPrimaryText.color,
            backgroundColor: globalTheme.altTextColor.color,
          },
        },
        "& .Mui-selected": {
          color: globalTheme.figmaPrimaryText.color,
          backgroundColor: globalTheme.background.backgroundColor,
        },
      },
    },
  };

  return (
    <div
      className="AddWidget"
      style={{
        color: globalTheme.figmaPrimaryText.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        backgroundColor: globalTheme.figmaWhite.color,
        width: isExpandedWidth ? "100%" : "25%",
        alignItems: "flex-start",
      }}
    >
      <ButtonBase
        onClick={onWidgetPress}
        className="ListingsWidget"
        sx={{
          color: globalTheme.figmaPrimaryText.color,
          borderRadius: "8px",
          backgroundColor: "transparent",
          width: "100%",
          marginTop: "-1px",
          marginBottom: "-1px",
        }}
      >
        <div
          className="ShortCutWidget"
          style={{
            color: globalTheme.figmaPrimaryText.color,
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            borderRadius: "8px",
            width: "100%",
            backgroundColor: pickedColor,
          }}
        >
          <p
            style={{
              marginTop: "10px",
              fontSize: "12px",
            }}
            className="CountWidgetTitle"
          >
            {widgetLabel}
          </p>
          <p
            className="CountWidgetTitle"
            style={{
              borderTop: `1px solid ${globalTheme.figmaPrimaryText.color}`,
              paddingTop: "3px",
              borderBottom: `1px solid ${globalTheme.figmaPrimaryText.color}`,
              paddingBottom: "3px",
              width: "75%",
              marginBottom: "10px",
              marginTop: "5px",
              fontSize: "12px",
            }}
          >
            {tempCount}
          </p>
        </div>
      </ButtonBase>
      <div
        className="AddWidgetSettingsOuter"
        style={{
          maxHeight: isExpanded ? "500px" : "0px",
        }}
      >
        <div className="AddWidgetSettings">
          <div className="AddWidgetSettingsInner">
            <FormControl fullWidth size="small" sx={styles.formOuter}>
              <InputLabel>Path</InputLabel>
              <Select
                value={widgetTitle}
                size="small"
                label="Path"
                onChange={handleChange}
                sx={{ ...styles.select }}
                MenuProps={{
                  sx: styles.selectMenu,
                }}
                renderValue={(selected) =>
                  pageTempEnums.find((page) => page.value === selected)!.label
                }
              >
                {pageTempEnums.map((page) => (
                  <MenuItem value={page.value} key={page.value}>
                    {page.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <ColorPicker
            setPickedColor={setPickedColor}
            pickedColor={pickedColor}
          />
        </div>
        <ButtonBase
          disabled={widgetTitle === ""}
          onClick={onAddWidgetPress}
          className="AddWidgetButton"
          style={{
            backgroundColor: globalTheme.figmaPrimaryText.color,
            color: globalTheme.figmaWhite.color,
            width: "100%",
            padding: "2px",
            borderRadius: "0px 0px 6px 6px",
            justifySelf: "flex-end",
          }}
        >
          <p>ADD WIDGET</p>
        </ButtonBase>
      </div>
    </div>
  );
}
