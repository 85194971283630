import React, { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";

import { useThemeState } from "../../utils/globalStates";
import "./TopBoxes.css";

export default function ListingReviewsListInfo({
  listingName,
  viewType,
}: {
  listingName: string;
  viewType: string;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  return (
    <div
      className="SearchAndExplore"
      style={{
        backgroundColor: globalTheme.figmaYellowPastel.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        ...globalTheme.boxShadow,
        maxWidth: "1170px",
      }}
    >
      <div className="SearchAndExploreHeader">
        <p
          className="SearchAndExploreHeaderTitle"
          style={{ color: globalTheme.figmaPrimaryText.color, maxWidth: "95%" }}
        >
          {viewType}
        </p>
        {listingName === "" ? (
          <Skeleton
            variant="text"
            style={{ width: "40%", fontSize: "16px", marginBottom: "-10px" }}
          />
        ) : (
          <p
            className="SearchAndExploreHeaderSubLarge"
            style={{
              color: globalTheme.figmaPrimaryText.color,
              maxWidth: "95%",
              marginBottom: "-10px",
            }}
          >
            {listingName !== "" && "for " + listingName}
          </p>
        )}
      </div>
    </div>
  );
}
