export const firebaseConfig = {
  apiKey: "AIzaSyDiqg0gkVVpnXIdNQRsWXNwf5SRl5qhIJw",
  authDomain: "app.gath3r.co",
  databaseURL: "https://gath3r-co-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "gath3r-co",
  storageBucket: "gath3r-co.appspot.com",
  messagingSenderId: "738965103671",
  appId: "1:738965103671:web:7782c56c815d1836588bb2",
  measurementId: "G-81HW2PFZG8"
};

export const recaptchaKey = '6LeUF04pAAAAAJ6yX2RyB0wzSIKb3shwsoObzdQL'

export const algoliaId = '2REQJWKK9N';
export const algoliaKey = '7ccb7444dc5d2a9abee9ca253a3693c1';

export const alchemyKey = 'xCaD043vFtLUCvCdvuvya3Il_kJQ22cb'

export const coinGeckoKey = 'CG-RnDNi6pGdfUWqLKWrhPLpwuy';