import icon from "../../../static/img/icon.png";
import './Misc.css';

function LoadingLogo() {

  
  return (
    <div className="LoadingLogo">
      <img src={icon} className="LoadingLogoIcon" alt="logo" />
    </div>
  );
}

export default LoadingLogo;

//<img src={logo} className="App-logo" alt="logo" />