import Web3 from 'web3'
import { isAddress } from 'web3-validator';

export const web3Provider = new Web3(
  Web3.givenProvider ||
    "https://eth-mainnet.g.alchemy.com/v2/xCaD043vFtLUCvCdvuvya3Il_kJQ22cb"
);

export const isValidEthAddress = (address: string) => {
  return isAddress(address);
};