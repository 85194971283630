import React, { useState, useEffect } from "react";

import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import NewListingBox from "../listings/new/NewListingBox";
import { Helmet } from "react-helmet-async";
import {
  useSnackbarErrorState,
  useSnackbarState,
  useHeaderSettingsState,
  useTabSettingsState,
  useSettingsStates,
  useThemeState,
} from "../../components/utils/globalStates";
import DashboardInfo from "../../components/ui/topBoxes/DashboardInfo";
import DashboardInfoNew from "../../components/ui/topBoxes/DashboardInfoNew";
import DashboardSearch from "../../components/ui/topBoxes/DashboardSearch";

import { ButtonBase, Skeleton, IconButton } from "@mui/material";

// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";

// Import Widgets
import ShortCutWidget from "./widgets/ShortCutWidget";
import ListingsWidget from "./widgets/ListingsWidget";
import RatingsWidget from "./widgets/RatingsWidget";
import CountWidget from "./widgets/CountWidget";
import UsersWidget from "./widgets/UsersWidget";
import ReviewWidget from "./widgets/ReviewWidget";
import SingleListingWidget from "./widgets/SingleListingWidget";
import CustomSearchWidget from "./widgets/CustomSearchWidget";

import { ref, set, remove } from "firebase/database";

import { firebaseRlDb } from "../../components/firebase/database";
import AddWidgetDialog from "./AddWidgetDialog";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../components/firebase/auth";
import "./Dashboard.css";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Dashboard() {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const snackbarError = useSnackbarErrorState();
  const snackbarStates = useSnackbarState();
  const settingsStates = useSettingsStates();
  const headerSettings = useHeaderSettingsState();
  const tabSettings = useTabSettingsState();

  const [dashSettings, setDashSettings] = useState<any[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [reloadWidgets, setReloadWidgets] = useState(false);
  const [addWidgetDialogOpen, setAddWidgetDialogOpen] = useState(false);
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [addwidgetRow, setAddWidgetRow] = useState(0);
  const [addWidgetSize, setAddWidgetSize] = useState(4);

  useEffect(() => {
    headerSettings.setLogoBGColor(globalTheme.dashboardBackground.color);
    headerSettings.setIsLogoOpen(true);
    tabSettings.setColor(globalTheme.backgroundMainBeige.color);
    tabSettings.setIconColor(globalTheme.backgroundMainOffYellow.color);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.dashboardBackground.color);
    return () => {
      headerSettings.setIsLogoOpen(false);
      tabSettings.setColor(globalTheme.backgroundMainBeige.color);
      tabSettings.setIconColor(globalTheme.backgroundMainOffYellow.color);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  // on Cancel edit

  const onCancelEdit = () => {
    const tempDashSettings = [...settingsStates.dashboard];
    const tempDashArray = [];
    for (let i = 0; i < tempDashSettings.length; i++) {
      tempDashArray.push([...tempDashSettings[i]]);
    }
    setDashSettings(tempDashArray);
    setReloadWidgets(!reloadWidgets);
    setIsEditing(false);
  };

  // On add widget
  const addWidget = (widgetSettings: any, addwidgetRow: number) => {
    const newDashSettings = [...dashSettings];
    if (newDashSettings.length === 0) {
      newDashSettings.push([]);
    }
    if (addwidgetRow > newDashSettings.length - 1) {
      newDashSettings.push([widgetSettings]);
    } else {
      newDashSettings[addwidgetRow].push(widgetSettings);
    }
    setDashSettings(newDashSettings);
    setAddWidgetDialogOpen(false);
    setReloadWidgets(!reloadWidgets);
  };

  // Save updated settings to firebase realtime database
  const onUpdateWidgets = async () => {
    if (isEditing) {
      try {
        settingsStates.setDashboard(dashSettings);
        setIsEditing(false);
        snackbarStates.setMessage("Updating Dashboard");
        snackbarStates.setOpenLength(null);
        snackbarStates.setIsActionLoading(true);
        snackbarStates.setIsOpen(true);
        await set(
          ref(firebaseRlDb, `public/settings/user/${user?.uid}/dashboard`),
          dashSettings
        );
        snackbarStates.setIsActionLoading(false);
        snackbarStates.setOpenLength(3000);
        snackbarStates.setMessage("Dashboard Updated");
      } catch (error) {
        console.error(error);
        snackbarStates.setIsOpen(false);
        snackbarStates.setIsActionLoading(false);
        snackbarStates.setMessage("");
        snackbarError.setMessage("Error updating dashboard");
        snackbarError.setOpenLength(4000);
        snackbarError.setIsOpen(true);
      }
    } else {
      setIsEditing(true);
    }
  };

  // Reset to default dashboard by deleting the user's dashboard settings
  const onReseetToDefault = async () => {
    try {
      settingsStates.setDashboard([]);
      setIsEditing(false);
      snackbarStates.setMessage("Resetting Dashboard to Default");
      snackbarStates.setOpenLength(null);
      snackbarStates.setIsActionLoading(true);
      snackbarStates.setIsOpen(true);
      await remove(
        ref(firebaseRlDb, `public/settings/user/${user?.uid}/dashboard`)
      );
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setOpenLength(3000);
      snackbarStates.setMessage("Dashboard Reset to Default");
    } catch (error) {
      console.error(error);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("");
      snackbarError.setMessage("Error resetting Dashboard");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
    }
  };

  // Delete Widget
  const deleteWidget = (rowIndex: number, elementIndex: number) => {
    const newDashSettings = [...dashSettings];
    if (newDashSettings[rowIndex].length === 1) {
      newDashSettings.splice(rowIndex, 1);
    } else {
      newDashSettings[rowIndex].splice(elementIndex, 1);
    }
    setDashSettings(newDashSettings);
    setReloadWidgets(!reloadWidgets);
  };

  //Move widget row up
  const moveWidgetRowUp = (rowIndex: number) => {
    const newDashSettings = [...dashSettings];
    const temp = newDashSettings[rowIndex - 1];
    newDashSettings[rowIndex - 1] = newDashSettings[rowIndex];
    newDashSettings[rowIndex] = temp;
    setDashSettings(newDashSettings);
    setReloadWidgets(!reloadWidgets);
  };

  //Move widget row down
  const moveWidgetRowDown = (rowIndex: number) => {
    const newDashSettings = [...dashSettings];
    const temp = newDashSettings[rowIndex + 1];
    newDashSettings[rowIndex + 1] = newDashSettings[rowIndex];
    newDashSettings[rowIndex] = temp;
    setDashSettings(newDashSettings);
    setReloadWidgets(!reloadWidgets);
  };
  useEffect(() => {
    const tempDashSettings = [...settingsStates.dashboard];
    const tempDashArray = [];
    for (let i = 0; i < tempDashSettings.length; i++) {
      tempDashArray.push([...tempDashSettings[i]]);
    }
    setDashSettings(tempDashArray);
  }, [settingsStates.dashboard]);

  const styles = {
    dialogRoot: {
      zIndex: 9999,
      backgroundColor: "rgba(253, 247, 234, 0.5)",
      "& .MuiDialog-paper": {
        backgroundColor: globalTheme.backgroundMainYellow.color,
        color: globalTheme.textMainOffBlack.color,
        width: "94%",
        maxWidth: "600px",
        margin: 0,
        paddingBottom: "0px",
        paddingTop: "0px",
        position: "absolute",
        borderRadius: "0px 0px 12px 12px",
        top: "-10px",
        zIndex: 9999,
        maxHeight: "85vh",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
    },
    dialogTitle: {
      color: globalTheme.textMainOffBlack.color,
      textAlign: "center",
      fontSize: "15px",
      marginTop: "40px",
      fontWeight: 600,
      maxWidth: "100%",
    },
  };
  return (
    <div
      className="Dashboard"
      style={{ backgroundColor: globalTheme.dashboardBackground.color }}
    >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Dashboard</title>
      </Helmet>
      <DashboardSearch />
      <div className="DashboardNewListingBox">
        <NewListingBox
          bgColorProps={globalTheme.figmaAlt2.color}
          alternativeDisplay={true}
        />
      </div>
      <DashboardInfoNew />
      {settingsStates.dashboardInitialLoaded ? (
        <div className="DashboardWidgetsOuter">
          {dashSettings.map((widgetRow, widgetRowIndex) => {
            let widgetCount = 0;
            for (let i = 0; i < widgetRow.length; i++) {
              widgetCount += widgetRow[i].rowLength;
            }
            return (
              <div key={widgetRowIndex + "outer"} className="DashboardRowOuter">
                {isEditing && (
                  <div
                    style={{
                      color: globalTheme.backgroundMainOffYellow.color,
                      backgroundColor: globalTheme.backgroundMainYellow.color,
                      height: isEditing ? "30px" : "0px",
                      border: `1px dashed ${globalTheme.backgroundMainOffYellow.color}`,
                    }}
                    className="DashboardRowDrag"
                  >
                    <p
                      className="DashboardRowDragText"
                      style={{
                        color: globalTheme.backgroundMainOffYellow.color,
                      }}
                    >
                      ORDER
                    </p>
                    {widgetRowIndex !== dashSettings.length - 1 && (
                      <IconButton
                        onClick={() => moveWidgetRowDown(widgetRowIndex)}
                        size="small"
                        sx={{
                          color: globalTheme.figmaPrimaryText.color,
                          "&:hover": {},
                        }}
                      >
                        <ArrowDropDownOutlinedIcon />
                      </IconButton>
                    )}
                    {widgetRowIndex !== 0 && (
                      <IconButton
                        onClick={() => moveWidgetRowUp(widgetRowIndex)}
                        size="small"
                        sx={{
                          color: globalTheme.figmaPrimaryText.color,
                          "&:hover": {},
                        }}
                      >
                        <ArrowDropUpOutlinedIcon />
                      </IconButton>
                    )}
                  </div>
                )}
                <div className="DashboardRow">
                  {widgetRow.map((widget: any, index: number) => {
                    switch (widget.type) {
                      case "listings":
                        return (
                          <div
                            className="DashboardRowFull"
                            key={index + "inner" + widgetRowIndex}
                          >
                            <ListingsWidget
                              bgColorCode={widget.bgColorCode}
                              isEditing={isEditing}
                              deleteWidget={deleteWidget}
                              fulldata={widget}
                              elementIndex={index}
                              rowIndex={widgetRowIndex}
                            />
                          </div>
                        );
                      case "customSearch":
                        return (
                          <div
                            className="DashboardRowFull"
                            key={index + "inner" + widgetRowIndex}
                          >
                            <CustomSearchWidget
                              bgColorCode={widget.bgColorCode}
                              isEditing={isEditing}
                              deleteWidget={deleteWidget}
                              fulldata={widget}
                              elementIndex={index}
                              rowIndex={widgetRowIndex}
                            />
                          </div>
                        );
                      case "users":
                        return (
                          <div
                            className="DashboardRowFull"
                            key={index + "inner" + widgetRowIndex}
                          >
                            <UsersWidget
                              bgColorCode={widget.bgColorCode}
                              listingsFilter={widget.filter}
                              isEditing={isEditing}
                              deleteWidget={deleteWidget}
                              fulldata={widget}
                              elementIndex={index}
                              rowIndex={widgetRowIndex}
                            />
                          </div>
                        );
                      case "ratings":
                        return (
                          <div
                            className="DashboardRowFull"
                            key={index + "inner" + widgetRowIndex}
                          >
                            <RatingsWidget
                              widgetTitle={widget.title}
                              bgColorCode={widget.bgColorCode}
                              listingsFilter={widget.filter}
                              firebasePath="/"
                              isEditing={isEditing}
                              deleteWidget={deleteWidget}
                              fulldata={widget}
                              elementIndex={index}
                              rowIndex={widgetRowIndex}
                            />
                          </div>
                        );
                      case "reviews":
                        return (
                          <div
                            className="DashboardRowFull"
                            key={index + "inner" + widgetRowIndex}
                          >
                            <ReviewWidget
                              bgColorCode={widget.bgColorCode}
                              isEditing={isEditing}
                              deleteWidget={deleteWidget}
                              fulldata={widget}
                              elementIndex={index}
                              rowIndex={widgetRowIndex}
                            />
                          </div>
                        );
                      case "shortcut":
                        return (
                          <div
                            className="DashboardRowQuarter"
                            key={index + "inner" + widgetRowIndex}
                          >
                            <ShortCutWidget
                              shortcutTitle={widget.title}
                              shortcutPath={
                                widget.path +
                                (widget.uidRequired ? user?.uid : "")
                              }
                              shortcutIcon={widget.icon}
                              isEditing={isEditing}
                              deleteWidget={deleteWidget}
                              fulldata={widget}
                              elementIndex={index}
                              rowIndex={widgetRowIndex}
                            />
                          </div>
                        );
                      case "count":
                        return (
                          <div
                            className="DashboardRowQuarter"
                            key={index + "inner" + widgetRowIndex}
                          >
                            <CountWidget
                              countTitle={widget.title}
                              bgColorCode={widget.bgColorCode}
                              isEditing={isEditing}
                              deleteWidget={deleteWidget}
                              fulldata={widget}
                              elementIndex={index}
                              rowIndex={widgetRowIndex}
                            />
                          </div>
                        );
                      case "singleListing":
                        return (
                          <div
                            className="DashboardRowHalf"
                            key={index + "inner" + widgetRowIndex}
                          >
                            <SingleListingWidget
                              isEditing={isEditing}
                              deleteWidget={deleteWidget}
                              fulldata={widget}
                              elementIndex={index}
                              rowIndex={widgetRowIndex}
                            />
                          </div>
                        );
                      default:
                        return null;
                    }
                  })}
                  {4 - widgetCount === 1 && (
                    <ButtonBase
                      onClick={() => {
                        setAddWidgetRow(widgetRowIndex);
                        setAddWidgetSize(1);
                        setAddWidgetDialogOpen(true);
                        setIsEditing(true);
                      }}
                      sx={{
                        color: globalTheme.figmaSecondaryText.color,
                        backgroundColor: "transparent",
                        width: "calc(25% - 10px)",
                        aspectRatio: "1",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "0px",
                        marginRight: "-1px",
                        border: `1px dashed ${globalTheme.figmaSecondaryText.color}`,
                        borderTop: isEditing
                          ? "none"
                          : `1px dashed ${globalTheme.figmaSecondaryText.color}`,
                        borderRadius: isEditing ? "0px" : "8px",
                      }}
                    >
                      <span className="material-symbols-outlined">add</span>
                      <p className="DashboardAddWidgetTextSmall">Add Widget</p>
                    </ButtonBase>
                  )}
                  {4 - widgetCount === 2 && (
                    <ButtonBase
                      onClick={() => {
                        setAddWidgetRow(widgetRowIndex);
                        setAddWidgetSize(2);
                        setAddWidgetDialogOpen(true);
                        setIsEditing(true);
                      }}
                      sx={{
                        color: globalTheme.figmaSecondaryText.color,
                        backgroundColor: "transparent",
                        width: "calc(50% - 10px)",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "0px",
                        marginRight: "-1px",
                        border: `1px dashed ${globalTheme.figmaSecondaryText.color}`,
                        borderTop: isEditing
                          ? "none"
                          : `1px dashed ${globalTheme.figmaSecondaryText.color}`,
                        borderRadius: isEditing ? "0px" : "8px",
                      }}
                    >
                      <span className="material-symbols-outlined">add</span>
                      <p className="DashboardAddWidgetTextSmall">Add Widget</p>
                    </ButtonBase>
                  )}
                  {4 - widgetCount === 3 && (
                    <ButtonBase
                      onClick={() => {
                        setAddWidgetRow(widgetRowIndex);
                        setAddWidgetSize(3);
                        setAddWidgetDialogOpen(true);
                        setIsEditing(true);
                      }}
                      sx={{
                        color: globalTheme.figmaSecondaryText.color,
                        backgroundColor: "transparent",
                        width: "calc(75% - 10px)",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "0px",
                        marginRight: "-1px",
                        border: `1px dashed ${globalTheme.figmaSecondaryText.color}`,
                        borderTop: isEditing
                          ? "none"
                          : `1px dashed ${globalTheme.figmaSecondaryText.color}`,
                        borderRadius: isEditing ? "0px" : "8px",
                      }}
                    >
                      <span className="material-symbols-outlined">add</span>
                      <p className="DashboardAddWidgetTextSmall">Add Widget</p>
                    </ButtonBase>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <>
          <div
            className="DashboardRow"
            style={{ width: "90%", marginTop: "15px", maxWidth: "800px" }}
          >
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: "8px",
                width: "calc(25% - 5px)",
                height: "70px",
              }}
            />
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: "8px",
                width: "calc(25% - 5px)",
                height: "70px",
              }}
            />
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: "8px",
                width: "calc(25% - 5px)",
                height: "70px",
              }}
            />
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: "8px",
                width: "calc(25% - 5px)",
                height: "70px",
              }}
            />
          </div>
          <div
            className="DashboardRow"
            style={{ width: "90%", marginTop: "15px", maxWidth: "800px" }}
          >
            <Skeleton
              variant="rectangular"
              width="100%"
              height="140px"
              sx={{ borderRadius: "8px" }}
            />
          </div>
          <div
            className="DashboardRow"
            style={{ width: "90%", marginTop: "15px", maxWidth: "800px" }}
          >
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: "8px",
                width: "calc(25% - 5px)",
                height: "70px",
              }}
            />
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: "8px",
                width: "calc(25% - 5px)",
                height: "70px",
              }}
            />
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: "8px",
                width: "calc(50% - 5px)",
                height: "70px",
              }}
            />
          </div>
          <div
            className="DashboardRow"
            style={{ width: "90%", marginTop: "15px", maxWidth: "800px" }}
          >
            <Skeleton
              variant="rectangular"
              width="100%"
              height="140px"
              sx={{ borderRadius: "8px" }}
            />
          </div>
          <div
            className="DashboardRow"
            style={{ width: "90%", marginTop: "15px", maxWidth: "800px" }}
          >
            <Skeleton
              variant="rectangular"
              width="100%"
              height="200px"
              sx={{ borderRadius: "8px" }}
            />
          </div>
          <div
            className="DashboardRow"
            style={{ width: "90%", marginTop: "15px", maxWidth: "800px" }}
          >
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: "8px",
                width: "calc(25% - 5px)",
                height: "70px",
              }}
            />
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: "8px",
                width: "calc(25% - 5px)",
                height: "70px",
              }}
            />
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: "8px",
                width: "calc(25% - 5px)",
                height: "70px",
              }}
            />
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: "8px",
                width: "calc(25% - 5px)",
                height: "70px",
              }}
            />
          </div>
          <div
            className="DashboardRow"
            style={{ width: "90%", marginTop: "15px", maxWidth: "800px" }}
          >
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: "8px",
                width: "calc(25% - 5px)",
                height: "70px",
              }}
            />
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: "8px",
                width: "calc(25% - 5px)",
                height: "70px",
              }}
            />
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: "8px",
                width: "calc(25% - 5px)",
                height: "70px",
              }}
            />
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: "8px",
                width: "calc(25% - 5px)",
                height: "70px",
              }}
            />
          </div>
        </>
      )}
      {isEditing && (
        <ButtonBase
          disabled={dashSettings.length > 11}
          onClick={() => {
            setAddWidgetRow(dashSettings.length);
            setAddWidgetSize(4);
            setAddWidgetDialogOpen(true);
            setIsEditing(true);
          }}
          sx={{
            color: globalTheme.backgroundMainOffYellow.color,
            backgroundColor: "transparent",
            width: "90%",
            maxWidth: "800px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            padding: "10px",
            marginTop: "15px",
            border: `1px dashed ${globalTheme.backgroundMainOffYellow.color}`,
          }}
        >
          {dashSettings.length > 11 ? (
            <div className="DashboardOpenEdit">
              <p className="DashboardAddWidgetText">
                Maximum number of Widget rows reached!
              </p>
            </div>
          ) : (
            <div className="DashboardOpenEdit">
              <p className="DashboardAddWidgetText">Add Widget</p>
              <span className="material-symbols-outlined">add</span>
            </div>
          )}
        </ButtonBase>
      )}
      <ButtonBase
        sx={{
          color: isEditing
            ? globalTheme.backgroundMainYellow.color
            : globalTheme.backgroundMainOffYellow.color,
          backgroundColor: isEditing
            ? globalTheme.backgroundMainOffYellow.color
            : "transparent",
          width: "90%",
          maxWidth: "800px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "8px",
          padding: "10px",
          marginTop: "30px",
          border: isEditing
            ? `1px solid ${globalTheme.figmaPrimaryText.color}`
            : `1px dashed ${globalTheme.backgroundMainOffYellow.color}`,
        }}
        onClick={onUpdateWidgets}
      >
        <div className="DashboardOpenEdit">
          <p className="DashboardOpenEditTitle">
            {isEditing ? "Update" : "Customize Dashboard"}
          </p>
          <span className="material-symbols-outlined">dashboard_customize</span>
        </div>
      </ButtonBase>
      {isEditing && (
        <ButtonBase
          sx={{
            color: globalTheme.backgroundMainOffYellow.color,
            backgroundColor: globalTheme.backgroundMainYellow.color,
            width: "90%",
            maxWidth: "800px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            padding: "10px",
            marginTop: "20px",
            border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
          }}
          onClick={onCancelEdit}
        >
          <div className="DashboardOpenEdit">
            <p className="DashboardOpenEditTitle">Cancel</p>
            <span className="material-symbols-outlined">cancel</span>
          </div>
        </ButtonBase>
      )}
      {isEditing && (
        <ButtonBase
          sx={{
            color: globalTheme.figmaPrimaryText.color,
            backgroundColor: globalTheme.figmaWarningBackground.color,
            width: "90%",
            maxWidth: "800px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            padding: "10px",
            marginTop: "40px",
            border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
          }}
          onClick={onReseetToDefault}
        >
          <div className="DashboardOpenEdit">
            <p className="DashboardOpenEditTitle">Reset to Default</p>
            <span className="material-symbols-outlined">delete</span>
          </div>
        </ButtonBase>
      )}
      <Dialog
        open={addWidgetDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAddWidgetDialogOpen(false)}
        sx={styles.dialogRoot}
      >
        <DialogContent
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <DialogTitle sx={styles.dialogTitle}>
            Add Widget
            <p
              style={{
                fontSize: "70%",
                fontWeight: "400",
                marginTop: "0px",
                marginBottom: "0px",
                lineHeight: "1.2",
                color: globalTheme.figmaPrimaryText.color,
              }}
            >
              Customize widgets for your dashboard
            </p>
            <p
              style={{
                fontSize: "60%",
                fontWeight: "600",
                marginTop: "0px",
                marginBottom: "0px",
                lineHeight: "1.2",
                color: globalTheme.figmaPrimaryText.color,
              }}
            >
              Click on the widget you want to add, then customize it
              accordingly.
            </p>
          </DialogTitle>
          <AddWidgetDialog
            addWidgetSize={addWidgetSize}
            addwidgetRow={addwidgetRow}
            addWidget={addWidget}
          />
        </DialogContent>

        <ButtonBase
          sx={{
            color: globalTheme.backgroundMainYellow.color,
            backgroundColor: globalTheme.backgroundMainOffYellow.color,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "0px 0px 12px 12px",
            padding: "10px",
            borderTop: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
            position: "absolute",
            bottom: "0px",
          }}
          onClick={() => setAddWidgetDialogOpen(false)}
        >
          <div className="DashboardOpenEdit">
            <p className="DashboardOpenEditTitle">Close</p>
            <span className="material-symbols-outlined">close</span>
          </div>
        </ButtonBase>
      </Dialog>
    </div>
  );
}
