import React, { useState, useEffect } from "react";
import AddListingsWidget from "./addWidget/AddListingWidget";
import AddReviewWidget from "./addWidget/AddReviewWidget";
import AddRatingWidget from "./addWidget/AddRatingWidget";
import AddUserWidget from "./addWidget/AddUserWidget";
import AddShortcutWidget from "./addWidget/AddShortcutWidget";
import AddCountWidget from "./addWidget/AddCountWidget";
import AddSingleListingWidget from "./addWidget/AddSingleListingWidget";
import AddCustomSearchWidget from "./addWidget/AddCustomSearchWidget";
import { useThemeState } from "../../components/utils/globalStates";
import "./Dashboard.css";

export default function AddWidgetDialog({
  addwidgetRow,
  addWidgetSize,
  addWidget,
}: {
  addwidgetRow: number;
  addWidgetSize: number;
  addWidget: Function;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  return (
    <div className="AddWidgetDialog">
      <div className="AddWidgetHeader">
        <p
          style={{ color: globalTheme.figmaPrimaryTextSub.color }}
          className="AddWidgetHeaderTitle"
        >
          Shortcuts
        </p>
        <p
          style={{ color: globalTheme.figmaPrimaryText.color }}
          className="AddWidgetHeaderSub"
        >
          Add shortcuts to your dashboard for quick access to your favorite
          pages.
        </p>
      </div>
      <AddShortcutWidget addWidget={addWidget} addwidgetRow={addwidgetRow} />
      <div className="AddWidgetHeader">
        <p
          style={{ color: globalTheme.figmaPrimaryTextSub.color }}
          className="AddWidgetHeaderTitle"
        >
          Counters
        </p>
        <p
          style={{ color: globalTheme.figmaPrimaryText.color }}
          className="AddWidgetHeaderSub"
        >
          Add counters for your Points, projects, and more.
        </p>
      </div>
      <AddCountWidget addWidget={addWidget} addwidgetRow={addwidgetRow} />
      {addWidgetSize > 1 && (
        <>
        <div className="AddWidgetHeader">
        <p
          style={{ color: globalTheme.figmaPrimaryTextSub.color }}
          className="AddWidgetHeaderTitle"
        >
          Project Shortcut
        </p>
        <p
          style={{ color: globalTheme.figmaPrimaryText.color }}
          className="AddWidgetHeaderSub"
        >
          Add your favorite project to dashboard for easy access.
        </p>
      </div>
      <AddSingleListingWidget addWidget={addWidget} addwidgetRow={addwidgetRow} />
        </>
      )}
      {addWidgetSize > 3 && (
        <>
        <div className="AddWidgetHeader">
            <p
              style={{ color: globalTheme.figmaPrimaryTextSub.color }}
              className="AddWidgetHeaderTitle"
            >
              Custom Search
            </p>
            <p
              style={{ color: globalTheme.figmaPrimaryText.color }}
              className="AddWidgetHeaderSub"
            >
              Add widgets which will list projects based on your custom search
              criteria.
            </p>
          </div>
          <AddCustomSearchWidget
            addWidget={addWidget}
            addwidgetRow={addwidgetRow}
          />
          <div className="AddWidgetHeader">
            <p
              style={{ color: globalTheme.figmaPrimaryTextSub.color }}
              className="AddWidgetHeaderTitle"
            >
              Projects
            </p>
            <p
              style={{ color: globalTheme.figmaPrimaryText.color }}
              className="AddWidgetHeaderSub"
            >
              Filter by new, trending, and top projects or projects from a
              specific category.
            </p>
          </div>
          <AddListingsWidget
            addWidget={addWidget}
            addwidgetRow={addwidgetRow}
          />
          <div className="AddWidgetHeader">
            <p
              style={{ color: globalTheme.figmaPrimaryTextSub.color }}
              className="AddWidgetHeaderTitle"
            >
              Reviews
            </p>
            <p
              style={{ color: globalTheme.figmaPrimaryText.color }}
              className="AddWidgetHeaderSub"
            >
              See the latest reviews for projects.
            </p>
          </div>
          <AddReviewWidget addWidget={addWidget} addwidgetRow={addwidgetRow} />
          <div className="AddWidgetHeader">
            <p
              style={{ color: globalTheme.figmaPrimaryTextSub.color }}
              className="AddWidgetHeaderTitle"
            >
              Ratings
            </p>
            <p
              style={{ color: globalTheme.figmaPrimaryText.color }}
              className="AddWidgetHeaderSub"
            >
              See the latest ratings for projects.
            </p>
          </div>
          <AddRatingWidget addWidget={addWidget} addwidgetRow={addwidgetRow} />
          <div className="AddWidgetHeader">
            <p
              style={{ color: globalTheme.figmaPrimaryTextSub.color }}
              className="AddWidgetHeaderTitle"
            >
              Users
            </p>
            <p
              style={{ color: globalTheme.figmaPrimaryText.color }}
              className="AddWidgetHeaderSub"
            >
              Filter by new, active, and top users.
            </p>
          </div>
          <AddUserWidget addWidget={addWidget} addwidgetRow={addwidgetRow} />
        </>
      )}
    </div>
  );
}
