import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import aa from "search-insights";
import Autocomplete from "@mui/material/Autocomplete";
import ButtonBase from "@mui/material/ButtonBase";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import DOMPurify from "dompurify";
import { materialFormsStylesSearch } from "../theme/globalMuiTheme";
import moment from "moment";
import { algoliaListingsRelevance } from "./algoliaSearch";
import { useDeviceState, useThemeState } from "../utils/globalStates";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../firebase/auth";
import "./Search.css";

moment().format();

export default function SearchInput() {
  const [user] = useAuthState(firebaseAuth);
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const deviceState = useDeviceState();
  const globalTheme = useThemeState((state) => state.globalTheme);

  useEffect(() => {
    let tempArray: any[] = [];
    if (query !== "") {
      setLoading(true);
      algoliaListingsRelevance
        .search(query, { clickAnalytics: true, userToken: user?.uid })
        .then((res) => {
          for (let i = 0; i < res.hits.length; i++) {
            const tempListing = res.hits[i];
            tempArray.push({
              data: tempListing,
              type: "query",
              searchId: res.queryID,
            });
          }

          // Get last visited listings from local storage

          let lastVisited = localStorage.getItem("visitedListings");
          if (lastVisited !== null && lastVisited !== undefined) {
            let lastVisitedParsed = JSON.parse(lastVisited);
            if (lastVisitedParsed.length > 0) {
              tempArray.push({ data: "Last Visited", type: "title" });
              for (let i = 0; i < lastVisitedParsed.length; i++) {
                const tempListingVisited = lastVisitedParsed[i];
                tempArray.push({
                  data: tempListingVisited,
                  type: "lastVisited",
                });
              }
            }
          }
          setResults(tempArray);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  }, [query]);

  const onResultClick = async (
    id: string,
    index: number,
    objectId: string,
    searchId: string
  ) => {
    console.log("clicked");
    try {
      aa("clickedObjectIDsAfterSearch", {
      index: "gath3r_listings",
      eventName: "Listing Clicked after Search",
      queryID: searchId,
      objectIDs: [id],
      positions: [index + 1],
    });
    } catch (error) {
      console.error(error);
      console.log(error)
    }
    navigate(`/projects/${id}`);
  };

  const onLastVisitedClick = async (id: string, objectId: string) => {
    navigate(`/projects/${id}`);
    try {
      aa("clickedObjectIDs", {
      index: "gath3r_listings",
      eventName: "Listing Clicked",
      objectIDs: [id],
    });
    } catch (error) {
      console.error(error);
      console.log(error)
    }
  };

  // Replace <em> tags with <strong> tags
  const replaceEm = (str: string) => {
    return str.replace(/<em>/g, "<strong>").replace(/<\/em>/g, "</strong>");
  };

  useEffect(() => {
    let tempArray: any[] = [];
    let lastVisited = localStorage.getItem("visitedListings");
    if (lastVisited !== null && lastVisited !== undefined) {
      let lastVisitedParsed = JSON.parse(lastVisited);
      if (lastVisitedParsed.length > 0) {
        tempArray.push({ data: "Last Visited", type: "title" });
        for (let i = 0; i < lastVisitedParsed.length; i++) {
          const tempListingVisited = lastVisitedParsed[i];
          tempArray.push({
            data: tempListingVisited,
            type: "lastVisited",
          });
        }
      }
    }
    setResults(tempArray);
  }, []);

  const styles = {
    autoComplete: {
      position: "absolute",
      zIndex: 9998,
      top: "30px",
      left: "5%",
      transition: "transform 0.3s ease",
      width: "calc(90% - 50px)",
      maxWidth: "800px",
      "@media (min-width: 900px)": {
        left: "calc(50% - 400px)",
      },
      "& .MuiOutlinedInput-root": {
        height: "35px",
        borderRadius: "10px",
        color: globalTheme.backgroundMainOffYellow.color,
        borderColor: globalTheme.backgroundMainOffYellow.color,
        borderWidth: "1px",
        backgroundColor: "transparent",
        "& fieldset": {
          borderColor: globalTheme.backgroundMainOffYellow.color,
          borderWidth: "1px",
          color: globalTheme.backgroundMainOffYellow.color,
        },
        "&:hover fieldset": {
          borderColor: globalTheme.backgroundMainOffYellow.color,
          borderWidth: "1px",
          color: globalTheme.backgroundMainOffYellow.color,
        },
        "&.Mui-focused fieldset": {
          borderColor: globalTheme.backgroundMainOffYellow.color,
          color: globalTheme.backgroundMainOffYellow.color,
        },
      },
      "& label.Mui-focused": {
        color: globalTheme.backgroundMainOffYellow.color,
      },
      "& label": {
        color: globalTheme.backgroundMainOffYellow.color,
      },
    },
  };

  return (
    <Autocomplete
      freeSolo
      fullWidth
      sx={{
        ...styles.autoComplete,
        transform: deviceState.isNotificationOpen
          ? "translateY(90px)"
          : "translateY(0px)",
      }}
      size="small"
      filterOptions={(x) => x}
      loading={loading}
      getOptionLabel={(option: any) =>
        option.type === "title" ? option.data : option.data.name
      }
      renderOption={(props: any, option: any) => {
        return option.type === "title" ? (
          <p key={option.data} className="AutocompleteResultHeader">
            {" "}
            {option.data}
          </p>
        ) : option.type === "lastVisited" ? (
          <ButtonBase
            key={option.data.visited}
            onClick={() => {
              onLastVisitedClick(option.data.id, option.data.objectID);
            }}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "space-between",
              width: "100%",
              padding: "10px",
              backgroundColor: "#fff",
            }}
          >
            <p className="AutocompleteResultTitle">{option.data.name}</p>
            <p className="AutocompleteResultText">
              {moment(option.data.visited).fromNow()}
            </p>
          </ButtonBase>
        ) : (
          <ButtonBase
            key={option.data.id}
            onClick={() => {
              onResultClick(
                option.data.id,
                props["data-option-index"],
                option.data.objectID,
                option.searchId
              );
            }}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "space-between",
              width: "100%",
              padding: "10px",
              backgroundColor: "#fff",
            }}
          >
            <p
              className="AutocompleteResultTitle"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  replaceEm(option.data._highlightResult.name.value),
                  {
                    FORBID_ATTR: ["style"],
                    FORBID_TAGS: ["style", "a"],
                  }
                ),
              }}
            ></p>
            <p
              className="AutocompleteResultText"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  replaceEm(
                    "in " + option.data._highlightResult.category.value
                  ),
                  {
                    FORBID_ATTR: ["style"],
                    FORBID_TAGS: ["style", "a"],
                  }
                ),
              }}
            ></p>
          </ButtonBase>
        );
      }}
      options={results}
      renderInput={(params) => (
        <TextField
          {...params}
          value={query}
          onChange={(event) => {
            setQuery(event.target.value);
          }}
          placeholder="Search for..."
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <React.Fragment>
                <SearchIcon
                  color="inherit"
                  sx={{ fontSize: "20px", marginLeft: "5px", opacity: 0.6 }}
                />
              </React.Fragment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={"20px"} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
