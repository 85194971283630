import React, { useState, useEffect } from "react";
import { marked } from "marked";
import CircularProgress from "@mui/material/CircularProgress";
import { searchClient } from "@rss3/js-sdk";
import { useRss3State, useThemeState } from "../../../../components/utils/globalStates";
import { parseIpfsLink, testIfMarkdown } from "../../../../components/utils/parsers";
import moment from "moment";
import styles from "./news.module.css";
import Rss3Item from "./Rss3Item";

moment().format();

interface ListingDialogProps {
  activeListing: string;
}

let isFetching = false;
export default function Rss3List({ activeListing }: ListingDialogProps) {
  const [loading, setLoading] = useState(false);
  const globalTheme = useThemeState((state) => state.globalTheme);

  const { listings, setListings, isFetched, setIsFetched } = useRss3State();

  useEffect(() => {
    const fetchListings = async () => {
      if (isFetching) return;
      if (activeListing === "") return;
      if (isFetched) return;
      isFetching = true;
      setLoading(true);
      try {
        const searchResults = await searchClient().activities({
          keyword: activeListing,
          limit: 5,
          platform: ["CROSSBELL", "MIRROR"],
        });
        if (
          searchResults.data !== undefined &&
          searchResults.data.docs !== undefined &&
          searchResults.data.docs.length > 0
        ) {
          let tempArray: any[] = [];
          for (let i = 0; i < searchResults.data.docs.length; i++) {
            const tempListing = searchResults.data.docs[i];
            if (
              (tempListing.platform === "Crossbell" ||
                tempListing.platform === "Mirror") &&
              tempListing.actions !== undefined &&
              (tempListing.actions[0].platform === "Crossbell" ||
                tempListing.actions[0].platform === "Mirror") &&
              tempListing.actions[0].metadata !== undefined &&
              tempListing.actions[0].metadata !== null &&
              tempListing.actions[0].metadata.title !== undefined &&
              tempListing.actions[0].metadata.title !== null &&
              tempListing.actions[0].metadata.title !== "" &&
              tempListing.actions[0].metadata.body !== undefined &&
              tempListing.actions[0].metadata.body !== null &&
              tempListing.actions[0].metadata.body !== ""
            ) {
              let tempImg = "";
              if (
                tempListing.actions[0].metadata.media !== null &&
                tempListing.actions[0].metadata.media !== undefined &&
                tempListing.actions[0].metadata.media.length > 0 &&
                tempListing.actions[0].metadata.media[0].address !== null &&
                tempListing.actions[0].metadata.media[0].address
              ) {
                tempImg = parseIpfsLink(
                  tempListing.actions[0].metadata.media[0].address
                );
              }
              tempArray.push({
                provider: "RSS3",
                title: tempListing.actions[0].metadata.title,
                body: testIfMarkdown(tempListing.actions[0].metadata.body)
                  ? marked.parse(tempListing.actions[0].metadata.body)
                  : tempListing.actions[0].metadata.body,
                handle: tempListing.actions[0].metadata.handle,
                timestamp: Number(tempListing.timestamp) * 1000,
                platform: tempListing.platform,
                img: tempImg,
                isMd: testIfMarkdown(tempListing.actions[0].metadata.body),
                link:
                  tempListing.platform === "Crossbell"
                    ? `https://crossbell.io/notes/${tempListing.actions[0].metadata.profile_id}-${tempListing.actions[0].metadata.publication_id}`
                    : `${tempListing.actions[0].metadata.author_url}/${tempListing.actions[0].metadata.publication_id}`,
              });
            }
          }
          tempArray.sort((a, b) => {
            return b.timestamp - a.timestamp;
          });
          setListings(tempArray);
        }
        setIsFetched(true);
        isFetching = false;
        setLoading(false);
      } catch (error) {
        console.error(error);
        isFetching = false;
        setLoading(false);
        setIsFetched(true);
      }
    };
    fetchListings();
  }, []);

  return !loading ? (
    listings.length > 0 ? (
      <div className={styles.newsListingsOuter}>
        {listings.map((listing: any, index: number) => {
          return (
            <div className={styles.outsideKeyListing} key={index}>
              <Rss3Item listing={listing}/>
            </div>
          );
        })}
      </div>
    ) : (
      <div className={styles.loadingDialog}>
        <p className={styles.newsListingsText} style={{ color: globalTheme.backgroundMainOffBeige.color }}>No RSS3 articles found.</p>
      </div>
    )
  ) : (
    <div className={styles.loadingDialog}>
      <CircularProgress color="inherit" sx={{ color: globalTheme.backgroundMainOffBeige.color }}/>
    </div>
  );
}
