export const initialDashSettings = [
  [
    {
      type: "shortcut",
      rowLength: 1,
      title: "Achievements",
      uidRequired: false,
      path: "/achievements",
      icon: "editor_choice",
      bgColorCode: "#FFF8BE",
    },
    {
      type: "shortcut",
      rowLength: 1,
      title: "My Listings",
      uidRequired: true,
      path: "/users/listings/",
      icon: "folder_open",
      bgColorCode: "#FFF8BE",
    },
    {
      type: "shortcut",
      rowLength: 1,
      title: "Bookmarks",
      uidRequired: false,
      path: "/profile/bookmarks",
      icon: "book",
      bgColorCode: "#FFF8BE",
    },
    {
      type: "shortcut",
      rowLength: 1,
      title: "Following",
      uidRequired: true,
      path: "/users/follow/out/",
      icon: "account_circle",
      bgColorCode: "#FFF8BE",
    },
  ],
  [
    {
      type: "listings",
      rowLength: 4,
      filter: "createdAt",
      bgColorCode: "#FFF8BE",
      category: "",
    },
  ],
  [
    {
      type: "listings",
      rowLength: 4,
      filter: "rating",
      bgColorCode: "#FFF8BE",
      category: "",
    },
  ],
  /*[
    {
      type: "customSearch",
      rowLength: 4,
      filter: "nft",
      bgColorCode: "#AAE1A9",
      title: "Custom Search",
    },
  ],*/
  [
    {
      type: "singleListing",
      rowLength: 2,
      projectId: "trust-wallet",
      bgColorCode: "#FFF8BE",
    },
    {
      type: "count",
      rowLength: 1,
      title: "Points",
      filter: "points",
      bgColorCode: "#FFF8BE",
      path: "/achievements",
      uidRequired: false,
    },
    {
      type: "count",
      rowLength: 1,
      title: "Listings",
      filter: "listingCount",
      bgColorCode: "#FFD379",
      path: "/users/listings",
      uidRequired: true,
    },
  ],
  [
    {
      type: "singleListing",
      rowLength: 2,
      projectId: "trezor",
      bgColorCode: "#FFF8BE",
    },
    {
      type: "count",
      rowLength: 1,
      title: "Reviews",
      filter: "reviewsCount",
      bgColorCode: "#FFD379",
      path: "/reviews/user",
      uidRequired: true,
    },
    {
      type: "count",
      rowLength: 1,
      title: "Ratings",
      filter: "ratingCount",
      bgColorCode: "#FFF8BE",
      path: "/reviews/user/rating",
      uidRequired: true,
    },
  ],
  [
    {
      type: "singleListing",
      rowLength: 2,
      projectId: "zora",
      bgColorCode: "#FFF8BE",
    },
    {
      type: "singleListing",
      rowLength: 2,
      projectId: "metamask",
      bgColorCode: "#FFF8BE",
    },
  ],
  [
    {
      type: "users",
      rowLength: 4,
      title: "Top Users",
      filter: "points",
      bgColorCode: "#FFF8BE",
    },
  ],
];
