import React, { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../../components/firebase/auth";
import { query, collection, where, getDocs, limit, orderBy } from "firebase/firestore";
import { firestoreDb } from "../../../../components/firebase/firestore";

import UserPageAchievementsItem from "./UserPageAchievementsItem";
import {
  useThemeState,
  useDeviceState,
} from "../../../../components/utils/globalStates";
import "./UserPageComp.css";

let initLoading = false;

export default function UserPageAchievements({ userId }: { userId: string }) {
  const [user] = useAuthState(firebaseAuth);
  const [isLoading, setIsLoading] = useState(true);
  const [achievements, setAchievements] = useState<any[]>([]);
  const globalTheme = useThemeState((state) => state.globalTheme);
  const deviceState = useDeviceState((state) => state);

  useEffect(() => {
    if (userId === null || userId === undefined) {
      return;
    }
    if (initLoading) return;
    initLoading = true;
    const getData = async () => {
      try {
        const achievementsQ = query(
          collection(firestoreDb, "userAchievements"),
          where("uid", "==", userId),
          orderBy("createdAt", "desc"),
          limit(8)
        );
        const achievementsSnap = await getDocs(achievementsQ);
        const achievementsData: any[] = [];
        achievementsSnap.forEach((doc) => {
          achievementsData.push(doc.data());
        });
        setAchievements(achievementsData);
        setIsLoading(false);
        initLoading = false;
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        initLoading = false;
      }
    };
    getData();
  }, [userId]);
  return (
    <div className="UserPageV2Achievements">
      {isLoading ? (
        <div className="UserPageV2AchievementsLoading">
          <Skeleton
            variant="rounded"
            sx={{
              width: "20% !important",
              height: "70px !important",
            }}
          />
          <Skeleton
            variant="rounded"
            sx={{
              width: "20% !important",
              height: "70px !important",
            }}
          />
          <Skeleton
            variant="rounded"
            sx={{
              width: "20% !important",
              height: "70px !important",
            }}
          />
          <Skeleton
            variant="rounded"
            sx={{
              width: "20% !important",
              height: "70px !important",
            }}
          />
        </div>
      ) : achievements.length > 0 ? (
        <div
          className="UserPageV2AchievementsSlider"
          style={{
            justifyContent:
              deviceState.deviceWidth >= 700
                ? achievements.length >= 8
                  ? "space-between"
                  : "space-evenly"
                : deviceState.deviceWidth >= 400
                ? achievements.length >= 6
                  ? "space-between"
                  : "space-evenly"
                : achievements.length >= 4
                ? "space-between"
                : "space-evenly",
          }}
        >
          {achievements.map((achievement, index) => {
            if (deviceState.deviceWidth < 400 && index < 4) {
              return (
                <UserPageAchievementsItem
                  achievement={achievement}
                  key={index}
                />
              );
            } else if (
              deviceState.deviceWidth >= 400 &&
              deviceState.deviceWidth < 700 &&
              index < 6
            ) {
              return (
                <UserPageAchievementsItem
                  achievement={achievement}
                  key={index}
                />
              );
            } else if (deviceState.deviceWidth >= 700 && index < 8) {
              return (
                <UserPageAchievementsItem
                  achievement={achievement}
                  key={index}
                />
              );
            }
          })}
        </div>
      ) : (
        userId === user?.uid && (
          <div className="UserPageV2AchievementsInner">
            <p
              className="ProfileCountSub"
              style={{
                color: globalTheme.figmaSecondaryText.color,
                marginTop: "0px",
              }}
            >
              No achievements yet
            </p>
          </div>
        )
      )}
    </div>
  );
}
