import React, {useState, useEffect} from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import WalletPage from './routes/WalletPage';
import WalletsList from './routes/WalletsList';
import WalletsLatest from './routes/WalletsLatest';
import './WalletsStart.css';

export default function WalletsStart() {

  return (
    <div className="WalletsStart" >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Wallets</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<WalletsLatest />} />
        <Route path="/list" element={<WalletsList />} />
        <Route path="/page/:walletId" element={<WalletPage />} />
        <Route path="*" element={<WalletsLatest />} />
      </Routes>
    </div>
  );
}
