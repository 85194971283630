import React, { useState, useEffect } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TextField from "@mui/material/TextField";
import { ButtonBase } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import { doc, onSnapshot } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../components/firebase/functions";
import { Helmet } from "react-helmet-async";

import { firestoreDb } from "../../../components/firebase/firestore";
import { isValidUrl } from "../../../components/utils/parsers";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import {
  useSnackbarState,
  useSnackbarErrorState,
  useHeaderSettingsState,
  useTabSettingsState,
  useAuthStates,
  useThemeState,
  useDeviceState,
} from "../../../components/utils/globalStates";

import { PfpViewer } from "../../../components/ui/img/PfpViewer";
import ProfileReviewPreview from "./components/ProfileReviewsPreview";
import UserPageCount from "../../user/page/components/UserPageCount";
import ProfileAchievementsSlider from "./components/ProfileAchievementsSlider";
import "./Profile.css";

export default function Profile() {
  const snackbarStates = useSnackbarState((state) => state);
  const snackbarErrorStates = useSnackbarErrorState((state) => state);
  const headerSettingsState = useHeaderSettingsState();
  const authStates = useAuthStates((state) => state);
  const tabSettingsState = useTabSettingsState();
  const deviceState = useDeviceState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [user, loading, error] = useAuthState(firebaseAuth);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState("");

  const [aboutText, setAboutText] = useState("");
  const [aboutTextUpdate, setAboutTextUpdate] = useState("");
  const [isUpdatingAbout, setIsUpdatingAbout] = useState(false);
  const [isEditingAbout, setIsEditingAbout] = useState(false);

  const [gPoints, setGPoints] = useState(0);
  const [userData, setUserData] = useState<any>({});

  const onEditAboutClick = () => {
    setIsEditingAbout(true);
  };

  const onEditAboutUpdate = async () => {
    if (aboutTextUpdate === aboutText) return;
    if (user === null || user === undefined) return;

    setIsUpdatingAbout(true);
    snackbarStates.setMessage("Updating info...");
    snackbarStates.setOpenLength(null);
    snackbarStates.setIsActionLoading(true);
    snackbarStates.setIsOpen(true);
    try {
      const updateAbout = httpsCallable(
        firebaseFunctions,
        "profile-about_update"
      );
      const tokenCheck = await user.getIdToken();
      await updateAbout({ token: tokenCheck, about: aboutTextUpdate });
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("Info updated");
      snackbarStates.setOpenLength(3000);
      setAboutText(aboutTextUpdate);
      setIsEditingAbout(false);
      setIsUpdatingAbout(false);
    } catch (error) {
      console.error(error);
      snackbarStates.setIsOpen(false);
      snackbarStates.setIsActionLoading(false);
      snackbarStates.setMessage("");
      snackbarErrorStates.setMessage("Error updating info, please try again");
      snackbarErrorStates.setOpenLength(3000);
      snackbarErrorStates.setIsOpen(true);
      setIsUpdatingAbout(false);
    }
  };

  useEffect(() => {
    if (user === null || user === undefined) return;
    if (
      user.photoURL !== null &&
      user.photoURL !== undefined &&
      isValidUrl(user.photoURL)
    ) {
      if (imageUrl !== user.photoURL) setImageUrl(user.photoURL);
    } else {
      if (imageUrl !== "") setImageUrl("");
    }
  }, [user, imageUrl]);

  // Fetch users private data

  useEffect(() => {
    if (authStates.userData === null || authStates.userData === undefined)
      return;

    setAboutText(authStates.userData.about);
    setAboutTextUpdate(authStates.userData.about);
    if (
      authStates.userData.points !== null &&
      authStates.userData.points !== undefined
    ) {
      setGPoints(authStates.userData.points);
    }
    setUserData(authStates.userData);
    setIsLoading(false);
  }, [authStates.userData]);

  useEffect(() => {
    headerSettingsState.setIsSettingsButtonOpen(true);
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.backgroundMainBeige.color);
    return () => {
      headerSettingsState.setIsSettingsButtonOpen(false);
      tabSettingsState.setColor(globalTheme.backgroundMainYellow.color);
      tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      headerSettingsState.setIsLogoOpen(false);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  const styles = {
    aboutForm: {
      width: "100%",
      color: globalTheme.backgroundMainOffBeige.color + " !important",
      borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
      "& .MuiInputBase-root": {
        color: globalTheme.backgroundMainOffBeige.color + " !important",
        borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
        "& textarea": {
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
          color: globalTheme.backgroundMainOffBeige.color + " !important",
          fontSize: "15px !important",
        },
        "& input": {
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
          color: globalTheme.backgroundMainOffBeige.color + " !important",
          fontSize: "15px !important",
        },
        "&:hover": {
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
          color: globalTheme.backgroundMainOffBeige.color + " !important",
        },
        "&:after": {
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
          color: "#39393c !important",
        },
        "&:before": {
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
          color: "#39393c !important",
        },
        "&.Mui-focused": {
          borderColor: globalTheme.backgroundMainOffBeige.color + " !important",
          color: "#39393c !important",
        },
      },
    },
    interestsSelect: {
      width: "95%",
      marginBottom: "12px",
      color: "#39393c !important",
      borderColorBottom: "#39393c !important",
      "&:after": {
        borderColor: "#39393c !important",
        color: "#39393c !important",
      },
    },
  };

  return (
    <div
      className="Profile"
      style={{ backgroundColor: globalTheme.backgroundMainBeige.color, paddingTop: "80px" }}
    >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Profile</title>
      </Helmet>
      <div
        className="ProfileHeader"
        style={{
          borderColor: globalTheme.solidMainBlack.color,
          position: "relative",
          boxShadow:
            deviceState.deviceWidth > 550
              ? globalTheme.boxShadow.boxShadow
              : "none",
          backgroundColor:
            deviceState.deviceWidth > 550
              ? globalTheme.backgroundMainBeigeDark.color
              : "transparent",
        }}
      >
        <div
          className="ProfileHeaderInner"
          style={
            {
              //border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
            }
          }
        >
          <ButtonBase
            onClick={() => navigate("/profile/edit/image")}
            className="ProfileAvatar"
            sx={{
              border: `1px solid ${globalTheme.solidMainBlack.color}`,
              borderRadius: "10px",
            }}
          >
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                className="ProfileAvatarImg"
                sx={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "8px",
                }}
              />
            ) : userData.pfpString === null ||
              userData.pfpString === undefined ||
              userData.pfpString === "" ? (
              <div
                className="ProfileAvatarImg"
                style={{ position: "relative" }}
              >
                <PfpViewer pfpString={""} randomize={true} />
                <EditOutlinedIcon
                  sx={{
                    width: "20%",
                    height: "20%",
                    color: globalTheme.figmaPrimaryText.color,
                    margin: 0,
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: globalTheme.figmaBeigeDark.color,
                    padding: "3px",
                    borderRadius: "0px 0px 0px 9px",
                    borderLeft: `1.5px solid ${globalTheme.figmaPrimaryText.color}`,
                    borderBottom: `1.5px solid ${globalTheme.figmaPrimaryText.color}`,
                  }}
                />
              </div>
            ) : (
              <div
                className="ProfileAvatarImg"
                style={{ position: "relative" }}
              >
                <PfpViewer pfpString={userData.pfpString} randomize={false} />
                <EditOutlinedIcon
                  sx={{
                    width: "20%",
                    height: "20%",
                    color: globalTheme.figmaPrimaryText.color,
                    margin: 0,
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: globalTheme.figmaBeigeDark.color,
                    padding: "3px",
                    borderRadius: "0px 0px 0px 8px",
                    borderLeft: `1.5px solid ${globalTheme.figmaPrimaryText.color}`,
                    borderBottom: `1.5px solid ${globalTheme.figmaPrimaryText.color}`,
                  }}
                />
              </div>
            )}
          </ButtonBase>
          <div
            className="ProfileHeaderInnerText"
            style={
              {
                //border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
              }
            }
          >
            <p
              className="UserPageUsername"
              style={{ color: globalTheme.backgroundMainOffBeigeDark.color }}
            >
              {userData.displayName}
            </p>
            <div className="ProfileAbout" style={{ marginBottom: "15px" }}>
              {isEditingAbout ? (
                <>
                  <TextField
                    disabled={isUpdatingAbout}
                    sx={styles.aboutForm}
                    multiline
                    variant="standard"
                    placeholder={
                      aboutText === ""
                        ? "Tell other users about yourself"
                        : aboutText
                    }
                    value={aboutTextUpdate}
                    onChange={(e) => setAboutTextUpdate(e.target.value)}
                  />
                  <div className="ProfileAboutEditButtons">
                    <ButtonBase
                      disabled={isUpdatingAbout}
                      onClick={() => {
                        setIsEditingAbout(false);
                      }}
                      sx={{ width: "40%" }}
                    >
                      <div
                        className="ProfileEditLinkInnerLink"
                        style={{
                          color: globalTheme.backgroundMainOffBeige.color,
                        }}
                      >
                        <CloseOutlinedIcon
                          color="inherit"
                          sx={{ fontSize: "16px", marginRight: "5px" }}
                        />
                        <p className="ProfileEditLinkText">Cancel</p>
                      </div>
                    </ButtonBase>
                    <ButtonBase
                      disabled={isUpdatingAbout}
                      onClick={onEditAboutUpdate}
                      sx={{ width: "40%" }}
                    >
                      <div
                        className="ProfileEditLinkInnerLink"
                        style={{
                          color: globalTheme.backgroundMainOffBeige.color,
                        }}
                      >
                        <EditOutlinedIcon
                          color="inherit"
                          sx={{ fontSize: "16px", marginRight: "5px" }}
                        />
                        <p className="ProfileEditLinkText">
                          {isUpdatingAbout ? "Updating..." : "Update"}
                        </p>
                      </div>
                    </ButtonBase>
                  </div>
                </>
              ) : (
                <ButtonBase onClick={onEditAboutClick} sx={{ width: "100%" }}>
                  {isLoading ? (
                    <div className="ProfileAboutInner">
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "17px", width: "80%" }}
                      />
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "17px", width: "80%" }}
                      />
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "17px", width: "30%" }}
                      />
                    </div>
                  ) : (
                    <div className="ProfileAboutInner">
                      {aboutText === "" ? (
                        <p
                          className="ProfileAboutInnerText"
                          style={{
                            color: globalTheme.backgroundMainOffBeige.color,
                          }}
                        >
                          Tell other users about yourself
                        </p>
                      ) : (
                        <p
                          className="ProfileAboutInnerText"
                          style={{
                            color: globalTheme.backgroundMainOffBeige.color,
                          }}
                        >
                          {aboutText}
                        </p>
                      )}
                      <p
                        className="ProfileAboutInnerText"
                        style={{
                          color: globalTheme.figmaLinks.color,
                          marginTop: "5px",
                        }}
                      >
                        (Tap to edit)
                      </p>
                    </div>
                  )}
                </ButtonBase>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="ProfileCountOuter">
        <UserPageCount
          userId={user?.uid}
          updateFollowers={false}
          userPoints={userData.points}
          userListingCount={userData.listingCount}
          userReviewsCount={userData.reviewsCount}
          userRatingsCount={userData.ratingCount}
          followingCount={userData.followingCount}
          followersCount={userData.followersCount}
        />
      </div>

      {user !== null && user !== undefined && (
        <div
          className="ProfileAbout"
          style={{ marginTop: "-15px", marginBottom: "-15px" }}
        >
          <ProfileAchievementsSlider userId={user.uid} />
        </div>
      )}
      {user !== null && user !== undefined && (
        <div className="ProfileAbout">
          <ProfileReviewPreview userId={user.uid} />
        </div>
      )}
    </div>
  );
}
