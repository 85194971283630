import React, { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import { useThemeState } from "../../../../components/utils/globalStates";
import "./ProfileSettingsComp.css";

export default function ColorThemePicker() {
  const themeState = useThemeState();
  const [systemColorTheme, setSystemColorTheme] = useState<boolean>(false);
  const [colorTheme, setColorTheme] = useState<boolean>(false);
  const globalTheme = useThemeState((state) => state.globalTheme);

  // Check localstorage for isSystemTheme and isDarkTheme
  useEffect(() => {
    if (localStorage.getItem("isSystemTheme") === "true") {
      setSystemColorTheme(true);
    } else {
      setSystemColorTheme(false);
    }

    if (localStorage.getItem("isDarkTheme") === "true") {
      setColorTheme(true);
    } else {
      setColorTheme(false);
    }
  }, []);

  const onSystemChange = (value: boolean) => {
    setSystemColorTheme(value);
    localStorage.setItem("isSystemTheme", value.toString());
    if (value) {
      themeState.setIsDark(
        window.matchMedia("(prefers-color-scheme: dark)").matches
      );
    } else {
      themeState.setIsDark(colorTheme);
    }
  };

  const onColorChange = (value: boolean) => {
    setColorTheme(value);
    localStorage.setItem("isDarkTheme", value.toString());
    if (!systemColorTheme) {
      themeState.setIsDark(value);
    }
  };

  const switchStyle = {
    color: globalTheme.pickerOrangeYellow.color + " !important",
    marginTop: "-5px",
    "& .Mui-checked": {
      color: globalTheme.pickerOrangeYellow.color + " !important",
    },
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: globalTheme.pickerOrangeYellow.color + " !important",
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: globalTheme.pickerOrangeYellow.color + " !important",
    },
    "& .MuiSwitch-track": {
      backgroundColor: globalTheme.textMainGrey.color + " !important",
    },
  };

  return (
    <div className="SocialConfig">
      <div className="SocialConfigInner" style={{ marginBottom: "5px" }}>
        <div className="SocialConfigTextField">
          <p
            className="ProfileSettingsSubTitle"
            style={{
              margin: "0px",
              color: globalTheme.backgroundMainOffBeige.color,
            }}
          >
            Follow System Theme
          </p>
        </div>
        <Switch
          sx={switchStyle}
          checked={systemColorTheme}
          onChange={() => onSystemChange(!systemColorTheme)}
        />
      </div>
      <div className="SocialConfigInner">
        <div className="SocialConfigTextField">
          <p
            className="ProfileSettingsSubTitle"
            style={{
              margin: "0px",
              opacity: systemColorTheme ? 0.5 : 1,
              color: globalTheme.backgroundMainOffBeige.color,
            }}
          >
            Dark Mode
          </p>
        </div>
        <Switch
          disabled={systemColorTheme}
          sx={switchStyle}
          checked={colorTheme}
          onChange={() => onColorChange(!colorTheme)}
        />
      </div>
    </div>
  );
}
