import { ButtonBase } from "@mui/material";
import { useThemeState } from "../../../components/utils/globalStates";
import "./DashboardAddWidget.css";

export default function ColorPicker({
  setPickedColor,
  pickedColor,
}: {
  setPickedColor: Function;
  pickedColor: string;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  return (
    <div className="WidgetColorPicker">
        <p className="WidgetColorPickerTitle">Pick color:</p>
      <div className="WidgetColorPickerInner">
        <ButtonBase
          onClick={() => setPickedColor(globalTheme.colorPaletteBlue.color)}
          className="ColorPicker"
          style={{
            backgroundColor: globalTheme.colorPaletteBlue.color,
            width: "100%",
            aspectRatio: "1/1",
          }}
        ></ButtonBase>
        {pickedColor === globalTheme.colorPaletteBlue.color && (
          <div
            className="WidgetColorPickerInnerOverlay"
            style={{ backgroundColor: globalTheme.figmaPrimaryText.color }}
          ></div>
        )}
      </div>
      <div className="WidgetColorPickerInner">
        <ButtonBase
          onClick={() => setPickedColor(globalTheme.colorPaletteGreen.color)}
          className="ColorPicker"
          style={{
            backgroundColor: globalTheme.colorPaletteGreen.color,
            width: "100%",
            aspectRatio: "1/1",
          }}
        ></ButtonBase>
        {pickedColor === globalTheme.colorPaletteGreen.color && (
          <div
            className="WidgetColorPickerInnerOverlay"
            style={{ backgroundColor: globalTheme.figmaPrimaryText.color }}
          ></div>
        )}
      </div>

      <div className="WidgetColorPickerInner">
        <ButtonBase
          onClick={() => setPickedColor(globalTheme.colorPalettepink.color)}
          className="ColorPicker"
          style={{
            backgroundColor: globalTheme.colorPalettepink.color,
            width: "100%",
            aspectRatio: "1/1",
          }}
        ></ButtonBase>
        {pickedColor === globalTheme.colorPalettepink.color && (
          <div
            className="WidgetColorPickerInnerOverlay"
            style={{ backgroundColor: globalTheme.figmaPrimaryText.color }}
          ></div>
        )}
      </div>
      <div className="WidgetColorPickerInner">
        <ButtonBase
          onClick={() => setPickedColor(globalTheme.colorPalettePurple.color)}
          className="ColorPicker"
          style={{
            backgroundColor: globalTheme.colorPalettePurple.color,
            width: "100%",
            aspectRatio: "1/1",
          }}
        ></ButtonBase>
        {pickedColor === globalTheme.colorPalettePurple.color && (
          <div
            className="WidgetColorPickerInnerOverlay"
            style={{ backgroundColor: globalTheme.figmaPrimaryText.color }}
          ></div>
        )}
      </div>
      <div className="WidgetColorPickerInner">
        <ButtonBase
          onClick={() => setPickedColor(globalTheme.colorPaletteBeige.color)}
          className="ColorPicker"
          style={{
            backgroundColor: globalTheme.colorPaletteBeige.color,
            width: "100%",
            aspectRatio: "1/1",
          }}
        ></ButtonBase>
        {pickedColor === globalTheme.colorPaletteBeige.color && (
          <div
            className="WidgetColorPickerInnerOverlay"
            style={{ backgroundColor: globalTheme.figmaPrimaryText.color }}
          ></div>
        )}
      </div>
      <div className="WidgetColorPickerInner">
        <ButtonBase
          onClick={() => setPickedColor(globalTheme.figmaGreyPastel.color)}
          className="ColorPicker"
          style={{
            backgroundColor: globalTheme.figmaGreyPastel.color,
            width: "100%",
            aspectRatio: "1/1",
          }}
        ></ButtonBase>
        {pickedColor === globalTheme.figmaGreyPastel.color && (
          <div
            className="WidgetColorPickerInnerOverlay"
            style={{ backgroundColor: globalTheme.figmaPrimaryText.color }}
          ></div>
        )}
      </div>
      <div className="WidgetColorPickerInner">
        <ButtonBase
          onClick={() => setPickedColor(globalTheme.colorPaletteYellow.color)}
          className="ColorPicker"
          style={{
            backgroundColor: globalTheme.colorPaletteYellow.color,
            width: "100%",
            aspectRatio: "1/1",
          }}
        ></ButtonBase>
        {pickedColor === globalTheme.colorPaletteYellow.color && (
          <div
            className="WidgetColorPickerInnerOverlay"
            style={{ backgroundColor: globalTheme.figmaPrimaryText.color }}
          ></div>
        )}
      </div>
      <div className="WidgetColorPickerInner">
        <ButtonBase
          onClick={() => setPickedColor(globalTheme.colorPaletteRed.color)}
          className="ColorPicker"
          style={{
            backgroundColor: globalTheme.colorPaletteRed.color,
            width: "100%",
            aspectRatio: "1/1",
          }}
        ></ButtonBase>
        {pickedColor === globalTheme.colorPaletteRed.color && (
          <div
            className="WidgetColorPickerInnerOverlay"
            style={{ backgroundColor: globalTheme.figmaPrimaryText.color }}
          ></div>
        )}
      </div>
    </div>
  );
}
