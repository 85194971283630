import { Routes, Route } from "react-router-dom";
import ProjectPageV2 from "./page/ProjectPageV2";
import "./Listings.css";

export default function ProjectsStart() {
  return (
    <div className="Listings">
      <Routes>
        <Route path="/:listingId" element={<ProjectPageV2 />} />
      </Routes>
    </div>
  );
}
