import React, { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { doc, setDoc } from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";

import { useThemeState } from "../../../components/utils/globalStates";
import "./AdminComponents.css";

export default function AdminNewsletterAddEmail() {
  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("Add email");

  const globalTheme = useThemeState((state) => state.globalTheme);

  const onUpload = async () => {
    try {
      setLoading(true);
      const docRef = doc(firestoreDb, "newsletter", userEmail.toLowerCase());
      await setDoc(docRef, {
        email: userEmail,
        subscribedAt: Date.now(),
      });
      setUserEmail("");
      setLoading(false);
    } catch (e) {
      console.error(e);
      setError("Error: Try again");
      setLoading(false);
    }
  };

  const materialListingFormStyles = {
  "& .MuiOutlinedInput-root": {
    color: globalTheme.backgroundMainOffBeige.color,
    borderColor: globalTheme.backgroundMainOffBeige.color,
    "& fieldset": {
      color: globalTheme.backgroundMainOffBeige.color,
    borderColor: globalTheme.backgroundMainOffBeige.color,
    },
    "&:hover fieldset": {
      color: globalTheme.backgroundMainOffBeige.color,
    borderColor: globalTheme.backgroundMainOffBeige.color,
    },
    "&.Mui-focused fieldset": {
      color: globalTheme.backgroundMainOffBeige.color,
    borderColor: globalTheme.backgroundMainOffBeige.color,
    },
  },
  "& label.Mui-focused": {
    color: globalTheme.backgroundMainOffBeige.color,
  },
  "& label": {
    color: globalTheme.backgroundMainOffBeige.color,
  },
};

  return (
    <div
      className="AdminNewsletterAddEmail"
      style={{
        borderBottom: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
        borderTop: `1px solid ${globalTheme.backgroundMainOffBeige.color}`,
        color: globalTheme.backgroundMainOffBeige.color,
      }}
    >
      <TextField
        label="Add new email to newsletter list"
        disabled={loading}
        variant="outlined"
        size="small"
        sx={{ ...materialListingFormStyles, marginRight: "20px", flex: 1 }}
        value={userEmail}
        placeholder="Email"
        onChange={(e) => setUserEmail(e.target.value)}
      />
      <Button
        variant="outlined"
        color="inherit"
        sx={{ fontSize: "14px", minWidth: "30%", }}
        onClick={onUpload}
        disabled={userEmail === "" || loading}
      >
        {loading ? <CircularProgress color="inherit" size={"20px"} /> : error}
      </Button>
    </div>
  );
}
