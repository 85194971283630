import React, { useState, useEffect } from "react";

import { ButtonBase } from "@mui/material";
import { Link } from "react-router-dom";
import { useThemeState } from "../../utils/globalStates";
import { figmaColorVariables } from "../../theme/globalTheme";
import "./TopBoxes.css";

export default function IssueReportInfo() {
  const [showInfo, setShowInfo] = useState(false);
  const globalTheme = useThemeState((state) => state.globalTheme);

  // Check local storage if user has seen the info
  useEffect(() => {
    const localInfo = localStorage.getItem("issueReportInfo");
    if (localInfo !== null) {
      const dateNow = Date.now();
      const dateThen = parseInt(localInfo);
      if (isNaN(dateThen)) {
        console.error("Invalid date in local storage");
        setShowInfo(true);
      } else {
        if (dateNow - dateThen > 60 * 60 * 1000 * 24 * 7) {
          setShowInfo(true);
        } else {
          setShowInfo(false);
        }
      }
    } else {
      setShowInfo(true);
    }
  }, []);

  const onInfoClose = () => {
    setShowInfo(false);
    const dateNow = Date.now();
    localStorage.setItem("issueReportInfo", dateNow.toString());
  };

  const buttonStyles = {
    color: "#1A1A1A",
    backgroundColor: figmaColorVariables.red100,
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    paddingTop: "5px",
    paddingBottom: "5px",
    border: "1px solid #1A1A1A",
    margin: "0px",
    cursor: "pointer",
    marginTop: "10px",
    "& span": {
      marginRight: "5px",
      fontSize: "15px",
    },
    "&:hover": {
      opacity: 0.9,
    },
  };

  return (
    <div
      className="SearchAndExplore"
      style={{
        backgroundColor: globalTheme.primarySurface.color,
        border: `1px solid ${globalTheme.solidMainBlack.color}`,
        ...globalTheme.boxShadow,
        marginTop: "0px",
        marginBottom: "5px",
        maxWidth: "1170px",
      }}
    >
      <div
        className="DashboardInfoNew"
        style={{
          justifyContent: "flex-start",
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        <p
          className="DashboardInfoTitle"
          style={{
            color: globalTheme.textMainBlack.color,
            marginLeft: "1.5%",
            marginRight: "1.5%",
            marginTop: "0px",
            marginBottom: "0px",

          }}
        >
          Report an issue or suggest a feature
        </p>
      </div>
      {showInfo ? (
        <div
        className="DashboardInfoNew"
        style={{
          justifyContent: "space-between",
          marginLeft: "1.5%",
          marginRight: "1.5%",
          marginTop: "0px",
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
      >
        <div className="IssueReportInfoInner">
          <p
            className="DashboardInfoText"
            style={{
              color: globalTheme.textMainBlack.color,
            }}
          >
            Gain points by reporting issues or suggesting features, the more
            detailed the better the reward. We are always looking to improve our
            platform and your feedback is invaluable to us.
          </p>
          <ul className="DashboardInfoUL" style={{marginLeft: '-1%'}}>
            <li className="DashboardInfoLI">
              <p
                className="DashboardInfoText"
                style={{
                  color: globalTheme.textMainBlack.color,
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                Add a title and description to your report. The more detailed
                the better.
              </p>
            </li>
            <li className="DashboardInfoLI">
              <p
                className="DashboardInfoText"
                style={{
                  color: globalTheme.textMainBlack.color,
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                You can also add screenshots to your report.
              </p>
            </li>
          </ul>
          {showInfo && (
        <ButtonBase
            onClick={onInfoClose}
            sx={{
              ...buttonStyles,
            }}
          >
            <span className="material-symbols-outlined">cancel</span>
            Close
          </ButtonBase>
      )}
      {showInfo && (
        <Link
          to="/profile/settings"
                className="DashboardInfoText"
                style={{
                  color: figmaColorVariables.blue300,
                  marginTop: "10px",
                  marginBottom: "10px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <span style={{ fontSize: "80%" }}>
                You can find this tutorial in the settings if you need it.
                </span> 
              </Link>
      )}
        </div>
      </div>
      ):(
        <div
        className="DashboardInfoNew"
        style={{
          justifyContent: "space-between",
          marginLeft: "1.5%",
          marginRight: "1.5%",
          marginTop: "0px",
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
      >
        <div className="IssueReportInfoInner">
          <p
            className="DashboardInfoText"
            style={{
              color: globalTheme.textMainBlack.color,
            }}
          >
            We are always looking to improve our
            platform and your feedback is invaluable to us.
          </p>
        </div>
      </div>
      )}
    </div>
  );
}
