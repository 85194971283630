import React, { useEffect, useState } from "react";
import { useDeviceState, useThemeState } from "../utils/globalStates";
import LogoMaskOnly from "../ui/img/LogoMaskOnly";
import "./Listeners.css";

export default function NotificationListeners() {
  const deviceState = useDeviceState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [notificationBackground, setNotificationBackground] = useState(
    globalTheme.figmaPrimaryText.color
  );
  const [notificationTextColor, setNotificationTextColor] = useState(
    globalTheme.solidMainBlack.color
  );
  const [notificationText, setNotificationText] = useState("");
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  // Show alpha notification each visit to the site
  /*useEffect(() => {
    const lastNotification = localStorage.getItem("lastAlphaNotification");
    const breakpointTime = Date.now() - 60 * 1000 * 60 * 24;
    if (
      lastNotification !== null &&
      lastNotification !== undefined &&
      lastNotification !== "" &&
      parseInt(lastNotification) > breakpointTime
    ) {
      return;
    }
    setTimeout(() => {
      setNotificationBackground(globalTheme.figmaBeigeLight.color);
      setNotificationTextColor(globalTheme.figmaPrimaryText.color);
      setNotificationText(
        "Welcome to the open beta of GATH3R! Check it out and let us know what you think!"
      );
      localStorage.setItem(
            "lastAlphaNotification",
            Date.now().toString()
          );
      deviceState.setIsNotificationOpen(true);
      setIsNotificationOpen(true);
      setTimeout(() => {
        deviceState.setIsNotificationOpen(false);
        setIsNotificationOpen(false);
      }, 10000);
    }, 1000);
  }, []);*/

  const setOnline = () => {
    setNotificationBackground(globalTheme.figmaPrimaryText.color);
    setNotificationTextColor(globalTheme.solidMainBlack.color);
    setNotificationText("You are now back online, please reload the app!");
    deviceState.setIsNotificationOpen(true);
    setIsNotificationOpen(true);
    setTimeout(() => {
      deviceState.setIsNotificationOpen(false);
      setIsNotificationOpen(false);
    }, 5000);
  };

  const setOffline = () => {
    setNotificationBackground(globalTheme.figmaWarningBackground.color);
    setNotificationTextColor(globalTheme.solidMainBlack.color);
    setNotificationText(
      "You are offline and some GATH3R services may not work as expected, please check your internet connection!"
    );
    deviceState.setIsNotificationOpen(true);
    setIsNotificationOpen(true);
  };
  // Connection listener
  useEffect(() => {
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOffline);

    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOffline);
    };
  }, []);
  return (
    <div
      className="NotificationListener"
      style={{
        backgroundColor: notificationBackground,
        height: isNotificationOpen ? "90px" : "0px",
      }}
    >
      <div className="NotificationListenerInner">
        <div className="NotificationListenerLogo">
          <LogoMaskOnly maskFill={"#ffd379"} />
        </div>
        <p style={{ color: notificationTextColor }} className="bubbleText left">
          {notificationText}
        </p>
      </div>
    </div>
  );
}
