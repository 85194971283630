import React, { useState, useEffect } from "react";

import { LazyLoadImg } from "../../../components/ui/misc/LazyLoadImg";

import XLogo from "../../../components/ui/img/socials/Xlogo";
import DiscordLogo from "../../../components/ui/img/socials/DiscordLogo";
import OpenseaLogo from "../../../components/ui/img/socials/OpenseaLogo";

import { CircularProgress } from "@mui/material";
import { alchemyEth } from "../../../components/web3/alchemy";
import { useThemeState } from "../../../components/utils/globalStates";
import { removeUrlPrefix } from "../../../components/utils/parsers";
import "./WalletComp.css";
import "../routes/Wallets.css";

export default function WalletGallery({
  walletId,
}: {
  walletId: string | undefined;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [galleryFeed, setGalleryFeed] = useState<any[]>([]);
  const globalTheme = useThemeState((state) => state.globalTheme);

  const fetchGalleryFeed = async () => {
    if (walletId === undefined) return;
    if (walletId === "") return;
    if (isLoading === true) return;
    setIsLoading(true);
    try {
      let options = {
        omitMetadata: false,
        orderBy: "TRANSFERTIME",
      };
      // @ts-ignore
      let response = await alchemyEth.nft.getNftsForOwner(walletId, options);

      let ownedNfts = response.ownedNfts;
      let nftData: any[] = [];
      for (let i = 0; i < ownedNfts.length; i++) {
        let nft = ownedNfts[i];
        if (
          nft.image.cachedUrl !== undefined &&
          nft.contract.isSpam !== true &&
          nft.raw.metadata !== undefined &&
          nft.raw.metadata.name !== undefined
        ) {
          nftData.push(nft);
        }
      }
      setGalleryFeed(nftData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching gallery feed", error);
      setIsLoading(false);
    }
  };

  // Fetch gallery feed if walletId is provided
  useEffect(() => {
    fetchGalleryFeed();
  }, [walletId]);

  return isLoading ? (
    <div className="WalletBodyLoading">
      <CircularProgress sx={{ color: globalTheme.figmaSecondaryText.color }} />
      <p
        className="WalletListItemTitle"
        style={{
          color: globalTheme.figmaSecondaryText.color,
          textAlign: "center",
          marginTop: "15px",
        }}
      >
        Fetching Wallet Activity from Blockchain
      </p>
    </div>
  ) : galleryFeed.length === 0 ? (
    <div className="WalletBodyLoading">
      <p
        className="WalletListItemTitle"
        style={{
          color: globalTheme.figmaSecondaryText.color,
          textAlign: "center",
        }}
      >
        No nfts found
      </p>
    </div>
  ) : (
    <div className="WalletBody">
      <div className="WalletBodyGallery">
        {galleryFeed.map((nft, index) => {
          return (
            <div
              className="WalletGalleryItem"
              key={index}
              style={{
                backgroundColor: globalTheme.figmaWhite.color,
                border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
                ...globalTheme.boxShadow,
              }}
            >
              <>
                <p
                  className="WalletItemInnerTitle"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  {nft.name}
                </p>
                {nft.image.cachedUrl !== undefined &&
                  nft.image.cachedUrl !== null &&
                  nft.image.cachedUrl !== "" && (
                    <LazyLoadImg imgUrl={nft.image.cachedUrl} />
                  )}
                <p
                  className="WalletItemInnerSub"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  {nft.name}
                </p>
                <p
                  className="WalletGalleryItemDesc"
                  style={{ color: globalTheme.figmaPrimaryText.color }}
                >
                  {nft.description !== undefined
                    ? nft.description
                    : nft.contract.openSeaMetadata.description !== undefined &&
                      nft.contract.openSeaMetadata.description}
                </p>
                <div className="WalletItemDivider"></div>
                {nft.contract.openSeaMetadata.floorPrice !== undefined && (
                  <p
                    className="WalletItemInnerFloorPrice"
                    style={{ color: globalTheme.figmaPrimaryText.color }}
                  >
                    <b>Floor price: </b>
                    {nft.contract.openSeaMetadata.floorPrice} ETH
                  </p>
                )}
              </>
              <div className="WalletItemLinks">
                {nft.contract.openSeaMetadata.twitterUsername !== undefined && (
                  <a
                    href={`https://twitter.com/${nft.contract.openSeaMetadata.twitterUsername}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="WalletItemLinksSvg"
                  >
                    <XLogo />
                  </a>
                )}
                {nft.contract.openSeaMetadata.discordUrl !== undefined && (
                  <a
                    href={nft.contract.openSeaMetadata.discordUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="WalletItemLinksSvg"
                  >
                    <DiscordLogo />
                  </a>
                )}
                {nft.contract.address !== undefined &&
                  nft.tokenId !== undefined && (
                    <a
                      href={`https://opensea.io/assets/${nft.contract.address}/${nft.tokenId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="WalletItemLinksSvg"
                    >
                      <OpenseaLogo />
                    </a>
                  )}
              </div>
              {nft.contract.openSeaMetadata.externalUrl !== undefined && (
                <a
                  href={nft.contract.openSeaMetadata.externalUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="WalletItemLinksText"
                >
                  {`${removeUrlPrefix(nft.contract.openSeaMetadata.externalUrl)
                    .charAt(0)
                    .toUpperCase()}${removeUrlPrefix(
                    nft.contract.openSeaMetadata.externalUrl
                  ).slice(1)}`}
                </a>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
