import InstagramLogo from "../img/socials/InstagramLogo";
import FacebookLogo from "../img/socials/FacebookLogo";
import XLogo from "../img/socials/Xlogo";
import TelegramLogo from "../img/socials/TelegramLogo";
import SubstackLogo from "../img/socials/SubstackLogo";
import './Misc.css';

export default function Socials({customColor}: {customColor?: string;}) {
  return (
      <div className="socials">
        <a
          href="https://instagram.com/gath3rco"
          target="_blank"
          rel="noopener noreferrer"
          className="socialLink"
        >
          <InstagramLogo customColor={customColor}/>
        </a>
        <a
          href="https://facebook.com/gath3rco"
          target="_blank"
          rel="noopener noreferrer"
          className="socialLink"
        >
          <FacebookLogo customColor={customColor}/>
        </a>
        <a
          href="https://twitter.com/gath3rco"
          target="_blank"
          rel="noopener noreferrer"
          className="socialLink"
        >
          <XLogo customColor={customColor}/>
        </a>
        <a href="https://T.me/gath3rco "
          target="_blank"
          rel="noopener noreferrer"
          className="socialLink"
        >
          <TelegramLogo customColor={customColor}/>
        </a>
        <a href="https://substack.com/@gath3rco"
          target="_blank"
          rel="noopener noreferrer"
          className="socialLink"
        >
          <SubstackLogo customColor={customColor}/>
        </a>
      </div>
  );
}
