import React, { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import { doc, getDoc } from "firebase/firestore";
import { firestoreDb } from "../../firebase/firestore";
import { useThemeState } from "../../utils/globalStates";
import "./TopBoxes.css";

export default function UserFollowInfo({
  userId,
  viewType,
  subType,
}: {
  userId: string | undefined;
  viewType: string;
  subType: string;
}) {
  const [userInfo, setUserInfo] = useState<any>();
  const [userInfoLoading, setUserInfoLoading] = useState(true);
  const globalTheme = useThemeState((state) => state.globalTheme);

  // Fetch user info from publicUserInfo collection
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        if (userId === undefined) return;
        const docRef = doc(firestoreDb, "publicUserInfo", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserInfo(docSnap.data());
          setUserInfoLoading(false);
        }
      } catch (error) {
        console.error("Error getting document:", error);
        setUserInfoLoading(false);
      }
    };
    fetchUserInfo();
  }, [userId]);

  return (
    <div
      className="SearchAndExplore"
      style={{
        backgroundColor: globalTheme.figmaYellowPastel.color,
        border: `1px solid ${globalTheme.figmaPrimaryText.color}`,
        ...globalTheme.boxShadow,
        maxWidth: "1170px",
      }}
    >
      <div className="SearchAndExploreHeader">
        <p
          className="SearchAndExploreHeaderTitle"
          style={{ color: globalTheme.figmaPrimaryText.color, maxWidth: "95%" }}
        >
          {viewType}
        </p>
        {userInfoLoading ? (
          <Skeleton
            variant="text"
            style={{ width: "40%", fontSize: "16px", marginBottom: "-10px" }}
          />
        ) : (
          <p
            className="SearchAndExploreHeaderSubLarge"
            style={{
              color: globalTheme.figmaPrimaryText.color,
              maxWidth: "95%",
              marginBottom: "-10px",
            }}
          >
            {userInfo !== undefined &&
              subType +
                (subType === "" ? '':' ') +
                (userInfo.displayName)}
          </p>
        )}
      </div>
    </div>
  );
}
