import moment from "moment";
import { useNavigate } from "react-router-dom";

import { Skeleton } from "@mui/material";
import { PfpViewer } from "../../../../components/ui/img/PfpViewer";
import { useThemeState } from "../../../../components/utils/globalStates";
import "./DashWidgetsComp.css";

moment().format();
export default function UsersWidgetItem({
  dataToRender,
  isLink,
}: {
  dataToRender: any;
  isLink: boolean;
}) {
  const navigate = useNavigate();
  const globalTheme = useThemeState((state) => state.globalTheme);

  const onLinkClick = () => {
    if (isLink) {
      navigate(`/users/page/${dataToRender.id}`);
    }
  };
  return dataToRender === null ? (
    <Skeleton
      variant="rectangular"
      style={{
        width: "100%",
        height: "30px",
        borderRadius: "8px",
        marginBottom: "3px",
        marginTop: "3px",
      }}
    />
  ) : (
    <div
      onClick={onLinkClick}
      className="ListingsWidgetItem"
      style={{
        color: globalTheme.figmaPrimaryText.color,
      }}
    >
      {dataToRender.pfpString === null ||
      dataToRender.pfpString === undefined ||
      dataToRender.pfpString === "" ? (
        <div className="UserWidgetItemAvatar">
          <PfpViewer pfpString={""} randomize={true} />
        </div>
      ) : (
        <div className="UserWidgetItemAvatar">
          <PfpViewer pfpString={dataToRender.pfpString} randomize={false} />
        </div>
      )}
      {dataToRender.type === "createdAt" && (
        <div className="ListingsWidgetItemInner">
          <div className="ListingsWidgetItemTitleOuter">
            <p
              className="ListingsWidgetItemTitle"
              style={{
                color: globalTheme.figmaPrimaryText.color,
              }}
            >
              {dataToRender.displayName === ""
                ? "Anonymous user"
                : dataToRender.displayName}
            </p>
          </div>
          <p
            className="ListingsWidgetItemSub"
            style={{
              color: globalTheme.figmaPrimaryTextSub.color,
            }}
          >
            Joined GATH3R {moment(dataToRender.createdAt).fromNow()}
          </p>
        </div>
      )}
      {dataToRender.type === "points" && (
        <div className="ListingsWidgetItemInner">
          <div className="ListingsWidgetItemTitleOuter">
            <p
              className="ListingsWidgetItemTitle"
              style={{
                color: globalTheme.figmaPrimaryText.color,
              }}
            >
              {dataToRender.displayName === ""
                ? "Anonymous user"
                : dataToRender.displayName}
              <span
                style={{
                  color: globalTheme.figmaPrimaryTextSub.color,
                  marginLeft: "5px",
                  fontSize: "75%",
                }}
              >
                {dataToRender.points} Points
              </span>
            </p>
          </div>
          <div
            className="ListingsWidgetItemTitleOuter"
            style={{ marginBottom: "0px", alignItems: "center" }}
          >
            <p
              className="RatingItemRatingText"
              style={{
                color: globalTheme.figmaPrimaryTextSub.color,
                marginLeft: "0px",
              }}
            >
              {dataToRender.listingCount} Listings -{" "}
            </p>
            <p
              className="RatingItemRatingText"
              style={{
                color: globalTheme.figmaPrimaryTextSub.color,
                marginLeft: "0px",
              }}
            >
              {dataToRender.ratingCount} Ratings -{" "}
            </p>
            <p
              className="RatingItemRatingText"
              style={{
                color: globalTheme.figmaPrimaryTextSub.color,
                marginLeft: "3px",
              }}
            >
              {dataToRender.reviewsCount} Reviews
            </p>
          </div>
        </div>
      )}
      {dataToRender.type === "latestHourPoints" && (
        <div className="ListingsWidgetItemInner">
          <p
            className="ListingsWidgetItemTitle"
            style={{
              color: globalTheme.figmaPrimaryText.color,
            }}
          >
            {dataToRender.displayName === ""
              ? "Anonymous user"
              : dataToRender.displayName}
            <span
              style={{
                color: globalTheme.figmaPrimaryTextSub.color,
                marginLeft: "5px",
                fontSize: "75%",
              }}
            >
              {dataToRender.pointsHour} Points last hour
            </span>
          </p>
          <div
            className="ListingsWidgetItemTitleOuter"
            style={{ marginBottom: "0px", alignItems: "center" }}
          >
            <p
              className="RatingItemRatingText"
              style={{
                color: globalTheme.figmaPrimaryTextSub.color,
                marginLeft: "0px",
              }}
            >
              {dataToRender.ratingCount} Ratings
            </p>
            <p
              className="RatingItemRatingText"
              style={{
                color: globalTheme.figmaPrimaryTextSub.color,
                marginLeft: "3px",
              }}
            >
              {dataToRender.reviewsCount} Reviews
            </p>
          </div>
        </div>
      )}
      {dataToRender.type === "latestDayPoints" && (
        <div className="ListingsWidgetItemInner">
          <p
            className="ListingsWidgetItemTitle"
            style={{
              color: globalTheme.figmaPrimaryText.color,
            }}
          >
            {dataToRender.displayName === ""
              ? "Anonymous user"
              : dataToRender.displayName}
            <span
              style={{
                color: globalTheme.figmaPrimaryTextSub.color,
                marginLeft: "5px",
                fontSize: "75%",
              }}
            >
              {dataToRender.pointsDay} Points last day
            </span>
          </p>
          <div
            className="ListingsWidgetItemTitleOuter"
            style={{ marginBottom: "0px", alignItems: "center" }}
          >
            <p
              className="RatingItemRatingText"
              style={{
                color: globalTheme.figmaPrimaryTextSub.color,
                marginLeft: "0px",
              }}
            >
              {dataToRender.ratingCount} Ratings
            </p>
            <p
              className="RatingItemRatingText"
              style={{
                color: globalTheme.figmaPrimaryTextSub.color,
                marginLeft: "3px",
              }}
            >
              {dataToRender.reviewsCount} Reviews
            </p>
          </div>
        </div>
      )}
      {dataToRender.type === "latestWeekPoints" && (
        <div className="ListingsWidgetItemInner">
          <p
            className="ListingsWidgetItemTitle"
            style={{
              color: globalTheme.figmaPrimaryText.color,
            }}
          >
            {dataToRender.displayName === ""
              ? "Anonymous user"
              : dataToRender.displayName}
            <span
              style={{
                color: globalTheme.figmaPrimaryTextSub.color,
                marginLeft: "5px",
                fontSize: "75%",
              }}
            >
              {dataToRender.pointsWeek} Points last week
            </span>
          </p>
          <div
            className="ListingsWidgetItemTitleOuter"
            style={{ marginBottom: "0px", alignItems: "center" }}
          >
            <p
              className="RatingItemRatingText"
              style={{
                color: globalTheme.figmaPrimaryTextSub.color,
                marginLeft: "0px",
              }}
            >
              {dataToRender.ratingCount} Ratings
            </p>
            <p
              className="RatingItemRatingText"
              style={{
                color: globalTheme.figmaPrimaryTextSub.color,
                marginLeft: "3px",
              }}
            >
              {dataToRender.reviewsCount} Reviews
            </p>
          </div>
        </div>
      )}
      {dataToRender.type === "lastActive" && (
        <div className="ListingsWidgetItemInner">
          <div className="ListingsWidgetItemTitleOuter">
            <p
              className="ListingsWidgetItemTitle"
              style={{
                color: globalTheme.figmaPrimaryText.color,
              }}
            >
              {dataToRender.displayName === ""
                ? "Anonymous user"
                : dataToRender.displayName}
            </p>
          </div>
          <p
            className="ListingsWidgetItemSub"
            style={{
              color: globalTheme.figmaPrimaryTextSub.color,
            }}
          >
            Active {moment(dataToRender.lastActive).fromNow()}
          </p>
        </div>
      )}
    </div>
  );
}
