import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { categoryEnums } from "../../../components/utils/enums";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../../components/firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { firestoreDb } from "../../../components/firebase/firestore";
import {
  useSnackbarState,
  useSnackbarErrorState,
  useThemeState,
} from "../../../components/utils/globalStates";
import { materialListingFormStyles } from "../../../components/theme/globalMuiTheme";
import "./ListingDialogs.css";

export default function AdminNewListingDialog({ closeDialog }: any) {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const snackbarState = useSnackbarState();
  const snackbarErrorState = useSnackbarErrorState();
  const globalTheme = useThemeState((state) => state.globalTheme);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [website, setWebsite] = useState("");
  const [category, setCategory] = useState("");
  const [xHandle, setXHandle] = useState("");
  const [instagramHandle, setInstagramHandle] = useState("");
  const [telegramHandle, setTelegramHandle] = useState("");
  const [mediumHandle, setMediumHandle] = useState("");
  const [substackHandle, setSubstackHandle] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [logo, setLogo] = useState("");
  const [keywordsWithCommas, setKeywordsWithCommas] = useState("");

  const [isUpdating, setIsUpdating] = useState(false);

  // Add listing to database
  const onAddListing = async () => {
    try {
      setIsUpdating(true);
      const tempId = name.replace(/\s/g, "-").toLowerCase();
      const whitespaceRemoved = keywordsWithCommas.replace(/\s/g, "");
      const tempDate = Date.now();
      await setDoc(doc(firestoreDb, "listings", tempId), {
        name: name,
        description: description,
        website: website,
        category: category,
        xHandle: xHandle,
        instagramHandle: instagramHandle,
        telegramHandle: telegramHandle,
        mediumHandle: mediumHandle,
        substackHandle: substackHandle,
        facebookUrl: facebookUrl,
        logo: logo,
        tags: whitespaceRemoved.split(","),
        id: tempId,
        createdBy: user?.uid,
        createdAt: tempDate,
        updatedAt: tempDate,
        owner: "",
        token: "",
        isVerified: true,
        viewsTotal: 0,
      });
      setIsUpdating(false);
      snackbarState.setOpenLength(3000);
      snackbarState.setMessage('Listing "' + tempId + '" added.');
      snackbarState.setIsOpen(true);
      closeDialog(false);
    } catch (err) {
      console.error(err);
      setIsUpdating(false);
      snackbarErrorState.setOpenLength(5000);
      snackbarErrorState.setMessage("Error adding listing!");
      snackbarErrorState.setIsOpen(true);
    }
  };
  return (
    <div className="AdminListDialog">
      <p
        style={{ ...globalTheme.altTextColorDarker }}
        className="AdminListDialogTitle"
      >
        Add new verified listing
      </p>
      <p
        className="AdminDialogDescription"
        style={{ ...globalTheme.altTextColorDarker }}
      >
        Category, name, website and description are required.
      </p>
      <div className="AdminListDialogForms">
        <TextField
          label="Category"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={category}
          SelectProps={{ MenuProps: { sx: { zIndex: 9999 } } }}
          onChange={(e) => setCategory(e.target.value)}
          select
          InputProps={{
            endAdornment: category !== "" && (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.altTextColorDarker, marginRight: "5%" }}
                onClick={() => setCategory("")}
              >
                RESET
              </Button>
            ),
          }}
        >
          {categoryEnums.map((catEnum) => (
            <MenuItem key={catEnum.value} value={catEnum.displayname}>
              {catEnum.displayname}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={name}
          placeholder="Name"
          onChange={(e) => setName(e.target.value)}
          InputProps={{
            endAdornment: name !== "" && (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.altTextColorDarker }}
                onClick={() => setName("")}
              >
                RESET
              </Button>
            ),
          }}
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={description}
          placeholder="Description"
          multiline
          minRows={4}
          onChange={(e) => setDescription(e.target.value)}
          InputProps={{
            endAdornment: description !== "" && (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.altTextColorDarker }}
                onClick={() => setDescription("")}
              >
                RESET
              </Button>
            ),
          }}
        />
        <TextField
          label="Website"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={website}
          placeholder="Website"
          onChange={(e) => setWebsite(e.target.value)}
          InputProps={{
            endAdornment: website !== "" && (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.altTextColorDarker }}
                onClick={() => setWebsite("")}
              >
                RESET
              </Button>
            ),
          }}
        />
        <TextField
          label="Logo URL"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={logo}
          onChange={(e) => setLogo(e.target.value)}
          InputProps={{
            endAdornment: logo !== "" && (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.altTextColorDarker }}
                onClick={() => setLogo("")}
              >
                RESET
              </Button>
            ),
          }}
        />
        <TextField
          label="X Handle"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={xHandle}
          onChange={(e) => setXHandle(e.target.value)}
          InputProps={{
            endAdornment: xHandle !== "" && (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.altTextColorDarker }}
                onClick={() => setXHandle("")}
              >
                RESET
              </Button>
            ),
          }}
        />
        <TextField
          label="Instagram Handle"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={instagramHandle}
          onChange={(e) => setInstagramHandle(e.target.value)}
          InputProps={{
            endAdornment: instagramHandle !== "" && (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.altTextColorDarker }}
                onClick={() => setInstagramHandle("")}
              >
                RESET
              </Button>
            ),
          }}
        />
        <TextField
          label="Medium Handle"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={mediumHandle}
          onChange={(e) => setMediumHandle(e.target.value)}
          InputProps={{
            endAdornment: mediumHandle !== "" && (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.altTextColorDarker }}
                onClick={() => setMediumHandle("")}
              >
                RESET
              </Button>
            ),
          }}
        />
        <TextField
          label="Telegram Handle"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={telegramHandle}
          onChange={(e) => setTelegramHandle(e.target.value)}
          InputProps={{
            endAdornment: telegramHandle !== "" && (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.altTextColorDarker }}
                onClick={() => setTelegramHandle("")}
              >
                RESET
              </Button>
            ),
          }}
        />
        <TextField
          label="Facebook URL"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={facebookUrl}
          onChange={(e) => setFacebookUrl(e.target.value)}
          InputProps={{
            endAdornment: facebookUrl !== "" && (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.altTextColorDarker }}
                onClick={() => setFacebookUrl("")}
              >
                RESET
              </Button>
            ),
          }}
        />
        <TextField
          label="Substack Handle"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={substackHandle}
          onChange={(e) => setSubstackHandle(e.target.value)}
          InputProps={{
            endAdornment: substackHandle !== "" && (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.altTextColorDarker }}
                onClick={() => setSubstackHandle("")}
              >
                RESET
              </Button>
            ),
          }}
        />
        <TextField
          label="Tags - Separate with commas"
          variant="outlined"
          fullWidth
          size="small"
          sx={{ ...materialListingFormStyles, marginBottom: "20px" }}
          value={keywordsWithCommas}
          onChange={(e) => setKeywordsWithCommas(e.target.value)}
          InputProps={{
            endAdornment: keywordsWithCommas !== "" && (
              <Button
                variant="text"
                size="small"
                sx={{ ...globalTheme.altTextColorDarker }}
                onClick={() => setKeywordsWithCommas("")}
              >
                RESET
              </Button>
            ),
          }}
        />
        <div
          className="AdminListDialogButtons"
          style={{ ...globalTheme.textColor }}
        >
          <Button
            variant="outlined"
            color="inherit"
            sx={{ marginRight: "0px", fontSize: "12px" }}
            onClick={() => closeDialog(false)}
            disabled={isUpdating}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            sx={{ marginRight: "0px", fontSize: "12px" }}
            onClick={onAddListing}
            disabled={isUpdating || name === "" || description === "" || website === "" || category === ""}
          >
            {isUpdating ? "Adding..." : "Add"}
          </Button>
        </div>
      </div>
    </div>
  );
}