import React from "react";
import "./Badges.css";

function ProjectVerifiedBadge() {
  return (
    <svg
      className="StandardBadge"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 34 40"
    >
      <g clipPath="url(#clip0_210_9972)">
        <path
          fill="#F7D100"
          d="M33.98 9.1a3.274 3.274 0 00-2.574-3.137c-4.49-.966-8.692-2.714-12.553-5.384a3.268 3.268 0 00-3.715 0C11.277 3.25 7.074 4.997 2.585 5.962A3.274 3.274 0 00.011 9.1C-.273 22.352 4.784 32.47 15.182 39.45a3.259 3.259 0 003.626 0C29.207 32.469 34.264 22.352 33.98 9.1zM16.995 29.62a5.962 5.962 0 005.954-5.954v-4.733h-6.171a2.597 2.597 0 00-2.594 2.594v.61a2.594 2.594 0 105.186 0v-1.571h1.622v1.57a4.22 4.22 0 01-4.214 4.217 4.221 4.221 0 01-4.216-4.216v-.61a4.22 4.22 0 014.216-4.216h7.793v6.355c0 4.176-3.397 7.575-7.576 7.575-4.178 0-7.575-3.399-7.575-7.575v-7.33c0-4.178 3.397-7.578 7.575-7.578 4.179 0 7.576 3.4 7.576 7.578H22.95a5.96 5.96 0 00-5.954-5.953 5.96 5.96 0 00-5.953 5.953v7.33a5.962 5.962 0 005.953 5.953z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_210_9972">
          <path fill="#fff" d="M0 0H33.991V40H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ProjectVerifiedBadge;
