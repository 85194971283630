import React, { useState, useEffect } from "react";
import {
  getDocs,
  where,
  limit,
  orderBy,
  OrderByDirection,
  collection,
  query,
  doc,
  getDoc,
} from "firebase/firestore";
import { Helmet } from "react-helmet-async";
import { Link, useParams, useNavigate } from "react-router-dom";
import { CircularProgress, Skeleton } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import IconButton from "@mui/material/IconButton";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import moment from "moment";

// Dialog
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Radio from "@mui/material/Radio";

import {
  useSnackbarErrorState,
  useHeaderSettingsState,
  useTabSettingsState,
  useThemeState,
} from "../../../components/utils/globalStates";

import { firestoreDb } from "../../../components/firebase/firestore";
import RatingListItemNew from "../../../components/ui/listItems/RatingListItemNew";
import AddReviewItem from "../../listings/components/AddReviewItem";
import ListingReviewsListInfo from "../../../components/ui/topBoxes/ListingReviewsListInfo";
import "./Reviews.css";
import "../../user/list/UserList.css";

moment().format();

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RatingsListing() {
  const { listingId } = useParams();
  const navigate = useNavigate();
  const snackbarError = useSnackbarErrorState();
  const headerSettingsState = useHeaderSettingsState();
  const tabSettingsState = useTabSettingsState();
  const globalTheme = useThemeState((state) => state.globalTheme);

  const [sortType, setSortType] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState<OrderByDirection>("desc");
  const [loading, setLoading] = useState(true);
  const [listings, setListings] = useState<any[]>([]);
  const [sortDialogOpen, setSortDialogOpen] = useState(false);
  const [listingInfo, setListingInfo] = useState<any | null>(null);
  const [listingName, setListingName] = useState("");

  useEffect(() => {
    if (listingId === undefined || listingId === null) {
      snackbarError.setMessage("Listing not found");
      snackbarError.setOpenLength(4000);
      snackbarError.setIsOpen(true);
      return navigate("/reviews");
    }
  }, [listingId]);

  // Load all listings made by user
  useEffect(() => {
    setLoading(true);
    const q = query(
      collection(firestoreDb, "reviews"),
      where("listing", "==", listingId),
      where("review", "==", false),
      limit(100),
      orderBy(sortType, sortOrder)
    );
    const loadListings = async () => {
      try {
        const querySnapshot = await getDocs(q);
        let tempArray: any[] = [];
        querySnapshot.forEach((doc) => {
          tempArray.push(doc.data());
        });
        setListings(tempArray);
        setLoading(false);
      } catch (error) {
        console.error("Error getting documents: ", error);
        setLoading(false);
      }
    };
    loadListings();
  }, [sortType, sortOrder]);

  // Fetch listing info from listings collection
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        if (listingId === undefined) return;
        const docRef = doc(firestoreDb, "listings", listingId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setListingInfo(docSnap.data());
          setListingName(docSnap.data().name);
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }
    };
    fetchUserInfo();
  }, [listingId]);

  useEffect(() => {
    headerSettingsState.setIsBackButtonOpen(true);
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.backgroundMainBeige.color);
    return () => {
      headerSettingsState.setIsBackButtonOpen(false);
      tabSettingsState.setColor(globalTheme.backgroundMainYellow.color);
      tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      headerSettingsState.setIsLogoOpen(false);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  const styles = {
    dialogRoot: {
      zIndex: 9999,
      backgroundColor: "rgba(253, 247, 234, 0.5)",
      "& .MuiDialog-paper": {
        backgroundColor: globalTheme.backgroundMainBeige.color,
        color: globalTheme.backgroundMainOffBeige.color,
        width: "94%",
        maxWidth: "600px",
        margin: 0,
        paddingBottom: "70px",
        paddingTop: "20px",
        position: "absolute",
        bottom: "-10px",
      },
    },
    dialogTitle: {
      color: globalTheme.backgroundMainOffBeige.color,
      textAlign: "center",
      fontSize: "16px",
      marginTop: "20px",
      fontWeight: 600,
    },
    radio: {
      color: globalTheme.backgroundMainOffBeige.color,
      "&.Mui-checked": {
        color: globalTheme.backgroundMainOffBeige.color,
      },
    },
  };

  return (
    <div
      className="Reviews"
      style={{
        paddingTop: "80px",
        backgroundColor: globalTheme.backgroundMainBeige.color,
      }}
    >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Project ratings</title>
      </Helmet>
      <ListingReviewsListInfo viewType="Ratings" listingName={listingName} />
      <div className="ReviewsListTabs" style={{maxWidth: '1200px'}}>
        <Link
          to={`/reviews/listing/${listingId}`}
          style={{
            textDecoration: "none",
            width: "50%",
            paddingTop: "4px",
            paddingBottom: "5px",
            border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
            borderRightWidth: "0.5px",
            borderRadius: "8px 0px 0px 8px",
            boxShadow: globalTheme.boxShadow.boxShadow,
          }}
        >
          <div
            className="ListingsPageTabsInner"
            style={{
              color: globalTheme.backgroundMainOffYellow.color,
              backgroundColor: "transparent",
            }}
          >
            <RateReviewOutlinedIcon
              color="inherit"
              sx={{
                fontSize: "16px",
                marginRight: "5px",
                marginTop: "2px",
                "@media (min-width: 350px)": {
                  fontSize: "17px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "18px",
                },
                "@media (min-width: 700px)": {
                  fontSize: "19px",
                },
              }}
            />

            <p
              className="ListingsPageTabsText"
              style={{ textDecoration: "none" }}
            >
              Reviews
            </p>
          </div>
        </Link>
        <div
          style={{
            width: "50%",
            backgroundColor: globalTheme.solidMainYellow.color,
            paddingTop: "5px",
            paddingBottom: "5px",
            border: `1px solid ${globalTheme.backgroundMainOffYellow.color}`,
            borderLeftWidth: "0.5px",
            borderRadius: "0px 8px 8px 0px",
            boxShadow: globalTheme.boxShadow.boxShadow,
          }}
        >
          <div
            className="ListingsPageTabsInner"
            style={{
              color: globalTheme.solidMainBlack.color,
            }}
          >
            <StarBorderOutlinedIcon
              color="inherit"
              sx={{
                fontSize: "17px",
                marginRight: "5px",
                "@media (min-width: 350px)": {
                  fontSize: "18px",
                },
                "@media (min-width: 550px)": {
                  fontSize: "19px",
                },
                "@media (min-width: 700px)": {
                  fontSize: "20px",
                },
              }}
            />
            <p className="ListingsPageTabsText">Ratings</p>
          </div>
        </div>
      </div>
      <div
        className="UserListSort"
        style={{ marginBottom: "0px", marginTop: "20px" }}
      >
        {listingInfo ? (
          <AddReviewItem listing={listingInfo} />
        ) : (
          <Skeleton variant="rectangular" width="100%" height="50px" />
        )}
      </div>
      <div className="UserListSort">
        <ButtonBase
          className="UserListSortButton"
          onClick={() => {
            setSortDialogOpen(true);
          }}
        >
          <div className="UserListSortInner">
            <p
              className="UserListSortTitle"
              style={{ color: globalTheme.textMainGrey.color }}
            >
              Sort by
            </p>
            <p
              className="UserListSortTitle"
              style={{
                color: globalTheme.backgroundMainOffBeige.color,
                marginLeft: "5px",
                fontWeight: 500,
              }}
            >
              {sortType === "createdAt" ? "Latest" : "Rating"}
            </p>
          </div>
        </ButtonBase>
        <IconButton
          size="small"
          onClick={() => {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
          }}
        >
          <SwapVertOutlinedIcon
            sx={{
              color: globalTheme.backgroundMainOffBeige.color,
              fontSize: "22px",
            }}
          />
        </IconButton>
      </div>
      {loading ? (
        <div className="UserListLoading">
          <CircularProgress
            sx={{ color: globalTheme.backgroundMainOffBeige.color }}
          />
        </div>
      ) : listings.length > 0 ? (
        <div className="UserListInner" style={{ marginTop: "5px" }}>
          {listings.map((reviewItem, index) => {
            return (
              <RatingListItemNew
                key={index}
                  listItem={reviewItem}
                  isListing={false}
                />
            );
          })}
          <Link
            to={`/projects/${listingId}`}
            style={{ textDecoration: "none", width: '100%'  }}
          >
            <p
              className="UserReviewsLinkBack"
              style={{ color: globalTheme.figmaHighlight.color }}
            >
              Back to listing
            </p>
          </Link>
        </div>
      ) : (
        <div className="UserListLoading">
          <p
            className="UserListSortTitle"
            style={{ color: globalTheme.backgroundMainOffBeige.color, width: '100%'  }}
          >
            Listing has no reviews yet...
          </p>
          <Link
            to={`/projects/${listingId}`}
            style={{ textDecoration: "none", width: '100%'  }}
          >
            <p
              className="UserReviewsLinkBack"
              style={{ color: globalTheme.figmaHighlight.color }}
            >
              Back to listing
            </p>
          </Link>
        </div>
      )}
      <Dialog
        open={sortDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSortDialogOpen(false)}
        sx={styles.dialogRoot}
      >
        <DialogTitle sx={styles.dialogTitle}>{"Sort by"}</DialogTitle>
        <DialogContent>
          <div className="UserListSortDialog">
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                setSortType("createdAt");
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Latest
                </p>
                <Radio checked={sortType === "createdAt"} sx={styles.radio} />
              </div>
            </ButtonBase>
            <ButtonBase
              sx={{ width: "90%" }}
              onClick={() => {
                setSortType("rating");
                setSortDialogOpen(false);
              }}
            >
              <div className="UserListSortDialogInner">
                <p
                  className="UserListSortDialogInnerText"
                  style={{ color: globalTheme.backgroundMainOffBeige.color }}
                >
                  Rating
                </p>
                <Radio checked={sortType === "rating"} sx={styles.radio} />
              </div>
            </ButtonBase>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
