import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  getDocs,
  orderBy,
  OrderByDirection,
  query,
  collection,
} from "firebase/firestore";
import IconButton from "@mui/material/IconButton";
import { CircularProgress } from "@mui/material";
import { Link} from "react-router-dom";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";

import AchievementsListItem from "../components/AchievementsListItem";
import AchievementsInfo from "../../../components/ui/topBoxes/AchievementsInfo";
import {
  useHeaderSettingsState,
  useTabSettingsState,
  useThemeState,
} from "../../../components/utils/globalStates";
import { firestoreDb } from "../../../components/firebase/firestore";
import "./AchievementsList.css";

export default function AchievementsList() {
  const headerSettingsState = useHeaderSettingsState();
  const [sortOrder, setSortOrder] = useState<OrderByDirection>("desc");
  const tabSettingsState = useTabSettingsState();
  const globalTheme = useThemeState((state) => state.globalTheme);
  const [loading, setLoading] = useState(true);
  const [achievementsArr, setAchievementsArr] = useState<any[]>([]);

  useEffect(() => {
    tabSettingsState.setColor(globalTheme.backgroundMainBeige.color);
    tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
    headerSettingsState.setLogoBGColor(globalTheme.backgroundMainBeige.color);
    headerSettingsState.setLogoColor(globalTheme.backgroundMainOffBeige.color);
    headerSettingsState.setIsLogoOpen(true);
    // @ts-ignore
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute("content", globalTheme.backgroundMainBeige.color);
    return () => {
      tabSettingsState.setColor(globalTheme.backgroundMainYellow.color);
      tabSettingsState.setIconColor(globalTheme.backgroundMainOffYellow.color);
      headerSettingsState.setLogoBGColor(
        globalTheme.backgroundMainYellow.color
      );
      headerSettingsState.setLogoColor(
        globalTheme.backgroundMainOffYellow.color
      );
      headerSettingsState.setIsLogoOpen(false);
      // @ts-ignore
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", globalTheme.solidMainBlack.color);
    };
  }, [globalTheme]);

  // Fetch achievements
  useEffect(() => {
    const fetchAchievements = async () => {
      const achievementsRef = collection(firestoreDb, "achievements");
      const q = query(achievementsRef, orderBy("count", sortOrder));
      const querySnapshot = await getDocs(q);
      const tempArr: any[] = [];
      querySnapshot.forEach((doc) => {
        tempArr.push(doc.data());
      });
      setAchievementsArr(tempArr);
      setLoading(false);
    };
    fetchAchievements();
  }, [sortOrder]);

  return (
    <div
      className="AchievementsList"
      style={{ backgroundColor: globalTheme.backgroundMainBeige.color }}
    >
      <Helmet prioritizeSeoTags>
        <title>GATH3R - Achievements</title>
      </Helmet>
      <AchievementsInfo />
      <div className="ReviewsListTabs">
        <Link
          to={"/achievements"}
          style={{
            textDecoration: "none",
            width: "50%",
            paddingTop: "4px",
            paddingBottom: "5px",
            border: `1px solid ${globalTheme.coreOrangeOffAnti.color}`,
            borderRightWidth: "0.5px",
            borderRadius: "8px 0px 0px 8px",
          }}
        >
          <div
            className="ListingsPageTabsInner"
            style={{
              color: globalTheme.coreOrangeOffAnti.color,
              backgroundColor: "transparent",
            }}
          >
            <p
              className="ListingsPageTabsText"
              style={{ textDecoration: "none" }}
            >
              My Achievements
            </p>
          </div>
        </Link>
        <div
          style={{
            width: "50%",
            backgroundColor: globalTheme.coreOrange.color,
            paddingTop: "4px",
            paddingBottom: "5px",
            border: `1px solid ${globalTheme.coreOrangeOffAnti.color}`,
            borderLeftWidth: "0.5px",
            borderRadius: "0px 8px 8px 0px",
          }}
        >
          <div
            className="ListingsPageTabsInner"
            style={{
              color: globalTheme.solidMainBlack.color,
            }}
          >
            <p className="ListingsPageTabsText"> All Achievements</p>
          </div>
        </div>
      </div>
      <div className="UserListSort" style={{ justifyContent: "flex-end" }}>
        <IconButton
          size="small"
          onClick={() => {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
          }}
        >
          <SwapVertOutlinedIcon
            sx={{
              color: globalTheme.coreOrangeOffAnti.color,
              fontSize: "22px",
            }}
          />
        </IconButton>
      </div>
      {loading ? (
        <div className="AchievementsListLoading">
          <CircularProgress
            style={{ color: globalTheme.coreOrangeOffAnti.color }}
          />
        </div>
      ) : achievementsArr.length > 0 ? (
        <div className="AchievementsListInner">
          {achievementsArr.map((achievement, index) => {
            return <AchievementsListItem key={index} listItem={achievement} />;
          })}
        </div>
      ) : (
        <div className="AchievementsListLoading">
          <p
            className="UserListSortTitle"
            style={{ color: globalTheme.coreOrangeOffAnti.color }}
          >
            No achievements yet
          </p>
        </div>
      )}
    </div>
  );
}
