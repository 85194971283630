import React, { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import {
  query,
  collection,
  where,
  getDocs,
  limit,
} from "firebase/firestore";

import RatingListItem from "../../../reviews/components/RatingListItem";
import ReviewItem from "../../../reviews/components/ReviewItem";
import { firestoreDb } from "../../../../components/firebase/firestore";
import { useThemeState } from "../../../../components/utils/globalStates";
import "./ProfileStartComp.css";

moment().format();
let initLoading = false;

export default function ProfileReviewPreview({ userId }: { userId: string }) {
  const [isLoading, setIsLoading] = useState(true);
  const [reviews, setReviews] = useState<any[]>([]);
  const globalTheme = useThemeState((state) => state.globalTheme);

  useEffect(() => {
    if (userId === null || userId === undefined) {
      return;
    }
    if (initLoading) return;
    initLoading = true;
    const getData = async () => {
      try {
        const reviewsQ = query(
          collection(firestoreDb, "reviews"),
          where("uid", "==", userId),
          limit(10)
        );
        const reviewSnap = await getDocs(reviewsQ);
        const reviewsData: any[] = [];
        reviewSnap.forEach((doc) => {
          reviewsData.push(doc.data());
        });
        setReviews(reviewsData);
        setIsLoading(false);
        initLoading = false;
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        initLoading = false;
      }
    };
    getData();
  }, [userId]);
  return (
    <div
      className="ProfileReviewsOuter"
      style={{ backgroundColor: globalTheme.backgroundMainBeige.color }}
    >
      {isLoading ? (
        <div className="ProfileReviewsItem">
          <Skeleton
            variant="text"
            style={{ fontSize: "18px", marginBottom: "5px", width: "90px" }}
          />
          <Skeleton
            variant="text"
            style={{ fontSize: "14px", marginBottom: "10px", width: "50px" }}
          />
          <Skeleton
            variant="text"
            style={{ fontSize: "16px", marginBottom: "10px", width: "70px" }}
          />
          <Skeleton
            variant="text"
            style={{ fontSize: "15px", marginBottom: "3px", width: "100%" }}
          />
          <Skeleton
            variant="text"
            style={{ fontSize: "15px", marginBottom: "3px", width: "100%" }}
          />
          <Skeleton
            variant="text"
            style={{ fontSize: "15px", marginBottom: "3px", width: "100%" }}
          />
          <Skeleton
            variant="text"
            style={{ fontSize: "15px", marginBottom: "3px", width: "100%" }}
          />
          <Skeleton
            variant="text"
            style={{ fontSize: "15px", marginBottom: "3px", width: "100%" }}
          />
          <Skeleton
            variant="text"
            style={{ fontSize: "15px", marginBottom: "3px", width: "100%" }}
          />
        </div>
      ) : (
        <div className="ProfileReviews">
          {reviews.length > 0 ? (
            <div className="ProfileAchivementsInner">
              {reviews.map((reviewItem, index) => {
                if (reviewItem.review === true) {
                  return (
                    <ReviewItem
                      key={index}
                      reviewItem={reviewItem}
                      isListing={false}
                      isUser={true}
                    />
                  );
                } else {
                  return (
                    <RatingListItem
                      key={index}
                      reviewItem={reviewItem}
                      isListing={false}
                      isUser={true}
                    />
                  );
                }
              })}
            </div>
          ) : (
            <div className="ProfileAchivementsInner">
              <p
                className="ProfileCountSub"
                style={{ color: globalTheme.backgroundMainOffBeigeDark.color }}
              >
                No reviews yet
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
