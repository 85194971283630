import { LazyLoadImg } from "../../../components/ui/misc/LazyLoadImg";
import moment from "moment";
import { useThemeState } from "../../../components/utils/globalStates";
import "./AchievementsProfile.css";

moment().format();

export default function AchievementsProgressItem({
  achievementData,
}: {
  achievementData: any;
}) {
  const globalTheme = useThemeState((state) => state.globalTheme);
  return (
    <div className="AchievementsInventoryItem">
      <div className="AchievementsInventoryItemBadge">
        {achievementData.url !== null &&
        achievementData.url !== undefined &&
        achievementData.url !== "" ? (
          <div className="AchievementsInventoryItemImg">
            <LazyLoadImg imgUrl={achievementData.url} />
          </div>
        ) : (
          <span
            style={{
              marginTop: "0px",
              color: globalTheme.figmaPrimaryText.color,
            }}
            className="material-symbols-outlined"
          >
            {achievementData.icon}
          </span>
        )}
      </div>
      <div className="AchievementsInventoryItemMiddle">
        <p
          className="AchievementsInventoryItemMiddleTitle"
          style={{
            color: globalTheme.figmaPrimaryText.color,
          }}
        >
          {achievementData.name}
        </p>
        <p
          className="AchievementsInventoryItemMiddleSub"
          style={{
            color: globalTheme.figmaPrimaryTextSub.color,
          }}
        >
          {achievementData.subtitle}
        </p>
      </div>
      <div className="AchievementsInventoryItemLast">
        {achievementData.progress === 100 ? (
          <p
            className="AchievementsInventoryItemLastText"
            style={{
              color: globalTheme.figmaPrimaryText.color,
              fontWeight: "600",
            }}
          >
            Fulfilled
          </p>
        ) : (
          <p
            className="AchievementsInventoryItemLastText"
            style={{
              color: globalTheme.figmaPrimaryText.color,
              fontWeight: "600",
            }}
          >
            {achievementData.progress}% Completed
          </p>
        )}
        <p
          className="AchievementsInventoryItemLastText"
          style={{
            color: globalTheme.figmaPrimaryTextSub.color,
          }}
        >
          {achievementData.points} points
        </p>
      </div>
    </div>
  );
}
